import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ProjectRepository} from './project-repository.model';
import * as ProjectRepositoryActions from './project-repository.actions';

export const projectRepositoriesFeatureKey = 'ProjectRepositories';

export interface State extends EntityState<ProjectRepository> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ProjectRepository> = createEntityAdapter<ProjectRepository>();

// export const adapter: EntityAdapter<ProjectRepository> = createEntityAdapter<ProjectRepository>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const projectRepositoryRepositoryReducer = createReducer(
  initialState,
  on(ProjectRepositoryActions.addProjectRepository,
    (state, action) => adapter.addOne(action.projectRepository, state)
  ),
  on(ProjectRepositoryActions.upsertProjectRepository,
    (state, action) => adapter.upsertOne(action.projectRepository, state)
  ),
  on(ProjectRepositoryActions.addProjectRepositories,
    (state, action) => adapter.addMany(action.projectRepositories, state)
  ),
  on(ProjectRepositoryActions.upsertProjectRepositories,
    (state, action) => adapter.upsertMany(action.projectRepositories, state)
  ),
  on(ProjectRepositoryActions.updateProjectRepository,
    (state, action) => adapter.updateOne(action.projectRepository, state)
  ),
  on(ProjectRepositoryActions.updateProjectRepositories,
    (state, action) => adapter.updateMany(action.projectRepositories, state)
  ),
  on(ProjectRepositoryActions.deleteProjectRepository,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ProjectRepositoryActions.deleteProjectRepositories,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ProjectRepositoryActions.loadProjectRepositories,
    (state, action) => adapter.setAll(action.projectRepositories, state)
  ),
  on(ProjectRepositoryActions.clearProjectRepositories,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return projectRepositoryRepositoryReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllProjectRepositories = selectAll;
export const getProjectRepositoryById = selectIds;
export const getProjectRepositoryEntities = selectEntities;
