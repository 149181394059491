<ng-container *ngIf="project$ | async as project">
  <app-page-title [title]="title">
    <ng-template>
      <div class="col-md-12 my-3 text-end">

        <!--      <button (click)="actionRecommendation(project)"  class="btn-sm mx-1" color="primary" mat-raised-button>-->
        <!--        <mat-icon class="material-symbols-outlined">add</mat-icon>-->
        <!--        Add Recommendation-->
        <!--      </button>-->

        <button (click)="actionChallengeAndWayForward(project)" class="btn-sm mx-1" color="primary" mat-raised-button>
          <mat-icon class="material-symbols-outlined">add</mat-icon>
          {{'Add Challenge & Way Forward' | concat | translate}}
        </button>

        <button (click)="componentAction(project, null)"  class="btn-sm mx-1" color="primary" mat-raised-button>
          <mat-icon class="material-symbols-outlined">add</mat-icon>
          {{'Add Component' | concat | translate}}
        </button>

        <button [matMenuTriggerFor]="viewMenu" class="btn-sm mx-1" color="primary" mat-raised-button>
          <mat-icon class="material-symbols-outlined">menu</mat-icon>
          {{'View' | concat | translate}}
        </button>

        <mat-menu #viewMenu="matMenu">

          <button (click)="viewProgress(project)" mat-menu-item> {{'View Progress Report ' | concat | translate}}</button>

          <button *ngIf="loggedInUserType === userTypeEnum.Staff" mat-menu-item routerLink="/project/{{ project?.uniqueId}}/challenges">
            {{'View Challenges' | concat | translate}}
          </button>

          <button *ngIf="loggedInUserType === userTypeEnum.Ministry" mat-menu-item routerLink="/portal-project/{{ project?.uniqueId}}/challenges">
            {{'View Challenges' | concat | translate}}
          </button>

          <button *ngIf="loggedInUserType === userTypeEnum.Staff" mat-menu-item routerLink="/project/{{ project?.uniqueId}}/images">
            {{'View Onsite Evidence' | concat | translate}}
          </button>

          <button *ngIf="loggedInUserType === userTypeEnum.Ministry" mat-menu-item routerLink="/portal-project/{{ project?.uniqueId}}/images">
            {{'View Onsite Evidence' | concat | translate}}
          </button>

          <button *ngIf="loggedInUserType === userTypeEnum.Staff" mat-menu-item routerLink="/project/{{ project?.uniqueId}}/repository">
            {{'View repository' | concat | translate}}
          </button>

          <button *ngIf="loggedInUserType === userTypeEnum.Ministry" mat-menu-item routerLink="/portal-project/{{ project?.uniqueId}}/repository">
            {{'View repository' | concat | translate}}
          </button>

        </mat-menu>

      </div>
    </ng-template>
  </app-page-title>

  <div class="col-md-12 my-3">
      <h5>{{'Project Summary' | concat | translate}}</h5>
        <div class="ext-container w-100">
          <mat-card class="inst-details">
            <div class="row px-3 py-2">

              <div class="col-md-4 my-2">
                <span class="title">{{'Project Name' | concat | translate}}</span>
                <div>{{ project?.name}}</div>
              </div>

              <div class="col-md-4 my-2">
                <span class="title">{{'Project Location' | concat | translate}}</span>
                <div>{{ project?.location}}</div>
              </div>

              <div class="col-md-4 my-2">
                <span class="title">{{'Supervisor' | concat | translate}}</span>
                <div>{{ project?.engineerName}}</div>
              </div>

              <div class="col-md-12 my-2">
                <span class="title">{{'Project Description ' | concat | translate}}</span>
                <div><span [innerHTML]="project?.details"></span></div>
              </div>

              <div class="col-md-4 my-2">
                <span class="title">{{'Contractor' | concat | translate}}</span>
                <div>{{ project?.contractor}}</div>
              </div>

              <div class="col-md-4 my-2">
                <span class="title">{{'Employer Name' | concat | translate}}</span>
                <div>{{ project?.employer}}</div>
              </div>

              <div class="col-md-4 my-2">
                <span class="title">{{'Objective' | concat | translate}}</span>
                <div>{{ project?.objective}}</div>
              </div>

              <div class="col-md-4 my-2">
                <span class="title">{{'Contract Date' | concat | translate}}</span>
                <div>{{ project?.signatureDate | date : 'dd MMM yyyy'}}</div>
              </div>

              <div class="col-md-4 my-2">
                <span class="title">{{'Commencement Date' | concat | translate}}</span>
                <div>{{ project?.commencementDate | date :'dd MMM yyyy'}}</div>
              </div>

              <div class="col-md-4 my-2">
                <span class="title">{{'Completion Date' | concat | translate}}</span>
                <div>{{ project?.completionDate | date: 'dd MMM yyyy'}}</div>
              </div>

            </div>
          </mat-card>
        </div>
    </div>

  <div class="row">

    <div class="col-md-4 mt-3">
      <div class="dash-box">
        <div class="col-md-12 px-0 pb-2">{{'Project Cost' | concat | translate}}</div>
        <div class="count col-md-12 px-0"> {{ project?.allocatedBudget | shortNumber}} </div>
        <span class="dash-icon">
        <mat-icon class="material-symbols-outlined">money</mat-icon>
      </span>
      </div>
    </div>

    <div class="col-md-4 mt-3">
      <div class="dash-box">
        <div class="col-md-12 px-0 pb-2">{{'Government Fund' | concat | translate}}</div>
        <div class="count col-md-12 px-0"> {{ project?.governmentFund | shortNumber}} </div>
        <span class="dash-icon">
        <mat-icon class="material-symbols-outlined">payments</mat-icon>
      </span>
      </div>
    </div>

    <div class="col-md-4 mt-3">
      <div class="dash-box">
        <div class="col-md-12 px-0 pb-2">{{'Foreign Fund' | concat | translate}}</div>
        <div class="count col-md-12 px-0"> {{ project?.foreignFund | shortNumber}} </div>
        <span class="dash-icon">
        <mat-icon class="material-symbols-outlined">payments</mat-icon>
      </span>
      </div>
    </div>

    <div class="col-md-4 mt-3">
      <div class="dash-box">
        <div class="col-md-12 px-0 pb-2">{{'Total Expenditure' | concat | translate}}</div>
        <div class="count col-md-12 px-0"> {{ project?.totalExpenditure | number}} </div>
        <span class="dash-icon">
        <mat-icon class="material-symbols-outlined">card_membership</mat-icon>
      </span>
      </div>
    </div>

    <div class="col-md-4 mt-3">
      <div class="dash-box">
        <div class="col-md-12 px-0 pb-2">{{'Expenditure From Commencement to Date' | concat | translate}} (%)</div>
        <div class="count col-md-12 px-0"> {{ project?.totalExpenditurePercentageFromStartDate | number}} </div>
        <span class="dash-icon">
        <mat-icon class="material-symbols-outlined">credit_card</mat-icon>
      </span>
      </div>
    </div>

    <div class="col-md-4 mt-3">
      <div class="dash-box">
        <div class="col-md-12 px-0 pb-2">{{'Financial Remained' | concat | translate}} (%)</div>
        <div class="count col-md-12 px-0"> {{ 100 - project?.totalExpenditurePercentageFromStartDate | number}} </div>
        <span class="dash-icon">
        <mat-icon class="material-symbols-outlined">credit_card</mat-icon>
      </span>
      </div>
    </div>

  </div>

  <div class="col-md-12 text-end mt-2">
    <button class="btn-sm mx-1 bg-secondary" mat-raised-button *ngIf="!showGantChart" (click)="showGantChart = !showGantChart">
      <mat-icon class="material-symbols-outlined">bar_chart_4_bars</mat-icon>
      {{'view gant chart' | concat | translate}}
    </button>

    <button  class="btn-sm mx-1 bg-secondary" mat-raised-button *ngIf="showGantChart" (click)="showGantChart = !showGantChart">
      <mat-icon class="material-symbols-outlined" svgIcon="document"></mat-icon>
      {{'view Component' | concat | translate}}
    </button>

  </div>

  <ng-container *ngIf="showGantChart">
    <div class="row my-2">
      <div class="col-md-12 move-up --order-7">
        <div *ngIf="gantChartOption" class="w-100 shadow-sm bg-white p-2">
          <apx-chart
            [series]="gantChartOption.series"
            [chart]="gantChartOption.chart"
            [stroke]="gantChartOption.stroke"
            [dataLabels]="gantChartOption.dataLabels"
            [plotOptions]="gantChartOption.plotOptions"
            [xaxis]="gantChartOption.xaxis"
            [colors]="gantChartOption.colors"
            [fill]="gantChartOption.fill"
            [yaxis]="gantChartOption.yaxis"
            [legend]="gantChartOption.legend"
            [title]="gantChartOption.title"
          ></apx-chart>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!showGantChart">
    <div class="row mt-3">
      <h5>{{'Project Components' | concat | translate}}</h5>

      <div class="row">

        <div class="col move-up --order-7">
          <div class="dash-box p-3">
            <div class="col-md-12 px-0 pb-2">{{'Completed' | concat | translate}}</div>
            <div class="count col-md-12 px-0"> {{ countProjectComponentByStatus(project?.components, projectStatus.COMPLETED) | number}} </div>
          </div>
        </div>

        <div class="col move-up --order-6">
          <div class="dash-box p-3 ">
            <div class="col-md-12 px-0 pb-2">{{'On Going' | concat | translate}}</div>
            <div class="count col-md-12 px-0"> {{ countProjectComponentByStatus(project?.components, projectStatus.ONGOING) | number}} </div>
          </div>
        </div>

        <div class="col move-up --order-5">
          <div class="dash-box p-3">
            <div class="col-md-12 px-0 pb-2">{{'On Hold' | concat | translate}}</div>
            <div class="count col-md-12 px-0"> {{ countProjectComponentByStatus(project?.components, projectStatus.ONHOLD) | number }} </div>
          </div>
        </div>

        <div class="col move-up --order-4">
          <div class="dash-box p-3">
            <div class="col-md-12 px-0 pb-2">{{'Not Started' | concat | translate}}</div>
            <div class="count col-md-12 px-0"> {{ countProjectComponentByStatus(project?.components, projectStatus.NOTSTARTED) | number }} </div>
          </div>
        </div>

        <div class="col move-up --order-3">
          <div class="dash-box p-3">
            <div class="col-md-12 px-0 pb-2">{{'Terminated' | concat | translate}}</div>
            <div class="count col-md-12 px-0"> {{ countProjectComponentByStatus(project?.components, projectStatus.TERMINATED) | number }} </div>
          </div>
        </div>

      </div>

      <div class="col-md-12 my-3 move-up --order-3">
        <app-data-table [columnHeader]="componentTableColumn" [tableData$]="components$" [allowTableColumnFromCache]="false">
          <ng-template let-data="data">

            <div class="action-icons">

              <button [matMenuTriggerFor]="menu" class="icon-holder" mat-icon-button>
                <mat-icon svgIcon="option"></mat-icon>
              </button>

              <mat-menu #menu="matMenu">

                <ng-container *ngIf="loggedInUserType === userTypeEnum.Staff">
                  <button mat-menu-item routerLink="/project/{{ project?.uniqueId}}/{{data?.uniqueId}}/component-task">
                    <mat-icon svgIcon="view"></mat-icon>
                    {{' View Component Task' | concat | translate}}
                  </button>
                </ng-container>

                <ng-container *ngIf="loggedInUserType === userTypeEnum.Ministry">
                  <button mat-menu-item routerLink="/portal-project/{{ project?.uniqueId}}/{{data?.uniqueId}}/component-task">
                    <mat-icon svgIcon="view"></mat-icon>
                    {{'View Component Task' | concat | translate}}
                  </button>
                </ng-container>

                <ng-container *ngIf="data?.editable; else editable">

                  <button (click)="componentAction(project, data)" mat-menu-item>
                    <mat-icon svgIcon="edit"></mat-icon>
                    {{'Edit Component' | concat | translate}}
                  </button>

                  <button (click)="removeComponent(data)" mat-menu-item>
                    <mat-icon svgIcon="icon_deactivate"></mat-icon>
                    {{'Remove Component' | concat | translate}}
                  </button>

                  <button (click)="componentTaskAction(null, data)" mat-menu-item>
                    <mat-icon class="material-symbols-outlined">add</mat-icon>
                    {{'Add Component Task' | concat | translate}}
                  </button>
                </ng-container>

                <ng-template #editable>
                  <button mat-menu-item>
                    <mat-icon class="material-symbols-outlined">info</mat-icon>
                    {{'Already Have Progress' | concat | translate}}
                  </button>
                </ng-template>
              </mat-menu>

            </div>

          </ng-template>
        </app-data-table>
      </div>
    </div>
  </ng-container>

</ng-container>
