import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {MenuItem} from '../../../interfaces/data.interfaces';
import {UserTypeEnum} from '../../../../store/entities/settings/user/user.model';
import {MenuService} from '../../../../services/menu.service';
import {IconTypeEnum} from '../../../enums/data.enums';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {selectLoggedInUser} from '../../../../store/entities/auth-user/auth-user.selector';
import {lastValueFrom, Observable, Subscription} from 'rxjs';
import {AuthUser} from '../../../../store/entities/auth-user/auth-user.model';
import {AuthService} from '../../../../services/auth.service';
import {environment} from '../../../../../environments/environment';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import gql from 'graphql-tag';
import {responseField} from '../../../../store/entities/base.graphql';
import {QueryService} from '../../../../services/query.service';
import {SettingsService} from '../../../../services/settings.service';
import {OfflineOnlineSelectComponent} from './offline-online-select/offline-online-select.component';
import {IndexedDBTableName} from '../../../interfaces/table.interface';


export interface LiveMeeting {
  meetingUniqueId?: string;
  meetingName?: string;
  meetingAvailable?: boolean;
}

export const GET_CURRENT_LIVE_MEETING = gql`
query GetCurrentLiveMeetings {
  getCurrentLiveMeetings {
    response {
      ${responseField}
    }
    data {
      meetingUniqueId
      meetingName
      meetingAvailable
    }
  }
}
`;


@Component({
  selector: 'app-staff-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {

  protected readonly IconTypeEnum = IconTypeEnum;

  authUser$: Observable<AuthUser>;
  moduleMenu: MenuItem[];

  loggedInUserType: UserTypeEnum;
  userTypeEnum = UserTypeEnum;

  authService = inject(AuthService);
  menuService = inject(MenuService);

  subscription = new Subscription();

  isProduction = environment.production;
  isLoadingLiveMeeting = true;

  constructor(
    private store: Store<AppState>,
    private dbService: NgxIndexedDBService,
    private queryService: QueryService,
    private settingsService: SettingsService,
  ) {

    // lastValueFrom(this.dbService.add(IndexedDBTableName.active_meeting, {data: 'Testing'})).then();
  }

  ngOnInit(): void {

    document.body.classList.remove('bg-gradient');
    document.body.classList.add('bg-white');


    // Clear offline data
    lastValueFrom(this.dbService.clear(IndexedDBTableName.active_meeting)).then();
    lastValueFrom(this.dbService.clear(IndexedDBTableName.active_meeting_agenda)).then();
    lastValueFrom(this.dbService.clear(IndexedDBTableName.active_meeting_agenda_paper)).then();

    this.subscription.add(this.queryService.query(GET_CURRENT_LIVE_MEETING).subscribe(responseObject => {
      if (responseObject?.length) {
        this.isLoadingLiveMeeting = false;
        const {response, data} = responseObject[0];
        if (data && data?.meetingAvailable) {
          this.settingsService.openModal(data, OfflineOnlineSelectComponent, '40%');
        }
      }
    }));

    this.authUser$ = this.store.select(selectLoggedInUser);

    this.subscription.add(
      this.authUser$.subscribe( data => {
        if (data){
          this.loggedInUserType = data?.userType;

          if (data?.userType === UserTypeEnum.Staff){
            this.moduleMenu = this.menuService.mainMenu;
          }
          if (data?.userType === UserTypeEnum.Ministry){
            this.moduleMenu = this.menuService.ministryMainMenu;
          }
          // this.moduleMenu = data?.userType === UserTypeEnum.Staff ? this.menuService.mainMenu : this.menuService.ministryMainMenu;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
