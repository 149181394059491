import gql from 'graphql-tag';
import {responseField} from '../../base.graphql';


export const documentationFileField = `
id
uniqueId
documentName
documentPath
`;

export const GET_DOCUMENTATION_FILES = gql`
query GetAllDocumentations($filtering: DocumentationFilteringInputObjects) {
  getAllDocumentations(filtering: $filtering) {
    response {
      ${responseField}
    }
    data {
      ${documentationFileField}
    }
  }
}
`;


export const CREATE_DOCUMENTATION_FILE = gql`
mutation CreateDocumentationMutation($input: DocumentationsInput) {
  createDocumentationMutation(input: $input) {
    response {
      ${responseField}
    }
    data {
      ${documentationFileField}
    }
  }
}
`;


export const DELETE_DOCUMENTATION_FILE = gql`
mutation DeleteDocumentationMutation($documentUniqueId: String) {
  deleteDocumentationMutation(documentUniqueId: $documentUniqueId) {
    response {
      ${responseField}
    }
  }
}
`;

