import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {User} from 'src/app/store/entities/settings/user/user.model';
import {AppState} from 'src/app/store/entities';
import {QueryService} from '../../../../services/query.service';
import {NotificationService} from "../../../../services/notification.service";
import {TwoFaServiceService} from "../../../../services/two-fa-service.service";

@Component({
  selector: 'app-two-factor-auth-form',
  templateUrl: './two-factor-auth-form.component.html',
  styleUrls: ['./two-factor-auth-form.component.scss']
})
export class TwoFactorAuthFormComponent implements OnInit {

  title: any;
  userDetails: User;
  qrCodeBase64: string;
  viewType: 'view' | 'confirm' = 'view';
  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private queryService: QueryService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<TwoFactorAuthFormComponent>,
    private twoFaService: TwoFaServiceService
  ) {
    this.userDetails = data;
  }

  async ngOnInit() {
    this.title = 'Set 2Factor Authentication';

    this.form = this.fb.group({
      twoFACode: [null, Validators.required],
    });

    const base64 = await this.twoFaService.allowDisallow2FA();
    this.qrCodeBase64 = `data:image/jpg;base64,${base64}`;
  }

  confirm2FA(formValue: any) {
    this.twoFaService.confirmSetting2FA(formValue.twoFACode.toString());
  }
}
