import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';
import {GraphQLBase} from "@apollo/client/react/hoc/hoc-utils";


export const minutesDecisionProgressFields = `
id
uniqueId
descriptions
date
progressType
isCurrent
`


export const minutesDecisionsField = `
  ${baseField}
  title
  date
  outcome
  descriptions
  progress {
    ${minutesDecisionProgressFields}
  }
`;


export const SAVE_MINUTES_DECISIONS = gql`
  mutation addMinutesDecisions($decision: MinutesDicisionsInputObjects!) {
    addMinutesDecisions(decision: $decision){
      response{
        ${responseField}
      }
      data{
        ${minutesDecisionsField}
      }
    }
  }
`;

export const UPDATE_MINUTES_DECISIONS = gql`
  mutation updateMinutesDecisions($decision: MinutesDicisionsInputObjects!) {
    updateMinutesDecisions(decision: $decision){
      response{
        ${responseField}
      }
      data{
        ${minutesDecisionsField}
      }
    }
  }
`;

export const DELETE_MINUTES_DECISIONS = gql`
  mutation deleteMinutesDecisions($decisionUniqueId: String!) {
    deleteMinutesDecisions(decisionUniqueId: $decisionUniqueId){
      response{
        ${responseField}
      }
    }
  }
`;


export const GET_ALL_DECISIONS = gql`
query GetAllDecisions($agendaUniqueId: String) {
  getAllDecisions(agendaUniqueId: $agendaUniqueId) {
    response {
      ${responseField}
    }
    data {
      ${minutesDecisionsField}
    }
  }
}
`


// Decision Progress


export const ADD_DECISION_PROGRESS = gql`
mutation AddDicissionProgressReport($input: DecisionsProgressReviewInputObject!) {
  addDicissionProgressReport(input: $input) {
    response {
      ${responseField}
    }
    data {
      ${minutesDecisionProgressFields}
    }
  }
}
`


export const UPDATE_DECISION_PROGRESS = gql`
mutation UpdateDicissionProgressReport($input: DecisionsProgressReviewInputObject!) {
  updateDicissionProgressReport(input: $input) {
    response {
      ${responseField}
    }
    data {
      ${minutesDecisionProgressFields}
    }
  }
}
`


export const REMOVE_DECISION_PROGRESS = gql`
mutation RemoveDicissionProgressReport($uniqueId: String) {
  removeDicissionProgressReport(uniqueId: $uniqueId) {
    response {
      ${responseField}
    }
  }
}
`
