import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ScheduledMeeting} from './scheduled-meeting.model';
import * as ScheduledMeetingActions from './scheduled-meeting.actions';

export const scheduledMeetingsFeatureKey = 'scheduledMeeting';

export interface State extends EntityState<ScheduledMeeting> {
  // additional entities state properties
}

export function sortById(a: ScheduledMeeting, b: ScheduledMeeting): any {
  // return a.id.localeCompare(b.id);
}

export const adapter: EntityAdapter<ScheduledMeeting> = createEntityAdapter<ScheduledMeeting>({
  // sortComparer: sortById
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const ScheduledMeetingReducer = createReducer(
  initialState,
  on(ScheduledMeetingActions.addScheduledMeeting,
    (state, action) => adapter.addOne(action.scheduledMeeting, state)
  ),
  on(ScheduledMeetingActions.upsertScheduledMeeting,
    (state, action) => adapter.upsertOne(action.scheduledMeeting, state)
  ),
  on(ScheduledMeetingActions.addScheduledMeetings,
    (state, action) => adapter.addMany(action.scheduledMeetings, state)
  ),
  on(ScheduledMeetingActions.upsertScheduledMeetings,
    (state, action) => adapter.upsertMany(action.scheduledMeetings, state)
  ),
  on(ScheduledMeetingActions.updateScheduledMeeting,
    (state, action) => adapter.updateOne(action.scheduledMeeting, state)
  ),
  on(ScheduledMeetingActions.updateScheduledMeetings,
    (state, action) => adapter.updateMany(action.scheduledMeetings, state)
  ),
  on(ScheduledMeetingActions.deleteScheduledMeeting,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ScheduledMeetingActions.deleteScheduledMeetings,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ScheduledMeetingActions.loadScheduledMeetings,
    (state, action) => adapter.setAll(action.scheduledMeetings, state)
  ),
  on(ScheduledMeetingActions.clearScheduledMeetings,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return ScheduledMeetingReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getScheduledMeetings = selectAll;
