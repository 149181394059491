<!-- Filter -->
<div *ngIf="showFilter" class="row table-filter">

  <div class="d-md-flex">

    <div class="col-12 col-md-9">
      <mat-form-field class="search-form-field col-12" appearance="outline">
        <input (keyup)="filterTable()" [(ngModel)]="searchKey" autocomplete="off" matInput placeholder="{{'Search' | concat | translate}}" />
        <button (click)="onSearchClear()" *ngIf="searchKey" mat-icon-button matSuffix type="button">
          <mat-icon class="material-symbols-outlined">close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="col-12 col-md-3">
      <div class="d-flex justify-content-center justify-content-md-end">

<!--        <button mat-icon-button [matMenuTriggerFor]="pageSizeMenu" matTooltip="{{'Select Page Size' | concat | translate}}">-->
<!--          <mat-icon svgIcon="next-page-arrow"></mat-icon>-->
<!--        </button>-->

<!--        <mat-menu #pageSizeMenu="matMenu">-->
<!--          <button mat-menu-item (click)="setPageSize(pageOption)" *ngFor="let pageOption of pageSizeOptions">-->
<!--            <span>{{pageOption | number}}</span>-->
<!--          </button>-->
<!--        </mat-menu>-->

        <ng-container *ngIf="allowTableColumnFromCache">
          <button mat-icon-button type="button" (click)="openColumnSection()" matTooltip="{{'Select Columns to Display' | concat | translate}}">
            <mat-icon svgIcon="columns"></mat-icon>
          </button>

          <button mat-icon-button type="button" (click)="updateCacheTableColumns(tableColumns)" matTooltip="{{'Reset Columns Display' | concat | translate}}">
            <mat-icon svgIcon="reset"></mat-icon>
          </button>
          <div class="my-auto bg-primary"><span class="vr"></span></div>

        </ng-container>

        <button *ngIf="showDownloadButtons" mat-icon-button type="button" matTooltip="{{'Copy table' | concat | translate}}" (click)="copyTable()">
          <mat-icon svgIcon="copy"></mat-icon>
        </button>

        <button *ngIf="showDownloadButtons" mat-icon-button type="button" matTooltip="{{'Export' | concat | translate}}" (click)="downloadExcelFile()">
          <mat-icon svgIcon="excel"></mat-icon>
        </button>

      </div>
    </div>

  </div>
</div>
<!-- end of Filter -->

<div class="">

  <table cdk-table [dataSource]="listData" class="bg-white table mb-0" matSort id="table">

    <ng-container *ngFor="let columnKey of cacheTableColumnKeywords; let i = index;">
      <ng-container *ngIf="i == 0">
        <!-- SN Column -->
        <ng-container *ngIf="!showCheckbox">
          <ng-container matColumnDef="{{columnKey}}">
            <th cdk-header-cell *matHeaderCellDef mat-sort-header> {{columnHeader[columnKey] | concat | translate}} </th>
            <td cdk-cell *matCellDef="let element; let i = index">
              <span class="d-md-none d-block table-header-sm">{{ columnHeader[columnKey] | concat | translate}}</span>
              {{i + 1 + myPaginator.pageIndex * myPaginator.pageSize}}
            </td>
          </ng-container>
        </ng-container>
        <!-- end of SN Column -->

        <!-- checkbox-->
        <ng-container *ngIf="showCheckbox" matColumnDef="{{columnKey}}">
          <th *matHeaderCellDef mat-header-cell>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [aria-label]="checkboxLabel()"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" mat-cell>
            <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                          (click)="$event.stopPropagation()"
                          [aria-label]="checkboxLabel(row)"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <!--     end of checkbox-->

      </ng-container>

      <ng-container *ngIf="!hasActionsColumn">
        <ng-container *ngIf="(i > 0)">
          <ng-container matColumnDef="{{columnKey}}">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{columnHeader[columnKey] | concat | translate}} </th>
            <td mat-cell *matCellDef="let element" class="text-wrap">
              <span class="d-md-none d-block table-header-sm">{{ columnHeader[columnKey] | concat | translate}}</span>

              <span [innerHTML]="settingsService.getTableObjectValue(element, columnKey)" class="text-wrap"></span>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="hasActionsColumn">

        <ng-container *ngIf="(i > 0) && (i < ( (cacheTableColumnKeywords)?.length - 1))">
          <ng-container matColumnDef="{{columnKey}}">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header>{{columnHeader[columnKey] | concat | translate}} </th>
            <td mat-cell *matCellDef="let element" class="text-wrap">
              <span class="d-md-none d-block table-header-sm">{{ columnHeader[columnKey] | concat | translate}}</span>
              <span [innerHTML]="settingsService.getTableObjectValue(element, columnKey)" class="text-wrap"></span>
            </td>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="i == ( (cacheTableColumnKeywords)?.length - 1)">
          <ng-container matColumnDef="{{columnKey}}">
            <th mat-header-cell  *matHeaderCellDef  mat-sort-header>{{columnHeader[columnKey] | concat | translate}} </th>
            <td mat-cell *matCellDef="let row">
              <ng-container [ngTemplateOutletContext]="{data : row}" [ngTemplateOutlet]="actions"></ng-container>
            </td>
          </ng-container>
        </ng-container>

      </ng-container>

    </ng-container>


    <!-- Table Configurations -->
    <tr  cdk-header-row *matHeaderRowDef="cacheTableColumnKeywords"></tr>
    <tr cdk-row (click)="selection.toggle(row)" *matRowDef="let row; columns: cacheTableColumnKeywords;"></tr>
    <!-- end of Table Configurations -->

    <!-- If No Data Found -->
    <ng-container *ngIf="(listData != null && listData?.data?.length == 0)">
      No data
    </ng-container>

    <!-- end if no Data Found -->

    <!-- Loading Data Message -->
    <ng-container *ngIf="showLoading">

      <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{ hide: listData != null }"></mat-footer-row>
      <ng-container matColumnDef="loading">
        <mat-footer-cell *matFooterCellDef>{{'loading' | concat | translate}}</mat-footer-cell>
      </ng-container>
    </ng-container>
    <!-- end of Loading Data Message -->

  </table>

  <mat-paginator
    [style.display]="hasPaginator ? 'block' : 'none'"
    #myPaginator [pageSize]="pageSize" showFirstLastButtons hidePageSize></mat-paginator>
</div>

