import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {MailConfig} from './mail-config.model';
import * as MailConfigActions from './mail-config.actions';

export const mailConfigsFeatureKey = 'mailConfigs';

export interface State extends EntityState<MailConfig> {
  // additional entities state properties
}

export const adapter: EntityAdapter<MailConfig> = createEntityAdapter<MailConfig>({
});

// export const adapter: EntityAdapter<MailConfig> = createEntityAdapter<MailConfig>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const mailConfigReducer = createReducer(
  initialState,
  on(MailConfigActions.addMailConfig,
    (state, action) => adapter.addOne(action.mailConfig, state)
  ),
  on(MailConfigActions.upsertMailConfig,
    (state, action) => adapter.upsertOne(action.mailConfig, state)
  ),
  on(MailConfigActions.addMailConfigs,
    (state, action) => adapter.addMany(action.mailConfigs, state)
  ),
  on(MailConfigActions.upsertMailConfigs,
    (state, action) => adapter.upsertMany(action.mailConfigs, state)
  ),
  on(MailConfigActions.updateMailConfig,
    (state, action) => adapter.updateOne(action.mailConfig, state)
  ),
  on(MailConfigActions.updateMailConfigs,
    (state, action) => adapter.updateMany(action.mailConfigs, state)
  ),
  on(MailConfigActions.deleteMailConfig,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(MailConfigActions.deleteMailConfigs,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(MailConfigActions.loadMailConfigs,
    (state, action) => adapter.setAll(action.mailConfigs, state)
  ),
  on(MailConfigActions.clearMailConfigs,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return mailConfigReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllMailConfigs = selectAll;
export const getMailConfigById = selectIds;
export const getMailConfigEntities = selectEntities;
