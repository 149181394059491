import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {Representative} from './representative.model';

export const loadRepresentatives = createAction(
  '[Representative/API] Load Representatives',
  props<{ representatives: Representative[] }>()
);

export const addRepresentative = createAction(
  '[Representative/API] Add Representative',
  props<{ representative: Representative }>()
);

export const upsertRepresentative = createAction(
  '[Representative/API] Upsert Representative',
  props<{ representative: Representative }>()
);

export const addRepresentatives = createAction(
  '[Representative/API] Add Representatives',
  props<{ representatives: Representative[] }>()
);

export const upsertRepresentatives = createAction(
  '[Representative/API] Upsert Representatives',
  props<{ representatives: Representative[] }>()
);

export const updateRepresentative = createAction(
  '[Representative/API] Update Representative',
  props<{ representative: Update<Representative> }>()
);

export const updateRepresentatives = createAction(
  '[Representative/API] Update Representatives',
  props<{ representatives: Update<Representative>[] }>()
);

export const deleteRepresentative = createAction(
  '[Representative/API] Delete Representative',
  props<{ id: number }>()
);

export const deleteRepresentatives = createAction(
  '[Representative/API] Delete Representatives',
  props<{ ids: number[] }>()
);

export const clearRepresentatives = createAction(
  '[Representative/API] Clear Representatives'
);


export const getAllRepresentative = createAction(
  '[Representative/API] Get All Meeting Representatives',
  props<{ meetingUniqueId: string }>()
);

export const removeRepresentative = createAction(
  '[Representative/API] remove Meeting Representative',
  props<{ uniqueId: string, id: number }>()
);

export const saveRepresentative = createAction(
  '[Representative/API] Save Meeting Representative',
  props<{ input: {
      meeting: string;
      absentUser?: string;
      respresentativeUser: string;
      attachedLetterPath?: string;
    } }>()
);

export const saveMinistryRepresentative = createAction(
  '[Representative/API] Save Ministry Meeting Representative',
  props<{ input: {
      meeting: string;
      absentUser?: string;
      respresentativeUser: string;
      attachedLetterPath?: string;
    } }>()
);



