<div class="bg-white">
  <div class="app-logo bg-primary scale-up" style="--order: 3" matRipple routerLink="/home">

    <ng-container *ngIf="!isPortalModule">

      <span *ngIf="moduleIconType === IconTypeEnum.SVG" class="module-icon"> <mat-icon svgIcon="{{ moduleIconTitle}}"></mat-icon></span>
      <span *ngIf="moduleIconType === IconTypeEnum.MATERIAL" class="module-icon"> <mat-icon class="material-symbols-outlined">{{moduleIconTitle}}</mat-icon></span>
      <br>
    </ng-container>

    <img *ngIf="isPortalModule" src="/assets/images/emblem.png" width="140" height="140" alt="Logo" class="move-down --order-5">

    <div *ngIf="loggedInInstitution && isPortalModule" class="text-center col-12 bg-primary-subtle rounded-3 p-1 fw-bold text-primary">
      <span>{{ loggedInInstitution?.shortform}}</span>
    </div>
  </div>
</div>

<mat-nav-list *ngIf="menuData">
  <div class="sb-module-name pl-2 faded">{{ moduleTitle | concat | translate }}</div>

  <ng-container *ngFor="let menu of sidebarMenu; let i = index">

    <!-- without children -->
    <ng-container *ngIf="!menu.children">
      <ng-container *ngxPermissionsOnly="menu?.permissions">
        <a class="content-item"
           style="{{ '--order:' + i * 0.25 }}"
           mat-list-item
           routerLink="{{ menu.route }}"
           routerLinkActive="active-menu"
           aria-label="Sidebar Menu"
           [matTooltip]="menu.name?.length > 24 ? (menu.name | concat | translate) : null"
           matTooltipPosition="right"
           [disabled]="menu?.disabled">
          <span class="m-title">{{ menu.name | concat | translate }}</span>
        </a>
      </ng-container>
    </ng-container>

    <!-- with children -->
    <ng-container *ngIf="menu.children">
      <mat-accordion *ngIf="getChildrenPermission(menu?.children)">
        <mat-expansion-panel class="content-item mx-1" style="{{ '--order:' + i * 0.25 }}" [expanded]="isActiveRoute(menu?.children)" [disabled]="menu?.disabled">
          <mat-expansion-panel-header class="big-menu-items text-primary">
            <span class="text text-primary">{{ menu.name | concat | translate }}</span>
          </mat-expansion-panel-header>

          <ng-container *ngFor="let menuItem of menu.children">
            <a *ngxPermissionsOnly="menuItem?.permissions" [routerLink]="menuItem.route"
               mat-list-item
               aria-label="Sidebar Menu"
               routerLinkActive="active-menu"
               [matTooltip]="menuItem.name?.length > 18 ? (menuItem.name | concat | translate) : null" matTooltipPosition="right">
              <span>{{ menuItem.name | concat | translate }}</span>
            </a>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </ng-container>
</mat-nav-list>
