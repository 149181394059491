import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {
  deleteTask,
  getAllAssignedTasks,
  getAllTasks,
  loadTasks,
  reAssignTask,
  removeTask,
  saveTask,
  upsertTask
} from "./task.actions";
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {
  DELETE_TASK,
  GET_ASSIGNED_TASK,
  GET_TASK,
  GET_TASK_ASSIGNMENT_BY_UNIQUE_ID,
  RE_ASSIGN_TASK,
  SAVE_TASK
} from "./task.graphql";

@Injectable()
export class TaskEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllTasks),
    switchMap((action) => {
      return this.apollo.query({
        query: action?.taskAssignmentUniqueId ? GET_TASK_ASSIGNMENT_BY_UNIQUE_ID : GET_TASK,
        variables: { uid: action.meetingUid, uniqueId: action?.taskAssignmentUniqueId}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadTasks({tasks: action?.taskAssignmentUniqueId? [result?.data] : result?.dataList}));
          }
        })
      );
    })
  ), { dispatch: false });

  assigned = createEffect(() => this.actions$.pipe(
    ofType(getAllAssignedTasks),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_ASSIGNED_TASK,
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadTasks({tasks: result?.dataList}));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveTask),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_TASK,
        variables: { input: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertTask({task: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  reAssignTask = createEffect(() => this.actions$.pipe(
    ofType(reAssignTask),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: RE_ASSIGN_TASK,
        variables: { input: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertTask({task: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeTask),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_TASK,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteTask({id: action.id}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private apollo: Apollo
  ) {
  }
}
