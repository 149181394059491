import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Decision} from './decision.model';
import * as DecisionActions from './decision.actions';

export const decisionsFeatureKey = 'decision';

export interface State extends EntityState<Decision> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Decision> = createEntityAdapter<Decision>({
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const decisionReducer = createReducer(
  initialState,
  on(DecisionActions.addDecision,
    (state, action) => adapter.addOne(action.decision, state)
  ),
  on(DecisionActions.upsertDecision,
    (state, action) => adapter.upsertOne(action.decision, state)
  ),
  on(DecisionActions.addDecisions,
    (state, action) => adapter.addMany(action.decisions, state)
  ),
  on(DecisionActions.upsertDecisions,
    (state, action) => adapter.upsertMany(action.decisions, state)
  ),
  on(DecisionActions.updateDecision,
    (state, action) => adapter.updateOne(action.decision, state)
  ),
  on(DecisionActions.updateDecisions,
    (state, action) => adapter.updateMany(action.decisions, state)
  ),
  on(DecisionActions.deleteDecision,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(DecisionActions.deleteDecisions,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(DecisionActions.loadDecisions,
    (state, action) => adapter.setAll(action.decisions, state)
  ),
  on(DecisionActions.clearDecisions,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return decisionReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getDecisions = selectAll;
