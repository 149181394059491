import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {
  deleteForumMember,
  getAllForumMemberByStage,
  loadForumMembers,
  removeForumMemberByStage,
  saveForumMembers,
  upsertForumMembers
} from './forum-member.actions';
import {map, switchMap} from 'rxjs/operators';
import {
  GET_ALL_USERS_BY_FORUM_STAGE,
  REMOVE_USERS_BY_FORUM_STAGE,
  SAVE_USERS_BY_FORUM_STAGE
} from './forum-member.graphql';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notification.service';
import {ForumMember} from './forum-member.model';
import {User} from '../user/user.model';

@Injectable()
export class ForumMemberEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllForumMemberByStage),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_ALL_USERS_BY_FORUM_STAGE,
        variables: { uid: action.stage}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            const forumMembers: ForumMember[] = result?.data[0]?.members?.map( (d: User) => {
              return {
                ...d,
                stage: action.stage
              };
            });
            this.store.dispatch(loadForumMembers({ forumMembers }));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveForumMembers),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_USERS_BY_FORUM_STAGE,
        variables: { inputDto: action.formDate }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            const forumMembers: ForumMember[] = result?.data?.members?.map( (d: User) => {
              return {
                ...d,
                stage: action.formDate?.stage
              };
            });
            this.store.dispatch(upsertForumMembers({ forumMembers }));
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeForumMemberByStage),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: REMOVE_USERS_BY_FORUM_STAGE,
        variables: { inputDto: action.member }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessage(result?.response?.message);
            this.store.dispatch(deleteForumMember({id: action.userId}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apollo: Apollo,
    private notificationService: NotificationService
  ) {}
}
