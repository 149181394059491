import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const permissionGroupField = `
  id
  groupName
  groupUniqueId
  permissions{
    ${baseField}
    name
    code
  }
`;

export const GET_PERMISSION = gql`
  query getSystemPermissionsList {
    getSystemPermissionsList {
      response{
        ${responseField}
      }
      data{
        ${permissionGroupField}
      }
    }
  }
`;
