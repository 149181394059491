import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {DocumentationFile, DocumentationFileInput} from './documentation-file.model';

export const loadDocumentationFiles = createAction(
  '[DocumentationFile/API] Load DocumentationFiles',
  props<{ documentationFiles: DocumentationFile[] }>()
);

export const addDocumentationFile = createAction(
  '[DocumentationFile/API] Add DocumentationFile',
  props<{ documentationFile: DocumentationFile }>()
);

export const upsertDocumentationFile = createAction(
  '[DocumentationFile/API] Upsert DocumentationFile',
  props<{ documentationFile: DocumentationFile }>()
);

export const addDocumentationFiles = createAction(
  '[DocumentationFile/API] Add DocumentationFiles',
  props<{ documentationFiles: DocumentationFile[] }>()
);

export const upsertDocumentationFiles = createAction(
  '[DocumentationFile/API] Upsert DocumentationFiles',
  props<{ documentationFiles: DocumentationFile[] }>()
);

export const updateDocumentationFile = createAction(
  '[DocumentationFile/API] Update DocumentationFile',
  props<{ documentationFile: Update<DocumentationFile> }>()
);

export const updateDocumentationFiles = createAction(
  '[DocumentationFile/API] Update DocumentationFiles',
  props<{ documentationFiles: Update<DocumentationFile>[] }>()
);

export const deleteDocumentationFile = createAction(
  '[DocumentationFile/API] Delete DocumentationFile',
  props<{ id: number }>()
);

export const deleteDocumentationFiles = createAction(
  '[DocumentationFile/API] Delete DocumentationFiles',
  props<{ ids: number[] }>()
);

export const clearDocumentationFiles = createAction(
  '[DocumentationFile/API] Clear DocumentationFiles'
);


export const getAllDocumentationFile = createAction(
  '[DocumentationFile/API] Get All DocumentationFiles',
  props<{ filtering: { categoryUniqueId: string; receivedDateFrom?: string; receivedDateTo?: string } }>()
);

export const saveDocumentationFile = createAction(
  '[DocumentationFile/API] Save Paper DocumentationFile',
  props<{ input: DocumentationFileInput }>()
);

export const removeDocumentationFile = createAction(
  '[DocumentationFile/API] Remove Paper DocumentationFile',
  props<{ uniqueId: string, id: number }>()
);

