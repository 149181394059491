import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ToWords} from 'to-words';
import {UserTypeEnum} from '../../../../store/entities/settings/user/user.model';

@Component({
  selector: 'app-view-document-report',
  templateUrl: './view-document-report.component.html',
  styleUrls: ['./view-document-report.component.scss']
})
export class ViewDocumentReportComponent implements OnInit {
  today = new Date();
  userType: UserTypeEnum;
  userTypeEnum = UserTypeEnum;
  toWords = new ToWords({
    localeCode: 'en-US',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
    }
  });
  title: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.userType = data?.userType;
  }

  ngOnInit(): void {
    this.title = 'Document Summary Report';

  }

  writeWords(amount: number) {
    return this.toWords.convert(Math.round(amount * 100) / 100, {});
  }

}
