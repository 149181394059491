import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NgForOf, NgIf} from '@angular/common';
import {DialogButtons, DialogData} from '../../../interfaces/data.interfaces';
import {FormValueTypeEnum} from '../../../enums/data.enums';
import {concat} from 'rxjs';
import {ConcatPipe} from '../../../pipes/concat.pipe';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  imports: [
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    ConcatPipe,
    TranslateModule
  ],
  standalone: true
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.dialogData = data;

    if (this.dialogData?.buttons?.length > 0){
      this.buttons = this.dialogData?.buttons;
    }

    if (this.dialogData?.title){
      this.title = this.dialogData?.title;
    }

    if (this.dialogData?.message){
      this.message = this.dialogData?.message;
    }

    if (this.dialogData?.placeholder){
      this.placeholder = this.dialogData?.placeholder;
    }

    if (this.dialogData?.isForm){
      this.buttons = [ { title: 'Cancel', value: false}, { title: 'Submit', value: true}];
    }
  }

  protected readonly FormValueTypeEnum = FormValueTypeEnum;

  dialogData: DialogData;
  title = 'Confirmation';
  message = 'are-you-sure';
  placeholder = 'are-you-sure';
  buttons: DialogButtons[] = [ { title: 'Cancel', value: false}, { title: 'Yes', value: true}];

  formGroup = new UntypedFormGroup({
    content: new UntypedFormControl()
  });

  protected readonly concat = concat;

  ngOnInit(): void {
  }
}
