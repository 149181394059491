import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';
import {designationField} from '../designation/designation.graphql';

export const assignmentConfigurationField = `
  ${baseField}
  parentDesignation {
    ${designationField}
  }
  asigneeDesignations {
    ${designationField}
  }
`;

export const GET_TASK_ASSIGNMENT_DESIGNATIONS_CONFIGURATION = gql`
query GetTaskAssignedDesignations {
  getTaskAssignedDesignations {
      response{
        ${responseField}
      }
      data{
        ${assignmentConfigurationField}
      }
    }
  }
`;

export const SAVE_TASK_DESIGNATION_ASSIGNMENT_CONFIGURATION = gql`
mutation TaskDesignationAssignmentConfiguration($input: DesignationAssignmentInputObjects) {
  taskDesignationAssignmentConfiguration(input: $input) {
    response {
      ${responseField}
    }
    data {
      ${assignmentConfigurationField}
    }
  }
}
`;


export const REMOVE_DESIGNATION_ASSIGNMENT_CONFIGURATION = gql`
mutation RemoveDesignationAssignmentConfiguration($uniqueId: String) {
  removeDesignationAssignmentConfiguration(uniqueId: $uniqueId) {
    response {
      ${responseField}
    }
  }
}
`;

export const UPDATE_DESIGNATION_ASSIGNATION_CONFIGURATION = gql`
mutation UpdateDesignationAssignmentConfiguration($input: DesignationAssignmentInputObjects) {
  updateDesignationAssignmentConfiguration(input: $input) {
    response {
      ${responseField}
    }
    data {
      ${assignmentConfigurationField}
    }
  }
}
`;

