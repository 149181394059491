import gql from 'graphql-tag';
import {baseField, basePageableGraphqlField, responseField} from '../base.graphql';
import {attachmentConfigField} from '../settings/attachment-configuration/attachment-configuration.graphql';

export const documentField = `
  ${baseField}
  title
  receivedDate
  letterDate
  referenceNumber
  theme
  paperNumber
  processingStatus
  folderUniqueId
  receivedStage
  paperHasStamp
  paperCirculatedDate
  submissionLetterName
  submissionLetterPath
  paperReviewsComments {
    id
    uniqueId
    reviewedByCc
    comments
  }
  committee{
    ${baseField}
    name
  }
  institution{
    ${baseField}
    name
  }
  category{
    ${baseField}
    name
  }
  attachments{
    uniqueId
    attachmentConfig:requiredAttachment{
      ${attachmentConfigField}
    }
    file{
      attachmentName
      base64String
    }
  }
`;

export const GET_DOCUMENT_PAGEABLE = gql`
  query getAllPapersList($filtering: CabinetPaperFilteringInputObjects) {
    getAllPapersList(filtering: $filtering) {
      response{
        ${responseField}
      }
      page {
        ${basePageableGraphqlField}
      }
      data{
        ${documentField}
      }
    }
  }
`;

export const GET_ALL_COMMITTEE_DOCUMENT = gql`
  query GetAllCommiteesPapers($commiteeUniqueId: String, $status: PaperStatusTypeEnum, $paperUniqueId: String, $paperHasReview: Boolean) {
    getAllCommiteesPapers(commiteeUniqueId: $commiteeUniqueId, status: $status, paperUniqueId: $paperUniqueId, paperHasReview: $paperHasReview) {
      response{
        ${responseField}
      }
      page {
        ${basePageableGraphqlField}
      }
      data{
        ${documentField}
      }
    }
  }
`;


export const GET_INCOMING_PAPERS = gql`
  query getIncomingPapers {
    getIncomingPapers {
      response{
        ${responseField}
      }
      data{
        ${documentField}
      }
    }
  }
`;

export const GET_DOCUMENT_BY_STAGE = gql`
  query getPapersByStageList($inputDto: PaperStageTypeEnum) {
    getPapersByStageList(stage: $inputDto) {
      response{
        ${responseField}
      }
      data{
        ${documentField}
      }
    }
  }
`;

export const SAVE_DOCUMENT = gql`
  mutation registerNewCabinetPaper($inputDto: NewCabinetPaperInputObjects!) {
    registerNewCabinetPaper(paper: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${documentField}
      }
    }
  }
`;

export const SAVE_DOCUMENT_FROM_MINISTRY = gql`
  mutation ministryRegisterNewCabinetPaper($inputDto: NewCabinetPaperInputObjects!) {
    ministryRegisterNewCabinetPaper(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${documentField}
      }
    }
  }
`;

export const UPDATE_DOCUMENT_FROM_MINISTRY = gql`
  mutation ministryUpdateNewCabinetPaper($inputDto: NewCabinetPaperInputObjects!) {
    ministryUpdateNewCabinetPaper(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${documentField}
      }
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation updateCabinetPaper($inputDto: UpdateCabinetPaperInputObjects!) {
    updateCabinetPaper(paper: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${documentField}
      }
    }
  }
`;

export const ATTEND_DOCUMENT = gql`
  mutation attendMinistryNewIncomingPaperMutation($inputDto: NewCabinetPaperInputObjects!) {
    attendMinistryNewIncomingPaperMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${documentField}
      }
    }
  }
`;

export const GET_DOCUMENT_DASHBOARD_DATA = gql`
  query getPaperDashboard($inputDto: PaperDashboardFilteringInputObjects) {
    getPaperDashboard(filtering: $inputDto){
      response {
        code
        message
      }
      data {
        categoryCards{
          name
          value
        }
        committeesCards{
          name
          value
        }
        statusCards{
          name
          value
        }
        institutionsCards{
          name
          value
        }
        forumsCards{
          name
          value
        }
      }
    }
  }
`;

export const GET_MINISTRY_DOCUMENT_DASHBOARD_DATA = gql`
  query getMinistryPapersDashboard($inputDto: MinistryFilteringInputObjects) {
    getMinistryPapersDashboard(filtering: $inputDto){
      response {
        code
        message
      }
      data {
        papersPerStatus{
          name:cardName
          value
        }
        directivesPerStatus{
          name:cardName
          value
        }
        overDueDirectives{
          name:cardName
          value
        }
      }
    }
  }
`;


export const DISMISS_PAPER = gql`
  mutation DismisPapers($uniqueId: String!) {
    dismisPapers(uniqueId: $uniqueId) {
      response{
        ${responseField}
      }
    }
  }
`;


export const STAMP_DOCUMENT = gql`
  mutation StampDocumentsMutation($input: StampDocumentsInputObjects!){
    stampPapersDocuments(input: $input){
      response{
        ${responseField}
      }
      data {
        ${documentField}
      }
    }
  }
`;


export const CREATE_PAPER_REVIEW = gql`
mutation CreatePaperReviewsComments($input: PaperReviewCommentsInput) {
  createPaperReviewsComments(input: $input) {
    response{
      ${responseField}
    }
    data {
      ${documentField}
    }
  }
}
`;


export const VERIFY_PAPER_REVIEW = gql`
mutation VerifyPaperReviewsComments($input: PaperReviewCommentsInput) {
  verifyPaperReviewsComments(input: $input) {
    response{
      ${responseField}
    }
    data {
      ${documentField}
    }
  }
}
`;

