
<ng-container *ngIf="dialogData?.isForm; else notForm">
  <h4>{{ message }}</h4>

  <div class="col-12" *ngIf="dialogData.formFieldType === FormValueTypeEnum.TEXT">
    <mat-form-field appearance="outline" class="material-form" [formGroup]="formGroup">
      <mat-label> {{ dialogData.placeholder !== null ? dialogData.placeholder : 'Content or Comment' | concat | translate}}</mat-label>
      <textarea autocomplete="off" formControlName="content" matInput #contentInput maxlength="250" rows="3"></textarea>
      <mat-hint align="end">{{ contentInput?.value?.length }}/250</mat-hint>
    </mat-form-field>
  </div>

  <div class="col-12" *ngIf="dialogData.formFieldType === FormValueTypeEnum.NUMBER || dialogData.formFieldType === FormValueTypeEnum.AMOUNT ">
    <mat-form-field appearance="outline" class="material-form" [formGroup]="formGroup">
      <mat-label>{{ dialogData.placeholder !== null ? dialogData.placeholder : 'Value' | concat | translate}}</mat-label>
      <input autocomplete="off" formControlName="content" matInput type="number">
    </mat-form-field>
  </div>

  <div class="col-12" *ngIf="dialogData.formFieldType === FormValueTypeEnum.EMAIL">
    <mat-form-field appearance="outline" class="material-form" [formGroup]="formGroup">
      <mat-label>{{ dialogData.placeholder !== null ? dialogData.placeholder : 'E-mail' | concat | translate}}</mat-label>
      <input autocomplete="off" formControlName="content" matInput type="email">
    </mat-form-field>
  </div>

  <div class="d-flex justify-content-end" mat-dialog-actions>
    <ng-container *ngFor="let data of buttons">
      <button [mat-dialog-close]="data.value ? formGroup.get('content').value : null" mat-raised-button
              class="rounded-3 text-white {{ data?.value ? 'bg-primary' : 'bg-danger'}}">
        {{ data?.title }}
      </button>
    </ng-container>
  </div>

</ng-container>

<ng-template #notForm>
  <h4>{{ title  | concat | translate}}</h4>

  <div>
    <p>{{ message }}</p>
  </div>
  <div class="d-flex justify-content-end" mat-dialog-actions>
    <button *ngFor="let data of buttons" [mat-dialog-close]="data.value" mat-raised-button
            class="rounded-3 text-white {{ data?.value ? 'bg-primary' : 'bg-danger'}}">
      {{ data?.title ? data?.title : 'Yes' | concat | translate }}
    </button>
  </div>

</ng-template>
