<section class="error-container mt-5" style="margin-top: 10% !important;">
  <span class="four"><span class="screen-reader-text">4</span></span>
  <span class="zero"><span class="screen-reader-text">0</span></span>
  <span class="four"><span class="screen-reader-text">4</span></span>
</section>

<div class="col-md-12 text-center">
  <h1>{{'no found' | concat | translate}}</h1>
  <p class="zoom-area">
    {{'no found content' | concat | translate}}
  </p>
  <div class="link-container mt-2">
    <button (click)="goBack()" class="more-link" mat-raised-button>{{'Go Back' | concat | translate}}</button>
  </div>
</div>

