import gql from 'graphql-tag';
import {baseField, basePageableGraphqlField, responseField} from "../../base.graphql";
import {meetingLocationField} from '../../settings/location/location.graphql';
import {meetingAgendaField} from '../meeting-agenda/meeting-agenda.graphql';

export const scheduledMeetingField = `
  ${baseField}
  title
  date
  stage
  startTime
  meetingTimeName
  meetingNumber
  meetingStatus
  noOfPaper
  meetingTypes
  meetingMaxInvitees
  meetingVenue
  agendas{
    ${meetingAgendaField}
  }
  location{
    ${meetingLocationField}
  }
  withdrawOrClosingRemarks
  withdrawOrClosingAttachmentPath
  withdrawOrClosingAttachmentName
  withdrawOrClosingAttachmentExtension
`;

export const GET_SCHEDULED_MEETINGS = gql`
  query getAllMeetings($filtering: MeettingSearchingInputObjects) {
    getAllMeetings(filtering: $filtering) {
      response{
        ${responseField}
      }
      page {
        ${basePageableGraphqlField}
      }
      data{
        ${scheduledMeetingField}
      }
    }
  }
`;

export const GET_SCHEDULED_MEETING_BY_STAGE = gql`
  query getPapersByStageList($inputDto: PaperStageTypeEnum) {
    getPapersByStageList(stage: $inputDto) {
      response{
        ${responseField}
      }
      data{
        ${scheduledMeetingField}
      }
    }
  }
`;
export const GET_MEETINGS_BY_MULTIPLE_STAGES = gql`
  query getMinistryMeetings($ministryFilter: MeetingFilterInput) {
    getMinistryMeetings(ministryFilter: $ministryFilter) {
      response{
        ${responseField}
      }
      data{
        ${scheduledMeetingField}
      }
    }
  }
`;
export const GET_MEETING_NOTICE = gql`
  query getMeetingNotices($meeting: String) {
    getMeetingNotices(meeting: $meeting) {
      response{
        ${responseField}
      }
      dataList{
        id
        uniqueId
        greetings
        body
        invitees {
          id
          uniqueId
          firstName
          lastName
          email
          phone
        }
        noticeSent
        createdDate
      }
    }
  }
`;

export const SAVE_SCHEDULED_MEETING = gql`
  mutation scheduleMeeting($meetting: NewMeettingObjects!) {
    scheduleMeeting(meetting: $meetting){
      response{
        ${responseField}
      }
      data{
        ${scheduledMeetingField}
      }
    }
  }
`;

export const CLOSE_WITHDRAW_MEETING = gql`
  mutation closeWithdrawScheduledMeeting($meetingDto: CloseWithDrawMeetingObjects!) {
    closeWithdrawScheduledMeeting(meetingDto: $meetingDto){
      response{
        ${responseField}
      }
      data{
        ${scheduledMeetingField}
      }
    }
  }
`;

export const SEND_MEETING_NOTICE = gql`
  mutation sendMeetingNotice($notice: MeetingNoticeInputObjects!) {
    sendMeetingNotice(notice: $notice){
      response{
        ${responseField}
      }
    }
  }
`;
export const PUBLISH_MEETING = gql`
  mutation publishMeeting($meetingUniqueId: String) {
    publishMeeting(meetingUniqueId: $meetingUniqueId){
      response{
        ${responseField}
      }
    }
  }
`;
export const UNPUBLISH_MEETING = gql`
  mutation unPublishMeeting($meetingUniqueId: String) {
    unPublishMeeting(meetingUniqueId: $meetingUniqueId){
      response{
        ${responseField}
      }
    }
  }
`;

export const UPDATE_SCHEDULED_MEETING = gql`
  mutation updateScheduledMeeting($meetting: NewMeettingObjects!) {
    updateScheduledMeeting(meetting: $meetting){
      response{
        ${responseField}
      }
      data{
        ${scheduledMeetingField}
      }
    }
  }
`;

export const DELETE_SCHEDULED_MEETING = gql`
  mutation deleteScheduledMeeting($meetingUniqueId: String) {
    deleteScheduledMeeting(meetingUniqueId: $meetingUniqueId){
      response{
        ${responseField}
      }
    }
  }
`;



export const GET_ALL_MEETING_STATISTICS = gql`
query GetAllMeetingStatistics {
  getAllMeetingStatistics {
    response {
      ${responseField}
    }
    data {
      draftCount
      minutesCount
      publishedCount
      withdrawnCount
      closedCount
    }
  }
}
`

export const ATTACH_SIGNED_MINUTES_MEETING = gql`
mutation AttachSignedMinutesMeeting($input: SignedMinutesInputObjects) {
  attachSignedMinutesMeeting(input: $input) {
    response{
      ${responseField}
    }
    data{
      ${scheduledMeetingField}
    }
  }
}
`

export const STAMP_MEETING_MINUTES = gql`
  mutation StampMeetingMinutes($input: StampMinutesDocumentsInputObjects!) {
    stampMeetingMinutes(input: $input) {
      response {
        ${responseField}
      }
    }
  }
`


export const UPDATE_MEETING_MAX_INVITEES_LIMIT = gql`
  mutation UpdateMeetingMaxInviteesLimitMutation($input: MeetingMaxInviteeInputObject!) {
    updateMeetingMaxInviteesLimitMutation(input: $input) {
      response{
        ${responseField}
      }
    }
  }
`
