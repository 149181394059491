import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  deleteProjectComponent,
  editProjectComponent,
  getProjectComponentByUid,
  removeProjectComponent,
  saveProjectComponent,
  upsertProjectComponent,
  upsertProjectComponents
} from './project-component.actions';
import {map, switchMap} from 'rxjs/operators';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {Apollo} from 'apollo-angular';
import {
  DELETE_PROJECT_COMPONENT,
  GET_PROJECT_COMPONENT_BY_UID,
  SAVE_PROJECT_COMPONENT,
  UPDATE_PROJECT_COMPONENT
} from './project-component.graphql';
import {loadProjectComponentTasks} from '../project-component-task/project-component-task.actions';

@Injectable()
export class ProjectComponentEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getProjectComponentByUid),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_PROJECT_COMPONENT_BY_UID,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(upsertProjectComponents({projectComponents: result?.data}));
            if (result?.data[0]?.projectTasks !== null){
              this.store.dispatch(loadProjectComponentTasks({ projectComponentTasks: result?.data[0]?.projectTasks }));
            }
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveProjectComponent),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_PROJECT_COMPONENT,
        variables: { inputDto: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertProjectComponent({projectComponent: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  update = createEffect(() => this.actions$.pipe(
    ofType(editProjectComponent),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_PROJECT_COMPONENT,
        variables: { inputDto: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertProjectComponent({projectComponent: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeProjectComponent),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_PROJECT_COMPONENT,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteProjectComponent({id: action?.id}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService
  ) {
  }
}
