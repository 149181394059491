import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {
  actionDocumentCategory,
  deleteDocumentCategory,
  getAllDocumentCategories,
  loadDocumentCategories,
  upsertDocumentCategory
} from './document-category.actions';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {GET_DOCUMENT_CATEGORY, SAVE_DOCUMENT_CATEGORY} from './document-category.graphql';
import {ActionTypeEnum} from '../../../../shared/enums/data.enums';
import {SettingsService} from '../../../../services/settings.service';


@Injectable()
export class DocumentCategoryEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllDocumentCategories),
    switchMap(() => {
      return this.apollo.query({
        query: GET_DOCUMENT_CATEGORY
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.store.dispatch(loadDocumentCategories({DocumentCategories: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(actionDocumentCategory),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_DOCUMENT_CATEGORY,
        variables: { inputDto: action.documentCategoryDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            if (action.documentCategoryDto.actionType === ActionTypeEnum.delete){
              this.store.dispatch(deleteDocumentCategory({id: action.id}));
              this.settingsService.reloadCurrentRoute(null);
            }
            this.store.dispatch(upsertDocumentCategory({documentCategory: result?.data}));

          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
  ) {
  }
}
