import gql from 'graphql-tag';
import {responseField} from '../../base.graphql';

export const translationFields = `
  id
  en
  sw
  keyword
`;

export const GET_ALL_TRANSLATIONS = gql`
  query getTranslations {
    getTranslations {
      response {
        ${responseField}
      }
      data {
        ${translationFields}
      }
    }
  }
`;

export const CREATE_TRANSLATIONS = gql`
  mutation saveTranslations($translations: [TranslationInput]) {
    saveTranslations(translations: $translations){
      response {
        code
        message
      }
      data {
        ${translationFields}
      }
    }
  }
`;

export const REMOVE_TRANSLATIONS = gql`
  mutation deleteTranslations($keyword: String) {
    deleteTranslations(keyword: $keyword){
      response {
        code
        message
      }
    }
  }
`;
