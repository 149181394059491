import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {
  deleteAssignmentConfiguration,
  editAssignmentConfiguration,
  getAllAssignmentConfigurations,
  loadAssignmentConfigurations,
  removeAssignmentConfiguration,
  saveAssignmentConfiguration,
  upsertAssignmentConfiguration
} from './assignment-configuration.actions';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {
  GET_TASK_ASSIGNMENT_DESIGNATIONS_CONFIGURATION,
  REMOVE_DESIGNATION_ASSIGNMENT_CONFIGURATION,
  SAVE_TASK_DESIGNATION_ASSIGNMENT_CONFIGURATION,
  UPDATE_DESIGNATION_ASSIGNATION_CONFIGURATION
} from './assignment-configuration.graphql';
import {SettingsService} from '../../../../services/settings.service';

@Injectable()
export class AssignmentConfigurationEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllAssignmentConfigurations),
    switchMap(() => {
      return this.apollo.query({
        query: GET_TASK_ASSIGNMENT_DESIGNATIONS_CONFIGURATION
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadAssignmentConfigurations({assignmentConfigurations: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveAssignmentConfiguration),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_TASK_DESIGNATION_ASSIGNMENT_CONFIGURATION,
        variables: { input: action.assignmentConfigurationDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertAssignmentConfiguration({assignmentConfiguration: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editAssignmentConfiguration),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_DESIGNATION_ASSIGNATION_CONFIGURATION,
        variables: { input: action.assignmentConfigurationDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertAssignmentConfiguration({assignmentConfiguration: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeAssignmentConfiguration),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: REMOVE_DESIGNATION_ASSIGNMENT_CONFIGURATION,
        variables: { uniqueId: action.uniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteAssignmentConfiguration({ id: action.id }));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apollo: Apollo,
    private notificationService: NotificationService,
    private settingsService: SettingsService
  ) {
  }
}
