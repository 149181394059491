import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {SmsConfig} from './sms-config.model';
import * as SmsConfigActions from './sms-config.actions';

export const smsConfigsFeatureKey = 'smsConfigs';

export interface State extends EntityState<SmsConfig> {
  // additional entities state properties
}

export const adapter: EntityAdapter<SmsConfig> = createEntityAdapter<SmsConfig>({
});

// export const adapter: EntityAdapter<SmsConfig> = createEntityAdapter<SmsConfig>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const smsConfigReducer = createReducer(
  initialState,
  on(SmsConfigActions.addSmsConfig,
    (state, action) => adapter.addOne(action.smsConfig, state)
  ),
  on(SmsConfigActions.upsertSmsConfig,
    (state, action) => adapter.upsertOne(action.smsConfig, state)
  ),
  on(SmsConfigActions.addSmsConfigs,
    (state, action) => adapter.addMany(action.smsConfigs, state)
  ),
  on(SmsConfigActions.upsertSmsConfigs,
    (state, action) => adapter.upsertMany(action.smsConfigs, state)
  ),
  on(SmsConfigActions.updateSmsConfig,
    (state, action) => adapter.updateOne(action.smsConfig, state)
  ),
  on(SmsConfigActions.updateSmsConfigs,
    (state, action) => adapter.updateMany(action.smsConfigs, state)
  ),
  on(SmsConfigActions.deleteSmsConfig,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(SmsConfigActions.deleteSmsConfigs,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(SmsConfigActions.loadSmsConfigs,
    (state, action) => adapter.setAll(action.smsConfigs, state)
  ),
  on(SmsConfigActions.clearSmsConfigs,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return smsConfigReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllSmsConfigs = selectAll;
export const getSmsConfigById = selectIds;
export const getSmsConfigEntities = selectEntities;
