import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Documentation} from './documentation.model';
import * as DocumentationActions from './documentation.actions';

export const documentationsFeatureKey = 'documentation';

export interface State extends EntityState<Documentation> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Documentation> = createEntityAdapter<Documentation>({
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const DocumentationReducer = createReducer(
  initialState,
  on(DocumentationActions.addDocumentation,
    (state, action) => adapter.addOne(action.documentation, state)
  ),
  on(DocumentationActions.upsertDocumentation,
    (state, action) => adapter.upsertOne(action.documentation, state)
  ),
  on(DocumentationActions.addDocumentations,
    (state, action) => adapter.addMany(action.documentations, state)
  ),
  on(DocumentationActions.upsertDocumentations,
    (state, action) => adapter.upsertMany(action.documentations, state)
  ),
  on(DocumentationActions.updateDocumentation,
    (state, action) => adapter.updateOne(action.documentation, state)
  ),
  on(DocumentationActions.updateDocumentations,
    (state, action) => adapter.updateMany(action.documentations, state)
  ),
  on(DocumentationActions.deleteDocumentation,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(DocumentationActions.deleteDocumentations,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(DocumentationActions.loadDocumentations,
    (state, action) => adapter.setAll(action.documentations, state)
  ),
  on(DocumentationActions.clearDocumentations,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return DocumentationReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getDocumentations = selectAll;
