import {createSelector} from '@ngrx/store';
import * as fromAttachmentConfigReducer from './attachment-configuration.reducer';
import {attachmentConfigsFeatureKey} from './attachment-configuration.reducer';
import {AppState} from '../../index';

export const currentAttachmentConfigState = (state: AppState) => state[attachmentConfigsFeatureKey];

export const selectAllAttachmentConfigs = createSelector(currentAttachmentConfigState, fromAttachmentConfigReducer.selectAll);

export const selectAttachmentConfigById = (id: string) => createSelector(
  selectAllAttachmentConfigs,
  (attachmentConfigs) => attachmentConfigs.find((attachmentConfig) => attachmentConfig.id === id)
);

export const selectAttachmentConfigByStage = (requiredFor: string) => createSelector(
  selectAllAttachmentConfigs,
  (d) => d.filter((r) => r.attachmentRequiredFor === requiredFor)
);
