import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChartComponent} from 'ng-apexcharts';
import {ChartOptions} from '../../../interfaces/data.interfaces';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {SettingsService} from '../../../../services/settings.service';
import {ActivatedRoute} from '@angular/router';
import {getProjectByUid} from '../../../../store/entities/projects/project/project.actions';
import {Project} from '../../../../store/entities/projects/project/project.model';
import {selectProjectByUid} from '../../../../store/entities/projects/project/project.selectors';
import {QueryService} from '../../../../services/query.service';
import {ProjectStatusEnum} from '../../../enums/data.enums';
import {NotificationService} from '../../../../services/notification.service';
import {ProjectReportComponent} from '../project-report/project-report.component';
import {removeProjectComponent} from '../../../../store/entities/projects/project-component/project-component.actions';
import {ProjectComponent} from '../../../../store/entities/projects/project-component/project-component.model';
import {
  selectAllProjectComponents
} from '../../../../store/entities/projects/project-component/project-component.selectors';
import {
  ProjectComponentTaskFormComponent
} from '../../../../modules/portal/portal-project/portal-project-forms/project-component-task-form/project-component-task-form.component';
import {
  ProjectComponentFormComponent
} from '../../../../modules/portal/portal-project/portal-project-forms/project-component-form/project-component-form.component';
import {
  ProjectChallengeFormComponent
} from '../../../../modules/portal/portal-project/portal-project-forms/project-challenge-form/project-challenge-form.component';
import {StorageService} from '../../../../services/storage-service.service';
import {UserTypeEnum} from '../../../../store/entities/settings/user/user.model';
import {GET_PROJECT_GANT_CHART} from '../../../../store/entities/projects/project/project.graphql';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit, OnDestroy {

  title: string;
  projectUid: string;

  project$: Observable<Project>;
  components$: Observable<ProjectComponent[]>;

  documentTableColumn: any;
  componentTableColumn: any;

  subscriptions: Subscription = new Subscription();

  projectStatus = ProjectStatusEnum;
  userTypeEnum = UserTypeEnum;
  loggedInUserType: UserTypeEnum;
  showGantChart = false;

  @ViewChild('documentArea') documentArea: ChartComponent;
  gantChartOption: Partial<ChartOptions>;

  constructor(
    private store: Store<AppState>,
    private settingsService: SettingsService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private queryService: QueryService,
    private notificationService: NotificationService,
  ) {
    this.projectUid = this.activatedRoute.snapshot.paramMap.get('uid');
    this.loggedInUserType = this.storageService.get('category');

    this.store.dispatch(getProjectByUid({uid: this.projectUid, withComponents: true}));
  }

  ngOnInit(): void {
    this.title = 'Project Detail';

    this.project$ = this.store.select(selectProjectByUid(this.projectUid));
    this.components$ = this.store.select(selectAllProjectComponents);

    this.componentTableColumn = {
      no: 'No',
      name: 'Component Title',
      percentCompositionOnProject: 'Percent Composition',
      budget: 'Budget',
      formattedStartDate: 'Start Date',
      // component_duration: 'Duration',
      formattedHandoverDate: 'Completion Date',
      location: 'Location',
      manager: 'Manager',
      progressPercentage: 'Progress (%)',
      formattedRecordedOn: 'Recorded On',
      action: ''
    };

    this.documentTableColumn = {
      no: 'No',
      name: 'Document Title',
      action: ''
    };

    this.getProjectGantChart();
  }

  countProjectComponentByStatus(components: ProjectComponent[], status: ProjectStatusEnum): number{
    return components?.filter( (r) => r?.status === status)?.length;
  }

  viewProgress(project: Project) {
    this.settingsService.openModal(project, ProjectReportComponent, '85%');
  }

  componentAction(project: Project, component: any) {
    this.settingsService.openModal({project, component}, ProjectComponentFormComponent);
  }

  componentTaskAction(task: any, component: any) {
    this.settingsService.openModal({task, component}, ProjectComponentTaskFormComponent);
  }

  removeComponent(res: any) {
    const message = 'Are you sure you want to remove this project component ? ';
    this.notificationService.confirmation(message).then(data => {
        if (data) {
          this.store.dispatch(removeProjectComponent({ uid: res?.uniqueId, id: res?.id }));
        }
      }
    );
  }

  actionChallengeAndWayForward(project: Project) {
    this.settingsService.openModal({project}, ProjectChallengeFormComponent);
  }

  getProjectGantChart() {

    this.subscriptions.add(
      this.queryService.query(GET_PROJECT_GANT_CHART, { uid: this.projectUid})
        .subscribe( (queryData: any) => {
        if (queryData[0]?.response?.code) {
          const result = queryData[0]?.data as
            { name: string, endDate: string, startDate: string, status: string }[];

          this.gantChartOption = {
              title: { text: 'Project Gant Chart', style: {fontSize:  '15px', fontWeight:  'bold', fontFamily:  'Quicksand'}},
            series: [
              {
                data: result?.map( d =>
                  (
                    {
                      x: d?.name,
                      y: [new Date(d?.startDate).getTime(), new Date(d?.endDate).getTime()]
                    }
                  )
                )
              }
            ],
            chart: {
              height: 390,
              type: 'rangeBar',
              zoom: {
                enabled: false
              }
            },
            colors: ['#0ea09d', '#36BDCB'],
            plotOptions: {
              bar: {
                horizontal: true,
                isDumbbell: false,
              }
            },
            legend: {
              show: false,
            },
            fill: {
              type: 'gradient',
              gradient: {
                gradientToColors: ['#36BDCB'],
                inverseColors: false
              }
            },
            xaxis: {type: 'datetime'}
          };
        }else {
          this.notificationService.errorMessage(queryData[0]?.response?.message);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
