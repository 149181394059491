import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {
  attachSignedMinutesScheduledMeeting,
  closeWithdrawScheduledMeeting,
  editScheduledMeeting,
  getAllScheduledMeetings,
  getMinistryMeetings,
  loadScheduledMeetings,
  publishMeeting,
  removeScheduledMeeting,
  saveScheduledMeeting,
  sendMeetingNotice, stampSignedMinutes,
  unPublishMeeting,
  updateScheduleMeetingInviteesMaxLimit,
  upsertScheduledMeeting
} from './scheduled-meeting.actions';
import {SettingsService} from '../../../../services/settings.service';
import {
  ATTACH_SIGNED_MINUTES_MEETING,
  CLOSE_WITHDRAW_MEETING,
  DELETE_SCHEDULED_MEETING,
  GET_MEETINGS_BY_MULTIPLE_STAGES,
  GET_SCHEDULED_MEETINGS,
  PUBLISH_MEETING,
  SAVE_SCHEDULED_MEETING,
  SEND_MEETING_NOTICE, STAMP_MEETING_MINUTES,
  UNPUBLISH_MEETING,
  UPDATE_MEETING_MAX_INVITEES_LIMIT,
  UPDATE_SCHEDULED_MEETING
} from './scheduled-meeting.graphql';
import {Apollo} from 'apollo-angular';
import {clearPages, insertPage} from '../../page/page.actions';
import {MeetingStatusTypeEnum} from './scheduled-meeting.model';
import { getMeetingsAgendas } from '../meeting-agenda/meeting-agenda.actions';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {IndexedDBTableName} from '../../../../shared/interfaces/table.interface';
import {lastValueFrom} from 'rxjs';

@Injectable()
export class ScheduledMeetingEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllScheduledMeetings),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_SCHEDULED_MEETINGS,
        variables: { filtering: action.filtering }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          this.store.dispatch(clearPages());

          if (result !== null && result?.response?.code === 9000) {
            if (!action.filtering?.stage && !action?.isRepository) {
              const data = result?.data.filter(a => a.meetingStatus !== MeetingStatusTypeEnum.CLOSED);
              this.store.dispatch(insertPage({ page: {
                ...result?.page,
                  totalElements: data.filter(a => a.meetingStatus === MeetingStatusTypeEnum.DRAFT).length
              } }));
              this.store.dispatch(loadScheduledMeetings({ scheduledMeetings: data }));
            } else {
              this.store.dispatch(insertPage({ page: { ...result?.page } }));
              this.store.dispatch(loadScheduledMeetings({ scheduledMeetings: result?.data }));
            }
          }

          if (action?.isOffline) {
            lastValueFrom(this.dbService.clear(IndexedDBTableName.active_meeting)).then(() => {
              lastValueFrom(this.dbService.add(IndexedDBTableName.active_meeting, {data: JSON.stringify(result?.data[0])})).then();
            });
          }
        })
      );
    })
  ), { dispatch: false });

  stageMultiple$ = createEffect(() => this.actions$.pipe(
    ofType(getMinistryMeetings),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_MEETINGS_BY_MULTIPLE_STAGES,
        variables: { ministryFilter: action.ministryFilter}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000){
            this.store.dispatch(loadScheduledMeetings({scheduledMeetings: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveScheduledMeeting),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_SCHEDULED_MEETING,
        variables: { meetting: action.meetting }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            // this.store.dispatch(upsertScheduledMeeting({ scheduledMeeting: result?.data }));
            this.store.dispatch(getAllScheduledMeetings({ filtering: { stage: null } }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  updateInviteesMaxLimit = createEffect(() => this.actions$.pipe(
    ofType(updateScheduleMeetingInviteesMaxLimit),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_MEETING_MAX_INVITEES_LIMIT,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(getAllScheduledMeetings({ filtering: { meetingUniqueId: action.input.meeting } }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  attachMinutes = createEffect(() => this.actions$.pipe(
    ofType(attachSignedMinutesScheduledMeeting),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: ATTACH_SIGNED_MINUTES_MEETING,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertScheduledMeeting({ scheduledMeeting: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  closeWithdrawScheduledMeeting$ = createEffect(() => this.actions$.pipe(
    ofType(closeWithdrawScheduledMeeting),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: CLOSE_WITHDRAW_MEETING,
        variables: { meetingDto: action.meetingDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageWithRedirect(result?.response?.message, '/meetings/meeting-repository').then();
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  notice = createEffect(() => this.actions$.pipe(
    ofType(sendMeetingNotice),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SEND_MEETING_NOTICE,
        variables: { notice: action.notice }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.settingsService.reloadCurrentRoute(null);
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  publishMeeting$ = createEffect(() => this.actions$.pipe(
    ofType(publishMeeting),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: PUBLISH_MEETING,
        variables: { meetingUniqueId: action.meetingUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.settingsService.reloadCurrentRoute(null);
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  unPublishMeeting$ = createEffect(() => this.actions$.pipe(
    ofType(unPublishMeeting),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UNPUBLISH_MEETING,
        variables: { meetingUniqueId: action.meetingUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.settingsService.reloadCurrentRoute(null);
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editScheduledMeeting),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_SCHEDULED_MEETING,
        variables: { meetting: action.meetting }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(upsertScheduledMeeting({ scheduledMeeting: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  delete = createEffect(() => this.actions$.pipe(
    ofType(removeScheduledMeeting),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_SCHEDULED_MEETING,
        variables: { meetingUniqueId: action.meetingUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.settingsService.reloadCurrentRoute(null);
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  stampSignedMinutes = createEffect(() => this.actions$.pipe(
    ofType(stampSignedMinutes),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: STAMP_MEETING_MINUTES,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(getMeetingsAgendas({ meetingUniqueId: action?.input?.meetingUniqueId }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
    private dbService: NgxIndexedDBService,
  ) { }

}
