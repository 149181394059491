import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {PageableParam} from '../../page/page.model';
import {Recommendations} from './recommendations.model';

export const loadRecommendationss = createAction(
  '[Recommendations/API] Load Recommendationss',
  props<{ recommendationss: Recommendations[] }>()
);

export const addRecommendations = createAction(
  '[Recommendations/API] Add Recommendations',
  props<{ recommendations: Recommendations }>()
);

export const upsertRecommendations = createAction(
  '[Recommendations/API] Upsert Recommendations',
  props<{ recommendations: Recommendations }>()
);

export const addRecommendationss = createAction(
  '[Recommendations/API] Add Recommendationss',
  props<{ recommendationss: Recommendations[] }>()
);

export const upsertRecommendationss = createAction(
  '[Recommendations/API] Upsert Recommendationss',
  props<{ recommendationss: Recommendations[] }>()
);

export const updateRecommendations = createAction(
  '[Recommendations/API] Update Recommendations',
  props<{ recommendations: Update<Recommendations> }>()
);

export const updateRecommendationss = createAction(
  '[Recommendations/API] Update Recommendationss',
  props<{ recommendationss: Update<Recommendations>[] }>()
);

export const deleteRecommendations = createAction(
  '[Recommendations/API] Delete Recommendations',
  props<{ id: number }>()
);

export const deleteRecommendationss = createAction(
  '[Recommendations/API] Delete Recommendationss',
  props<{ ids: number[] }>()
);

export const clearRecommendationss = createAction(
  '[Recommendations/API] Clear Recommendationss'
);

export const getAllRecommendationsPageable = createAction(
  '[Recommendations/API] get all active Recommendationss pageable',
  props<{ pageable: PageableParam, stageName: string, meetingStatus?: string}>()
);


export const getAllRecommendations = createAction(
  '[Recommendations/API] Get All Agenda Recommendations',
  props<{ agendaUniqueId: string }>()
);

export const saveRecommendations = createAction(
  '[Recommendations/API] Save Recommendations',
  props<{ recommendation: any }>()
);

export const editRecommendations = createAction(
  '[Recommendations/API] edit Recommendations',
  props<{ recommendation: any }>()
);

export const removeRecommendations = createAction(
  '[Recommendations/API] remove Scheduled Meeting',
  props<{ uniqueId: string, id: number }>()
);



