import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {SettingsService} from '../../../../services/settings.service';
import {Apollo} from 'apollo-angular';
import {
  deleteRecommendations,
  editRecommendations,
  getAllRecommendations,
  loadRecommendationss,
  removeRecommendations,
  saveRecommendations,
  upsertRecommendations
} from './recommendations.actions';
import {
  DELETE_RECOMMENDATION,
  GET_RECOMMENDATIONS,
  SAVE_RECOMMENDATION,
  UPDATE_RECOMMENDATION
} from './recommendations.graphql';

@Injectable()
export class RecommendationsEffects {


  get = createEffect(() => this.actions$.pipe(
    ofType(getAllRecommendations),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_RECOMMENDATIONS,
        variables: { agendaUniqueId: action.agendaUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadRecommendationss({ recommendationss: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });


  save = createEffect(() => this.actions$.pipe(
    ofType(saveRecommendations),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_RECOMMENDATION,
        variables: { recommendation: action.recommendation }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertRecommendations({ recommendations: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editRecommendations),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_RECOMMENDATION,
        variables: { recommendation: action.recommendation }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertRecommendations({ recommendations: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  delete = createEffect(() => this.actions$.pipe(
    ofType(removeRecommendations),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_RECOMMENDATION,
        variables: { uniqueId: action.uniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteRecommendations({id: action.id}))

          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
  ) { }

}
