import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {DocumentCategory} from './document-category.model';
import * as DocumentCategoryActions from './document-category.actions';

export const documentCategoriesFeatureKey = 'DocumentCategories';

export interface State extends EntityState<DocumentCategory> {
  // additional entities state properties
}
//
export function sortByDocumentCategoryName(a: DocumentCategory, b: DocumentCategory): any {
  // return a.category_name.localeCompare(b.category_name);
}

export const adapter: EntityAdapter<DocumentCategory> = createEntityAdapter<DocumentCategory>({
  // sortComparer: sortByDocumentCategoryName
});

// export const adapter: EntityAdapter<DocumentCategory> = createEntityAdapter<DocumentCategory>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const documentCategoryReducer = createReducer(
  initialState,
  on(DocumentCategoryActions.addDocumentCategory,
    (state, action) => adapter.addOne(action.documentCategory, state)
  ),
  on(DocumentCategoryActions.upsertDocumentCategory,
    (state, action) => adapter.upsertOne(action.documentCategory, state)
  ),
  on(DocumentCategoryActions.addDocumentCategories,
    (state, action) => adapter.addMany(action.DocumentCategories, state)
  ),
  on(DocumentCategoryActions.upsertDocumentCategories,
    (state, action) => adapter.upsertMany(action.DocumentCategories, state)
  ),
  on(DocumentCategoryActions.updateDocumentCategory,
    (state, action) => adapter.updateOne(action.documentCategory, state)
  ),
  on(DocumentCategoryActions.updateDocumentCategories,
    (state, action) => adapter.updateMany(action.DocumentCategories, state)
  ),
  on(DocumentCategoryActions.deleteDocumentCategory,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(DocumentCategoryActions.deleteDocumentCategories,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(DocumentCategoryActions.loadDocumentCategories,
    (state, action) => adapter.setAll(action.DocumentCategories, state)
  ),
  on(DocumentCategoryActions.clearDocumentCategories,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return documentCategoryReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllDocumentCategories = selectAll;
export const getDocumentCategoryById = selectIds;
export const getDocumentCategoryEntities = selectEntities;
