import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActionTypeEnum} from '../../../../shared/enums/data.enums';


@Injectable({
  providedIn: 'root'
})
export class ForthcomingService {

  constructor(
    private http: HttpClient
  ) {
  }

  getAll(ministryUid){
    if (ministryUid){
      return this.http.get('forthcoming_papers_management/' + ministryUid);
    }else {
      return this.http.get('forthcoming_papers_management');
    }
  }

  action(formData){
    if ( formData?.actionType === ActionTypeEnum.create){
      return this.http.post('forthcoming_papers_management', formData);
    }
    else if ( formData?.actionType === ActionTypeEnum.update){
      return this.http.put('forthcoming_papers_management', formData);
    }
    else if ( formData?.actionType === ActionTypeEnum.delete){
      return this.http.delete('forthcoming_papers_management/' + formData?.id);
    }
  }


}
