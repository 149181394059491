import {createSelector} from '@ngrx/store';
import * as fromReducer from './project-component.reducer';
import {AppState} from '../../index';
import {DatePipe} from '@angular/common';
import {ProjectComponent} from './project-component.model';
import {ProjectStatusEnum} from '../../../../shared/enums/data.enums';

export const currentProjectComponentState = (state: AppState) => state[fromReducer.projectComponentsFeatureKey];
export const selectAllProjectComponentsFromReducer = createSelector(currentProjectComponentState, fromReducer.selectAll);

export const selectAllProjectComponents = createSelector(
  selectAllProjectComponentsFromReducer,
  (components) => {
    return components.map( component => {
      return {
        ...component,
        editable: isProgressExist(component),
        progressPercentage: calculateProjectProgress(component),
        formattedStartDate: new DatePipe('en-GB').transform(component?.startDate, 'dd MMM yyyy'),
        formattedHandoverDate: new DatePipe('en-GB').transform(component?.handoverDate, 'dd MMM yyyy'),
        formattedRecordedOn: new DatePipe('en-GB').transform(component?.recordedOn, 'dd MMM yyyy'),
        projectTasks: component?.projectTasks?.map( (res) => {
          return {
            ...res,
            formattedStartDate: new DatePipe('en-GB').transform(res?.startDate, 'dd MMM yyyy'),
            formattedHandoverDate: new DatePipe('en-GB').transform(res?.handoverDate, 'dd MMM yyyy'),
          };
        })
      };
    });
  });

export const selectProjectComponentByUid = (uid: string) => createSelector(
  selectAllProjectComponents, (d) => d.find(r => r?.uniqueId === uid));

function calculateProjectProgress(component: ProjectComponent): number{
  const completedTask = component?.projectTasks
    ?.filter( (task) => task?.status === ProjectStatusEnum.COMPLETED)?.length;
  return (completedTask / component?.projectTasks?.length) * 100;
}

function isProgressExist(component: ProjectComponent): boolean{
  let allowProjectToBeEdited = true;
  component?.projectTasks?.forEach( (task) => {
    if (task?.taskProgress?.length > 0){
      allowProjectToBeEdited = false;
    }
  });
  return allowProjectToBeEdited;
}
