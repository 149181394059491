import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const projectRecommendationField = `
  ${baseField}
  descriptions
  recordedOn
`;


export const GET_PROJECT_RECOMMENDATION_PROJECT_UID = gql`
  query allProjectRecommendations($uid: String!) {
    allProjectRecommendations(project: $uid){
      response{
        ${responseField}
      }
      data{
        ${projectRecommendationField}
      }
    }
  }
`;

export const SAVE_PROJECT_RECOMMENDATION = gql`
  mutation createProjectRecommendationsMutation($inputDto: ProjectRecommendationInputObjects!) {
    createProjectRecommendationsMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectRecommendationField}
      }
    }
  }
`;

export const UPDATE_PROJECT_RECOMMENDATION = gql`
  mutation updateProjectRecommendationsMutation($inputDto: ProjectRecommendationInputObjects!) {
    updateProjectRecommendationsMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectRecommendationField}
      }
    }
  }
`;

export const DELETE_PROJECT_RECOMMENDATION = gql`
  mutation deleteProjectRecommendationsMutation($uid: String!) {
    deleteProjectRecommendationsMutation(uniqueId: $uid){
      response{
        ${responseField}
      }
    }
  }
`;

