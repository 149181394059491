import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Project} from '../../../../../store/entities/projects/project/project.model';
import {AppState} from '../../../../../store/entities';
import {NotificationService} from '../../../../../services/notification.service';
import {saveProjectImage} from '../../../../../store/entities/projects/project-image/project-image.actions';
import * as sanitizeHtml from 'sanitize-html';

@Component({
  selector: 'app-project-image-form',
  templateUrl: './project-image-form.component.html',
  styleUrls: ['./project-image-form.component.scss']
})
export class ProjectImageFormComponent implements OnInit {

  title: string;
  project: Project;
  form: UntypedFormGroup;

  editorStyle: any;

  images: File[] = [];
  imageDtoList: any[];

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.project = data;
  }

  ngOnInit(): void {
    this.title = 'On Site Evidence' ;

    this.form = this.fb.group({
      project: [this.project?.uniqueId, Validators.required],
      description: [null, Validators.required],
      attachmentDto: [null, Validators.required],
    });

    this.editorStyle = {
      height: 'auto'
    };

    if (this.project){
      this.form.get('description').patchValue(sanitizeHtml(this.project?.description));
    }
  }


  onImageSelect(event) {
    this.images.push(...event.addedFiles);
    this.imageDtoList = [];
    this.images.forEach((fileData) => {

      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onload = () => {
        this.imageDtoList.push({
          attachmentName: fileData.name,
          attachmentExtension: fileData.type,
          base64Data: reader?.result?.toString()?.split(',')[1],
        });
        this.form.patchValue({ attachmentDto: this.imageDtoList });
      };

    });
  }

  onImageRemove(event) {
    this.images.splice(this.images.indexOf(event), 1);
  }

  add(formData) {
    if (formData.attachmentDto.length === 0){
      this.notificationService.errorMessage('No Images Attached');
      return;
    }
    this.store.dispatch(saveProjectImage({ formData}));
  }

}
