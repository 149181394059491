import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {changePassword} from 'src/app/store/entities/settings/user/user.actions';
import {User} from 'src/app/store/entities/settings/user/user.model';
import {AppState} from 'src/app/store/entities';
import {CustomValidators} from '../../../customs/custom-validators';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {

  changePasswordForm: UntypedFormGroup;
  title: any;
  userDetails: User;

  oldPasswordHide = true;
  newPasswordHide = true;
  confirmPasswordHide = true;
  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.userDetails = data;
  }

  ngOnInit() {
    this.title = 'Change Password';
    this.initChangePasswordForm();
  }


  initChangePasswordForm() {
    const specialCharacters = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    this.changePasswordForm = this.fb.group({
      oldPassword: [null, Validators.required],
      newPassword: [null,
        [
          // 1. Password Field is Required
          Validators.required,
          // 2. check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {hasNumber: true}),
          // 3. check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
          // 4. check whether the entered password has a lower-case letter
          CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
          // 5. check whether the entered password has a special character
          CustomValidators.patternValidator(specialCharacters, {hasSpecialCharacters: true}),
          // 6. Has a minimum length of 6 characters
          Validators.minLength(8)
        ]
      ],
      confirmPassword: [null,
        [
          // 1. Password Field is Required
          Validators.required,
          // 2. check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {hasNumber: true}),
          // 3. check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
          // 4. check whether the entered password has a lower-case letter
          CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
          // 5. check whether the entered password has a special character
          CustomValidators.patternValidator(specialCharacters, {hasSpecialCharacters: true}),
          // 6. Has a minimum length of 6 characters
          Validators.minLength(8)
        ]
      ],
    });
  }

  saveChangePassword(passwordDto) {
    this.store.dispatch(changePassword({
      oldPassword: passwordDto.oldPassword,
      newPassword: passwordDto.newPassword,
      confirmPassword: passwordDto.confirmPassword
    }));
  }
}
