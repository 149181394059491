import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {ApolloClientOptions, ApolloLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {onError} from '@apollo/client/link/error';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../environments/environment';
import {NotificationService} from './services/notification.service';

const uri = environment.GRAPHQL_API;

export function errorLink(){

  const notificationService = new NotificationService();

  return onError(({ graphQLErrors, networkError }) => {
    console.error('GraphQLErrors: ', graphQLErrors);
    if (graphQLErrors) {
      graphQLErrors?.map(({ message, locations, path }) => {
          if (message.search('Access is denied') > 0) {
            notificationService.errorMessage('Hauna Ruhusa kufika Huduma Unayohitaji');
          }
          else if (message.search('getUserRolesAndPermissions') === 0){
            localStorage.clear();
          }
          else if (message.search('captureDeviceLocation') === 0){
            notificationService.errorMessage('Hitilafu: ' + message);
          }
        }
      );
    }

    if (networkError) {
      if (environment.production) {
        console.log(`[Network error]: message: ${networkError.message}, name: ${networkError.name}, stack:  ${networkError.stack}`);
      } else {
        console.log(`[Network error]: message: ${networkError.message}, name: ${networkError.name}, stack:  ${networkError.stack}`);
      }
    }

  });
}

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {

  return {
    link: ApolloLink.from([
      errorLink(),
      httpLink.create({uri})
    ]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
      query: {
        fetchPolicy: 'network-only',
      },
      mutate: {
        fetchPolicy: 'network-only'
      }
    }
  };
}

@NgModule({
  imports: [ApolloModule],
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, MatDialog],
    },
  ],
})
export class GraphQLModule {
}
