<app-modal-header [title]="title"></app-modal-header>

<div *ngIf="!showAll" class="col-md-12 animate__animated animate__fadeInDown">

    <div class="col-md-12">
      <div class="row">
        <div class="ext-container col-md-12">
          <mat-card class="inst-details">
            <div class="row">

              <div class="col-md-3 my-2">
                <span class="title">{{'Directive' | concat | translate}}</span>
                <div>{{ selectedDirective?.directive_descriptions}}</div>
              </div>

              <div class="col-md-3 my-2">
                <span class="title">{{'Decision' | concat | translate}}</span>
                <div>{{ selectedDirective?.directive_decision }}</div>
              </div>

              <div class="col-md-3 my-2">
                <span class="title">{{'Committee' | concat | translate}}</span>
                <div>{{ selectedDirective?.directive_committee }}</div>
              </div>

              <div class="col-md-3 my-2">
                <button (click)="showAll = !showAll" class="icon-holder" mat-icon-button matTooltip="Close View">
                  <mat-icon class="material-symbols-outlined">close</mat-icon>
                </button>
              </div>


            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <ng-container *ngIf="selectedDirective?.directive_to_person; else assignedToCommittee">
        <h4 class="pt-1"><b>{{'Assigned Staff' | concat | translate}}:</b> {{ selectedDirective?.assignedStaff }}</h4>
      </ng-container>


      <ng-template #assignedToCommittee>
        <h4 class="pt-1"><b>{{'Assigned Committee' | concat | translate}}:</b> {{ selectedDirective?.directive_committee}}</h4>
      </ng-template>
    </div>


    <div class="col-md-12" >
      <hr>
      <h4 class="pt-1"><b>{{'Response' | concat | translate}}</b></h4>

      <table class="table table-hover table-striped table-bordered">
        <thead>
        <tr>
          <th>{{'No' | concat | translate}}.</th>
          <th>{{'Response Date' | concat | translate}}</th>
          <th>{{'Response' | concat | translate}}</th>
          <th>{{'Submitted By' | concat | translate}}</th>
          <th></th>
        </tr>
        </thead>

        <tbody>
        <ng-container *ngFor="let response of selectedDirective?.directive_responses; index as i; ">
          <tr>
            <td>{{ i + 1}}</td>
            <td>{{ response?.response_recorded_on | date : 'dd MMM yyyy'}}</td>
            <td> <span [innerHTML]="response?.response_descriptions"></span></td>
            <td>{{ response?.full_name}}</td>
            <td>
              <ng-container *ngIf="response?.response_attachments?.length > 0">
                <button (click)="viewAttachment(response?.response_attachments[0]?.attachment_path)" class="icon-holder" mat-icon-button
                        matTooltip="{{'View Attachment' | concat | translate}}">
                  <mat-icon svgIcon="icon_bill_document"></mat-icon>
                </button>
              </ng-container>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>

  </div>

<div *ngIf="showAll" class="col-md-12 px-0">
    <app-data-table [columnHeader]="tableColumns" [tableData$]="directives$">
      <ng-template let-data="data">
        <div class="action-icons">
          <button [matMenuTriggerFor]="menu" class="icon-holder" mat-icon-button>
            <mat-icon svgIcon="option"></mat-icon>
          </button>

          <button (click)="viewDetail(data)" class="icon-holder" mat-icon-button
                  matTooltip="{{'View Detail' | concat | translate}}">
            <mat-icon class="material-symbols-outlined">article</mat-icon>
          </button>

          <ng-container *ngIf="data?.directive_attachements?.length > 0">
            <button (click)="viewAttachment(data?.directive_attachements[0]?.attachment_path)" class="icon-holder" mat-icon-button
                    matTooltip="{{'View Attachment' | concat | translate}}">
              <mat-icon svgIcon="icon_bill_document"></mat-icon>
            </button>
          </ng-container>

          <mat-menu #menu="matMenu">

            <button (click)="addRespond(data)" mat-menu-item>
              <mat-icon class="material-symbols-outlined">send</mat-icon>
              {{'Respond' | concat | translate}}
            </button>

            <button (click)="addRespond(data)" *ngIf="data?.directive_responses?.length === 0" mat-menu-item>
              <mat-icon svgIcon="edit"></mat-icon>
              {{'Edit' | concat | translate}}
            </button>

            <button (click)="move(data, directiveMovementType.ASSOS)" mat-menu-item>
              <mat-icon class="material-symbols-outlined">exit_to_app</mat-icon>
              {{'Move' | concat | translate}}
            </button>

            <button (click)="move(data, directiveMovementType.RET)" mat-menu-item>
              <mat-icon class="material-symbols-outlined">low_priority</mat-icon>
              {{'Return' | concat | translate}}
            </button>

            <!--            <button (click)="directiveAction(data?.id, actionTypeEnum.activate)" *ngIf="!data?.is_active" mat-menu-item>-->
<!--              <mat-icon svgIcon="icon_activate"></mat-icon>-->
<!--              Activate-->
<!--            </button>-->

<!--            <button (click)="directiveAction(data?.id, actionTypeEnum.delete)" *ngIf="data?.is_active" mat-menu-item>-->
<!--              <mat-icon svgIcon="icon_deactivate"></mat-icon>-->
<!--              Deactivate-->
<!--            </button>-->

          </mat-menu>
        </div>
      </ng-template>
    </app-data-table>
  </div>
