import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {SettingsService} from '../../../../services/settings.service';
import {Apollo} from 'apollo-angular';
import {
  deleteDocumentComment,
  dismissDocumentComment,
  getAllDocumentComment,
  loadDocumentComments,
  saveDocumentComment,
  upsertDocumentComment
} from './document-comment.actions';
import {DISMISS_PAPER_COMMENT, GET_MY_PAPER_COMMENTS, SEND_PAPER_COMMENT} from './document-comment.graphql';

@Injectable()
export class DocumentCommentEffects {


  get = createEffect(() => this.actions$.pipe(
    ofType(getAllDocumentComment),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_MY_PAPER_COMMENTS,
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadDocumentComments({ documentComments: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });


  save = createEffect(() => this.actions$.pipe(
    ofType(saveDocumentComment),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SEND_PAPER_COMMENT,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessage(result?.response?.message);
            this.store.dispatch(upsertDocumentComment({ documentComment: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  dismiss = createEffect(() => this.actions$.pipe(
    ofType(dismissDocumentComment),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DISMISS_PAPER_COMMENT,
        variables: { uniqueId: action.uniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            if (action?.uniqueId) {
              this.notificationService.successMessage(result?.response?.message);
              this.store.dispatch(deleteDocumentComment({ id: action?.id }));
            } else {
              this.store.dispatch(getAllDocumentComment())
            }
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
  ) { }

}
