import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {FolderCategory} from './folder-category.model';
import * as FolderCategoryActions from './folder-category.actions';

export const folderCategoriesFeatureKey = 'FolderCategories';

export interface State extends EntityState<FolderCategory> {
  // additional entities state properties
}
//
export function sortByFolderCategoryName(a: FolderCategory, b: FolderCategory): any {
  // return a.category_name.localeCompare(b.category_name);
}

export const adapter: EntityAdapter<FolderCategory> = createEntityAdapter<FolderCategory>({
  // sortComparer: sortByFolderCategoryName
});

// export const adapter: EntityAdapter<FolderCategory> = createEntityAdapter<FolderCategory>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const folderCategoryReducer = createReducer(
  initialState,
  on(FolderCategoryActions.addFolderCategory,
    (state, action) => adapter.addOne(action.folderCategory, state)
  ),
  on(FolderCategoryActions.upsertFolderCategory,
    (state, action) => adapter.upsertOne(action.folderCategory, state)
  ),
  on(FolderCategoryActions.addFolderCategories,
    (state, action) => adapter.addMany(action.FolderCategories, state)
  ),
  on(FolderCategoryActions.upsertFolderCategories,
    (state, action) => adapter.upsertMany(action.FolderCategories, state)
  ),
  on(FolderCategoryActions.updateFolderCategory,
    (state, action) => adapter.updateOne(action.folderCategory, state)
  ),
  on(FolderCategoryActions.updateFolderCategories,
    (state, action) => adapter.updateMany(action.FolderCategories, state)
  ),
  on(FolderCategoryActions.deleteFolderCategory,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(FolderCategoryActions.deleteFolderCategories,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(FolderCategoryActions.loadFolderCategories,
    (state, action) => adapter.setAll(action.FolderCategories, state)
  ),
  on(FolderCategoryActions.clearFolderCategories,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return folderCategoryReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllFolderCategories = selectAll;
export const getFolderCategoryById = selectIds;
export const getFolderCategoryEntities = selectEntities;
