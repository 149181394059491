import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'concat',
  standalone: true
})
export class ConcatPipe implements PipeTransform {

  transform(value: any): any {
    return  value?.toLowerCase()?.trim()?.split(' ')?.join('-')?.replace('--', '-');
  }

}
