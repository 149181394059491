import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {PageableParam} from '../../page/page.model';
import {MinutesDirectives} from './minutes-directives.model';

export const loadMinutesDirectivess = createAction(
  '[MinutesDirectives/API] Load MinutesDirectivess',
  props<{ minutesDirectivess: MinutesDirectives[] }>()
);

export const addMinutesDirectives = createAction(
  '[MinutesDirectives/API] Add MinutesDirectives',
  props<{ minutesDirectives: MinutesDirectives }>()
);

export const upsertMinutesDirectives = createAction(
  '[MinutesDirectives/API] Upsert MinutesDirectives',
  props<{ minutesDirectives: MinutesDirectives }>()
);

export const addMinutesDirectivess = createAction(
  '[MinutesDirectives/API] Add MinutesDirectivess',
  props<{ minutesDirectivess: MinutesDirectives[] }>()
);

export const upsertMinutesDirectivess = createAction(
  '[MinutesDirectives/API] Upsert MinutesDirectivess',
  props<{ minutesDirectivess: MinutesDirectives[] }>()
);

export const updateMinutesDirectives = createAction(
  '[MinutesDirectives/API] Update MinutesDirectives',
  props<{ minutesDirectives: Update<MinutesDirectives> }>()
);

export const updateMinutesDirectivess = createAction(
  '[MinutesDirectives/API] Update MinutesDirectivess',
  props<{ minutesDirectivess: Update<MinutesDirectives>[] }>()
);

export const deleteMinutesDirectives = createAction(
  '[MinutesDirectives/API] Delete MinutesDirectives',
  props<{ id: number }>()
);

export const deleteMinutesDirectivess = createAction(
  '[MinutesDirectives/API] Delete MinutesDirectivess',
  props<{ ids: number[] }>()
);

export const clearMinutesDirectivess = createAction(
  '[MinutesDirectives/API] Clear MinutesDirectivess'
);

export const getAllMinutesDirectivesPageable = createAction(
  '[MinutesDirectives/API] get all active MinutesDirectivess pageable',
  props<{ pageable: PageableParam, stageName: string, meetingStatus?: string}>()
);


export const getAllMinutesDirectives = createAction(
  '[MinutesDirectives/API] Get All Meetings',
  props<{ filtering: any }>()
);


export const getAllAgendaDirectives = createAction(
  '[MinutesDirectives/API] Get All Agenda Directives',
  props<{ agendaUniqueId: string }>()
);

export const saveMinutesDirectives = createAction(
  '[MinutesDirectives/API] Save MinutesDirectives',
  props<{ directive: any }>()
);

export const editMinutesDirectives = createAction(
  '[MinutesDirectives/API] edit MinutesDirectives',
  props<{ directive: any }>()
);

export const removeMinutesDirectives = createAction(
  '[MinutesDirectives/API] remove Scheduled Meeting',
  props<{ directiveUniqueId: string, id: number }>()
);


export const getDirectiveByMinistryDashboard = createAction(
  '[MinutesDirectives/API] Get Directive By Ministry Dashboard',
);

