import {createSelector} from '@ngrx/store';
import * as fromProjectReducer from './/project.reducer';
import {projectsFeatureKey} from './project.reducer';
import {AppState} from '../../index';
import {DatePipe} from '@angular/common';
import {ProjectStatusEnum} from '../../../../shared/enums/data.enums';
import {Project} from './project.model';
import {ProjectComponent} from '../project-component/project-component.model';

export const currentProjectState = (state: AppState) => state[projectsFeatureKey];
export const selectAllProjectsFromReducer = createSelector(currentProjectState, fromProjectReducer.selectAll);

export const selectAllProjects = createSelector(
  selectAllProjectsFromReducer,
  (projects) => {
      return projects.map( project => {
       const formattedStatus = project?.projectStatusEnum === ProjectStatusEnum.ONGOING ? 'On Going' : project?.projectStatusEnum;
       const totalExpenditure = calculateTotalExpenditure(project);
       const totalExpenditurePercentageFromStartDate = (totalExpenditure / project?.allocatedBudget) * 100;

       return {
         ...project,
         formattedStatus,
         totalExpenditure,
         totalExpenditurePercentageFromStartDate,
         formattedSignatureDate: new DatePipe('en-GB').transform( project?.signatureDate, 'dd MMM yyyy'),
         formattedCompletionDate: new DatePipe('en-GB').transform( project?.completionDate, 'dd MMM yyyy'),
         components: project?.components?.map( (component) => {
           return {
             ...component,
             editable: isProgressExist(component),
             progressPercentage: calculateProjectProgress(component),
             formattedStartDate: new DatePipe('en-GB').transform(component?.startDate, 'dd MMM yyyy'),
             formattedHandoverDate: new DatePipe('en-GB').transform(component?.handoverDate, 'dd MMM yyyy'),
             formattedRecordedOn: new DatePipe('en-GB').transform(component?.recordedOn, 'dd MMM yyyy'),
           };
         }),
        };
      });
  });

export const selectTotalProjectByStatus = (status: ProjectStatusEnum) => createSelector(
  selectAllProjects,
  (projects: Project[]) => projects.filter((project) => project.projectStatusEnum === status)?.length
);

export const selectProjectByUid = (uid: string) => createSelector(
  selectAllProjects,
  (Projects) => Projects.find((project) => project.uniqueId === uid)
);

function calculateProjectProgress(component: ProjectComponent): number{
  const completedTask = component?.projectTasks
    ?.filter( (task) => task?.status === ProjectStatusEnum.COMPLETED)?.length;
  return (completedTask / component?.projectTasks?.length) * 100;
}

function calculateTotalExpenditure(project: Project): number{
  let total = 0;
  project?.components?.forEach( (component) => {
    component?.projectTasks
      ?.filter( (task) => task?.status === ProjectStatusEnum.COMPLETED)
      ?.forEach( (task) => {
        total += task?.budget;
      });
  });
  return total;
}

function isProgressExist(component: ProjectComponent): boolean{
  let allowProjectToBeEdited = true;
  component?.projectTasks?.forEach( (task) => {
      if (task?.taskProgress?.length > 0){
        allowProjectToBeEdited = false;
      }
  });
  return allowProjectToBeEdited;
}
