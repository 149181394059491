import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {FolderCategory, FolderCategoryDto} from './folder-category.model';

export const loadFolderCategories = createAction(
  '[FolderCategory/API] Load FolderCategories',
  props<{ FolderCategories: FolderCategory[] }>()
);

export const addFolderCategory = createAction(
  '[FolderCategory/API] Add FolderCategory',
  props<{ folderCategory: FolderCategory }>()
);

export const upsertFolderCategory = createAction(
  '[FolderCategory/API] Upsert FolderCategory',
  props<{ folderCategory: FolderCategory }>()
);

export const addFolderCategories = createAction(
  '[FolderCategory/API] Add FolderCategories',
  props<{ FolderCategories: FolderCategory[] }>()
);

export const upsertFolderCategories = createAction(
  '[FolderCategory/API] Upsert FolderCategories',
  props<{ FolderCategories: FolderCategory[] }>()
);

export const updateFolderCategory = createAction(
  '[FolderCategory/API] Update FolderCategory',
  props<{ folderCategory: Update<FolderCategory> }>()
);

export const updateFolderCategories = createAction(
  '[FolderCategory/API] Update FolderCategories',
  props<{ FolderCategories: Update<FolderCategory>[] }>()
);

export const deleteFolderCategory = createAction(
  '[FolderCategory/API] Delete FolderCategory',
  props<{ id: number }>()
);

export const deleteFolderCategories = createAction(
  '[FolderCategory/API] Delete FolderCategories',
  props<{ ids: string[] }>()
);

export const clearFolderCategories = createAction(
  '[FolderCategory/API] Clear FolderCategories'
);

export const getAllFolderCategories = createAction(
  '[FolderCategories/API] List All FolderCategories',
  props<{ uid: string, withPapers: boolean }>()
);

export const saveFolderCategory = createAction(
  '[FolderCategory/API] create folderCategory',
  props<{ folderCategoryDto: FolderCategoryDto }>()
);

export const editFolderCategory = createAction(
  '[FolderCategory/API] edit folderCategory',
  props<{ folderCategoryDto: FolderCategoryDto }>()
);

export const removeFolderCategory = createAction(
  '[FolderCategory/API] delete folderCategory',
  props<{ uid: string, id: number }>()
);
