import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {TaskResponse, TaskResponseDto} from './task-response.model';

export const loadTaskResponses = createAction(
  '[TaskResponse/API] Load TaskResponses',
  props<{ taskResponses: TaskResponse[] }>()
);

export const addTaskResponse = createAction(
  '[TaskResponse/API] Add TaskResponse',
  props<{ taskResponse: TaskResponse }>()
);

export const upsertTaskResponse = createAction(
  '[TaskResponse/API] Upsert TaskResponse',
  props<{ taskResponse: TaskResponse }>()
);

export const addTaskResponses = createAction(
  '[TaskResponse/API] Add TaskResponses',
  props<{ taskResponses: TaskResponse[] }>()
);

export const upsertTaskResponses = createAction(
  '[TaskResponse/API] Upsert TaskResponses',
  props<{ taskResponses: TaskResponse[] }>()
);

export const updateTaskResponse = createAction(
  '[TaskResponse/API] Update TaskResponse',
  props<{ taskResponse: Update<TaskResponse> }>()
);

export const updateTaskResponses = createAction(
  '[TaskResponse/API] Update TaskResponses',
  props<{ taskResponses: Update<TaskResponse>[] }>()
);

export const deleteTaskResponse = createAction(
  '[TaskResponse/API] Delete TaskResponse',
  props<{ id: number }>()
);

export const deleteTaskResponses = createAction(
  '[TaskResponse/API] Delete TaskResponses',
  props<{ ids: string[] }>()
);

export const clearTaskResponses = createAction(
  '[TaskResponse/API] Clear TaskResponses'
);

export const getAllTaskResponsesByTaskUid = createAction(
  '[TaskResponses/API] get All TaskResponses by task uid',
  props<{ uid: string }>()
);

export const saveTaskResponse = createAction(
  '[TaskResponse/API] create, edit taskResponse',
  props<{ formData: TaskResponseDto }>()
);

export const removeTaskResponse = createAction(
  '[TaskResponse/API] remove taskResponse',
  props<{ uid: string, id: number }>()
);
