import gql from 'graphql-tag';
import {responseField} from './base.graphql';

export const GET_ANNOTATION = gql`
  query getUserAnnotationDocument ($path: String) {
    getUserAnnotationDocument(path: $path) {
      response{
        ${responseField}
      }
      data{
        details{
          index
          annotation
          annotationType
        }
      }
    }
  }
`;

export const SAVE_ANNOTATION = gql`
  mutation createUserAnnotationDocument ($inputDto: UserAnnotationDocumentInputObject) {
    createUserAnnotationDocument(input: $inputDto) {
      response{
        ${responseField}
      }
      data{
        details{
          index
          annotation
          annotationType
        }
      }
    }
  }
`;

export const DELETE_ANNOTATION = gql`
  mutation deleteUserAnnotationDocument ($inputDto: DeleteAnnotationInputObject) {
    deleteUserAnnotationDocument(input: $inputDto) {
      response{
        ${responseField}
      }
    }
  }
`;

