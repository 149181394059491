<div class="d-flex justify-content-end">

  <ng-container *ngIf="filePath">
    <button (click)="exportAnnotation()" class="mx-1 btn-sm bg-primary" type="button" mat-raised-button>
      <mat-icon class="material-symbols-outlined">picture_as_pdf</mat-icon>
      {{'Save Annotation' | concat | translate }}
    </button>

    <button (click)="toggleHideAnnotation()" class="mx-1 btn-sm" type="button" mat-raised-button>
      <mat-icon class="material-symbols-outlined">{{ hideAnnotation ? 'grading' : 'playlist_remove' }}</mat-icon>
      {{ hideAnnotation ? 'Show Annotations' : 'Hide Annotation' | concat | translate }}
    </button>

    <button (click)="removeAnnotation(AnnotationType.DRAWING)" class="mx-1 text-bg-danger text-white btn-sm" type="button" mat-raised-button>
      <mat-icon class="material-symbols-outlined">delete</mat-icon>
      {{'Remove Drawing Annotation' | concat | translate }}
    </button>

    <button (click)="removeAnnotation(AnnotationType.TEXT)" class="mx-1 text-bg-danger text-white btn-sm" type="button" mat-raised-button>
      <mat-icon class="material-symbols-outlined">delete</mat-icon>
      {{'Remove Text Annotation' | concat | translate }}
    </button>

    <button (click)="toggleFullScreen()" class="toolbarButton new-tab "
            matTooltip="{{'Open Fullscreen' | concat | translate }}" type="button" mat-icon-button>
      <mat-icon class="material-symbols-outlined">fullscreen</mat-icon>
    </button>

  </ng-container>

</div>

<div #fullscreen>
  <ngx-extended-pdf-viewer [base64Src]="base64Src$ | async"
                           [showDownloadButton]="showDownloadButton"
                           [showPrintButton]="showPrintButton"
                           [enablePrint]="showPrintButton"
                           [filenameForDownload]="filenameForDownload"
                           [showHandToolButton]="showHandToolButton"
                           [textLayer]="textLayer"
                           [showZoomButtons]="showZoomButtons"
                           [showRotateButton]="showRotateButton"
                           [showScrollingButton]="showScrollingButton"
                           [showSpreadButton]="showSpreadButton"
                           [showPropertiesButton]="showPropertiesButton"
                           [showDrawEditor]="showDrawEditor"
                           [showTextEditor]="showTextEditor"
                           [height]="height"
                           [showOpenFileButton]="false"
                           (pagesLoaded)="pageRendered($event)"
                           (annotationEditorModeChanged)="annotationEditorModeChanged($event)"
                           useBrowserLocale="true">
  </ngx-extended-pdf-viewer>
</div>


