import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {PageableParam} from '../../page/page.model';
import {ScheduledMeeting} from './scheduled-meeting.model';

export const loadScheduledMeetings = createAction(
  '[ScheduledMeeting/API] Load ScheduledMeetings',
  props<{ scheduledMeetings: ScheduledMeeting[] }>()
);

export const addScheduledMeeting = createAction(
  '[ScheduledMeeting/API] Add ScheduledMeeting',
  props<{ scheduledMeeting: ScheduledMeeting }>()
);

export const upsertScheduledMeeting = createAction(
  '[ScheduledMeeting/API] Upsert ScheduledMeeting',
  props<{ scheduledMeeting: ScheduledMeeting }>()
);

export const addScheduledMeetings = createAction(
  '[ScheduledMeeting/API] Add ScheduledMeetings',
  props<{ scheduledMeetings: ScheduledMeeting[] }>()
);

export const upsertScheduledMeetings = createAction(
  '[ScheduledMeeting/API] Upsert ScheduledMeetings',
  props<{ scheduledMeetings: ScheduledMeeting[] }>()
);

export const updateScheduledMeeting = createAction(
  '[ScheduledMeeting/API] Update ScheduledMeeting',
  props<{ scheduledMeeting: Update<ScheduledMeeting> }>()
);

export const updateScheduledMeetings = createAction(
  '[ScheduledMeeting/API] Update ScheduledMeetings',
  props<{ scheduledMeetings: Update<ScheduledMeeting>[] }>()
);

export const deleteScheduledMeeting = createAction(
  '[ScheduledMeeting/API] Delete ScheduledMeeting',
  props<{ id: number }>()
);

export const deleteScheduledMeetings = createAction(
  '[ScheduledMeeting/API] Delete ScheduledMeetings',
  props<{ ids: number[] }>()
);

export const clearScheduledMeetings = createAction(
  '[ScheduledMeeting/API] Clear ScheduledMeetings'
);


// export const getAllScheduledMeetings = createAction(
//   '[ScheduledMeeting/API] get all ScheduledMeetings',
//   props<{ status?: ScheduledMeetingStatusEnum}>()
// );

// export const getAllScheduledMeetingByStage = createAction(
//   '[ScheduledMeeting/API] get all active ScheduledMeetings by stage',
//   props<{ stage: ScheduledMeetingStageEnum}>()
// );

export const getAllScheduledMeetingPageable = createAction(
  '[ScheduledMeeting/API] get all active ScheduledMeetings pageable',
  props<{ pageable: PageableParam, stageName: string, meetingStatus?: string}>()
);


export const getAllScheduledMeetings = createAction(
  '[ScheduledMeeting/API] Get All Meetings',
  props<{ filtering?: any, isRepository?: boolean, isOffline?: boolean }>()
);

export const saveScheduledMeeting = createAction(
  '[ScheduledMeeting/API] Save ScheduledMeeting',
  props<{ meetting: any }>()
);

export const updateScheduleMeetingInviteesMaxLimit = createAction(
  '[ScheduledMeeting/API] Update Meeting Invitees Limit ScheduledMeeting',
  props<{ input: { meeting: string, maxInvitess: number } }>()
);

export const editScheduledMeeting = createAction(
  '[ScheduledMeeting/API] edit ScheduledMeeting',
  props<{ meetting: any }>()
);

export const removeScheduledMeeting = createAction(
  '[ScheduledMeeting/API] remove Scheduled Meeting',
  props<{ meetingUniqueId: string }>()
);

export const sendMeetingNotice = createAction(
  '[ScheduledMeeting/API] send Meeting Notice',
  props<{ notice: any }>()
);

export const getMinistryMeetings = createAction(
  '[ScheduledMeeting/API] get Ministry Meetings',
  props<{ ministryFilter: any }>()
);

export const publishMeeting = createAction(
  '[ScheduledMeeting/API] publish Meeting',
  props<{ meetingUniqueId: string }>()
);

export const unPublishMeeting = createAction(
  '[ScheduledMeeting/API] unPublish Meeting',
  props<{ meetingUniqueId: string }>()
);
export const closeWithdrawScheduledMeeting = createAction(
  '[ScheduledMeeting/API] close Withdraw Scheduled Meeting',
  props<{ meetingDto: any }>()
);

export const attachSignedMinutesScheduledMeeting = createAction(
  '[ScheduledMeeting/API] attach Signed Scheduled Meeting',
  props<{ input: {
      uniqueId?: string;
      attachmentName?: string;
      base64String?: string;
      attachmentExtension?: string;
    } }>()
);

export const stampSignedMinutes = createAction(
  '[ScheduledMeeting/API] Stamp Signed Minutes',
  props<{ input: {
      meetingUniqueId?: string;
    } }>()
);

