import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ProjectComponentTask} from './project-component-task.model';
import * as ProjectComponentTaskActions from './project-component-task.actions';

export const projectComponentTasksFeatureKey = 'ProjectComponentTasks';

export interface State extends EntityState<ProjectComponentTask> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ProjectComponentTask> = createEntityAdapter<ProjectComponentTask>();

// export const adapter: EntityAdapter<ProjectComponentTask> = createEntityAdapter<ProjectComponentTask>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const projectComponentTaskComponentReducer = createReducer(
  initialState,
  on(ProjectComponentTaskActions.addProjectComponentTask,
    (state, action) => adapter.addOne(action.projectComponentTask, state)
  ),
  on(ProjectComponentTaskActions.upsertProjectComponentTask,
    (state, action) => adapter.upsertOne(action.projectComponentTask, state)
  ),
  on(ProjectComponentTaskActions.addProjectComponentTasks,
    (state, action) => adapter.addMany(action.projectComponentTasks, state)
  ),
  on(ProjectComponentTaskActions.upsertProjectComponentTasks,
    (state, action) => adapter.upsertMany(action.projectComponentTasks, state)
  ),
  on(ProjectComponentTaskActions.updateProjectComponentTask,
    (state, action) => adapter.updateOne(action.projectComponentTask, state)
  ),
  on(ProjectComponentTaskActions.updateProjectComponentTasks,
    (state, action) => adapter.updateMany(action.projectComponentTasks, state)
  ),
  on(ProjectComponentTaskActions.deleteProjectComponentTask,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ProjectComponentTaskActions.deleteProjectComponentTasks,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ProjectComponentTaskActions.loadProjectComponentTasks,
    (state, action) => adapter.setAll(action.projectComponentTasks, state)
  ),
  on(ProjectComponentTaskActions.clearProjectComponentTasks,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return projectComponentTaskComponentReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllProjectComponentTasks = selectAll;
export const getProjectComponentTaskById = selectIds;
export const getProjectComponentTaskEntities = selectEntities;
