import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AsyncPipe, JsonPipe, NgForOf, NgIf} from '@angular/common';
import {TableColumn} from '../../../interfaces/table.interface';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {ConcatPipe} from '../../../pipes/concat.pipe';
import {TranslateModule} from '@ngx-translate/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxChange, MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TableColumnSelectionService} from './table-column-selection.service';

@Component({
  selector: 'app-table-column-selection',
  templateUrl: './table-column-selection.component.html',
  styleUrls: ['./table-column-selection.component.scss'],
  imports: [
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    NgForOf,
    AsyncPipe,
    MatOptionModule,
    MatSelectModule,
    NgIf,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    ConcatPipe,
    TranslateModule,
    MatFormFieldModule,
    JsonPipe,
    MatCheckboxModule,
    MatTooltipModule
  ],
  standalone: true
})
export class TableColumnSelectionComponent implements OnInit {

  receivedData: any;
  tableColumns: TableColumn[];
  cacheTableColumns: TableColumn[];
  title = 'Table Column Selection'

  selectedColumns: TableColumn[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private columnSelectionService: TableColumnSelectionService
  ) {
    this.receivedData = data;
    this.cacheTableColumns = data?.cacheTableColumns;

    if (this.cacheTableColumns){
      this.selectedColumns = this.cacheTableColumns;
    }

    if (data?.tableColumns?.length > 0){
      this.tableColumns = this.receivedData?.tableColumns;
    }

    if (data?.extraTableColumns?.length > 0){
      this.tableColumns.push(data?.extraTableColumns);
    }

  }

  ngOnInit(): void {
  }

  hasColumn(keyword: string) {
    return this.cacheTableColumns.findIndex( d => d?.keyword === keyword) > -1;
  }

  onChange(tableColumn: TableColumn, $event: MatCheckboxChange){
    if ($event.checked) {
      // checkbox checked
      // if checked : add it to the array of existing list
      this.selectedColumns.push(tableColumn);
    } else {
      // checkbox unchecked
      // if unchecked : find and remove it from the list of existing array
      const index = this.selectedColumns.findIndex(data => data?.keyword === tableColumn?.keyword);
      if (index > -1) {
        this.selectedColumns.splice(index, 1);
      }
    }

    this.columnSelectionService.changeData({ columns: this.selectedColumns });
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.dialog.closeAll();
  }
}
