import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {actionRole, deleteRole, getAllRoles, loadRoles, upsertRole} from './role.actions';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {GET_ROLES, SAVE_ROLE} from './role.graphql';
import {ActionTypeEnum} from '../../../../shared/enums/data.enums';
import {SettingsService} from '../../../../services/settings.service';

@Injectable()
export class RoleEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllRoles),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_ROLES,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.store.dispatch(loadRoles({roles: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(actionRole),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_ROLE,
        variables: { inputDto: action.roleDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            if (action.roleDto.actionType === ActionTypeEnum.delete){
              this.store.dispatch(deleteRole({id: action.id}));
              this.settingsService.reloadCurrentRoute(null);
            }
            this.store.dispatch(upsertRole({role: result?.data}));

          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apollo: Apollo,
    private notificationService: NotificationService,
    private settingsService: SettingsService
  ) {
  }
}
