import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {FinancialYear} from './financial-year.model';
import * as FinancialYearActions from './financial-year.actions';

export const financialYearsFeatureKey = 'financialYears';

export interface State extends EntityState<FinancialYear> {
  // additional entities state properties
}
//
export function sortByFinancialYearName(a: FinancialYear, b: FinancialYear): any {
  return a?.fyYearName.localeCompare(b?.fyYearName);
}

export const adapter: EntityAdapter<FinancialYear> = createEntityAdapter<FinancialYear>({
  // sortComparer: sortByFinancialYearName
});

// export const adapter: EntityAdapter<FinancialYear> = createEntityAdapter<FinancialYear>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const financialYearReducer = createReducer(
  initialState,
  on(FinancialYearActions.addFinancialYear,
    (state, action) => adapter.addOne(action.financialYear, state)
  ),
  on(FinancialYearActions.upsertFinancialYear,
    (state, action) => adapter.upsertOne(action.financialYear, state)
  ),
  on(FinancialYearActions.addFinancialYears,
    (state, action) => adapter.addMany(action.financialYears, state)
  ),
  on(FinancialYearActions.upsertFinancialYears,
    (state, action) => adapter.upsertMany(action.financialYears, state)
  ),
  on(FinancialYearActions.updateFinancialYear,
    (state, action) => adapter.updateOne(action.financialYear, state)
  ),
  on(FinancialYearActions.updateFinancialYears,
    (state, action) => adapter.updateMany(action.financialYears, state)
  ),
  on(FinancialYearActions.deleteFinancialYear,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(FinancialYearActions.deleteFinancialYears,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(FinancialYearActions.loadFinancialYears,
    (state, action) => adapter.setAll(action.financialYears, state)
  ),
  on(FinancialYearActions.clearFinancialYears,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return financialYearReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllFinancialYears = selectAll;
export const getFinancialYearById = selectIds;
export const getFinancialYearEntities = selectEntities;
