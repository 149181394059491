import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {Project, ProjectDto} from './project.model';

export const loadProjects = createAction(
  '[Project/API] Load Projects',
  props<{ projects: Project[] }>()
);

export const addProject = createAction(
  '[Project/API] Add Project',
  props<{ project: Project }>()
);

export const upsertProject = createAction(
  '[Project/API] Upsert Project',
  props<{ project: Project }>()
);

export const addProjects = createAction(
  '[Project/API] Add Projects',
  props<{ projects: Project[] }>()
);

export const upsertProjects = createAction(
  '[Project/API] Upsert Projects',
  props<{ projects: Project[] }>()
);

export const updateProject = createAction(
  '[Project/API] Update Project',
  props<{ project: Update<Project> }>()
);

export const updateProjects = createAction(
  '[Project/API] Update Projects',
  props<{ projects: Update<Project>[] }>()
);

export const deleteProject = createAction(
  '[Project/API] Delete Project',
  props<{ id: number }>()
);

export const deleteProjects = createAction(
  '[Project/API] Delete Projects',
  props<{ ids: string[] }>()
);

export const clearProjects = createAction(
  '[Project/API] Clear Projects'
);

export const getAllProjects = createAction(
  '[Projects/API] get All Projects',
  props<{ filtering?: any }>()
);

export const saveProject = createAction(
  '[Project/API] create project',
  props<{ formData: ProjectDto }>()
);

export const editProject = createAction(
  '[Project/API] edit project',
  props<{ formData: ProjectDto }>()
);

export const getProjectByUid = createAction(
  '[Project/API] get project by uid',
  props<{ uid: string, withComponents: boolean }>()
);

export const removeProject = createAction(
  '[Project/API] remove project',
  props<{ uid: string, id: number }>()
);

