<app-modal-header [title]="title"></app-modal-header>


<div class="messages">

  <ng-container *ngFor="let progress of progresses">

    <div class="col-md-12">
      <div class="message message-left col-md-12">
        <small><b>{{ progress?.implementation_recorded_on | date: 'dd MMM yyyy'}}</b></small>
        <br>
        <span [innerHTML]="progress?.implementation_descriptions"></span>
        <div class="col-md-12 px-0">
          <button (click)="viewAttachment(progress?.implementation_attachment)" mat-raised-button>{{'View Attachment' | concat | translate}}</button>
        </div>
      </div>


    </div>
  </ng-container>

</div>

