import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  deleteProjectChallenge,
  editProjectChallenge,
  getProjectChallengeByProjectUid,
  loadProjectChallenges,
  removeProjectChallenge,
  saveProjectChallenge,
  upsertProjectChallenge
} from './project-challenge.actions';
import {map, switchMap} from 'rxjs/operators';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {Apollo} from 'apollo-angular';
import {
  DELETE_PROJECT_CHALLENGE,
  GET_PROJECT_CHALLENGE_PROJECT_UID,
  SAVE_PROJECT_CHALLENGE,
  UPDATE_PROJECT_CHALLENGE
} from './project-challenge.graphql';

@Injectable()
export class ProjectChallengeEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getProjectChallengeByProjectUid),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_PROJECT_CHALLENGE_PROJECT_UID,
        variables: { uid: action.projectUid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadProjectChallenges({projectChallenges: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveProjectChallenge),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_PROJECT_CHALLENGE,
        variables: { inputDto: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertProjectChallenge({projectChallenge: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editProjectChallenge),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_PROJECT_CHALLENGE,
        variables: { inputDto: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertProjectChallenge({projectChallenge: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeProjectChallenge),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_PROJECT_CHALLENGE,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteProjectChallenge({id: action.id}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService
  ) {
  }
}
