
<div class="text-muted mb-3 text-center">
  {{ 'You have live meeting description' | concat | translate }}
</div>

<div class="row">
  <div class="col-md-6">
    <div class="card-two-factor mb-3" matRipple (click)="downloadMeetingData()">

      <div class="">
        <mat-icon class="" style="width: 40px; height: 40px;" svgIcon="live_meeting"></mat-icon>
        <div class="">
          <span class="text-primary">{{ 'Live Meeting' | concat | translate }}: </span>
          <span class="text-primary fw-bold">{{ data?.meetingName }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card-one-factor mb-3" matRipple (click)="closeDialog()">

      <div class="">
        <mat-icon class="" style="width: 40px; height: 40px;" svgIcon="continue"></mat-icon>
        <div class="">
          <span class="text-primary fw-bold">{{ 'Continue Title' | concat | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isDownloading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  <span>{{ 'Downloading Data' | concat | translate }}...</span>
</div>
