import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DecisionService {

  constructor(
    private http: HttpClient
  ) {
  }

  getAll(){
    return this.http.get('cse_all_decisions_and_status');
  }

  report(){
    return this.http.get('decision_reports_management_url');
  }

  decisionByMinistryReport(ministryUid: string): Observable<any[]>{
    if (ministryUid){
      return this.http.get<any[]>('ministry_decision_reports_management_url/' + ministryUid);
    }else {
      return this.http.get<any[]>('ministry_decision_reports_management_url');
    }
  }

}
