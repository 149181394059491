import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const financialYearField = `
  ${baseField}
  start
  end
  fyYearName
  isCurrent
`;

export const GET_FINANCIAL_YEAR = gql`
  query getFinancialYears {
    getFinancialYears {
      response{
        ${responseField}
      }
      data{
        ${financialYearField}
      }
    }
  }
`;
