<div class="d-flex align-items-center">

<!--  <ng-container *ngIf="loggedInUserType === userTypeEnum.Staff" >-->
<!--    <button (click)="viewDirectives()" class="example-icon favorite-icon sm-hide d-md-inline" mat-icon-button matTooltip="Directives">-->
<!--      <mat-icon class="material-symbols-outlined">list_alt</mat-icon>-->
<!--    </button>-->

<!--    <button (click)="addDirectives(null)" *ngxPermissionsOnly="'can_create_directive'" class="sm-hide d-md-inline"-->
<!--            mat-icon-button matTooltip="Add Directives">-->
<!--      <mat-icon class="material-symbols-outlined">edit_note</mat-icon>-->
<!--    </button>-->

<!--  </ng-container>-->

<!--  <button (click)="handover()" class="sm-hide d-md-inline" mat-icon-button matTooltip="Handover Duties">-->
<!--    <mat-icon class="material-symbols-outlined">handshake</mat-icon>-->
<!--  </button>-->

<!--  <button (click)="returnDuty()" class="sm-hide d-md-inline" mat-icon-button matTooltip="Return Duties">-->
<!--    <mat-icon class="material-symbols-outlined">assignment_returned</mat-icon>-->
<!--  </button>-->

  <button [matMenuTriggerFor]="supportMenu" class="favorite-icon border mx-2" mat-icon-button matTooltip="{{'Help' | concat | translate}}">
    <mat-icon class="material-symbols-outlined top-custom-icon">help</mat-icon>
  </button>

  <mat-menu #supportMenu="matMenu">
    <a href="emailto:{{'support email' | concat | translate}}" mat-menu-item>
      <mat-icon svgIcon="email"></mat-icon>
      {{'support email' | concat | translate}}
    </a>

    <a href="#" mat-menu-item>
      <mat-icon svgIcon="pdf"></mat-icon>
      {{'user manual' | concat | translate}}
    </a>
  </mat-menu>


  <button *ngIf="!isOffline" (click)="refresh()" class="example-icon favorite-icon border mx-2" mat-icon-button matTooltip="{{'Refresh' | concat | translate}}">
    <mat-icon class="material-symbols-outlined top-custom-icon">sync</mat-icon>
  </button>

  <button [matMenuTriggerFor]="notificationsMenu" [matBadge]="paperComments$ | async | count" class="example-icon favorite-icon border" mat-icon-button>
    <mat-icon [svgIcon]="isLanding ? 'notification-bell' : 'notification-bell-black'"></mat-icon>
  </button>

  <mat-menu #notificationsMenu="matMenu" style="min-width: 500px;">
    <div class="border-bottom p-2" *ngFor="let notification of (paperComments$ | async)">
      <div class="text-primary h6">
        {{ notification?.title }}
      </div>
      <div class="text-primary text-sm" *ngIf="notification?.relatedPaperTitle">
        <span style="font-weight: bold;">{{ 'Paper' | concat | translate }}: </span>
        <span>{{ notification?.relatedPaperTitle }}</span>
      </div>
      <div class="text-muted mt-2">
        <span style="font-weight: bold;">{{ 'Message' | concat | translate }}: </span>
        <span>{{ notification?.message }}</span>
      </div>

      <div class="d-flex justify-content-end mt-2">
        <span class="text-muted cursor-pointer" (click)="dismissComment({ uniqueId: notification.uniqueId, id: notification.id })">{{ 'Dismiss' | concat | translate }}</span>
      </div>
    </div>
  </mat-menu>

  <button [matMenuTriggerFor]="langMenu" class="example-icon favorite-icon border mx-2" mat-icon-button>
    <mat-icon svgIcon="translate"></mat-icon>
  </button>

  <mat-menu #langMenu="matMenu">
    <button (click)="switchLang(language)" *ngFor="let language of translate.getLangs()" mat-menu-item> {{ language | translate }}
    </button>
  </mat-menu>

  <button [matMenuTriggerFor]="userMenu" mat-stroked-button class="{{isLanding ? 'text-white border-white' : ''}}">
     {{(loggedUser?.email)}}
  </button>

<!--  <button [matMenuTriggerFor]="userMenu" mat-icon-button class="d-block d-md-none">-->
<!--    <mat-icon class="material-symbols-outlined">keyboard_arrow_down</mat-icon>-->
<!--  </button>-->

  <mat-menu #userMenu="matMenu">

<!--    <ng-container *ngIf="loggedInUserType === userTypeEnum.Staff" >-->

<!--      <button (click)="viewDirectives()" class="" mat-menu-item>-->
<!--        <mat-icon class="material-symbols-outlined">list_alt</mat-icon> Directives-->
<!--      </button>-->

<!--      <button (click)="addDirectives(null)" *ngxPermissionsOnly="'can_create_directive'" class=""-->
<!--              mat-menu-item>-->
<!--        <mat-icon class="material-symbols-outlined">edit_note</mat-icon> Add Directives-->
<!--      </button>-->

<!--    </ng-container>-->

    @if(!isOffline) {
      <button (click)="handover()" class="" mat-menu-item>
        <mat-icon class="material-symbols-outlined">handshake</mat-icon>
        {{ 'Handover Duties' | concat | translate }}
      </button>

      <button (click)="returnDuty()" class="" mat-menu-item>
        <mat-icon class="material-symbols-outlined">assignment_returned</mat-icon>
        {{ 'Return Duties' | concat | translate }}
      </button>

      <mat-divider></mat-divider>

      <button *ngIf="!loggedUser?.webauthnEnabled" (click)="registerPasskey(loggedUser?.webauthnEnabled)" mat-menu-item>
        <mat-icon svgIcon="finger_print2" class=""></mat-icon>
        @if (loggedUser?.webauthnEnabled) {
          <span>{{ 'Remove Passkey' | concat | translate }} </span>
        } @else {
          <span>{{ 'Set Passkey' | concat | translate }} </span>
        }
      </button>

      @if (!loggedUser?.twoFaEnabled) {
        <button (click)="setTwoFactorAuth(true)" mat-menu-item>
          <mat-icon class="material-symbols-outlined">lock_on</mat-icon>
          {{ 'Set 2Factor Authentication' | concat | translate }}
        </button>
      }
<!--      @else {-->
<!--        <button (click)="setTwoFactorAuth(false)" mat-menu-item>-->
<!--          <mat-icon svgIcon="lock-off"></mat-icon>-->
<!--          {{ 'Remove 2Factor Authentication' | concat | translate }}-->
<!--        </button>-->
<!--      }-->
      <button (click)="openChangePassword()" mat-menu-item>
        <mat-icon class="material-symbols-outlined">vpn_key</mat-icon>
        {{ 'Change Password' | concat | translate }}
      </button>
    }

    <button (click)="logout()" mat-menu-item>
      <mat-icon class="material-symbols-outlined">power_settings_new</mat-icon>
      {{'Log out' | concat | translate}}
    </button>
  </mat-menu>

</div>


