import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Attachment} from './attachment.model';
import * as AttachmentActions from './attachment.actions';

export const attachmentsFeatureKey = 'attachments';

export interface State extends EntityState<Attachment> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Attachment> = createEntityAdapter<Attachment>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(AttachmentActions.addAttachment,
    (state, action) => adapter.addOne(action.attachment, state)
  ),
  on(AttachmentActions.upsertAttachment,
    (state, action) => adapter.upsertOne(action.attachment, state)
  ),
  on(AttachmentActions.addAttachments,
    (state, action) => adapter.addMany(action.attachments, state)
  ),
  on(AttachmentActions.upsertAttachments,
    (state, action) => adapter.upsertMany(action.attachments, state)
  ),
  on(AttachmentActions.updateAttachment,
    (state, action) => adapter.updateOne(action.attachment, state)
  ),
  on(AttachmentActions.updateAttachments,
    (state, action) => adapter.updateMany(action.attachments, state)
  ),
  on(AttachmentActions.deleteAttachment,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AttachmentActions.deleteAttachments,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AttachmentActions.loadAttachments,
    (state, action) => adapter.setAll(action.attachments, state)
  ),
  on(AttachmentActions.clearAttachments,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
