import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {User, UserTypeEnum} from 'src/app/store/entities/settings/user/user.model';
import {AppState} from 'src/app/store/entities';
import {StorageService} from '../../../../../services/storage-service.service';
import {getUsersToBeHandedOver, handOverDuty} from '../../../../../store/entities/settings/user/user.actions';
import {selectAllUsers} from '../../../../../store/entities/settings/user/user.selectors';
import {AuthUser} from '../../../../../store/entities/auth-user/auth-user.model';

@Component({
  selector: 'app-hand-over-duty-form',
  templateUrl: './hand-over-duty-form.component.html',
  styleUrls: ['./hand-over-duty-form.component.scss']
})
export class HandOverDutyFormComponent implements OnInit, OnDestroy {

  title: string;
  form: UntypedFormGroup;
  subscriptions: Subscription = new Subscription();
  user$: Observable<User[]>;

  loggedInUserType: UserTypeEnum;
  loggedUser: AuthUser;
  userTypeEnum = UserTypeEnum;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private storageService: StorageService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.loggedUser = this.storageService.get('account');
    this.store.dispatch(getUsersToBeHandedOver({ roleUniqueId: this.loggedUser?.roleObject?.uniqueId}));
  }

  ngOnInit(): void {
    this.title = 'Handover your Duty';

    this.form = this.fb.group({
      onBehalf: [false, Validators.required],
      handingOverUser: [this.loggedUser?.uniqueId, Validators.required],
      receiver: [null, Validators.required],
      permissionsList: [this.loggedUser?.roleObject?.permissions?.map( res => res?.uniqueId), Validators.required],
    });

    this.user$ = this.store.select(selectAllUsers);

  }

  submitForm(inputDto: any): void {
    this.store.dispatch(handOverDuty({ inputDto }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
