import {Component, HostListener, inject, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-fixed-header',
  templateUrl: './modal-fixed-header.component.html',
  styleUrls: ['./modal-fixed-header.component.scss']
})
export class ModalFixedHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() passwordChanged: boolean;
  @Input() isTranslate = true;

  dialog = inject(MatDialog);

  ngOnInit(): void {
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.dialog.closeAll();
  }
}
