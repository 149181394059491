import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Device} from './device.model';

export const loadDevices = createAction(
  '[Device/API] Load Devices',
  props<{ devices: Device[] }>()
);

export const addDevice = createAction(
  '[Device/API] Add Device',
  props<{ device: Device }>()
);

export const upsertDevice = createAction(
  '[Device/API] Upsert Device',
  props<{ device: Device }>()
);

export const addDevices = createAction(
  '[Device/API] Add Devices',
  props<{ devices: Device[] }>()
);

export const upsertDevices = createAction(
  '[Device/API] Upsert Devices',
  props<{ devices: Device[] }>()
);

export const updateDevice = createAction(
  '[Device/API] Update Device',
  props<{ device: Update<Device> }>()
);

export const updateDevices = createAction(
  '[Device/API] Update Devices',
  props<{ devices: Update<Device>[] }>()
);

export const deleteDevice = createAction(
  '[Device/API] Delete Device',
  props<{ id: number }>()
);

export const deleteDevices = createAction(
  '[Device/API] Delete Devices',
  props<{ ids: string[] }>()
);

export const clearDevices = createAction(
  '[Device/API] Clear Devices'
);

export const getAllDevices = createAction(
  '[Devices/API] List All Devices'
);

export const registerUserDevice = createAction(
  '[Device/API] registerUserDevice',
  props<{ input: any }>()
);

export const removeUserDevice = createAction(
  '[Device/API] removeUserDevice',
  props<{ uid: string }>()
);

export const deactivateDevice = createAction(
  '[Device/API] deactivateUserDevice',
  props<{ uid: string }>()
);
