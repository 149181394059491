import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {ForthcomingService} from './forthcoming.service';
import {
  allInstitutionPaperSubmissionPlans,
  allPaperSubmissionPlans,
  loadForthComings,
  removePaperPlan,
  saveForthComing
} from './forthcoming.actions';
import {SettingsService} from '../../../../services/settings.service';
import {DELETE_PAPER_PLAN, GET_ALL_PAPER_PLANS, GET_PLANS_BY_INSTITUTION, SAVE_PAPER_PLAN} from './forthcoming.graphql';
import {Apollo} from 'apollo-angular';

@Injectable()
export class ForthcomingEffects {

  getByInst = createEffect(() => this.actions$.pipe(
    ofType(allInstitutionPaperSubmissionPlans),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_PLANS_BY_INSTITUTION,
        variables: {institution: action.institution}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.store.dispatch(loadForthComings({forthComings: result?.dataList}));
          }
        })
      );
    })
  ), { dispatch: false });

  getAll = createEffect(() => this.actions$.pipe(
    ofType(allPaperSubmissionPlans),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_ALL_PAPER_PLANS,
        variables: {search: action.search}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.store.dispatch(loadForthComings({forthComings: result?.dataList}));
          }
        })
      );
    })
  ), { dispatch: false });


  save = createEffect(() => this.actions$.pipe(
    ofType(saveForthComing),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_PAPER_PLAN,
        variables: { paperSubmissionPlanDto: action.paperSubmissionPlanDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            // this.store.dispatch(upsertMinutesDirectives({ MinutesDirectives: result?.data }));
            this.settingsService.reloadCurrentRoute(null);
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  delete = createEffect(() => this.actions$.pipe(
    ofType(removePaperPlan),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_PAPER_PLAN,
        variables: { uniqueId: action.uniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.settingsService.reloadCurrentRoute(null);
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private forthComingService: ForthcomingService,
    private apollo: Apollo,

  ) { }

}
