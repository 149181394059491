import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const userField = `
  ${baseField}
  prefix
  suffix
  firstName
  lastName
  email
  phone
  photo
  group
  isActive
  userType
  accountLocked
  twoFaEnabled
  webauthnEnabled
  updatePassword
  group
  designation{
    ${baseField}
    name
  }
  role{
    ${baseField}
    name
  }
  institution{
    ${baseField}
    name
  }
`;

export const LOGIN = gql `
  mutation tokenAuth($password: String!, $username: String, $email: String) {
    tokenAuth(password: $password, username: $username, email: $email) {
      token
      refreshToken
      success
      user {
        firstName
        lastName
        email
        isStaff
        username
        verified
      }
    }
  }
`;

export const GET_LOGGED_IN_USER_DETAIL = gql `
  query getUserRolesAndPermissions {
    getUserRolesAndPermissions{
      response {
        code
        message
      }
      data {
        id
        uniqueId
        name
       permissions {
         uniqueId
         code
       }
      }
      updatePassword
      hasHandover
      institution{
        ${baseField}
        name
        shortform
      }
      userProfile{
        ${userField}
      }
    }
  }
`;

export const REVOKE_TOKEN = gql`
  mutation revokeToken($token: String!) {
    revokeToken(refreshToken: $token) {
      success
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($token: String!) {
    refreshToken(refreshToken: $token) {
      success
      token
      refreshToken
      errors
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation passwordChange($oldPassword: String!, $newPassword: String!, $confirmPassword: String!) {
    passwordChange(oldPassword: $oldPassword, newPassword: $newPassword, confirmPassword: $confirmPassword) {
      success
      token
      refreshToken
      errors
    }
  }
`;


export const GET_ALL_USERS = gql`
  query getSystemUserProfileDetails($uid: String) {
    getSystemUserProfileDetails(userId: $uid) {
      response {
        code
        message
      }
      data {
        ${userField}
      }
    }
  }
`;


export const GET_USERS_OF_TASK_ASSIGNED_DESIGNATION = gql`
query GetUsersOfTaskAssignedDesignation {
  getUsersOfTaskAssignedDesignation {
    response {
      ${responseField}
    }
    data {
      id
      uniqueId
      firstName
      lastName
      email
      phone
      photo
      designation {
        id
        uniqueId
        shortForm
        name
        isActive
      }
      isActive
      userType
      updatePassword
      prefix
      suffix
      userIsHandover
      group
    }
  }
}
`;

export const SUBMIT_RESET_PASSWORD = gql`
  mutation getPasswordResetToken($email: String!) {
    getPasswordResetToken(email: $email) {
      response{
        code
        message
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation userResetPassword($password: String!, $confirmPassword: String!, $token: String!) {
    userResetPassword(password1: $password, password2: $confirmPassword, token: $token) {
      response{
        code
        message
      }
    }
  }
`;

export const SAVE_USER = gql`
  mutation createUsers($inputDto: UserProfileInputObjects!) {
    createUsers(userProfile: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${userField}
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUsers($inputDto: UserProfileInputObjects!) {
    updateUsers(userProfile: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${userField}
      }
    }
  }
`;


export const TRANSFER_USER = gql`
mutation TransferUser($input: TransferUserProfileInputObjects!) {
  transferUser(input: $input) {
      response{
        ${responseField}
      }
      data{
        ${userField}
      }
    }
  }
`;

export const ACTIVATE_DEACTIVATE_USER = gql`
  mutation deleteUserFromSystem($isActive: Boolean!, $uid: String!) {
    deleteUserFromSystem(isActive: $isActive, uniqueId: $uid){
      response{
        ${responseField}
      }
      data{
        ${userField}
      }
    }
  }
`;

export const HANDOVER_USERS = gql`
  query getHandoverGroups($uid: String) {
    getHandoverGroups(roleUniqueId: $uid){
      response{
        ${responseField}
      }
      data{
        uniqueId
        usersList{
          ${userField}
        }
      }
    }
  }
`;

export const RETURN_HANDOVER_DUTY = gql`
  mutation returnToOffice($inputDto: ReturnToOfficeInputObjects) {
    returnToOffice(input: $inputDto){
      response{
        ${responseField}
      }
    }
  }
`;

export const HANDOVER_DUTY = gql`
  mutation handoverOfficeToUser($inputDto: NewHandoverInputObjects) {
    handoverOfficeToUser(handover: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${userField}
      }
    }
  }
`;

export const ASSIGN_USER_TO_HANDOVER_GROUP = gql`
  mutation assingUserToHandoverGroup($inputDto: NewHandoverClusterInputObjects) {
    assingUserToHandoverGroup(handoverCluster: $inputDto){
      response{
        ${responseField}
      }
    }
  }
`;

export const REMOVE_USER_TO_HANDOVER_GROUP = gql`
  mutation removeUserFromHandoverGroup($inputDto: RemoveHandoverClusterUserInputObjects) {
    removeUserFromHandoverGroup(input: $inputDto){
      response{
        ${responseField}
      }
    }
  }
`;

export const UN_LOCK_USER = gql`
  mutation unlockUserAccountMutation($uid: String!) {
    unlockUserAccountMutation(profileUniqueId: $uid){
      response{
        ${responseField}
      }data {
        ${userField}
      }
    }
  }
`;

export const GET_ROLE_AND_PERMISSION_SUMMARY = gql`
  query getMonthlyPermissionReviews {
    getMonthlyPermissionReviews{
      response{
        ${responseField}
      }
      data
    }
  }
`;

export const GET_AUDIT = gql`
  query getAuditLogsReviews($inputDto: LogsFilteringInputObjects) {
    getAuditLogsReviews(filtering: $inputDto) {
      response{
        ${responseField}
      }
      data{
        fullName
        username
        performedAction
        actionTime
        query
        variables {
          variableValues
          variableValues
        }
      }
    }
  }
`;

export const GET_SETTING_USER_DASHBOARD = gql`
  query getAdminDashboard {
    getAdminDashboard{
      response{
        ${responseField}
      }
      data{
        usersSummaryCards{
          value
          name
        }
        usersGroupsCards{
          name
          value
        }
        devicesCards{
          value
          name
        }
        loginsSummaryCards{
          value
          name
        }
        currentLoginUsers{
          value
          name
        }
        filesStorageCards{
          value
          name
        }
      }
    }
  }
`;


export const SAVE_DEVICE_LOCATION = gql`
  mutation captureDeviceLocation($inputDto: DeviceLocationInputObjects) {
    captureDeviceLocation(input: $inputDto) {
      response{
        ${responseField}
      }
    }
  }
`;


export const GET_SYSTEM_USERS = gql`
  query GetSystemUsers($filtering: UserSearchingInputObjects) {
    getSystemUsers(filtering: $filtering) {
      response {
        ${responseField}
      }
      data {
        ${userField}
      }
    }
  }
`

