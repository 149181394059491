import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ProjectRecommendation} from './project-recommendation.model';
import * as ProjectRecommendationActions from './project-recommendation.actions';

export const projectRecommendationsFeatureKey = 'ProjectRecommendations';

export interface State extends EntityState<ProjectRecommendation> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ProjectRecommendation> = createEntityAdapter<ProjectRecommendation>();

// export const adapter: EntityAdapter<ProjectRecommendation> = createEntityAdapter<ProjectRecommendation>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const projectRecommendationRecommendationReducer = createReducer(
  initialState,
  on(ProjectRecommendationActions.addProjectRecommendation,
    (state, action) => adapter.addOne(action.projectRecommendation, state)
  ),
  on(ProjectRecommendationActions.upsertProjectRecommendation,
    (state, action) => adapter.upsertOne(action.projectRecommendation, state)
  ),
  on(ProjectRecommendationActions.addProjectRecommendations,
    (state, action) => adapter.addMany(action.projectRecommendations, state)
  ),
  on(ProjectRecommendationActions.upsertProjectRecommendations,
    (state, action) => adapter.upsertMany(action.projectRecommendations, state)
  ),
  on(ProjectRecommendationActions.updateProjectRecommendation,
    (state, action) => adapter.updateOne(action.projectRecommendation, state)
  ),
  on(ProjectRecommendationActions.updateProjectRecommendations,
    (state, action) => adapter.updateMany(action.projectRecommendations, state)
  ),
  on(ProjectRecommendationActions.deleteProjectRecommendation,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ProjectRecommendationActions.deleteProjectRecommendations,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ProjectRecommendationActions.loadProjectRecommendations,
    (state, action) => adapter.setAll(action.projectRecommendations, state)
  ),
  on(ProjectRecommendationActions.clearProjectRecommendations,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return projectRecommendationRecommendationReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllProjectRecommendations = selectAll;
export const getProjectRecommendationById = selectIds;
export const getProjectRecommendationEntities = selectEntities;
