import {Component, inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LiveMeeting} from '../landing-page.component';
import {AppState} from '../../../../../store/entities';
import {Store} from '@ngrx/store';
import {
  getAllScheduledMeetings,
  loadScheduledMeetings
} from '../../../../../store/entities/meetings/scheduled-meeting/scheduled-meeting.actions';
import {
  getMeetingsAgendas,
  loadMeetingAgendas
} from '../../../../../store/entities/meetings/meeting-agenda/meeting-agenda.actions';
import {Actions, ofType} from '@ngrx/effects';
import {lastValueFrom, Subscription} from 'rxjs';
import {
  selectMeetingAgendas,
  selectNoOfflinePapers
} from '../../../../../store/entities/meetings/meeting-agenda/meeting-agenda.selectors';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {IndexedDBTableName} from '../../../../interfaces/table.interface';
import {Router} from '@angular/router';

@Component({
  selector: 'app-two-factor-auth-form',
  templateUrl: './offline-online-select.component.html',
  styleUrls: ['./offline-online-select.component.scss']
})
export class OfflineOnlineSelectComponent implements OnInit {

  data: LiveMeeting = inject(MAT_DIALOG_DATA);
  private store: Store<AppState> = inject(Store<AppState>);
  private actions$: Actions = inject(Actions);
  private dbService: NgxIndexedDBService = inject(NgxIndexedDBService);
  private router: Router = inject(Router);

  title: any;
  ticket: string;

  userEmail: string;
  totp: boolean;
  passkey: boolean;

  meetingHasLoaded = false;
  meetingAgendaHasLoaded = false;
  isDownloading = false;
  subscription$: Subscription = new Subscription();
  totalAgendaPaperAttachments = 0;
  totalLocallySavedAttachments = 0;

  constructor(
    private dialogRef: MatDialogRef<OfflineOnlineSelectComponent>,
  ) {
  }

  async ngOnInit() {
    this.listenToActions();
  }

  async downloadMeetingData() {
    this.isDownloading = true;
    this.store.dispatch(getAllScheduledMeetings({
      filtering: {meetingUniqueId: this.data?.meetingUniqueId},
      isOffline: true
    }));
    this.store.dispatch(getMeetingsAgendas({
      meetingUniqueId: this.data?.meetingUniqueId,
      isOffline: true
    }));
  }

  closeDialog() {
    this.dialogRef.close();
  }

  listenToActions() {
    this.subscription$.add(this.store.select(selectMeetingAgendas).subscribe(agendas => {
      if (agendas?.length) {
        this.meetingAgendaHasLoaded = true;
        let totalPaperAttachments = 0;
        for (const agenda of agendas) {
          totalPaperAttachments += agenda?.paper?.attachments?.length;
        }
        this.totalAgendaPaperAttachments = totalPaperAttachments;
        this.checkNavigationChange();
      }
    }));
    this.subscription$.add(
      this.actions$
        .pipe(ofType(loadScheduledMeetings))
        .subscribe((_) => {
          this.meetingHasLoaded = true;
          this.checkNavigationChange();
        })
    );
    this.subscription$.add(this.store.select(selectNoOfflinePapers).subscribe(noOfflinePapers => {
      this.totalLocallySavedAttachments = noOfflinePapers;
      this.checkNavigationChange();
    }));
  }

  checkNavigationChange() {
    if (this.isDownloading
      && this.meetingHasLoaded
      && this.meetingAgendaHasLoaded
      && (this.totalAgendaPaperAttachments === this.totalLocallySavedAttachments)
    ) {
      window.location.href = `/offline/meeting/${this.data?.meetingUniqueId}`;
    }
  }

}
