import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const projectImageField = `
  ${baseField}
  description
  attachmentName
  #attachmentExtension
  base64Data
  recordedOn
`;


export const GET_PROJECT_IMAGE_PROJECT_UID = gql`
  query allProjectOnsiteEvidences($uid: String) {
    allProjectOnsiteEvidences(project: $uid){
      response{
        ${responseField}
      }
      data{
        ${projectImageField}
      }
    }
  }
`;

export const SAVE_PROJECT_IMAGE = gql`
  mutation createProjectEvidenceMutation($inputDto: OnSiteEvidenceInputObjects!) {
    createProjectEvidenceMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectImageField}
      }
    }
  }
`;

export const DELETE_PROJECT_IMAGE = gql`
  mutation deleteProjectEvidenceMutation($uid: String!) {
    deleteProjectEvidenceMutation(uniqueId: $uid){
      response{
        ${responseField}
      }
      data{
        ${projectImageField}
      }
    }
  }
`;
