import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Role} from './role.model';
import * as RoleActions from './role.actions';

export const rolesFeatureKey = 'roles';

export interface State extends EntityState<Role> {
  // additional entities state properties
}

export function sortByRoleName(a: Role, b: Role): any {
  return a?.name.localeCompare(b?.name);
}

export const adapter: EntityAdapter<Role> = createEntityAdapter<Role>({
  // sortComparer: sortByRoleName
});

// export const adapter: EntityAdapter<Role> = createEntityAdapter<Role>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const roleReducer = createReducer(
  initialState,
  on(RoleActions.addRole,
    (state, action) => adapter.addOne(action.role, state)
  ),
  on(RoleActions.upsertRole,
    (state, action) => adapter.upsertOne(action.role, state)
  ),
  on(RoleActions.addRoles,
    (state, action) => adapter.addMany(action.roles, state)
  ),
  on(RoleActions.upsertRoles,
    (state, action) => adapter.upsertMany(action.roles, state)
  ),
  on(RoleActions.updateRole,
    (state, action) => adapter.updateOne(action.role, state)
  ),
  on(RoleActions.updateRoles,
    (state, action) => adapter.updateMany(action.roles, state)
  ),
  on(RoleActions.deleteRole,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(RoleActions.deleteRoles,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(RoleActions.loadRoles,
    (state, action) => adapter.setAll(action.roles, state)
  ),
  on(RoleActions.clearRoles,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return roleReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllRoles = selectAll;
export const getRoleById = selectIds;
export const getRoleEntities = selectEntities;
