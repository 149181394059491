<app-modal-header  [title]="title"></app-modal-header>

<div class="col-md-12">
    <app-data-table [columnHeader]="tableColumn" [tableData$]="progress$" [allowTableColumnFromCache]="false">
      <ng-template let-data="data">
        <div class="action-icons">

          <button class="icon-holder" mat-icon-button *ngIf="data?.psCoverLetter"
                  (click)="viewAttachment(data?.psCoverLetter, 'PS Cover Letter')">
            <mat-icon svgIcon="document"></mat-icon>
          </button>

          <ng-container *ngIf="task?.status === status.NOTSTARTED || task?.status === status.ONHOLD">
            <button [matMenuTriggerFor]="menu" class="icon-holder" mat-icon-button>
              <mat-icon svgIcon="option"></mat-icon>
            </button>

            <mat-menu #menu="matMenu">

              <button (click)="action(data)" mat-menu-item> <mat-icon svgIcon="edit"></mat-icon>
                {{'Edit' | concat | translate}}
              </button>

              <button (click)="remove(data)" mat-menu-item>  <mat-icon svgIcon="icon_delete"></mat-icon>
                {{'Remove' | concat | translate}}
              </button>
            </mat-menu>
          </ng-container>

        </div>
      </ng-template>
    </app-data-table>
  </div>

