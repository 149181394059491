import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  deleteProjectRecommendation,
  editProjectRecommendation,
  getProjectRecommendationByProjectUid,
  loadProjectRecommendations,
  removeProjectRecommendation,
  saveProjectRecommendation,
  upsertProjectRecommendation
} from './project-recommendation.actions';
import {map, switchMap} from 'rxjs/operators';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {Apollo} from 'apollo-angular';
import {
  DELETE_PROJECT_RECOMMENDATION,
  GET_PROJECT_RECOMMENDATION_PROJECT_UID,
  SAVE_PROJECT_RECOMMENDATION,
  UPDATE_PROJECT_RECOMMENDATION
} from './project-recommendation.graphql';

@Injectable()
export class ProjectRecommendationEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getProjectRecommendationByProjectUid),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_PROJECT_RECOMMENDATION_PROJECT_UID,
        variables: { uid: action.projectUid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadProjectRecommendations({projectRecommendations: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveProjectRecommendation),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_PROJECT_RECOMMENDATION,
        variables: { inputDto: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertProjectRecommendation({projectRecommendation: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  update = createEffect(() => this.actions$.pipe(
    ofType(editProjectRecommendation),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_PROJECT_RECOMMENDATION,
        variables: { inputDto: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertProjectRecommendation({projectRecommendation: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeProjectRecommendation),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_PROJECT_RECOMMENDATION,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteProjectRecommendation({id: action?.id}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService
  ) {
  }
}
