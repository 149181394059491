<ng-container *ngIf="user$ | async as user">
  <app-modal-header [title]="user.firstName + ' ' + user?.middleName + ' ' + user.lastName">

  </app-modal-header>

    <div class="row ">

      <div class="col-md-6">
        <h4>  <b>{{ 'email' | concat | translate}}: </b> <br> {{ user.email }} </h4>
      </div>

      <div class="col-md-6">
        <h4>  <b>{{'check number' | concat | translate}}: </b> <br> {{ user.checkNumber }} </h4>
      </div>

      <div class="col-md-6">
        <h4>  <b>{{'nida' | concat | translate}}: </b> <br> {{ user.nationalId }} </h4>
      </div>

      <div class="col-md-6">
        <h4>
          <b>{{ 'roles' | concat | translate}}: </b> <br>
          <ng-container *ngFor="let role of user.rolesList">
            <span class="text-primary"><i>{{ role.name }}</i> </span> &nbsp;&nbsp;
          </ng-container>
        </h4>

      </div>
    </div>
</ng-container>


