import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {MinutesDirectives} from './minutes-directives.model';
import * as MinutesDirectivesActions from './minutes-directives.actions';

export const minutesDirectivessFeatureKey = 'minutesDirectives';

export interface State extends EntityState<MinutesDirectives> {
  // additional entities state properties
}

export const adapter: EntityAdapter<MinutesDirectives> = createEntityAdapter<MinutesDirectives>({
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const MinutesDirectivesReducer = createReducer(
  initialState,
  on(MinutesDirectivesActions.addMinutesDirectives,
    (state, action) => adapter.addOne(action.minutesDirectives, state)
  ),
  on(MinutesDirectivesActions.upsertMinutesDirectives,
    (state, action) => adapter.upsertOne(action.minutesDirectives, state)
  ),
  on(MinutesDirectivesActions.addMinutesDirectivess,
    (state, action) => adapter.addMany(action.minutesDirectivess, state)
  ),
  on(MinutesDirectivesActions.upsertMinutesDirectivess,
    (state, action) => adapter.upsertMany(action.minutesDirectivess, state)
  ),
  on(MinutesDirectivesActions.updateMinutesDirectives,
    (state, action) => adapter.updateOne(action.minutesDirectives, state)
  ),
  on(MinutesDirectivesActions.updateMinutesDirectivess,
    (state, action) => adapter.updateMany(action.minutesDirectivess, state)
  ),
  on(MinutesDirectivesActions.deleteMinutesDirectives,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(MinutesDirectivesActions.deleteMinutesDirectivess,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(MinutesDirectivesActions.loadMinutesDirectivess,
    (state, action) => adapter.setAll(action.minutesDirectivess, state)
  ),
  on(MinutesDirectivesActions.clearMinutesDirectivess,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return MinutesDirectivesReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getMinutesDirectivess = selectAll;
