import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {AttachmentConfig} from './attachment-configuration.model';
import * as AttachmentConfigActions from './attachment-configuration.actions';

export const attachmentConfigsFeatureKey = 'attachmentConfigs';

export interface State extends EntityState<AttachmentConfig> {
  // additional entities state properties
}

export function sortByAttachmentConfigName(a: AttachmentConfig, b: AttachmentConfig): any {
  return a?.name.localeCompare(b?.name);
}

export const adapter: EntityAdapter<AttachmentConfig> = createEntityAdapter<AttachmentConfig>({
  // sortComparer: sortByAttachmentConfigName
});

// export const adapter: EntityAdapter<AttachmentConfig> = createEntityAdapter<AttachmentConfig>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const attachmentConfigurationReducer = createReducer(
  initialState,
  on(AttachmentConfigActions.addAttachmentConfig,
    (state, action) => adapter.addOne(action.attachmentConfig, state)
  ),
  on(AttachmentConfigActions.upsertAttachmentConfig,
    (state, action) => adapter.upsertOne(action.attachmentConfig, state)
  ),
  on(AttachmentConfigActions.addAttachmentConfigs,
    (state, action) => adapter.addMany(action.attachmentConfigs, state)
  ),
  on(AttachmentConfigActions.upsertAttachmentConfigs,
    (state, action) => adapter.upsertMany(action.attachmentConfigs, state)
  ),
  on(AttachmentConfigActions.updateAttachmentConfig,
    (state, action) => adapter.updateOne(action.attachmentConfig, state)
  ),
  on(AttachmentConfigActions.updateAttachmentConfigs,
    (state, action) => adapter.updateMany(action.attachmentConfigs, state)
  ),
  on(AttachmentConfigActions.deleteAttachmentConfig,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AttachmentConfigActions.deleteAttachmentConfigs,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AttachmentConfigActions.loadAttachmentConfigs,
    (state, action) => adapter.setAll(action.attachmentConfigs, state)
  ),
  on(AttachmentConfigActions.clearAttachmentConfigs,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return attachmentConfigurationReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllAttachmentConfigs = selectAll;
export const getAttachmentConfigById = selectIds;
export const getAttachmentConfigEntities = selectEntities;
