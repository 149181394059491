 <app-modal-header [title]="title"></app-modal-header>

  <form (ngSubmit)="submitForm(form.value)" [formGroup]="form" autocomplete="off" novalidate>
    <div class="row">

      <div class="col-md-12">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Description' | concat | translate}}</mat-label>
          <textarea formControlName="response_descriptions" matInput required></textarea>
          <mat-error>
                <span *ngIf="!form.get('response_descriptions').valid && form.get('response_descriptions').touched">
                    {{'this field is required' | concat | translate}}
                </span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row px-3">
          <div class="col-md-12 pt-2">
            <input #importInput (change)="onSingleFileSelected($event)" accept=".pdf" style="display: none" type="file">
            <button (click)="importInput.click()" class="mx-1 btm-sm" color="primary" mat-raised-button type="button">
              {{'Attachment' | concat | translate}}
            </button>
          </div>
          <div class="col-md-8 pt-3">
            {{documentTitle}}
          </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="text-end">
          <button [disabled]="!form.valid" color="primary" mat-raised-button type="submit">
            {{'Save' | concat | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
