import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {actionMailConfig, getAllMailConfigs, upsertMailConfig} from './mail-config.actions';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {GET_MAIL_CONFIG, SAVE_MAIL_CONFIG} from './mail-config.graphql';
import {ActionTypeEnum} from '../../../../shared/enums/data.enums';
import {SettingsService} from '../../../../services/settings.service';

@Injectable()
export class MailConfigEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllMailConfigs),
    switchMap(() => {
      return this.apollo.query({
        query: GET_MAIL_CONFIG
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(upsertMailConfig({mailConfig: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(actionMailConfig),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_MAIL_CONFIG,
        variables: { inputDto: action.mailConfigDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(upsertMailConfig({mailConfig: result?.data}));

            if (action.mailConfigDto.actionType === ActionTypeEnum.delete){
              this.settingsService.reloadCurrentRoute(null);
            }

          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo
  ) {
  }
}
