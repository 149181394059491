import {createSelector} from '@ngrx/store';
import * as fromProjectRepositoryReducer from './project-repository.reducer';
import {AppState} from '../../index';
import {DatePipe} from '@angular/common';

export const currentProjectRepositoryState = (state: AppState) => state[fromProjectRepositoryReducer.projectRepositoriesFeatureKey];

export const selectAllProjectRepositoriesFromReducer = createSelector(currentProjectRepositoryState, fromProjectRepositoryReducer.selectAll);
export const selectTotalProjectRepositories = createSelector(currentProjectRepositoryState, fromProjectRepositoryReducer.selectTotal);

export const selectAllProjectRepositories = createSelector(
  selectAllProjectRepositoriesFromReducer,
  (projects) => {
    return projects.map( res => {
      return {
        ...res,
        formattedRecordedOn: new DatePipe('en-GB').transform( res?.recordedOn, 'dd MMM yyyy')
      };
    });
  });

export const selectProjectRepositoryById = (id: number) => createSelector(
  selectAllProjectRepositories,
  (ProjectRepositories) => ProjectRepositories.find((projectRepository) => projectRepository.id === id)
);

export const selectProjectRepositoryByUid = (uid: string) => createSelector(
  selectAllProjectRepositories,
  (ProjectRepositories) => ProjectRepositories.find((projectRepository) => projectRepository.uniqueId === uid)
);
