import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {getAttachmentsByUid} from '../../../../store/entities/settings/attachment/attachment.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {IndexedDBTableName} from '../../../interfaces/table.interface';
import {OfflineAgendaPaper} from '../../../../store/entities/meetings/meeting-agenda/meeting-agenda.model';
import {of} from 'rxjs';
import {ViewAttachmentComponent} from '../view-attachment/view-attachment.component';
import {SettingsService} from '../../../../services/settings.service';

@Component({
  selector: 'app-view-attachment-list',
  templateUrl: './view-attachment-list.component.html',
  styleUrls: ['./view-attachment-list.component.scss']
})
export class ViewAttachmentListComponent implements OnInit {

  tableColumns: any;
  title: string;
  isOffline = false;

  constructor(
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dbService: NgxIndexedDBService,
    private settingsService: SettingsService
  ) {
    this.title = data?.title ?? 'Attachment list';
    this.isOffline = data?.length ? data[0]?.isOffline : false;
  }

  ngOnInit() {
    this.tableColumns = {
      sn: 'SN',
      'file.attachmentName': 'attachment Name',
      action: ''
    };
  }

  viewAttachment(uid: string, title: string){
    if (!this.isOffline) {
      this.store.dispatch(getAttachmentsByUid({uid, title}));
    } else {
      this.dbService.getAll(IndexedDBTableName.active_meeting_agenda_paper).toPromise().then((attachmentObjects: {
        agenda: string;
        id: number;
        data: string
      }[]) => {
        console.log(attachmentObjects);
        for (const attachmentObject of attachmentObjects) {
          const attachment: OfflineAgendaPaper = JSON.parse(attachmentObject?.data);
          console.log(attachment?.attachmentBase64);
          if (attachment?.attachmentPath === uid) {
            this.settingsService.openModal(
              {
                base64$: of(attachment?.attachmentBase64),
                fileTitle: attachment?.attachmentName,
                filePath: null
              },
              ViewAttachmentComponent, '85%');
            break;
          }
        }
      });

    }
  }
}
