export enum ComponentViewerEnum {
  ALL= 'ALL',
  ASSIGNED= 'ASSIGNED',
}

export enum ProjectStatusEnum {
  STALLED = 'STALLED',
  ONGOING = 'ONGOING',
  COMPLETED = 'COMPLETED',
  TERMINATED = 'TERMINATED',
  ONHOLD = 'ONHOLD',
  NOTSTARTED = 'NOTSTARTED',
}

export enum DocumentStatusEnum {
  Pending = 'Pending',
  OnGoing = 'OnGoing',
  Completed = 'Completed',
  WithDrawn = 'WithDrawn',
}

export enum PaperReportTypeEnum {
  PAPER = 'PAPER',
  CABINET_PAPER_IMPLEMENTATION = 'CABINET_PAPER_IMPLEMENTATION',
  CABINET_DECISION_IMPLEMENTATION = 'CABINET_DECISION_IMPLEMENTATION',
  IMPLEMENTATION_OF_MATTER_ARISING = 'IMPLEMENTATION_OF_MATTER_ARISING',
}
export enum MeetingReportTypeEnum {
  // MEETING = 'MEETING',
  ANNUAL_MEETING = 'ANNUAL_MEETING',
}

export enum ReportPaperStatusTypeEnum {
  PENDING = 'PENDING',
  DISCUSSED = 'DISCUSSED',
  WITHDRAWN = 'WITHDRAWN',
  DELETED = 'DELETED',
}

export enum ActionTypeEnum {
  create = 'create', edit= 'edit', 'delete' = 'delete', update = 'update', activate = 'activate'
}

export enum IconTypeEnum{
  SVG = 'SVG', MATERIAL = 'MATERIAL'
}

export enum FormValueTypeEnum{
  TEXT = 'TEXT',
  AMOUNT = 'AMOUNT',
  NUMBER = 'NUMBER',
  SELECTOR = 'SELECTOR',
  DATE = 'DATE',
  EMAIL = 'EMAIL',
  URL = 'URL',
  TEL = 'TEL',
}

export enum AnnotationType {
  'TEXT' = 3, 'DRAWING' = 15, 'IMAGE' = 13
}
