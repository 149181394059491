import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ForumMember} from './forum-member.model';
import * as ForumMemberActions from './forum-member.actions';

export const forumMembersFeatureKey = 'forumMembers';

export interface State extends EntityState<ForumMember> {
  // additional entities state properties
}


export const adapter: EntityAdapter<ForumMember> = createEntityAdapter<ForumMember>();

// export const adapter: EntityAdapter<ForumMember> = createEntityAdapter<ForumMember>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const forumMemberReducer = createReducer(
  initialState,
  on(ForumMemberActions.addForumMember,
    (state, action) => adapter.addOne(action.forumMember, state)
  ),
  on(ForumMemberActions.upsertForumMember,
    (state, action) => adapter.upsertOne(action.forumMember, state)
  ),
  on(ForumMemberActions.addForumMembers,
    (state, action) => adapter.addMany(action.forumMembers, state)
  ),
  on(ForumMemberActions.upsertForumMembers,
    (state, action) => adapter.upsertMany(action.forumMembers, state)
  ),
  on(ForumMemberActions.updateForumMember,
    (state, action) => adapter.updateOne(action.forumMember, state)
  ),
  on(ForumMemberActions.updateForumMembers,
    (state, action) => adapter.updateMany(action.forumMembers, state)
  ),
  on(ForumMemberActions.deleteForumMember,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ForumMemberActions.deleteForumMembers,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ForumMemberActions.loadForumMembers,
    (state, action) => adapter.setAll(action.forumMembers, state)
  ),
  on(ForumMemberActions.clearForumMembers,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return forumMemberReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllForumMembers = selectAll;
export const getForumMemberById = selectIds;
export const getForumMemberEntities = selectEntities;
