import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {NotificationService} from './notification.service';
import {getUserRoleAndPermissions} from "../store/entities/settings/user/user.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../store/entities";

@Injectable({
  providedIn: 'root'
})
export class WebAuthService {

  private http = inject(HttpClient);
  private store: Store<AppState> = inject(Store<AppState>);
  private notificationService: NotificationService = inject(NotificationService);
  private SERVER_URL = environment.SERVER_URL;

  async registerUser() {
    const options: any = await this.http.post(this.SERVER_URL + '/registration/start', {}).toPromise();

    options.user.id = base64url.decode(options.user.id);
    options.challenge = base64url.decode(options.challenge);

    if (options.excludeCredentials) {
      for (const cred of options.excludeCredentials) {
        cred.id = base64url.decode(cred.id);
      }
    }

    // Use platform authenticator and discoverable credential.
    options.authenticatorSelection = {
      authenticatorAttachment: 'platform',
      requireResidentKey: true
    };

    // Invoke the WebAuthn create() method.

    const cred: any = await navigator.credentials.create({
      publicKey: options,
    });

    // TODO: Add an ability to create a passkey: Register the credential to the server endpoint.
    const credential: any = {};
    credential.id = cred.id;
    credential.rawId = cred.id; // Pass a Base64URL encoded ID string.
    credential.type = cred.type;

    // The authenticatorAttachment string in the PublicKeyCredential object is a new addition in WebAuthn L3.
    if (cred?.authenticatorAttachment) {
      credential.authenticatorAttachment = cred.authenticatorAttachment;
    }

    // Base64URL encode some values.
    const clientDataJSON = base64url.encode(cred.response.clientDataJSON);
    const attestationObject = base64url.encode(cred.response.attestationObject);

    // Obtain transports.
    const transports = cred.response.getTransports ? cred.response.getTransports() : [];

    credential.response = {
      clientDataJSON,
      attestationObject,
      transports
    };
    const credentialData = {
      credential
    };

    if (credential && credentialData){
      this.http.post(this.SERVER_URL + '/registration/finish', credentialData).toPromise().then(_ => {
        this.notificationService.successMessage('Operation success');
      }).catch(_ => {
        this.notificationService.errorMessage('Operation Failed, please try again.');
      }).finally(() => this.store.dispatch(getUserRoleAndPermissions({redirectHome: false})));
    }

  }

  async removePasskeys() {
    this.http.post(this.SERVER_URL + '/remove/webauthn', {}).toPromise().then(response => {
      this.notificationService.successMessage('Operation success');
    }).catch(_ => {
      this.notificationService.errorMessage('Operation Failed, please try again');
    }).finally(() => this.store.dispatch(getUserRoleAndPermissions({redirectHome: false})));
  }


  async authenticateUser(username: string, ticket: string) {

    const options: any = await this.http.post(this.SERVER_URL + '/signin/start', {username}).toPromise();

    // Base64URL decode the challenge.
    options.challenge = base64url.decode(options.challenge);

    // An empty allowCredentials array invokes an account selector by discoverable credentials.
    options.allowCredentials = [];

    // Invoke the WebAuthn get() method.
    const cred: any = await navigator.credentials.get({
      publicKey: options,
      // Request a conditional UI.
      mediation: 'conditional'
    });

    const credential: any = {};
    credential.id = cred.id;
    credential.rawId = cred.id; // Pass a Base64URL encoded ID string.
    credential.type = cred.type;

    // Base64URL encode some values.
    const clientDataJSON = base64url.encode(cred.response.clientDataJSON);
    const authenticatorData = base64url.encode(cred.response.authenticatorData);
    const signature = base64url.encode(cred.response.signature);
    const userHandle = base64url.encode(cred.response.userHandle);

    credential.response = {
      clientDataJSON,
      authenticatorData,
      signature,
      userHandle,
    };

    console.log('credential', credential);

    const credentialData = {
      username,
      ticket,
      credential
    };
    return this.http.post(this.SERVER_URL + '/signin/finish', credentialData).toPromise();
  }


  // async authenticateUser(username: string) {
  //   const options = await this.http.post(this.SERVER_URL + '/signin/start', username).toPromise();
  //   const credential = await this.startAuthentication(options);
  //
  //   const credentialData = {
  //     id: credential.id,
  //     rawId: this.arrayBufferToBase64(credential.rawId),
  //     type: credential.type,
  //     response: {
  //       authenticatorData: this.arrayBufferToBase64(credential.response.authenticatorData),
  //       clientDataJSON: this.arrayBufferToBase64(credential.response.clientDataJSON),
  //       signature: this.arrayBufferToBase64(credential.response.signature),
  //       userHandle: credential.response.userHandle ? this.arrayBufferToBase64(credential.response.userHandle) : null
  //     }
  //   };
  //
  //   return this.http.post(this.SERVER_URL + '/signin/finish', credentialData).toPromise();
  // }

  private async startAuthentication(authenticationOptions: any): Promise<any> {
    try {
      // Convert any base64 strings to ArrayBuffer
      authenticationOptions.challenge = this.base64ToArrayBuffer(authenticationOptions.challenge);
      authenticationOptions.allowCredentials.forEach((cred: any) => {
        cred.id = this.base64ToArrayBuffer(cred.id);
      });

      // Call WebAuthn's navigator.credentials.get method
      const assertion: any = await navigator.credentials.get({ publicKey: authenticationOptions });

      // Prepare the assertion for sending to the backend
      return {
        id: assertion.id,
        rawId: this.arrayBufferToBase64(assertion.rawId),
        type: assertion.type,
        response: {
          authenticatorData: this.arrayBufferToBase64(assertion.response.authenticatorData),
          clientDataJSON: this.arrayBufferToBase64(assertion.response.clientDataJSON),
          signature: this.arrayBufferToBase64(assertion.response.signature),
          userHandle: assertion.response.userHandle ? this.arrayBufferToBase64(assertion.response.userHandle) : null
        }
      };
    } catch (error) {
      console.error('WebAuthn authentication failed', error);
      throw error;
    }
  }

  private async startRegistration(options: any): Promise<Credential | any> {
     try {
       const publicKey = this.preformatPublicKeyCredentialCreationOptions(options);
       return await navigator.credentials.create({publicKey});
     }catch (error){
       if (error instanceof DOMException) {
         if (error.name === 'NotAllowedError') {
           console.error('User denied the operation or it timed out.');
         } else if (error.name === 'SecurityError') {
           console.error('Operation was not allowed due to security reasons.');
         } else if (error.name === 'AbortError') {
           console.error('Operation aborted.');
         }
         // Handle other possible DOMExceptions
       } else {
         console.error('An unexpected error occurred:', error);
       }
     }
  }

  private preformatPublicKeyCredentialCreationOptions(options: any) {
    options.challenge = new Uint8Array(options.challenge);
    options.user.id = new Uint8Array(options.user.id);
    return options;
  }

  // private arrayBufferToBase64(buffer: ArrayBuffer): string {
  //   return btoa(String.fromCharCode(...new Uint8Array(buffer)));
  // }

  private base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}



export const base64url = {
  encode(buffer) {
    const base64 = window.btoa(String.fromCharCode(...new Uint8Array(buffer)));
    return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  },
  decode(base64url) {
    const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');
    const binStr = window.atob(base64);
    const bin = new Uint8Array(binStr.length);
    for (let i = 0; i < binStr.length; i++) {
      bin[i] = binStr.charCodeAt(i);
    }
    return bin.buffer;
  }
};
