import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'Pluck', pure: true, standalone: true })
export class PluckPipe implements PipeTransform {

  transform<T, K extends keyof T>(input: T, map: keyof T): T[K];
  transform(input: any[], map: string): any[];
  transform(input: any, map: string): any;

  transform(input: any, map: string): any {
    if (Array.isArray(input)) {
      return input.map(e => extractDeepPropertyByMapKey(e, map));
    }

    return isObject(input) ? extractDeepPropertyByMapKey(input, map) : input;
  }

}

export function isObject(value: any) {
  return value !== null && typeof value === 'object';
}

export function isUndefined(value: any) {
  return typeof value === 'undefined';
}

export function isNull(value: any) {
  return value === null;
}

export function extractDeepPropertyByMapKey(obj: any, map: string): any {
  const keys = map?.split('.');
  const head = keys.shift();

  return keys.reduce((prop: any, key: string) => {
    return !isUndefined(prop) && !isNull(prop) && !isUndefined(prop[key]) ? prop[key] : undefined;
  }, obj[head || '']);
}
