import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {SettingsService} from '../../../../services/settings.service';
import {ActivatedRoute} from '@angular/router';
import {getProjectByUid} from '../../../../store/entities/projects/project/project.actions';
import {Project} from '../../../../store/entities/projects/project/project.model';
import {selectProjectByUid} from '../../../../store/entities/projects/project/project.selectors';
import {ProjectStatusEnum} from '../../../enums/data.enums';
import {NotificationService} from '../../../../services/notification.service';
import {
  ProjectRecommendation
} from '../../../../store/entities/projects/project-recommendation/project-recommendation.model';
import {
  getProjectRecommendationByProjectUid,
  removeProjectRecommendation
} from '../../../../store/entities/projects/project-recommendation/project-recommendation.actions';
import {
  selectAllProjectRecommendations
} from '../../../../store/entities/projects/project-recommendation/project-recommendation.selectors';
import {
  ProjectRecommendationFormComponent
} from '../../../../modules/portal/portal-project/portal-project-forms/project-recommendation-form/project-recommendation-form.component';

@Component({
  selector: 'app-project-recommendation-detail',
  templateUrl: './project-recommendation-detail.component.html',
  styleUrls: ['./project-recommendation-detail.component.scss']
})
export class ProjectRecommendationDetailComponent implements OnInit {

  title: string;
  projectUid: string;
  project$: Observable<Project>;
  recommendations$: Observable<ProjectRecommendation[]>;
  tableColumn: any;
  projectStatus = ProjectStatusEnum;

  constructor(
    private store: Store<AppState>,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
  ) {
    this.projectUid = this.activatedRoute.snapshot.paramMap.get('uid');
    this.store.dispatch(getProjectByUid({uid: this.projectUid, withComponents: false}));
    this.store.dispatch(getProjectRecommendationByProjectUid({projectUid: this.projectUid}));
  }

  ngOnInit(): void {
    this.title = 'Project Recommendations';

    this.tableColumn = {
      no: 'No',
      descriptions: 'Description',
      formattedRecordedOn: 'Recorded On',
      action: 'Action'
    };

    this.project$ = this.store.select(selectProjectByUid(this.projectUid));
    this.recommendations$ = this.store.select(selectAllProjectRecommendations);
  }

  remove(res) {
    const message = 'Are you sure you want to remove?';
    this.notificationService.confirmation(message).then(
      data => {
        if (data) {
          this.store.dispatch(removeProjectRecommendation({ uid: res?.uniqueId, id: res?.id }));
        }
      }
    );
  }

  action(project: Project, recommendation: ProjectRecommendation) {
    this.settingsService.openModal({project, recommendation}, ProjectRecommendationFormComponent);
  }

}
