<div class="marquee" *ngIf="!isProduction">
  <div class="marquee-text" *ngFor="let i of [1,2,3,4,5,6,7,8]">
    <span>DEMO</span>
  </div>
</div>

<div class="align-items-center justify-content-center d-flex flex-column auth-layout">
  <div class="auth px-3 px-md-0">

    <div class="folder-container mx-auto col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
      <div class="folder position-relative">
        <div class="position-absolute" style="top:-36px;left:0; height:40px;  width:150px; z-index:1;">
          <svg class=""  style="height:100%;overflow:visible" viewBox="0 0 278 64.04"  xmlns="http://www.w3.org/2000/svg">
            <path class="folder-front shadow-top " d="M159.9,0L16,0C7.2,0,0,7.2,0,16v48l278,0C244.4,64,196.4,0,159.9,0z"/>
          </svg>
          <div class="position-absolute bg-white" style="bottom:-16px;left:0; height:20px;  width:200px; z-index:1;"> </div>
        </div>

        <div class="position-absolute" style="top:-36px;right:0; height:40px; transform: scaleX(-1); width:150px; z-index:0;">
          <svg class="" style="height:100%" viewBox="0 0 278 64.04"  xmlns="http://www.w3.org/2000/svg">
            <path class="folder-back drop-shadow" d="M159.9,0L16,0C7.2,0,0,7.2,0,16v48l278,0C244.4,64,196.4,0,159.9,0z"/>
          </svg>
        </div>

        <div class="folder-paper shadow-top-slight position-absolute"> </div>
        <div class="folder-paper-2 shadow-top-slight  position-absolute mx-auto"> </div>

        <div class="position-absolute folder-pin-holder mx-auto d-flex">
          <svg height="78.7px"  style="overflow:visible;enable-background:new 0 0 31.1 78.7;" viewBox="0 0 31.1 78.7" width="31.1px"
               x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" y="0px">
                  <path class="folder-pin" d="M29.1,23v-7.4C29.1,8.1,23,2,15.5,2h0C8.1,2,2,8.1,2,15.5v50.1c0,6.1,4.9,11,11,11h0c6.1,0,11-4.9,11-11V31.1"/>
                </svg>
        </div>
      </div>
    </div>

    <div class="auth-container mx-auto bg-white col-sm-8	col-md-6 col-lg-5	col-xl-4 col-xxl-3 px-3 move-up"
         style="--order: 4.5">

      <div class="row">
        <div class="col-md-12 pb-5">
          <div class="col-md-12 text-center pt-3">
            <img alt="Logo" class="w-20" src="/assets/images/emblem.png">
            <h5 class="auth-title mb-0 fw-bold">{{'The United Republic of Tanzania' | concat | translate}}</h5>
            <h5 class="auth-title">{{'eCabinet' | concat | translate}}</h5>
          </div>
          <ng-container *ngTemplateOutlet="AuthContent"></ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center mt-2 col-md-12 footer-content">
    &copy; 2021 - {{ currentYear }} - {{'Design and Developed by' | concat | translate}}
    <a href="https://www.ega.go.tz" target="_blank" title="e-GA"> {{'e - Government Authority' | concat | translate}} </a>
    <br> {{'Content Maintained by CSO' | concat | translate}}
  </div>

</div>
