import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {SettingsService} from '../../../../services/settings.service';
import {ActivatedRoute} from '@angular/router';
import {getProjectByUid} from '../../../../store/entities/projects/project/project.actions';
import {Project} from '../../../../store/entities/projects/project/project.model';
import {selectProjectByUid} from '../../../../store/entities/projects/project/project.selectors';
import {ProjectStatusEnum} from '../../../enums/data.enums';
import {NotificationService} from '../../../../services/notification.service';
import {ProjectImage} from '../../../../store/entities/projects/project-image/project-image.model';
import {
  getProjectImageByProjectUid,
  removeProjectImage
} from '../../../../store/entities/projects/project-image/project-image.actions';
import {selectAllProjectImages} from '../../../../store/entities/projects/project-image/project-image.selectors';
import {
  ProjectImageFormComponent
} from '../../../../modules/portal/portal-project/portal-project-forms/project-image-form/project-image-form.component';

@Component({
  selector: 'app-project-images-detail',
  templateUrl: './project-images-detail.component.html',
  styleUrls: ['./project-images-detail.component.scss']
})
export class ProjectImagesDetailComponent implements OnInit {

  title: string;
  projectUid: string;
  project$: Observable<Project>;
  images$: Observable<ProjectImage[]>;
  projectStatus = ProjectStatusEnum;

  constructor(
    private store: Store<AppState>,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
  ) {
    this.projectUid = this.activatedRoute.snapshot.paramMap.get('uid');
    this.store.dispatch(getProjectByUid({uid: this.projectUid, withComponents: false}));
    this.store.dispatch(getProjectImageByProjectUid({projectUid: this.projectUid}));
  }

  ngOnInit(): void {
    this.title = 'Project Images';

    this.project$ = this.store.select(selectProjectByUid(this.projectUid));
    this.images$ = this.store.select(selectAllProjectImages);
  }

  remove(res) {
    const message = 'Are you sure you want to remove? ';
    this.notificationService.confirmation(message).then(
      data => {
        if (data) {
          this.store.dispatch(removeProjectImage({ uid: res?.uniqueId, id: res?.id }));
        }
      }
    );
  }

  viewImage(selectedImage){
    const myModal = document.getElementById('image' + selectedImage);
    myModal.classList.add('d-block');
    myModal.classList.add('show');
  }

  action(project: Project) {
    this.settingsService.openModal(project, ProjectImageFormComponent);
  }

  isImage(attachmentName: string | undefined) {
    // const decodedData = atob(base64Data);
    // return decodedData.startsWith('\xFF\xD8\xFF');
    const names = attachmentName.split('.');
    return names[names.length - 1] === 'png' || names[names.length - 1] === 'jpg' || names[names.length - 1] === 'jpeg';
  }
}
