import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {SettingsService} from '../../../../services/settings.service';
import {Apollo} from 'apollo-angular';
import {
  deleteRepresentative,
  getAllRepresentative,
  loadRepresentatives,
  removeRepresentative,
  saveMinistryRepresentative,
  saveRepresentative,
  upsertRepresentative
} from "./representative.actions";
import {
  ADD_MEETING_REPRESENTATIVES,
  ADD_MINISTRY_MEETING_REPRESENTATIVES,
  GET_MEETING_REPRESENTATIVES,
  REMOVE_REPRESENTATIVE
} from "./representative.graphql";

@Injectable()
export class RepresentativeEffects {


  get = createEffect(() => this.actions$.pipe(
    ofType(getAllRepresentative),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_MEETING_REPRESENTATIVES,
        variables: { meetingUniqueId: action.meetingUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadRepresentatives({ representatives: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });


  save = createEffect(() => this.actions$.pipe(
    ofType(saveRepresentative),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: ADD_MEETING_REPRESENTATIVES,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessage(result?.response?.message);
            this.store.dispatch(upsertRepresentative({ representative: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  saveMinistry = createEffect(() => this.actions$.pipe(
    ofType(saveMinistryRepresentative),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: ADD_MINISTRY_MEETING_REPRESENTATIVES,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessage(result?.response?.message);
            this.store.dispatch(upsertRepresentative({ representative: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeRepresentative),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: REMOVE_REPRESENTATIVE,
        variables: { uniqueId: action.uniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessage(result?.response?.message);

            this.store.dispatch(deleteRepresentative({ id: action.id }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
  ) { }

}
