import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {Documentation, InternalUserEnum} from './documentation.model';

export const loadDocumentations = createAction(
  '[Documentation/API] Load Documentations',
  props<{ documentations: Documentation[] }>()
);

export const addDocumentation = createAction(
  '[Documentation/API] Add Documentation',
  props<{ documentation: Documentation }>()
);

export const upsertDocumentation = createAction(
  '[Documentation/API] Upsert Documentation',
  props<{ documentation: Documentation }>()
);

export const addDocumentations = createAction(
  '[Documentation/API] Add Documentations',
  props<{ documentations: Documentation[] }>()
);

export const upsertDocumentations = createAction(
  '[Documentation/API] Upsert Documentations',
  props<{ documentations: Documentation[] }>()
);

export const updateDocumentation = createAction(
  '[Documentation/API] Update Documentation',
  props<{ documentation: Update<Documentation> }>()
);

export const updateDocumentations = createAction(
  '[Documentation/API] Update Documentations',
  props<{ documentations: Update<Documentation>[] }>()
);

export const deleteDocumentation = createAction(
  '[Documentation/API] Delete Documentation',
  props<{ id: number }>()
);

export const deleteDocumentations = createAction(
  '[Documentation/API] Delete Documentations',
  props<{ ids: number[] }>()
);

export const clearDocumentations = createAction(
  '[Documentation/API] Clear Documentations'
);


export const getAllDocumentation = createAction(
  '[Documentation/API] Get All Paper Documentations',
  props<{ filtering: { receivedDateFrom?: string; receivedDateTo?: string } }>()
);

export const saveDocumentation = createAction(
  '[Documentation/API] Save Paper Documentation',
  props<{ input: { uniqueId?: string; categoryName: string; userGroups: InternalUserEnum[] } }>()
);

export const removeDocumentation = createAction(
  '[Documentation/API] Remove Paper Documentation',
  props<{ uniqueId: string, id: number }>()
);

export const revokeDocumentation = createAction(
  '[Documentation/API] Revoke Paper Documentation',
  props<{ accessUniqueId: string }>()
);

