<div cdkDrag cdkDragRootElement=".cdk-overlay-pane"
     class="modal-header-title {{ showCoverPhoto? 'no-bg' : ''}}">
    <div cdkDragHandle class="title">
      {{title | concat | translate}}
      <div class="handle"></div>
      <span class="close-modal" mat-dialog-close (click)="closeDialog.emit()">
          <mat-icon class="material-symbols-outlined">close</mat-icon>
      </span>
    </div>

</div>
