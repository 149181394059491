import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Institution, InstitutionDto} from './institution.model';

export const loadInstitutions = createAction(
  '[Institution/API] Load Institutions',
  props<{ institutions: Institution[] }>()
);

export const addInstitution = createAction(
  '[Institution/API] Add Institution',
  props<{ institution: Institution }>()
);

export const upsertInstitution = createAction(
  '[Institution/API] Upsert Institution',
  props<{ institution: Institution }>()
);

export const addInstitutions = createAction(
  '[Institution/API] Add Institutions',
  props<{ institutions: Institution[] }>()
);

export const upsertInstitutions = createAction(
  '[Institution/API] Upsert Institutions',
  props<{ institutions: Institution[] }>()
);

export const updateInstitution = createAction(
  '[Institution/API] Update Institution',
  props<{ institution: Update<Institution> }>()
);

export const updateInstitutions = createAction(
  '[Institution/API] Update Institutions',
  props<{ institutions: Update<Institution>[] }>()
);

export const deleteInstitution = createAction(
  '[Institution/API] Delete Institution',
  props<{ id: number }>()
);

export const deleteInstitutions = createAction(
  '[Institution/API] Delete Institutions',
  props<{ ids: string[] }>()
);

export const clearInstitutions = createAction(
  '[Institution/API] Clear Institutions'
);

export const getAllInstitutions = createAction(
  '[Institutions/API] List All Institutions'
);

export const actionInstitution = createAction(
  '[Institution/API] create, edit & delete institution',
  props<{ institutionDto: InstitutionDto, id?: number }>()
);
