import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Representative} from './representative.model';
import * as RepresentativeActions from './representative.actions';

export const representativesFeatureKey = 'representative';

export interface State extends EntityState<Representative> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Representative> = createEntityAdapter<Representative>({
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const RepresentativeReducer = createReducer(
  initialState,
  on(RepresentativeActions.addRepresentative,
    (state, action) => adapter.addOne(action.representative, state)
  ),
  on(RepresentativeActions.upsertRepresentative,
    (state, action) => adapter.upsertOne(action.representative, state)
  ),
  on(RepresentativeActions.addRepresentatives,
    (state, action) => adapter.addMany(action.representatives, state)
  ),
  on(RepresentativeActions.upsertRepresentatives,
    (state, action) => adapter.upsertMany(action.representatives, state)
  ),
  on(RepresentativeActions.updateRepresentative,
    (state, action) => adapter.updateOne(action.representative, state)
  ),
  on(RepresentativeActions.updateRepresentatives,
    (state, action) => adapter.updateMany(action.representatives, state)
  ),
  on(RepresentativeActions.deleteRepresentative,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(RepresentativeActions.deleteRepresentatives,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(RepresentativeActions.loadRepresentatives,
    (state, action) => adapter.setAll(action.representatives, state)
  ),
  on(RepresentativeActions.clearRepresentatives,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return RepresentativeReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getRepresentatives = selectAll;
