import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {Translation} from './translation.model';

export const loadTranslations = createAction(
  '[Translation/API] Load translations',
  props<{ translations: Translation[] }>()
);

export const addTranslation = createAction(
  '[Translation/API] Add Translation',
  props<{ translation: Translation }>()
);

export const upsertTranslation = createAction(
  '[Translation/API] Upsert Translation',
  props<{ translation: Translation }>()
);

export const addTranslations = createAction(
  '[Translation/API] Add translations',
  props<{ translations: Translation[] }>()
);

export const upsertTranslations = createAction(
  '[Translation/API] Upsert translations',
  props<{ translations: Translation[]}>()
);

export const updateTranslation = createAction(
  '[Translation/API] Update Translation',
  props<{ translation: Update<Translation> }>()
);

export const updateTranslations = createAction(
  '[Translation/API] Update translations',
  props<{ translations: Update<Translation>[] }>()
);

export const deleteTranslation = createAction(
  '[Translation/API] Delete Translation',
  props<{ id: number }>()
);

export const deleteTranslations = createAction(
  '[translation/API] Delete translations',
  props<{ ids: number[] }>()
);

export const clearTranslations = createAction(
  '[translation/API] Clear translations'
);


export const getAllTranslations = createAction(
  '[translation/API] get all  translations',
);

export const createTranslations = createAction(
  '[Translation/API] Save Translations',
  props<{ translations: Translation[] }>()
);

export const removeTranslations = createAction(
  '[Translation/API] remove Translation',
  props<{ keyword: string, id: any }>()
);

