import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';

import {MatSelect} from '@angular/material/select';
import {Observable, Subject, Subscription} from 'rxjs';
import {Project} from '../../../../store/entities/projects/project/project.model';
import {getAllInstitutions} from '../../../../store/entities/settings/institution/institution.actions';
import {Institution} from '../../../../store/entities/settings/institution/institution.model';
import {selectAllInstitutions} from '../../../../store/entities/settings/institution/institution.selectors';
import {map} from 'rxjs/operators';
import {getAllProjectCategories} from '../../../../store/entities/settings/project-category/project-category.actions';
import {ProjectCategory} from '../../../../store/entities/settings/project-category/project-category.model';
import {
  selectAllProjectCategories
} from '../../../../store/entities/settings/project-category/project-category.selectors';
import {editProject, saveProject} from '../../../../store/entities/projects/project/project.actions';
import {DatePipe} from '@angular/common';
import {StorageService} from '../../../../services/storage-service.service';
import {UserTypeEnum} from '../../../../store/entities/settings/user/user.model';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss']
})
export class ProjectFormComponent implements OnInit, OnDestroy {

  title: string;
  form: UntypedFormGroup;
  project: Project;
  ministries$: Observable<Institution[]>;
  projectCategories$: Observable<ProjectCategory[]>;
  userType: UserTypeEnum;
  userTypeEnum = UserTypeEnum;

  ministryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  projectFilterCtrl: UntypedFormControl = new UntypedFormControl();
  @ViewChild('ministrySelect', { static: true }) ministrySelect: MatSelect;
  @ViewChild('projectSelect', { static: true }) projectSelect: MatSelect;
  protected onDestroy = new Subject<void>();

  subscriptions: Subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private storageService: StorageService,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.userType = this.storageService.get('category');
    this.project = data;

    if (this.userType === UserTypeEnum.Staff){
      this.store.dispatch(getAllInstitutions());
    }
    this.store.dispatch(getAllProjectCategories());
  }

  ngOnInit(): void {
    this.title = this.project ? 'Edit Project' : 'Add Project';

    this.form = this.fb.group({
      categoryUid: [null, Validators.required],
      institutionUid: [null, Validators.required],
      name: [null, Validators.required],
      location: [null],
      contractor: [null],
      details: [null],
      engineerName: [null],
      allocatedBudget: [null],
      signatureDate: [null],
      expectedOutcome: [null],
      objective: [null],
      employer: [null],
      commencementDate: [null],
      completionDate: [null],
      governmentFund: [null],
      foreignFund: [null]
    });

    if (this.project) {
      this.form.get('categoryUid').patchValue(this.project?.category?.uniqueId);
      this.form.get('institutionUid').patchValue(this.project?.institution?.uniqueId);
      this.form.patchValue(this.project);
    }

    this.ministries$ = this.store.select(selectAllInstitutions);
    this.projectCategories$ = this.store.select(selectAllProjectCategories);

  }

  onMinistrySearch(searchValue: any) {
    this.ministries$ = this.store.select(selectAllInstitutions).pipe(
      map(data =>
        data?.filter((v) =>
          v.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
          v.shortform.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
      ));
  }

  onProjectCategorySearch(searchValue: any) {
    this.projectCategories$ = this.store.select(selectAllProjectCategories).pipe(
      map(data =>
        data?.filter((v) => v.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
      ));
  }

  add(formData) {
    if (this.project) {
      formData.uniqueId = this.project.uniqueId;
    }

    formData.institution = formData.institutionUid;
    formData.category = formData.categoryUid;
    delete formData.institutionUid;
    delete formData.categoryUid;

    formData.allocatedBudget = formData?.governmentFund + formData?.foreignFund;
    formData.signatureDate = new DatePipe('en-GB').transform(formData?.signatureDate, 'yyyy-MM-dd');
    formData.commencementDate = new DatePipe('en-GB').transform(formData?.commencementDate, 'yyyy-MM-dd');
    formData.completionDate = new DatePipe('en-GB').transform(formData?.completionDate, 'yyyy-MM-dd');

    if (this.project){
      this.store.dispatch(editProject({ formData }));
    }else {
      this.store.dispatch(saveProject({ formData }));
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.subscriptions.unsubscribe();
  }
}
