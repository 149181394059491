import {AppState} from '../store/entities';
import {inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {NgxPermissionsService} from 'ngx-permissions';
import {Store} from '@ngrx/store';
import {logoutAction} from '../store/entities/auth-user/auth-user.actions';
import {lastValueFrom, Subscription, throwError} from 'rxjs';
import {StorageService} from './storage-service.service';
import {environment} from '../../environments/environment';
import {SettingsService} from './settings.service';
import {getUserRoleAndPermissions, saveDeviceLocation} from '../store/entities/settings/user/user.actions';
import {AuthTokenModel} from '../shared/interfaces/data.interfaces';
import {catchError, map} from 'rxjs/operators';
import {NotificationService} from './notification.service';
import {
  TwoFactorAuthLoginComponent
} from '../shared/components/auth/two-factor-auth-login/two-factor-auth-login.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  subscriptions: Subscription = new Subscription();

  http = inject(HttpClient);
  router = inject(Router);
  notificationService = inject(NotificationService);
  storageService = inject(StorageService);
  ngxPermissionsService = inject(NgxPermissionsService);
  settingsService = inject(SettingsService);

  constructor(private store: Store<AppState>) {}

  async login(username: string, password: string): Promise<AuthTokenModel> {

    const body = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
      Authorization: 'Basic ' + btoa(environment.CLIENT_ID + ':' + environment.CLIENT_SECRET),
      SerialNumber: this.storageService.get('serialNumber'),
      DeviceId: this.storageService.get('deviceId'),
    });

    return await lastValueFrom(
      this.http
        .post<AuthTokenModel>(environment.SERVER_URL + `/oauth2/access_token`, body, { headers })
        .pipe(
          map((tokenDetails: any) => {
              if (tokenDetails?.two_fa_enabled || tokenDetails?.webauthn_enabled) {
                const dialogRef = this.settingsService.openModal({
                  ticket: tokenDetails?.ticket,
                  userEmail: username,
                  totp: tokenDetails?.two_fa_enabled,
                  passkey: tokenDetails?.webauthn_enabled
                }, TwoFactorAuthLoginComponent, '30%');
                // this.settingsService.openModal({ticket: tokenDetails?.ticket }, OfflineOnlineSelectComponent, '30%');
                dialogRef.afterClosed().subscribe((data: any) => {
                  if (data && data.access_token) {
                    this.storageService.set('currentClient', data.access_token);
                    this.storageService.set('refreshToken', data.refresh_token);
                    this.store.dispatch(getUserRoleAndPermissions({redirectHome: true}));

                    this.setTimeOut(data.expires_in);
                  }
                });
              }
              if (tokenDetails && tokenDetails.access_token) {
                this.storageService.set('currentClient', tokenDetails.access_token);
                this.storageService.set('refreshToken', tokenDetails.refresh_token);
                this.store.dispatch(getUserRoleAndPermissions({redirectHome: true}));
                this.setTimeOut(tokenDetails.expires_in);
              }

              if (tokenDetails && tokenDetails.error) {
                this.notificationService.errorMessage('Incorrect username / Email Address or Password');
              }
              return tokenDetails;
          })
        )
    );
  }


  refreshToken() {

    if (this.storageService.get('currentClient')) {
      const secret = environment.CLIENT_SECRET;
      const clientId = environment.CLIENT_ID;
      const refreshToken = this.storageService.get('refreshToken');

      const body = `refresh_token=${encodeURIComponent(refreshToken)}&client_secret=${encodeURIComponent(secret)}&client_id=${encodeURIComponent(clientId)}&grant_type=refresh_token`;

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
        Authorization: 'Basic ' + btoa(environment.CLIENT_ID + ':' + environment.CLIENT_SECRET),
      });

      lastValueFrom(
        this.http
          .post<AuthTokenModel>(environment.SERVER_URL + `/oauth2/access_token`, body, { headers })
          .pipe(
            catchError((error: HttpErrorResponse) => {
              if (error.status === 0) {
                this.logout();
              }
              return throwError(() => new Error('Error: ' + error));
            })
          )
          .pipe(
            map((tokenDetails) => {
              if (tokenDetails && tokenDetails.access_token) {

                this.storageService.set('currentClient', tokenDetails.access_token);
                this.storageService.set('refreshToken', tokenDetails.refresh_token);

                this.setTimeOut(tokenDetails.expires_in);

              }
            })
          )
      ).then();
    }

  }

  setTimeOut(expiresIn: number){
    /*NOTE: take expiry time minus 5 minutes, to request a new token by refresh token */
    const expiryTimeInMills = (expiresIn - 300) * 1000;

    this.storageService.set('expiry', expiresIn * 1000);

    const refreshId: any = setTimeout(() => this.refreshToken(), expiryTimeInMills);

    if (sessionStorage.getItem('refreshId') !== null){
      clearTimeout(parseInt(sessionStorage.getItem('refreshId'), 10));
    }

    sessionStorage.setItem('refreshId', refreshId);
  }

  authRole(): Promise<any> {
    let redirectHome = true;

    if (this.storageService.get('access')) {
      redirectHome = false;
      this.ngxPermissionsService.loadPermissions(this.storageService.get('access'));
    }

    if (this.storageService.get('currentClient')) {
      const inputDto = {
        deviceId: this.storageService.get('deviceId'),
        longitude: JSON.stringify(this.storageService.get('coordLo')),
        latitude: JSON.stringify(this.storageService.get('coordLa'))
      };

      this.store.dispatch(saveDeviceLocation({inputDto}));

      this.store.dispatch(getUserRoleAndPermissions({ redirectHome }));
    }

    return new Promise((resolve) => resolve(this.storageService.get('access')));
  }

  logout(): any {
    // this.store.dispatch(revokeToken({ token: this.storageService.get('refreshToken')}));
    this.store.dispatch(logoutAction());
    this.clearStore();
    this.notificationService.dialog.closeAll();

    let deviceId = null;
    let serialNumber = null;
    let username = null;

    if (this.storageService.get('deviceId')){
      deviceId = this.storageService.get('deviceId');
    }

    if (this.storageService.get('serialNumber')){
      serialNumber = this.storageService.get('serialNumber');
    }

    if (this.storageService.get('username')){
      username = this.storageService.get('username');
    }

    if (deviceId !== null && serialNumber !== null && username !== null){
      this.router.navigateByUrl('/login-with-email/' + username + '?deviceId=' + deviceId + '&serialNumber=' + serialNumber).then();
    } else {
      this.router.navigate(['', 'login']).then();
    }

  }

  alreadyLoggedIn(): boolean {
    return !!this.storageService.get('currentClient');
  }

  clearStore(){
    if (sessionStorage.getItem('refreshId') !== null){
      clearTimeout(parseInt(sessionStorage.getItem('refreshId'), 10));
    }

    let deviceId = null;
    let serialNumber = null;
    let username = null;

    if (this.storageService.get('deviceId')){
      deviceId = this.storageService.get('deviceId');
    }

    if (this.storageService.get('serialNumber')){
      serialNumber = this.storageService.get('serialNumber');
    }

    if (this.storageService.get('username')){
      username = this.storageService.get('username');
    }

    this.store.dispatch(logoutAction());
    localStorage.clear();
    this.storageService.clear();
    window.sessionStorage.clear();
    sessionStorage.clear();
    this.ngxPermissionsService.flushPermissions();

    if (deviceId){
      this.storageService.set('deviceId', deviceId);
    }

    if (serialNumber){
      this.storageService.set('serialNumber', serialNumber);
    }

    if (username){
      this.storageService.set('username', username);
    }

  }

  collectFailedRequest(err: any): void {
    const errorCode = err.status;
    const errorDescription = err.reason;
    let message = err?.reason !== null ? err?.reason : err?.message;

    if (errorCode === 400) {
      message = 'Bad Inputs';
    }

    if (errorCode === 401) {
      this.clearStore();
      this.router.navigateByUrl('/login').then();

      if (errorDescription === null) {
        message = 'You Dont Have Right to Access this Application, Please Contact Support Team for More Details';
      }
    }
    if (errorCode === 423) {
      message = 'Account is Locked';
      this.notificationService.errorMessage(message);
    }
    if (errorCode === 404) {
      message = 'Service Temporarily Unavailable';
    }
    if (errorCode === 500) {
      message = null;
      // message = 'Something went wrong in server side.';
      console.error('Something went wrong in server side.');
      this.storageService.clear();
    }
    if (errorCode === 504) {
      message = 'Service Temporarily Unavailable';
    }

    if (message !== null){
      // this.notificationService.errorMessage(message)
    }
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
