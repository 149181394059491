import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  deleteProjectComponentTask,
  editProjectComponentTask,
  removeProjectComponentTask,
  saveProjectComponentTask,
  upsertProjectComponentTask
} from './project-component-task.actions';
import {map, switchMap} from 'rxjs/operators';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {Apollo} from 'apollo-angular';
import {
  DELETE_PROJECT_COMPONENT_TASK,
  SAVE_PROJECT_COMPONENT_TASK,
  UPDATE_PROJECT_COMPONENT_TASK
} from './project-component-task.graphql';

@Injectable()
export class ProjectComponentTaskEffects {

  save = createEffect(() => this.actions$.pipe(
    ofType(saveProjectComponentTask),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_PROJECT_COMPONENT_TASK,
        variables: { inputDto: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertProjectComponentTask({projectComponentTask: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  update = createEffect(() => this.actions$.pipe(
    ofType(editProjectComponentTask),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_PROJECT_COMPONENT_TASK,
        variables: { inputDto: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertProjectComponentTask({projectComponentTask: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeProjectComponentTask),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_PROJECT_COMPONENT_TASK,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteProjectComponentTask({id: action.id}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService
  ) {
  }
}
