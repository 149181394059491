import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {Store} from '@ngrx/store';
import {lastValueFrom, Observable, Subscription} from 'rxjs';

import {Sort} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {IndexedDBTableName, TableColumn, TableColumnType} from '../../../interfaces/table.interface';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {Clipboard} from '@angular/cdk/clipboard';
import {PageableParam, SearchFieldsDtoInput} from '../../../../store/entities/page/page.model';
import {SettingsService} from '../../../../services/settings.service';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../../../store/entities';
import {selectPage} from '../../../../store/entities/page/page.selectors';
import {TableColumnSelectionComponent} from '../table-column-selection/table-column-selection.component';
import {DialogService} from '../../../../services/dialog.service';
import {TableColumnSelectionService} from '../table-column-selection/table-column-selection.service';

@Component({
  selector: 'app-table-pageable',
  templateUrl: './table-pageable.component.html',
  styleUrls: ['./table-pageable.component.scss'],
})
export class TablePageableComponent implements OnInit, OnDestroy {

  @Input() tableColumns: TableColumn[];
  @Input() extraTableColumns: TableColumn[];
  @Input() currentPage = 1;
  @Input() pageSize = 10;
  @Input() tableCssClass: string[];
  @Input() tableData$: Observable<any>;
  @Input() pageSizeOptions: number[] = [10, 50, 100, 250];
  @Input() searchFields: SearchFieldsDtoInput[];

  cacheTableData: any;
  openAdvanceFilter = false;

  @ContentChild('advanceFilterContext', {static: true}) advanceFilterContext: TemplateRef<any>;
  @ContentChild('actions', {static: true}) actions: TemplateRef<any>;

  tableColumnType = TableColumnType;
  @Output() onTableValueChanges =
    new EventEmitter<{ totalElements: number, totalPages: number, selectedTableColumn: TableColumn[] }>();
  cacheTotalElements: number;
  @Output() pageable = new EventEmitter<PageableParam>();
  @Output() selectionChange = new EventEmitter<any>();
  @Output() onOpenAdvanceFilter = new EventEmitter<boolean>();
  @Output() downloadExcel = new EventEmitter<boolean>();

  subscriptions: Subscription = new Subscription();
  selection = new SelectionModel<any>(true, []);

  totalPages = 1;
  pagination = [];
  sortKey: string;
  sortDirectionKey: string;
  searchKey: string;

  cacheTableColumns: TableColumn[];
  cacheTableColumnKeywords: string[];
  cacheTableColumnIndexedKey: number = null;

  settingsService = inject(SettingsService);
  notificationService = inject(NotificationService);
  clipboard = inject(Clipboard);
  dialogService = inject(DialogService);
  dbService = inject(NgxIndexedDBService);
  changeDetectorRefs = inject(ChangeDetectorRef);
  columnSelectionService = inject(TableColumnSelectionService);

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {

    this.subscriptions.add(
      this.store.select(selectPage).subscribe( (page) => {
        if (page != null){
          this.totalPages = page?.numberOfPages;
          this.cacheTotalElements = page?.totalElements;

          this.pagination = this.settingsService.generatePagination(this.currentPage, page?.numberOfPages);
        }
      })
    );

    this.subscriptions.add(
      this.tableData$?.subscribe( (tableData) => {
        if (tableData){
          this.cacheTableData = tableData;
        }
      })
    );

    this.subscriptions.add(
      this.selection?.changed.subscribe( (selectedRow) => {
        if (selectedRow){
          this.selectionChange.emit(this.selection.selected);
        }
      })
    );

    /*listen for column selection dialog emitted data*/
    this.subscriptions.add(
      this.columnSelectionService.currentData.subscribe( data => {
        if (data?.columns){
          let value = data?.columns as TableColumn[];

          if (value?.length > 0){
            /*NOTE: check is object has no action columns while exist object has action column, update returned value*/
            if (value?.findIndex( d => d?.isActionColumn) < 0) {
              value = [...value, { id: value?.length + 1, keyword: 'action', title: 'action', isActionColumn: true}];
            }
            this.updateCacheTableColumns(value);
          }
        }
      })
    );

    this.getValuesFromIndexedDb().then();
  }

  async getValuesFromIndexedDb(){
    return lastValueFrom(
      this.dbService.getByIndex(IndexedDBTableName.table_column, 'route', window.location.pathname)
    ).then( (data: any) => {

      if (data) {
        this.cacheTableColumnIndexedKey = data?.id;
        this.cacheTableColumns = data?.columns;
        this.cacheTableColumnKeywords = this.settingsService.getTableColumnKeywords(data?.columns);
        this.changeDetectorRefs.detectChanges();
        // this.cdkTable.renderRows();
      }else {
        /*add new record on indexedTable*/
        this.updateCacheTableColumns(this.tableColumns);
      }
    });
  }

  loadData() {
    if (this.searchKey !== null){
      this.pageable.emit({pageNumber: this.currentPage, itemsPerPage: this.pageSize, searchKey: this.searchKey});
    } else {
      this.pageable.emit({pageNumber: this.currentPage, itemsPerPage: this.pageSize});
    }

  }

  getPage(pageIndex: number) {
    this.currentPage = pageIndex + 1;
    this.pagination = this.settingsService.generatePagination(this.currentPage, this.totalPages);
    this.loadData();
  }

  onSearchClear() {
    this.searchKey = null;
    this.currentPage = 1;
    this.loadData();
  }

  filterTable() {
    this.searchKey = this.searchKey.trim().toLowerCase();
    this.currentPage = 1;
    this.loadData();
  }

  sortData(event: Sort) {
    this.sortKey = event.active;
    this.sortDirectionKey = event.direction ?? this.sortDirectionKey;
    this.loadData();
  }

  getPageSize(page: number) {
    this.pageSize = page;
    this.loadData();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;

    return numSelected === this.cacheTableData.data.length;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  copyTable() {
    if (this.clipboard.copy(document.getElementById('table').innerText)){
      this.notificationService.message('Table Content Copied!');
    }else {
      this.notificationService.errorMessage('Copy Failed!');
    }
  }

  openColumnSection() {
    const data = {
      tableColumns: this.tableColumns,
      extraTableColumns: this.extraTableColumns,
      cacheTableColumns: this.cacheTableColumns,
    };

    this.dialogService.open({ data, component: TableColumnSelectionComponent, width: '20%'}).then();
  }

  updateCacheTableColumns(tableColumns) {
    this.cacheTableColumns = tableColumns;
    this.cacheTableColumnKeywords = this.settingsService.getTableColumnKeywords(tableColumns);

    if (this.cacheTableColumnIndexedKey){
      /*update indexed record*/
      lastValueFrom(
        this.dbService.update(IndexedDBTableName.table_column,
          {columns: this.cacheTableColumns, route: window.location.pathname, id: this.cacheTableColumnIndexedKey})
      ).then();

    }else {

      lastValueFrom(
        this.dbService.add(IndexedDBTableName.table_column,
          {columns: this.cacheTableColumns, route: window.location.pathname})
      ).then( data => {
        if (data){
          this.cacheTableColumnIndexedKey = data?.id;
        }
      });

    }

    this.onTableValueChanges.emit({
      totalElements: this.cacheTotalElements,
      totalPages: this.totalPages,
      selectedTableColumn: this.cacheTableColumns
    });

    this.changeDetectorRefs.detectChanges();
    // this.cdkTable.renderRows();

  }

  toggleAdvanceFilter(){
    this.openAdvanceFilter = !this.openAdvanceFilter;
    this.onOpenAdvanceFilter.emit(this.openAdvanceFilter);
  }

  downloadExcelFile(value) {
    this.downloadExcel.emit(value);
  }
}
