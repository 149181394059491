import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {
  deactivateDevice,
  deleteDevice,
  getAllDevices,
  loadDevices,
  registerUserDevice,
  removeUserDevice,
  upsertDevice
} from './device.actions';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {DE_ACTIVATE_DEVICE, GET_DEVICE, REMOVE_DEVICE, SAVE_DEVICE} from './device.graphql';

@Injectable()
export class DeviceEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllDevices),
    switchMap(() => {
      return this.apollo.query({
        query: GET_DEVICE
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadDevices({devices: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(registerUserDevice),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_DEVICE,
        variables: { inputDto: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertDevice({device: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  deactivate = createEffect(() => this.actions$.pipe(
    ofType(deactivateDevice),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DE_ACTIVATE_DEVICE,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertDevice({ device: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeUserDevice),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: REMOVE_DEVICE,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteDevice({id: result?.data?.id}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private apollo: Apollo
  ) {
  }
}
