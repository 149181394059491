import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {PageableParam} from '../../page/page.model';
import {DirectiveProgress} from './directives-progress.model';

export const loadDirectiveProgresss = createAction(
  '[DirectiveProgress/API] Load DirectiveProgresss',
  props<{ directiveProgresss: DirectiveProgress[] }>()
);

export const addDirectiveProgress = createAction(
  '[DirectiveProgress/API] Add DirectiveProgress',
  props<{ directiveProgress: DirectiveProgress }>()
);

export const upsertDirectiveProgress = createAction(
  '[DirectiveProgress/API] Upsert DirectiveProgress',
  props<{ directiveProgress: DirectiveProgress }>()
);

export const addDirectiveProgresss = createAction(
  '[DirectiveProgress/API] Add DirectiveProgresss',
  props<{ directiveProgresss: DirectiveProgress[] }>()
);

export const upsertDirectiveProgresss = createAction(
  '[DirectiveProgress/API] Upsert DirectiveProgresss',
  props<{ directiveProgresss: DirectiveProgress[] }>()
);

export const updateDirectiveProgress = createAction(
  '[DirectiveProgress/API] Update DirectiveProgress',
  props<{ directiveProgress: Update<DirectiveProgress> }>()
);

export const updateDirectiveProgresss = createAction(
  '[DirectiveProgress/API] Update DirectiveProgresss',
  props<{ directiveProgresss: Update<DirectiveProgress>[] }>()
);

export const deleteDirectiveProgress = createAction(
  '[DirectiveProgress/API] Delete DirectiveProgress',
  props<{ id: number }>()
);

export const deleteDirectiveProgresss = createAction(
  '[DirectiveProgress/API] Delete DirectiveProgresss',
  props<{ ids: number[] }>()
);

export const clearDirectiveProgresss = createAction(
  '[DirectiveProgress/API] Clear DirectiveProgresss'
);

export const getAllDirectiveProgressPageable = createAction(
  '[DirectiveProgress/API] get all active DirectiveProgresss pageable',
  props<{ pageable: PageableParam, stageName: string, meetingStatus?: string }>()
);


export const getAllDirectivesImplementations = createAction(
  '[DirectiveProgress/API] Get All Directives Implementations',
  props<{ filtering?: any, directiveUniqueId?: string }>()
);

export const saveDirectiveProgress = createAction(
  '[DirectiveProgress/API] Save DirectiveProgress',
  props<{ progress: any }>()
);

export const editDirectiveProgress = createAction(
  '[DirectiveProgress/API] edit DirectiveProgress',
  props<{ progress: any }>()
);

export const removeDirectiveProgress = createAction(
  '[DirectiveProgress/API] remove Scheduled Meeting',
  props<{ directiveUniqueId: string }>()
);

export const reviewDirectiveProgressReport = createAction(
  '[DirectiveProgress/API] remove Scheduled Meeting',
  props<{ review: any }>()
);

export const getDirectiveReviewReports = createAction(
  '[DirectiveProgress/API] get Directive Review Reports',
  props<{ directive: string }>()
);

