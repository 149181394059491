import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {Store} from '@ngrx/store';
import {ProjectComponent} from '../../../../../store/entities/projects/project-component/project-component.model';
import {
  editProjectComponentTask,
  saveProjectComponentTask
} from '../../../../../store/entities/projects/project-component-task/project-component-task.actions';
import {AppState} from '../../../../../store/entities';
import {
  ProjectComponentTask
} from '../../../../../store/entities/projects/project-component-task/project-component-task.model';

@Component({
  selector: 'app-project-component-task-form',
  templateUrl: './project-component-task-form.component.html',
  styleUrls: ['./project-component-task-form.component.scss']
})
export class ProjectComponentTaskFormComponent implements OnInit {

  title: string;
  task: ProjectComponentTask;
  component: ProjectComponent;
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.task = data?.task;
    this.component = data?.component;
  }

  ngOnInit(): void {
    this.title = this.task ? 'Edit ' : ' Add ' + 'Project Component Task';

    this.form = this.fb.group({
      name: [null, Validators.required],
      location: [null, Validators.required],
      manager: [null, Validators.required],
      startDate: [null, Validators.required],
      handoverDate: [null, Validators.required],
      descriptions: [null, Validators.required],
      budget: [null, Validators.required],
      percentCompositionOnComponent: [null, Validators.required],
      component: [this.component?.uniqueId, Validators.required],
    });

    if (this.task) {
      this.form.patchValue(this.task);
    }
  }

  add(formData) {
    formData.handoverDate = new DatePipe('en-GB').transform(formData.handoverDate, 'yyyy-MM-dd');
    formData.startDate = new DatePipe('en-GB').transform(formData.startDate, 'yyyy-MM-dd');

    if (this.task){
      formData.uniqueId = this.task?.uniqueId;
      this.store.dispatch(editProjectComponentTask({ formData}));
    }else {
      this.store.dispatch(saveProjectComponentTask({ formData}));
    }

  }
}
