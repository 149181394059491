<ng-container *ngIf="useAttachmentConfig; else dontUseAttachmentConfig">
  <ng-container *ngIf="attachmentConfigs$ | async as attachmentHints">

    <ng-container *ngIf="attachmentHints?.length > 0 && !isSingleDragDrop">
      <h6> {{'attachments' | translate}} </h6>

      <ng-container *ngIf="!isMultiple; else multipleAllowed">
        <div class="alert bg-dark-subtle rounded-4">
          <table class="table table-hover align-middle move-up --order-4 mb-0">
            <thead>
            <tr>
              <th>{{'sn' | translate}}</th>
              <th>{{'attachment-title' | translate}}</th>
              <th>{{'is-required' | translate}}</th>
              <th></th>
            </tr>
            </thead>

            <tbody class="border-top-0">
            <ng-container *ngFor="let attachmentConfig of attachmentHints; let i = index">
              <tr>
                <td>{{ i + 1}}</td>
                <td><span [innerHTML]="attachmentConfig?.attachmentName"></span></td>
                <td><span [innerHTML]="attachmentConfig?.attachmentIsMandatory ? 'yes' : 'no' | concat | translate"></span></td>
                <td>
                  <ng-container *ngIf="isAttachmentExist(attachmentConfig); else noAttachment">

                    <button (click)="viewAttachment(attachmentConfig?.id)" color="primary" class="mx-1" mat-mini-fab
                            matTooltip="{{ 'view attachment' | concat | translate}}" type="button">
                      <mat-icon svgIcon="document"></mat-icon>
                    </button>

                    <button (click)="removeSingleFile(attachmentConfig, i)" mat-mini-fab class="bg-danger"
                            matTooltip="{{'Remove' | concat | translate}}" type="button">
                      <mat-icon svgIcon="close"></mat-icon>
                    </button>
                  </ng-container>

                  <ng-template #noAttachment>
                    <input #importInput (change)="onSingleFileSelected($event, i, attachmentConfig)"
                           accept=".pdf" style="display: none" type="file">
                    <button (click)="importInput.click()"
                            [disabled]="uploadingFile"
                            class="mx-1 btm-sm" color="primary" mat-raised-button type="button">
                      <span>{{'attach' | translate}}</span>
                    </button>
                  </ng-template>

                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="isSingleDragDrop">
      <div class="row">
        <div class="col-12">
          <ngx-dropzone (change)="onMultipleFileSelect($event)" [expandable]="true" accept=".pdf">
            <ngx-dropzone-label>{{'drag-pdf-only' | translate}}</ngx-dropzone-label>
            <ngx-dropzone-preview (removed)="onMultipleFileRemove(f)" *ngFor="let f of files"
                                  [file]="f" [removable]="true" ngProjectAs="ngx-dropzone-preview">
              <ngx-dropzone-label>{{ f.name }} <ng-container *ngIf="f?.type">({{ f.type }})</ng-container></ngx-dropzone-label>
            </ngx-dropzone-preview>

          </ngx-dropzone>
        </div>
      </div>
    </ng-container>

    <ng-template #multipleAllowed>

      <!--    <div class="alert alert-info">-->
      <!--      <ng-container *ngFor="let attachmentHint of attachmentHints">-->
      <!--        <span [innerHTML]="attachmentHint?.title"></span>-->
      <!--      </ng-container>-->
      <!--    </div>-->

      <div class="row">
        <div class="col-12">
          <ngx-dropzone (change)="onMultipleFileSelect($event)" [expandable]="true" accept=".pdf">
            <ngx-dropzone-label>{{'drag-pdf-only' | translate}}</ngx-dropzone-label>
            <ngx-dropzone-preview (removed)="onMultipleFileRemove(f)" *ngFor="let f of files"
                                  [file]="f" [removable]="true" ngProjectAs="ngx-dropzone-preview">
              <ngx-dropzone-label>{{ f.name }} <ng-container *ngIf="f?.type">({{ f.type }})</ng-container></ngx-dropzone-label>
            </ngx-dropzone-preview>

          </ngx-dropzone>
        </div>
      </div>
    </ng-template>
  </ng-container>

</ng-container>


<ng-template #dontUseAttachmentConfig>

  <form [formGroup]="form"  autocomplete="off" novalidate>
    <div class="row">

      <div class="alert bg-dark-subtle rounded-4">
        <table class="table table-hover align-middle move-up --order-4 mb-0">
          <thead>
          <tr>
            <th>{{'sn' | translate}}</th>
            <th>{{'attachment-title' | translate}}</th>
            <th></th>
            <th></th>
          </tr>
          </thead>

          <tbody class="border-top-0">

          <ng-container formArrayName="attachments">
            <ng-container *ngFor="let item of getFormArray.controls; let i = index" [formGroupName]="i">
              <tr>
                <td>{{ i + 1 }}</td>

                <td>
                  <mat-form-field appearance="outline" class="material-form no-hint">
                    <mat-label>{{'attachment title' | concat | translate}}</mat-label>
                    <input formControlName="attachmentName" matInput required (input)="onSingleFileSelectedFormData(null, i, getFormArray.controls[i].value?.attachmentName)">
                  </mat-form-field>
                </td>

                <td>
                  <input #importInput (change)="onSingleFileSelectedFormData($event, i, getFormArray.controls[i].value?.attachmentName)"
                         formControlName="attachment" accept=".pdf" style="display: none" type="file">
                  <button (click)="importInput.click()" [disabled]="uploadingFile"
                          class="mx-1 btm-sm" color="primary" mat-raised-button type="button">
                    <span>{{'attach' | translate}}</span>
                  </button>

                  <ng-container *ngIf="isAttachmentExist({'id': i})">
                    <button (click)="viewAttachment(i)" color="primary" class="mx-1" mat-mini-fab
                            matTooltip="{{ 'view attachment' | concat | translate}}" type="button">
                      <mat-icon svgIcon="document"></mat-icon>
                    </button>
                  </ng-container>

                </td>

                <td>

                  <button (click)="removeSingleFile(getFormArray.controls[i].value, i)" class="material-symbols-outlined" color="accent" mat-icon-button
                          matTooltip="{{'Remove' | concat | translate}}" type="button">
                    <mat-icon>close</mat-icon>
                  </button>
                </td>
              </tr>

            </ng-container>
          </ng-container>

            <tr>
              <td colspan="4" class="text-end">
                <button (click)="addFormRow(null)" class="mx-1 material-symbols-outlined" color="primary"
                        mat-raised-button type="button">
                  {{'Add Attachment' | concat | translate}}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </form>
</ng-template>
