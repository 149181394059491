import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const mailConfigField = `
  ${baseField}
  host
  port
  fromEmail
  username
  password
  useTls
  useSsl
  failSilently
  timeout
`;

export const GET_MAIL_CONFIG = gql`
  query getSmtpConfigurations {
    getSmtpConfigurations {
      response{
        ${responseField}
      }
      data{
        ${mailConfigField}
      }
    }
  }
`;

export const SAVE_MAIL_CONFIG = gql`
  mutation smtpConfigManagement($inputDto: SMTPConfigInputObjects!) {
    smtpConfigManagement(config: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${mailConfigField}
      }
    }
  }
`;
