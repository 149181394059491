import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {ProjectImage, ProjectImageDto} from './project-image.model';

export const loadProjectImages = createAction(
  '[ProjectImage/API] Load ProjectImages',
  props<{ projectImages: ProjectImage[] }>()
);

export const addProjectImage = createAction(
  '[ProjectImage/API] Add ProjectImage',
  props<{ projectImage: ProjectImage }>()
);

export const upsertProjectImage = createAction(
  '[ProjectImage/API] Upsert ProjectImage',
  props<{ projectImage: ProjectImage }>()
);

export const addProjectImages = createAction(
  '[ProjectImage/API] Add ProjectImages',
  props<{ projectImages: ProjectImage[] }>()
);

export const upsertProjectImages = createAction(
  '[ProjectImage/API] Upsert ProjectImages',
  props<{ projectImages: ProjectImage[] }>()
);

export const updateProjectImage = createAction(
  '[ProjectImage/API] Update ProjectImage',
  props<{ projectImage: Update<ProjectImage> }>()
);

export const updateProjectImages = createAction(
  '[ProjectImage/API] Update ProjectImages',
  props<{ projectImages: Update<ProjectImage>[] }>()
);

export const deleteProjectImage = createAction(
  '[ProjectImage/API] Delete ProjectImage',
  props<{ id: number }>()
);

export const deleteProjectImages = createAction(
  '[ProjectImage/API] Delete ProjectImages',
  props<{ ids: string[] }>()
);

export const clearProjectImages = createAction(
  '[ProjectImage/API] Clear ProjectImages'
);



export const getProjectImageByProjectUid = createAction(
  '[ProjectImage/API] get projectImage by project uid',
  props<{ projectUid: string }>()
);

export const saveProjectImage = createAction(
  '[ProjectImage/API] create & edit projectImage',
  props<{ formData: ProjectImageDto }>()
);

export const removeProjectImage = createAction(
  '[ProjectImage/API] remove projectImage',
  props<{ uid: string, id: number }>()
);
