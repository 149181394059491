<app-modal-header  [title]="title"></app-modal-header>

<form (ngSubmit)="add(form.value)" [formGroup]="form"  autocomplete="off" novalidate>
  <div class="row">
    <div class="col-md-12">
      <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{'project component title' | concat | translate}}</mat-label>
        <input autocomplete="off" formControlName="name" matInput>
        <mat-error>
                <span *ngIf="!form.get('name').valid && form.get('name').touched">
                    {{'this field is required' | concat | translate}}
                </span>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{'Description' | concat | translate}}</mat-label>
        <input autocomplete="off" formControlName="details" matInput>
        <mat-error>
                <span *ngIf="!form.get('details').valid && form.get('details').touched">
                    {{'this field is required' | concat | translate}}
                </span>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{'Budget TZS' | concat | translate}}</mat-label>
        <input [max]="project?.allocatedBudget" autocomplete="off" formControlName="budget" matInput type="number">
        <mat-hint>{{ 'maximum amount' | concat | translate}} {{ project?.allocatedBudget | number}} (TZS)</mat-hint>
        <mat-error>
                <span *ngIf="!form.get('budget').valid && form.get('budget').touched">
                    {{'this field is required' | concat | translate}}
                </span>
        </mat-error>
      </mat-form-field>
    </div>


    <div class="col-md-6">
      <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{'Component Percentage' | concat | translate}}</mat-label>
        <input [max]="100" autocomplete="off" formControlName="percentCompositionOnProject" matInput type="number">
        <mat-hint>{{ 'maximum percentage' | concat | translate}} 100%</mat-hint>
        <mat-error>
                <span *ngIf="!form.get('percentCompositionOnProject').valid && form.get('percentCompositionOnProject').touched">
                    {{'this field is required' | concat | translate}}
                </span>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{'Location' | concat | translate}}</mat-label>
        <input autocomplete="off" formControlName="location" matInput>
        <mat-error>
                <span *ngIf="!form.get('location').valid && form.get('location').touched">
                    {{'this field is required' | concat | translate}}
                </span>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{'Manager' | concat | translate}}</mat-label>
        <input autocomplete="off" formControlName="manager" matInput>
        <mat-error>
                <span *ngIf="!form.get('manager').valid && form.get('manager').touched">
                    {{'this field is required' | concat | translate}}
                </span>
        </mat-error>
      </mat-form-field>
    </div>


    <div class="col-md-6">
      <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{'Start Date' | concat | translate}} </mat-label>
<!--        <input [matDatepicker]="startDate" formControlName="startDate" matInput>-->
        <input [matDatepicker]="startDate" [max]="project?.completionDate" [min]="project?.signatureDate"
               formControlName="startDate" matInput>
        <mat-hint>
          {{ 'minimum date' | concat | translate}} {{ project?.signatureDate | date}},
          {{ 'maximum date' | concat | translate}} {{ project?.completionDate | date}}
        </mat-hint>
        <mat-datepicker-toggle [for]="startDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
        <mat-error>
                <span *ngIf="!form.get('startDate').valid && form.get('startDate').touched">
                    {{'this field is required' | concat | translate}}
                </span>
        </mat-error>
      </mat-form-field>
    </div>


    <div class="col-md-6">
      <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{'End Date' | concat | translate}} </mat-label>
        <input [matDatepicker]="handoverDate" [max]="project?.completionDate" [min]="form.get('startDate').value"
               formControlName="handoverDate" matInput>
        <mat-hint>
          {{ 'minimum date' | concat | translate}} {{ form.get('startDate').value | date}},
          {{ 'maximum date' | concat | translate}} {{ project?.completionDate | date}}
        </mat-hint>
        <mat-datepicker-toggle [for]="handoverDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #handoverDate></mat-datepicker>
        <mat-error>
                <span *ngIf="!form.get('handoverDate').valid && form.get('handoverDate').touched">
                    {{'this field is required' | concat | translate}}
                </span>
        </mat-error>
      </mat-form-field>
    </div>

  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="text-end">
        <button [disabled]="!form.valid" color="primary" mat-raised-button type="submit">
          {{'Save' | concat | translate}}
        </button>
      </div>
    </div>
  </div>
</form>
