import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {getAllFinancialYears, loadFinancialYears} from './financial-year.actions';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {GET_FINANCIAL_YEAR} from './financial-year.graphql';

@Injectable()
export class FinancialYearEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllFinancialYears),
    switchMap(() => {
      return this.apollo.query({
        query: GET_FINANCIAL_YEAR
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.store.dispatch(loadFinancialYears({financialYears: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apollo: Apollo,
  ) {
  }
}
