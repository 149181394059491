import gql from 'graphql-tag';
import {responseField} from '../../base.graphql';

export const inviteeField = `
  id
  uuid
  inviteeFullName
  inviteeContact
  inviteeEmail
  inviteeInsitution
  inviteeDesignation
  inviteeCreatedDate
`;

export const GET_MEETING_INVITEES = gql`
query GetMeetingInvitees($meetingUniqueId: String) {
  getMeetingInvitees(meetingUniqueId: $meetingUniqueId) {
    response {
      ${responseField}
    }
    data {
      ${inviteeField}
    }
  }
}
`;


export const ADD_MEETING_INVITEES = gql`
mutation AddMeetingInvitees($input: MeetingInviteeInputObject) {
  addMeetingInvitees(input: $input) {
    response {
      ${responseField}
    }
    data {
      ${inviteeField}
    }
  }
}
`;


export const EDIT_MEETING_INVITEES = gql`
mutation EditMeetingInvitees($input: MeetingInviteesDetails) {
  editMeetingInvitees(input: $input) {
    response {
      ${responseField}
    }
    data {
      ${inviteeField}
    }
  }
}
`;


export const REMOVE_MEETING_INVITEE = gql`
mutation RemoveMeetingInvitee($uniqueId: String) {
  removeMeetingInvitee(uniqueId: $uniqueId) {
    response {
      ${responseField}
    }
  }
}
`

