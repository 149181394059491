import {createSelector} from '@ngrx/store';
import * as fromMeetingAgenda from './meeting-agenda.reducer';
import {AppState} from '../../index';

export const currentState = (state: AppState) => (state[fromMeetingAgenda.meetingAgendasFeatureKey]);

export const selectMeetingAgendasFromReducer = createSelector(currentState, fromMeetingAgenda.selectAll);
export const selectEntities = createSelector(currentState, fromMeetingAgenda.selectEntities);

export const selectMeetingAgendas = createSelector(
  selectMeetingAgendasFromReducer,
  (meetingAgendas) => {
    return meetingAgendas.map((meetingAgenda) => {
      return {
        ...meetingAgenda,
        paperName: meetingAgenda?.paper?.title
      };
    });
  });

export const selectMeetingAgendaByUuid = (agendaUniqueId: string) => createSelector(
  selectMeetingAgendas,
  (meetingAgendas) => {
    return meetingAgendas.find(a => a.uniqueId === agendaUniqueId);
  });

export const selectNoOfflinePapers = createSelector(
  currentState, fromMeetingAgenda?.getNoOfflinePapers);



