import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const projectComponentTaskField = `
  ${baseField}
  name
  budget
  location
  duration
  manager
  percentCompositionOnComponent
  startDate
  handoverDate
  descriptions
  status
  active
  completedOn
  recordedOn
  taskProgresses{
    ${baseField}
    description
    status
    recordedOn
    psCoverLetter
  }
`;

export const SAVE_PROJECT_COMPONENT_TASK = gql`
  mutation createComponentTaskMutation($inputDto: ProjectTaskInputObjects!) {
    createComponentTaskMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectComponentTaskField}
      }
    }
  }
`;

export const UPDATE_PROJECT_COMPONENT_TASK = gql`
  mutation updateComponentTaskMutation($inputDto: ProjectTaskInputObjects!) {
    updateComponentTaskMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectComponentTaskField}
      }
    }
  }
`;

export const DELETE_PROJECT_COMPONENT_TASK = gql`
  mutation deleteComponentTaskMutation($uid: String!) {
    deleteComponentTaskMutation(uniqueId: $uid){
      response{
        ${responseField}
      }
    }
  }
`;
