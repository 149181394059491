<app-modal-header [title]="title"></app-modal-header>

<form (ngSubmit)="add(form.value)" [formGroup]="form"  autocomplete="off" novalidate>
  <div class="row">

    <div class="col-md-12">
      <h5 class="mb-0">{{'Challenge' | concat | translate}}</h5>
      <quill-editor  [styles]="editorStyle" class="col-12" formControlName="challenge">
      </quill-editor>
    </div>

    <div class="col-md-12 my-5">
      <h5 class="mb-0">{{'Way Forward' | concat | translate}}</h5>
      <quill-editor [styles]="editorStyle" class="col-12" formControlName="wayForward">
      </quill-editor>
    </div>

  </div>

  <div class="row mt-2">
    <div class="col-md-12">
      <div class="text-end">
        <button [disabled]="!form.valid" class="" color="primary" mat-raised-button type="submit">
          {{'Save' | concat | translate}}
        </button>
      </div>
    </div>
  </div>
</form>
