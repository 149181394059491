import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Directive} from './directive.model';
import * as DirectiveActions from './directive.actions';

export const directivesFeatureKey = 'directive';

export interface State extends EntityState<Directive> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Directive> = createEntityAdapter<Directive>({
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const directiveReducer = createReducer(
  initialState,
  on(DirectiveActions.addDirective,
    (state, action) => adapter.addOne(action.directive, state)
  ),
  on(DirectiveActions.upsertDirective,
    (state, action) => adapter.upsertOne(action.directive, state)
  ),
  on(DirectiveActions.addDirectives,
    (state, action) => adapter.addMany(action.directives, state)
  ),
  on(DirectiveActions.upsertDirectives,
    (state, action) => adapter.upsertMany(action.directives, state)
  ),
  on(DirectiveActions.updateDirective,
    (state, action) => adapter.updateOne(action.directive, state)
  ),
  on(DirectiveActions.updateDirectives,
    (state, action) => adapter.updateMany(action.directives, state)
  ),
  on(DirectiveActions.deleteDirective,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(DirectiveActions.deleteDirectives,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(DirectiveActions.loadDirectives,
    (state, action) => adapter.setAll(action.directives, state)
  ),
  on(DirectiveActions.clearDirectives,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return directiveReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getDirectives = selectAll;
