<app-modal-header [title]="title"></app-modal-header>

<div class="alert alert-warning">
  <p class="mb-0">
    {{'At least three Uppercase, three lowercase and number are required' | concat | translate}}
  </p>
</div>

  <form (ngSubmit)="saveChangePassword(changePasswordForm.value)" [formGroup]="changePasswordForm" autocomplete="off"
        class="col-md-12" novalidate>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{'Old Password' | concat | translate}}</mat-label>
        <input formControlName="oldPassword" matInput [type]="oldPasswordHide ? 'password' : 'text'">
        <mat-icon matSuffix svgIcon="{{ oldPasswordHide ? 'eye' : 'password' }}" class="cursor-pointer"
                  (click)="oldPasswordHide = !oldPasswordHide">
        </mat-icon>
        <mat-error>
          <span *ngIf="!changePasswordForm.get('oldPassword').valid && changePasswordForm.get('oldPassword').touched">
            {{'This field is required' | concat | translate}}
          </span>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{'New Password' | concat | translate}}</mat-label>
        <input formControlName="newPassword" matInput [type]="newPasswordHide ? 'password' : 'text'">
        <mat-icon matSuffix svgIcon="{{ newPasswordHide ? 'eye' : 'password' }}" class="cursor-pointer"
                  (click)="newPasswordHide = !newPasswordHide">
        </mat-icon>
        <mat-error>
          <span *ngIf="!changePasswordForm.get('newPassword').valid && changePasswordForm.get('newPassword').touched">
            {{'This field is required' | concat | translate}}
          </span>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{'Confirm Password' | concat | translate}}</mat-label>
        <input formControlName="confirmPassword" matInput [type]="confirmPasswordHide ? 'password' : 'text'">
        <mat-icon matSuffix svgIcon="{{ confirmPasswordHide ? 'eye' : 'password' }}" class="cursor-pointer"
                  (click)="confirmPasswordHide = !confirmPasswordHide">
        </mat-icon>
        <mat-error>
          <span
            *ngIf="!changePasswordForm.get('confirmPassword').valid && changePasswordForm.get('confirmPassword').touched">
            {{'This field is required' | concat | translate }}
          </span>
        </mat-error>
      </mat-form-field>
    </div>

     <div class="row">
      <div class="col-md-12">
        <div class="text-end">
          <button [disabled]="!changePasswordForm.valid &&
          changePasswordForm.get('newPassword') === changePasswordForm.get('confirmPassword')"
                  color="primary" mat-raised-button type="submit">
            {{'Save' | concat | translate}}
          </button>
        </div>
      </div>
    </div>

  </form>
