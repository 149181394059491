import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {DocumentComment} from './document-comment.model';
import * as DocumentCommentActions from './document-comment.actions';

export const documentCommentsFeatureKey = 'documentComment';

export interface State extends EntityState<DocumentComment> {
  // additional entities state properties
}

export const adapter: EntityAdapter<DocumentComment> = createEntityAdapter<DocumentComment>({
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const DocumentDocumentCommentReducer = createReducer(
  initialState,
  on(DocumentCommentActions.addDocumentComment,
    (state, action) => adapter.addOne(action.documentComment, state)
  ),
  on(DocumentCommentActions.upsertDocumentComment,
    (state, action) => adapter.upsertOne(action.documentComment, state)
  ),
  on(DocumentCommentActions.addDocumentComments,
    (state, action) => adapter.addMany(action.documentComments, state)
  ),
  on(DocumentCommentActions.upsertDocumentComments,
    (state, action) => adapter.upsertMany(action.documentComments, state)
  ),
  on(DocumentCommentActions.updateDocumentComment,
    (state, action) => adapter.updateOne(action.documentComment, state)
  ),
  on(DocumentCommentActions.updateDocumentComments,
    (state, action) => adapter.updateMany(action.documentComments, state)
  ),
  on(DocumentCommentActions.deleteDocumentComment,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(DocumentCommentActions.deleteDocumentComments,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(DocumentCommentActions.loadDocumentComments,
    (state, action) => adapter.setAll(action.documentComments, state)
  ),
  on(DocumentCommentActions.clearDocumentComments,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return DocumentDocumentCommentReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getDocumentComments = selectAll;
