import {createSelector} from '@ngrx/store';
import * as fromReducer from './project-component-task.reducer';
import {AppState} from '../../index';
import {DatePipe} from '@angular/common';

export const currentProjectComponentTaskState = (state: AppState) => state[fromReducer.projectComponentTasksFeatureKey];

export const selectAllProjectComponentTasksFromReducer = createSelector(currentProjectComponentTaskState, fromReducer.selectAll);

export const selectAllProjectComponentTasks = createSelector(
  selectAllProjectComponentTasksFromReducer,
  (tasks) => {
    return tasks.map( task => {
      return {
        ...task,
        formattedStartDate: new DatePipe('en-GB').transform( task?.startDate, 'dd MMM yyyy'),
        formattedHandoverDate: new DatePipe('en-GB').transform( task?.handoverDate, 'dd MMM yyyy'),
      };
    });
  });
