import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {ProjectCategory, ProjectCategoryDto} from './project-category.model';

export const loadProjectCategories = createAction(
  '[ProjectCategory/API] Load ProjectCategories',
  props<{ ProjectCategories: ProjectCategory[] }>()
);

export const addProjectCategory = createAction(
  '[ProjectCategory/API] Add ProjectCategory',
  props<{ projectCategory: ProjectCategory }>()
);

export const upsertProjectCategory = createAction(
  '[ProjectCategory/API] Upsert ProjectCategory',
  props<{ projectCategory: ProjectCategory }>()
);

export const addProjectCategories = createAction(
  '[ProjectCategory/API] Add ProjectCategories',
  props<{ ProjectCategories: ProjectCategory[] }>()
);

export const upsertProjectCategories = createAction(
  '[ProjectCategory/API] Upsert ProjectCategories',
  props<{ ProjectCategories: ProjectCategory[] }>()
);

export const updateProjectCategory = createAction(
  '[ProjectCategory/API] Update ProjectCategory',
  props<{ projectCategory: Update<ProjectCategory> }>()
);

export const updateProjectCategories = createAction(
  '[ProjectCategory/API] Update ProjectCategories',
  props<{ ProjectCategories: Update<ProjectCategory>[] }>()
);

export const deleteProjectCategory = createAction(
  '[ProjectCategory/API] Delete ProjectCategory',
  props<{ id: number }>()
);

export const deleteProjectCategories = createAction(
  '[ProjectCategory/API] Delete ProjectCategories',
  props<{ ids: string[] }>()
);

export const clearProjectCategories = createAction(
  '[ProjectCategory/API] Clear ProjectCategories'
);

export const getAllProjectCategories = createAction(
  '[ProjectCategories/API] List All ProjectCategories'
);

export const actionProjectCategory = createAction(
  '[ProjectCategory/API] create, edit & delete projectCategory',
  props<{ projectCategoryDto: ProjectCategoryDto, id?: number }>()
);
