<app-modal-header  [title]="title"></app-modal-header>
<form (ngSubmit)="add(form.value)" [formGroup]="form"  autocomplete="off" >
  <div class="row">

    <ng-container *ngIf="userType === userTypeEnum.Staff">

      <div class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'project name' | concat | translate}}</mat-label>
          <input autocomplete="off" formControlName="name" matInput>
          <mat-error>
                <span *ngIf="!form.get('name').valid && form.get('name').touched">
                    {{'this field is required' | concat | translate}}
                </span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Institution' | concat | translate}} </mat-label>
          <mat-select #ministrySelect formControlName="institutionUid">
            <mat-option>
              <ngx-mat-select-search (ngModelChange)="onMinistrySearch($event)"
                                     [clearSearchInput]="false"
                                     [formControl]="ministryFilterCtrl"
                                     noEntriesFoundLabel="{{'No matching found' | concat | translate}}"
                                     placeholderLabel="{{'Search' | concat | translate}}...">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let ministry of ministries$ | async" [value]="ministry?.uniqueId">
              {{ ministry?.name}}
            </mat-option>
          </mat-select>
          <mat-error>
                <span *ngIf="!form.get('institutionUid').valid && form.get('institutionUid').touched">
                    {{'this field is required' | concat | translate}}
                </span>
          </mat-error>
        </mat-form-field>
      </div>

    </ng-container>


    <div class="col-md-4">
      <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{'project Category' | concat | translate}} </mat-label>
        <mat-select #projectSelect formControlName="categoryUid">
          <mat-option>
            <ngx-mat-select-search (ngModelChange)="onProjectCategorySearch($event)"
                                   [clearSearchInput]="false"
                                   [formControl]="projectFilterCtrl"
                                   noEntriesFoundLabel="{{'No matching found' | concat | translate}}"
                                   placeholderLabel="{{'Search' | concat | translate}}...">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let projectCategory of projectCategories$ | async" [value]="projectCategory?.uniqueId">
            {{ projectCategory?.name}}
          </mat-option>
        </mat-select>
        <mat-error>
                <span *ngIf="!form.get('categoryUid').valid && form.get('categoryUid').touched">
                    {{'this field is required' | concat | translate}}
                </span>
        </mat-error>
      </mat-form-field>
    </div>


    <ng-container *ngIf="userType === userTypeEnum.Ministry">
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Location' | concat | translate}}</mat-label>
          <input autocomplete="off" formControlName="location" matInput>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Supervisor' | concat | translate}}</mat-label>
          <input autocomplete="off" formControlName="engineerName" matInput>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Description' | concat | translate}}</mat-label>
          <input autocomplete="off" formControlName="details" matInput >
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Employer' | concat | translate}}</mat-label>
          <input autocomplete="off" formControlName="employer" matInput>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Contractor' | concat | translate}}</mat-label>
          <input autocomplete="off" formControlName="contractor" matInput >
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Objective' | concat | translate}}</mat-label>
          <input autocomplete="off" formControlName="objective" matInput >
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Expected OutCome' | concat | translate}}</mat-label>
          <input autocomplete="off" formControlName="expectedOutcome" matInput >
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Government Fund(TZS)' | concat | translate}}</mat-label>
          <input autocomplete="off" formControlName="governmentFund" matInput type="number">
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Foreign Fund(TZS)' | concat | translate}}</mat-label>
          <input autocomplete="off" formControlName="foreignFund" matInput type="number">
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Contract Date' | concat | translate}} </mat-label>
          <input [matDatepicker]="signatureDate" formControlName="signatureDate" matInput>
          <mat-datepicker-toggle [for]="signatureDate" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #signatureDate></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Commencement Date' | concat | translate}} </mat-label>
          <input [matDatepicker]="commencementDate" [min]="form.get('signatureDate').value" formControlName="commencementDate" matInput>
          <mat-datepicker-toggle [for]="commencementDate" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #commencementDate></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Completion Date' | concat | translate}} </mat-label>
          <input [matDatepicker]="completionDate" [min]="form.get('commencementDate').value" formControlName="completionDate" matInput>
          <mat-datepicker-toggle [for]="completionDate" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #completionDate></mat-datepicker>
        </mat-form-field>
      </div>

    </ng-container>

  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="text-end">
        <button [disabled]="!form.valid" color="primary" mat-raised-button type="submit">
          {{'Save' | concat | translate}}
        </button>
      </div>
    </div>
  </div>
</form>
