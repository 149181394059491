import gql from 'graphql-tag';
import {responseField} from '../../base.graphql';

export const paperCommentFields = `
id
uniqueId
title
message
relatedPaperTitle
`;

export const GET_MY_PAPER_COMMENTS = gql`
  query GetMyPaperNotificationsList {
    getMyPaperNotificationsList {
      response {
        ${responseField}
      }
      data {
        ${paperCommentFields}
      }
    }
  }
`;


export const SEND_PAPER_COMMENT = gql`
  mutation SendPapersNotifications($input: PapersNotificationInputObjects!) {
    sendPapersNotifications(input: $input) {
      response {
        ${responseField}
      }
    }
  }
`;


export const DISMISS_PAPER_COMMENT = gql`
  mutation DismissPaperNotifications($uniqueId: String) {
    dismissPaperNotifications(uniqueId: $uniqueId) {
      response {
        ${responseField}
      }
    }
  }
`;

