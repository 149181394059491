import {createSelector} from '@ngrx/store';
import * as fromReducer from './project-recommendation.reducer';
import {AppState} from '../../index';
import {DatePipe} from '@angular/common';

export const currentProjectRecommendationState = (state: AppState) => state[fromReducer.projectRecommendationsFeatureKey];
export const selectAllProjectRecommendationsFromReducer = createSelector(currentProjectRecommendationState, fromReducer.selectAll);

export const selectAllProjectRecommendations = createSelector(
  selectAllProjectRecommendationsFromReducer,
  (projects) => {
    return projects.map( res => {
      return {
        ...res,
        formattedRecordedOn: new DatePipe('en-GB').transform( res?.recordedOn, 'dd MMM yyyy')
      };
    });
  });
