import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const minutesNotesField = `
  ${baseField}
  descriptions
`;

export const GET_ALL_NOTES = gql`
query GetAllNotes($agendaUniqueId: String) {
  getAllNotes(agendaUniqueId: $agendaUniqueId) {
    response {
      ${responseField}
    }
    data {
      ${minutesNotesField}
    }
  }
}
`;

export const GET_MINUTES_NOTES_BY_STAGE = gql`
  query getPapersByStageList($inputDto: PaperStageTypeEnum) {
    getPapersByStageList(stage: $inputDto) {
      response{
        ${responseField}
      }
      data{
        ${minutesNotesField}
      }
    }
  }
`;
export const GET_MINUTES_NOTES_BY_MEETING = gql`
  query getMeetingsAgendas($meetingUniqueId: String) {
    getMeetingsAgendas(meetingUniqueId: $meetingUniqueId) {
      response{
        ${responseField}
      }
      data{
        ${minutesNotesField}
      }
    }
  }
`;

export const SAVE_MINUTES_NOTES = gql`
  mutation addMinutesNotes($notes: MinutesNotesInputObjects!) {
    addMinutesNotes(notes: $notes){
      response{
        ${responseField}
      }
      data{
        ${minutesNotesField}
      }
    }
  }
`;

export const UPDATE_MINUTES_NOTES = gql`
  mutation updateMinutesNotes($notes: MinutesNotesInputObjects!) {
    updateMinutesNotes(notes: $notes){
      response{
        ${responseField}
      }
      data{
        ${minutesNotesField}
      }
    }
  }
`;

export const DELETE_MINUTES_NOTES = gql`
  mutation deleteMinutesNotes($notesUniqueId: String!) {
    deleteMinutesNotes(notesUniqueId: $notesUniqueId){
      response{
        ${responseField}
      }
    }
  }
`;
