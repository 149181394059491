import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {PageableParam} from '../../page/page.model';
import {MinutesNotes} from './minutes-notes.model';

export const loadMinutesNotess = createAction(
  '[MinutesNotes/API] Load MinutesNotess',
  props<{ minutesNotess: MinutesNotes[] }>()
);

export const addMinutesNotes = createAction(
  '[MinutesNotes/API] Add MinutesNotes',
  props<{ minutesNotes: MinutesNotes }>()
);

export const upsertMinutesNotes = createAction(
  '[MinutesNotes/API] Upsert MinutesNotes',
  props<{ minutesNotes: MinutesNotes }>()
);

export const addMinutesNotess = createAction(
  '[MinutesNotes/API] Add MinutesNotess',
  props<{ minutesNotess: MinutesNotes[] }>()
);

export const upsertMinutesNotess = createAction(
  '[MinutesNotes/API] Upsert MinutesNotess',
  props<{ minutesNotess: MinutesNotes[] }>()
);

export const updateMinutesNotes = createAction(
  '[MinutesNotes/API] Update MinutesNotes',
  props<{ minutesNotes: Update<MinutesNotes> }>()
);

export const updateMinutesNotess = createAction(
  '[MinutesNotes/API] Update MinutesNotess',
  props<{ minutesNotess: Update<MinutesNotes>[] }>()
);

export const deleteMinutesNotes = createAction(
  '[MinutesNotes/API] Delete MinutesNotes',
  props<{ id: number }>()
);

export const deleteMinutesNotess = createAction(
  '[MinutesNotes/API] Delete MinutesNotess',
  props<{ ids: number[] }>()
);

export const clearMinutesNotess = createAction(
  '[MinutesNotes/API] Clear MinutesNotess'
);

export const getAllMinutesNotesPageable = createAction(
  '[MinutesNotes/API] get all active MinutesNotess pageable',
  props<{ pageable: PageableParam, stageName: string, meetingStatus?: string}>()
);


export const getAllMinutesNotes = createAction(
  '[MinutesNotes/API] Get All Minutes Notes',
  props<{ agendaUniqueId: string }>()
);

export const saveMinutesNotes = createAction(
  '[MinutesNotes/API] Save MinutesNotes',
  props<{ notes: any }>()
);

export const editMinutesNotes = createAction(
  '[MinutesNotes/API] edit MinutesNotes',
  props<{ notes: any }>()
);

export const removeMinutesNotes = createAction(
  '[MinutesNotes/API] remove Scheduled Meeting',
  props<{ notesUniqueId: string, id: number }>()
);

