
export interface TableColumn {
  id?: number;
  keyword: string;
  title?: string;
  type?: TableColumnType;
  dateFormat?: string;
  sortableColumn?: any;
  isActionColumn?: boolean;
  columnClass?: string[];
  theadCellClass?: string | string[];
  tbodyCellClass?: string | string[];
}

export enum TableColumnType {
  index = 'index',
  text = 'text',
  number = 'number',
  decimal = 'decimal',
  date = 'date',
  url = 'url',
  img = 'img',
  inputCheckbox = 'inputCheckbox',
}


export enum IndexedDBTableName {
  advance_table_search = 'advance_table_search',
  table_column = 'table_column',
  translation = 'translations',
  active_meeting = 'active_meeting',
  active_meeting_agenda = 'active_meeting_agenda',
  active_meeting_agenda_paper = 'active_meeting_agenda_paper',
}
