import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Page} from './page.model';

export const loadPages = createAction(
  '[Page/API] Load pages',
  props<{ pages: Page[] }>()
);

export const addPage = createAction(
  '[Page/API] Add Page',
  props<{ page: Page }>()
);

export const upsertPage = createAction(
  '[Page/API] Upsert Page',
  props<{ page: Page }>()
);

export const addPages = createAction(
  '[Page/API] Add Pages',
  props<{ pages: Page[] }>()
);

export const upsertPages = createAction(
  '[Page/API] Upsert Pages',
  props<{ pages: Page[] }>()
);

export const updatePage = createAction(
  '[Page/API] Update Page',
  props<{ page: Update<Page> }>()
);

export const updatePages = createAction(
  '[Page/API] Update Pages',
  props<{ pages: Update<Page>[] }>()
);

export const deletePage = createAction(
  '[Page/API] Delete Page',
  props<{ id: string }>()
);

export const deletePages = createAction(
  '[Page/API] Delete Pages',
  props<{ ids: string[] }>()
);

export const clearPages = createAction(
  '[Page/API] Clear Pages'
);

export const insertPageTotalElement = createAction(
  '[Page/API] insert Page total element',
  props<{ totalElement: number }>()
);

export const insertPage = createAction(
  '[Page/API] insert Page',
  props<{ page: Page }>()
);
