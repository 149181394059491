import {createSelector} from '@ngrx/store';
import * as fromProjectChallengeReducer from './project-challenge.reducer';
import {AppState} from '../../index';
import {DatePipe} from '@angular/common';

export const currentProjectChallengeState = (state: AppState) => state[fromProjectChallengeReducer.projectChallengesFeatureKey];

export const selectAllProjectChallengesFromReducer = createSelector(currentProjectChallengeState, fromProjectChallengeReducer.selectAll);
export const selectTotalProjectChallenges = createSelector(currentProjectChallengeState, fromProjectChallengeReducer.selectTotal);

export const selectAllProjectChallenges = createSelector(
  selectAllProjectChallengesFromReducer,
  (projects) => {
    return projects.map( res => {
      return {
        ...res,
        formattedRecordedOn: new DatePipe('en-GB').transform( res?.recordedOn, 'dd MMM yyyy')
      };
    });
  });

export const selectProjectChallengeById = (id: number) => createSelector(
  selectAllProjectChallenges,
  (ProjectChallenges) => ProjectChallenges.find((projectChallenge) => projectChallenge.id === id)
);

export const selectProjectChallengeByUid = (uid: string) => createSelector(
  selectAllProjectChallenges,
  (ProjectChallenges) => ProjectChallenges.find((projectChallenge) => projectChallenge.uniqueId === uid)
);
