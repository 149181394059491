import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const smsConfigField = `
  ${baseField}
  senderId
  senderKey
  userId
  mobileService
`;

export const GET_SMS_CONFIG = gql`
  query getSmsConfigurations {
    getSmsConfigurations {
      response{
        ${responseField}
      }
      data{
        ${smsConfigField}
      }
    }
  }
`;

export const SAVE_SMS_CONFIG = gql`
  mutation smsConfigManagement($inputDto: SMSConfigInputObjects!) {
    smsConfigManagement(config: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${smsConfigField}
      }
    }
  }
`;
