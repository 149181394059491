<ng-container *ngIf="project$ | async as project">

  <app-page-title [title]="title" isBoldedTitle="true">
    <ng-template>
      <button (click)="action(project)" class="btn-sm mx-1" color="primary" mat-raised-button>
        <mat-icon class="material-symbols-outlined">add</mat-icon>
        {{'Add' | concat | translate}}
      </button>
    </ng-template>
  </app-page-title>

   <div class="col-md-12 my-3">
      <h5>{{'Project Summary' | concat | translate}}</h5>
        <div class="ext-container w-100">
          <mat-card class="inst-details">
            <div class="row">

              <div class="col-md-6 my-2">
                <span class="title">{{'Project Name' | concat | translate}}</span>
                <div>{{ project?.name}}</div>
              </div>

              <div class="col-md-6 my-2">
                <span class="title">{{'Project Location' | concat | translate}}</span>
                <div>{{ project?.location}}</div>
              </div>
            </div>
          </mat-card>
        </div>
    </div>


  <div class="row mt-3">

    <div class="col-12">
      <app-data-table [columnHeader]="tableColumns" [tableData$]="repositories$" [allowTableColumnFromCache]="false">
        <ng-template let-data="data">
          <div class="action-icons">

            <button class="icon-holder" mat-icon-button (click)="viewAttachment(data?.file?.base64String, '---')">
              <mat-icon svgIcon="document"></mat-icon>
            </button>

            <ng-container *ngIf="project?.projectStatusEnum !== projectStatus.COMPLETED">
              <button class="icon-holder" (click)="remove(data)" mat-icon-button matTooltip="{{'Remove' | concat | translate}}">
                <mat-icon svgIcon="icon_deactivate"></mat-icon>
              </button>
            </ng-container>

          </div>
        </ng-template>
      </app-data-table>
    </div>

  </div>

</ng-container>
