import {StorageService} from '../../../../services/storage-service.service';
import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {SettingsService} from '../../../../services/settings.service';
import {Router} from '@angular/router';

import {AuthService} from '../../../../services/auth.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {Observable, Subscription} from 'rxjs';
import {AuthUser} from '../../../../store/entities/auth-user/auth-user.model';
import {NotificationService} from 'src/app/services/notification.service';
import {ChangePasswordFormComponent} from '../../auth/change-password-form/change-password-form.component';
import {UserTypeEnum} from '../../../../store/entities/settings/user/user.model';
import {
  HandOverDutyFormComponent
} from '../../../../modules/settings/uaa/uaa-forms/hand-over-duty/hand-over-duty-form.component';
import {DirectiveListComponent} from '../../views/directive-list/directive-list.component';
import {getUserRoleAndPermissions, returnDuty} from 'src/app/store/entities/settings/user/user.actions';
import {Directive} from '../../../../store/entities/meetings/directive/directive.model';
import {DirectiveFormComponent} from '../../forms/directive-form/directive-form.component';
import {TranslateService} from '@ngx-translate/core';
import {DocumentComment} from '../../../../store/entities/document/document-comment/document-comment.model';
import {selectDocumentComments} from '../../../../store/entities/document/document-comment/document-comment.selectors';
import {
  dismissDocumentComment,
  getAllDocumentComment
} from '../../../../store/entities/document/document-comment/document-comment.actions';
import {TwoFactorAuthFormComponent} from '../../auth/two-factor-auth-form/two-factor-auth-form.component';
import {TwoFaServiceService} from '../../../../services/two-fa-service.service';
import {WebAuthService} from '../../../../services/web-auth.service';
import {Actions, ofType} from '@ngrx/effects';
import {addAuthUser} from '../../../../store/entities/auth-user/auth-user.actions';

@Component({
  selector: 'app-top-right-toolbar',
  templateUrl: './top-right-toolbar.component.html',
  styleUrls: ['./top-right-toolbar.component.scss']
})
export class TopRightToolbarComponent implements OnInit, OnDestroy {

  @Input() notifications: number;
  @Input() isOffline = false;
  @Input() showModuleMenu = true;
  @Input() isLanding = false;
  @Input() loggedUser: AuthUser;
  @Input() loggedInUserType: UserTypeEnum;

  showModulePanel = false;
  subscription: Subscription = new Subscription();
  userTypeEnum = UserTypeEnum;

  paperComments$: Observable<DocumentComment[]>;

  subscription$: Subscription = new Subscription();

  constructor(
    private settingsService: SettingsService,
    private auth: AuthService,
    private router: Router,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private storageService: StorageService,
    public translate: TranslateService,
    private twoFaService: TwoFaServiceService,
    private webAuthService: WebAuthService,
    private actions$: Actions
  ) {
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.showModulePanel = false;
    }
  }

  ngOnInit(): void {
    if (this.loggedUser === null) {
      this.loggedUser = this.storageService.get('account');
    }

    if (this.loggedInUserType === null){
      this.loggedInUserType = this.storageService.get('category');
    }

    this.listenToActions();

    this.store.dispatch(getAllDocumentComment());

    this.paperComments$ = this.store.pipe(select(selectDocumentComments));
  }

  setTwoFactorAuth(isSet: boolean) {
    if (isSet) {
      this.settingsService.openModal( this.loggedUser, TwoFactorAuthFormComponent, '40%');
    } else {
      const message = 'Are you sure you want to remove 2Factor Authentication? ';
      this.notificationService.confirmation(message).then(
        async (data) => {
          if (data) {
            await this.twoFaService.allowDisallow2FA(false);
            location.reload();
          }
        }
      );
    }
  }

  openChangePassword() {
    this.settingsService.openModal( this.loggedUser, ChangePasswordFormComponent);
  }

  logout() {
    this.auth.logout();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  handover() {
    this.settingsService.openModal(null, HandOverDutyFormComponent);
  }

  returnDuty() {
    this.store.dispatch(returnDuty());
  }

  switchLang(language: string) {
    this.settingsService.switchLang(language);
  }

  dismissComment(input: { uniqueId: string, id: number }) {
    this.store.dispatch(dismissDocumentComment(input));
  }

  viewDirectives() {
    this.settingsService.openModal(null, DirectiveListComponent, '85%');
  }

  addDirectives(directive: Directive) {
    this.settingsService.openModal({directive}, DirectiveFormComponent);
  }

  refresh() {
    window.location.reload();
  }

  registerPasskey(isRemove: boolean) {
    if (!isRemove) {
      this.webAuthService.registerUser().then();
    } else {
      const message = 'Are you sure you want to remove? ';
      this.notificationService.confirmation(message).then(
        data => {
          if (data) {
            this.webAuthService.removePasskeys().then();
          }
        }
      );
    }
  }

  private listenToActions() {
    this.subscription$.add(
      this.actions$
        .pipe(ofType(addAuthUser))
        .subscribe((data) => {
          this.loggedUser = this.storageService.get('account');
          console.log('loggedUser', this.loggedUser);
        })
    );
  }
}
