import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {ForthComing} from './forthcoming.model';

export const loadForthComings = createAction(
  '[ForthComing/API] Load ForthComings',
  props<{ forthComings: ForthComing[] }>()
);

export const addForthComing = createAction(
  '[ForthComing/API] Add ForthComing',
  props<{ forthComing: ForthComing }>()
);

export const upsertForthComing = createAction(
  '[ForthComing/API] Upsert ForthComing',
  props<{ forthComing: ForthComing }>()
);

export const addForthComings = createAction(
  '[ForthComing/API] Add ForthComings',
  props<{ forthComings: ForthComing[] }>()
);

export const upsertForthComings = createAction(
  '[ForthComing/API] Upsert ForthComings',
  props<{ forthComings: ForthComing[] }>()
);

export const updateForthComing = createAction(
  '[ForthComing/API] Update ForthComing',
  props<{ forthComing: Update<ForthComing> }>()
);

export const updateForthComings = createAction(
  '[ForthComing/API] Update ForthComings',
  props<{ forthComings: Update<ForthComing>[] }>()
);

export const deleteForthComing = createAction(
  '[ForthComing/API] Delete ForthComing',
  props<{ id: number }>()
);

export const deleteForthComings = createAction(
  '[ForthComing/API] Delete ForthComings',
  props<{ ids: number[] }>()
);


export const clearForthComings = createAction(
  '[ForthComing/API] Clear ForthComings'
);


export const getAllForthComings = createAction(
  '[ForthComing/API] get all active ForthComings',
  props<{ ministryUid: any }>()
);

export const allInstitutionPaperSubmissionPlans = createAction(
  '[ForthComing/API] all Institution Paper Submission Plans',
  props<{ institution: any }>()
);

export const allPaperSubmissionPlans = createAction(
  '[ForthComing/API] All Paper Submission Plans',
  props<{ search: string }>()
);

export const saveForthComing = createAction(
  '[ForthComing/API] Save ForthComing',
  props<{ paperSubmissionPlanDto: any }>()
);

export const removePaperPlan = createAction(
  '[ForthComing/API] remove Paper Plan',
  props<{ uniqueId: string }>()
);
