import gql from 'graphql-tag';
import {responseField} from '../../base.graphql';
import {scheduledMeetingField} from '../../meetings/scheduled-meeting/scheduled-meeting.graphql';
import {userField} from "../../settings/user/user.graphql";

export const taskField = `
  id
  uniqueId
  description
  startDate
  endDate
  attachmentList {
    fullFileName
    fileName
    base64String
  }
  meeting {
    ${scheduledMeetingField}
  }
  taskAssignment {
    taskAssignedBy {
      ${userField}
    }
    assignedUser {
      id
      uniqueId
      assignedUser {
        ${userField}
      }
      isIncharge
      assignmentStatus
      isFirstAssignment
      isCurrent
    }
  }
  recordedOn
  hasResponse
  status
`;

export const GET_TASK = gql`
  query getMeetingTaskAssignments($uid: String) {
    getMeetingTaskAssignments(meeting: $uid) {
      response{
        ${responseField}
      }
      dataList{
        ${taskField}
      }
    }
  }
`;

export const GET_TASK_ASSIGNMENT_BY_UNIQUE_ID = gql`
query GetMeetingTaskAssignment($uniqueId: String) {
  getMeetingTaskAssignment(uniqueId: $uniqueId) {
      response{
        ${responseField}
      }
      data {
        ${taskField}
      }
    }
  }
`;

export const GET_ASSIGNED_TASK = gql`
  query getMyMeetingTaskAssignments {
    getMyMeetingTaskAssignments {
      response{
        ${responseField}
      }
      dataList{
        ${taskField}
      }
    }
  }
`;

export const SAVE_TASK = gql`
mutation CreateUpdateMeetingTaskAssignment($input: MeetingTaskAssignmentInputObjects!) {
  createUpdateMeetingTaskAssignment(input: $input) {
      response{
        ${responseField}
      }
      data{
        ${taskField}
      }
    }
  }
`;


export const RE_ASSIGN_TASK = gql`
mutation ReassignTask($input: MeetingTaskAssignmentInputObjects) {
  reassignTask(input: $input) {
    response{
        ${responseField}
    }
    data{
        ${taskField}
    }
  }
}
`


export const DELETE_TASK = gql`
  mutation deleteMeetingTaskAssignment($uid: String!) {
    deleteMeetingTaskAssignment(uniqueId: $uid){
      response{
        ${responseField}
      }
    }
  }
`;
