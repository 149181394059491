import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {DocumentCategory, DocumentCategoryDto} from './document-category.model';

export const loadDocumentCategories = createAction(
  '[DocumentCategory/API] Load DocumentCategories',
  props<{ DocumentCategories: DocumentCategory[] }>()
);

export const addDocumentCategory = createAction(
  '[DocumentCategory/API] Add DocumentCategory',
  props<{ documentCategory: DocumentCategory }>()
);

export const upsertDocumentCategory = createAction(
  '[DocumentCategory/API] Upsert DocumentCategory',
  props<{ documentCategory: DocumentCategory }>()
);

export const addDocumentCategories = createAction(
  '[DocumentCategory/API] Add DocumentCategories',
  props<{ DocumentCategories: DocumentCategory[] }>()
);

export const upsertDocumentCategories = createAction(
  '[DocumentCategory/API] Upsert DocumentCategories',
  props<{ DocumentCategories: DocumentCategory[] }>()
);

export const updateDocumentCategory = createAction(
  '[DocumentCategory/API] Update DocumentCategory',
  props<{ documentCategory: Update<DocumentCategory> }>()
);

export const updateDocumentCategories = createAction(
  '[DocumentCategory/API] Update DocumentCategories',
  props<{ DocumentCategories: Update<DocumentCategory>[] }>()
);

export const deleteDocumentCategory = createAction(
  '[DocumentCategory/API] Delete DocumentCategory',
  props<{ id: number }>()
);

export const deleteDocumentCategories = createAction(
  '[DocumentCategory/API] Delete DocumentCategories',
  props<{ ids: string[] }>()
);

export const clearDocumentCategories = createAction(
  '[DocumentCategory/API] Clear DocumentCategories'
);

export const getAllDocumentCategories = createAction(
  '[DocumentCategories/API] List All DocumentCategories'
);

export const actionDocumentCategory = createAction(
  '[DocumentCategory/API] create, edit & delete documentCategory',
  props<{ documentCategoryDto: DocumentCategoryDto, id?: number }>()
);
