import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ChartComponent} from 'ng-apexcharts';
import {ChartOptions} from '../../../interfaces/data.interfaces';
import {Observable, Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';

import {SettingsService} from '../../../../services/settings.service';

@Component({
  selector: 'app-paper-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.scss']
})
export class DocumentDetailComponent implements OnInit, OnDestroy {
  title: string;
  subTitle: string;
  documentTableColumn: any;
  documents$: Observable<any>;

  @ViewChild('evaluationChart') evaluationChart: ChartComponent;
  @ViewChild('rateRank') rateChart: ChartComponent;
  topProjectEvaluationChartOptions: Partial<ChartOptions>;
  projectRateChartOption: Partial<ChartOptions>;
  subscriptions: Subscription = new Subscription();

  startDate = new DatePipe('en-Gb').transform(
    new Date().setUTCFullYear(1900, 0o1, 0o1), 'yyyy-MM-dd');

  endDate = new DatePipe('en-Gb').transform(new Date(), 'yyyy-MM-dd');
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });

  constructor(
    private store: Store<AppState>,
    private settingsService: SettingsService,
  ) {
    this.getTopProjectEvaluationData(this.startDate, this.endDate);
    this.getProjectRateData(this.startDate, this.endDate);
  }

  ngOnInit(): void {
    this.title = 'Paper Detail';

    this.subTitle = this.settingsService.dateRangeFormat(this.startDate, this.endDate, 'mediumDate');

    this.subscriptions.add(
      this.range.valueChanges.subscribe( dates => {
        if (dates?.start && dates?.end){
          this.startDate = new DatePipe('en-Gb').transform(dates?.start, 'yyyy-MM-dd');
          this.endDate = new DatePipe('en-Gb').transform(dates?.end, 'yyyy-MM-dd');

          this.subTitle = this.settingsService.dateRangeFormat(this.startDate, this.endDate, 'mediumDate');

          this.getTopProjectEvaluationData(this.startDate, this.endDate);
          this.getProjectRateData(this.startDate, this.endDate);
        }
      })
    );
  }

  getTopProjectEvaluationData(start: any, end: any){
    // this.subscriptions.add(
    //   this.queryService.getCount(start, end, COUNT_MEMBER_PER_UNIT, 'registration').subscribe( (data: Statistics[]) => {

    this.topProjectEvaluationChartOptions = {
      title: {
        text: 'Project Budget Evaluation',
        style: {
          fontSize:  '15px',
          fontWeight:  'bold',
          fontFamily:  undefined,
        }
      },
      series: [
        {
          name: 'Government',
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        },
        {
          name: 'Foreign',
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        },
      ],
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '35%'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [
          'Jan',
          'feb',
          'March',
          'April',
          'May',
          'Jun',
          'July',
          'Aug',
          'Sept'
        ]
      },
      yaxis: {
        title: {
          text: '$ (thousands)'
        }
      },
      fill: {
        opacity: 1,
        colors: ['#ffb822', '#0277bd'],
      },
      tooltip: {
        y: {
          formatter(val) {
            return '$ ' + val + ' thousands';
          }
        }
      }
    };

      // })
    // );
  }

  getProjectRateData(start: any, end: any){
    // this.subscriptions.add(
    //   this.queryService.getCount(start, end, COUNT_MEMBER_PER_RANK, 'registration').subscribe( (data: Statistics[]) => {

        this.projectRateChartOption = {

          title: {
            text: 'Project Completion Rate',
            style: {
              fontSize:  '15px',
              fontWeight:  'bold',
              fontFamily:  undefined,
            }
          },
          series: [
            {
              name: 'Expectation',
              data: [10, 20]
            },
            {
              name: 'Actual',
              data: [20, 40]
            }
          ],
          chart: {
            height: 430,
            type: 'area',
            stacked: true
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            categories: ['one', 'two']
          },
          fill: {
            opacity: 1,
            colors: ['#ffb822', '#0277bd'],
          },
        };
    //   })
    // );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  download(param) {
  }
}
