import {createSelector} from '@ngrx/store';
import * as fromInstitutionReducer from './institution.reducer';
import {institutionsFeatureKey} from './institution.reducer';
import {AppState} from '../../index';

export const currentInstitutionState = (state: AppState) => state[institutionsFeatureKey];

export const selectAllInstitutions = createSelector(currentInstitutionState, fromInstitutionReducer.selectAll);
export const selectTotalInstitutions = createSelector(currentInstitutionState, fromInstitutionReducer.selectTotal);

export const selectInstitutionById = (id: number) => createSelector(
  selectAllInstitutions,
  (institutions) => institutions.find((institution) => institution.id === id)
);
