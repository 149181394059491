import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {PageableParam} from '../../page/page.model';
import {MeetingAgenda} from './meeting-agenda.model';

export const loadMeetingAgendas = createAction(
  '[MeetingAgenda/API] Load MeetingAgendas',
  props<{ meetingAgendas: MeetingAgenda[] }>()
);

export const addMeetingAgenda = createAction(
  '[MeetingAgenda/API] Add MeetingAgenda',
  props<{ meetingAgenda: MeetingAgenda }>()
);

export const upsertMeetingAgenda = createAction(
  '[MeetingAgenda/API] Upsert MeetingAgenda',
  props<{ meetingAgenda: MeetingAgenda }>()
);

export const addMeetingAgendas = createAction(
  '[MeetingAgenda/API] Add MeetingAgendas',
  props<{ meetingAgendas: MeetingAgenda[] }>()
);

export const upsertMeetingAgendas = createAction(
  '[MeetingAgenda/API] Upsert MeetingAgendas',
  props<{ meetingAgendas: MeetingAgenda[] }>()
);

export const updateMeetingAgenda = createAction(
  '[MeetingAgenda/API] Update MeetingAgenda',
  props<{ meetingAgenda: Update<MeetingAgenda> }>()
);

export const updateMeetingAgendas = createAction(
  '[MeetingAgenda/API] Update MeetingAgendas',
  props<{ meetingAgendas: Update<MeetingAgenda>[] }>()
);

export const deleteMeetingAgenda = createAction(
  '[MeetingAgenda/API] Delete MeetingAgenda',
  props<{ id: number }>()
);

export const deleteMeetingAgendas = createAction(
  '[MeetingAgenda/API] Delete MeetingAgendas',
  props<{ ids: number[] }>()
);

export const clearMeetingAgendas = createAction(
  '[MeetingAgenda/API] Clear MeetingAgendas'
);


// export const getAllMeetingAgendas = createAction(
//   '[MeetingAgenda/API] get all MeetingAgendas',
//   props<{ status?: MeetingAgendaStatusEnum}>()
// );

// export const getAllMeetingAgendaByStage = createAction(
//   '[MeetingAgenda/API] get all active MeetingAgendas by stage',
//   props<{ stage: MeetingAgendaStageEnum}>()
// );

export const getAllMeetingAgendaPageable = createAction(
  '[MeetingAgenda/API] get all active MeetingAgendas pageable',
  props<{ pageable: PageableParam, stageName: string, meetingStatus?: string}>()
);


export const getAllMeetingAgendas = createAction(
  '[MeetingAgenda/API] Get All Meetings',
  props<{ filtering: any }>()
);

export const exportMeetingMinutes = createAction(
  '[MeetingAgenda/API] Export Meeting Minutes',
  props<{ meetingUniqueId: any }>()
);

export const exportMeetingAgenda = createAction(
  '[MeetingAgenda/API] Export Meeting Agenda',
  props<{ meetingUniqueId: any }>()
);

export const exportMeetingActionExtract = createAction(
  '[MeetingAgenda/API] Export Meeting Action Extract',
  props<{ meetingUniqueId: any, agendaUniqueId: string[], institutionUniqueId: string }>()
);

export const getMeetingsAgendas = createAction(
  '[MeetingAgenda/API] Get Meetings Agendas',
  props<{ meetingUniqueId: string, isOffline?: boolean }>()
);

export const getMinutesByPaper = createAction(
  '[MeetingAgenda/API] get Minutes By Paper',
  props<{ paperUniqueId: string }>()
);

export const saveMeetingAgenda = createAction(
  '[MeetingAgenda/API] Save MeetingAgenda',
  props<{ agenda: any }>()
);

export const editMeetingAgenda = createAction(
  '[MeetingAgenda/API] edit MeetingAgenda',
  props<{ agenda: any }>()
);

export const removeMeetingAgenda = createAction(
  '[MeetingAgenda/API] remove Scheduled Meeting',
  props<{ uniqueId: string, id: number }>()
);

export const removeMeetingAgendaAttachment = createAction(
  '[MeetingAgenda/API] remove meeting agenda attachment',
  props<{ uniqueId: string }>()
);

export const saveAgendaSignedMinutes = createAction(
  '[MeetingAgenda/API] Save AgendaSignedMinutes',
  props<{ input: {
      attachmentName: string;
      attachmentPath: string;
      meetingUniqueId: string;
      signedMinutesAgenda: string;
    } }>()
);

export const setAgendaTime = createAction(
  '[MeetingAgenda/API] Set Agenda Time',
  props<{ input: {
      uniqueId: string;
      agendaStartTime: string;
    } }>()
);

export const addOfflineAgendaPaper = createAction(
  '[MeetingAgenda/API] Add offline agenda paper'
);

