import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  deleteProjectRepository,
  getProjectRepositoryByProjectUid,
  loadProjectRepositories,
  removeProjectRepository,
  saveProjectRepository,
  upsertProjectRepository
} from './project-repository.actions';
import {map, switchMap} from 'rxjs/operators';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {Apollo} from 'apollo-angular';
import {
  DELETE_PROJECT_REPOSITORY,
  GET_PROJECT_REPOSITORY_PROJECT_UID,
  SAVE_PROJECT_REPOSITORY
} from './project-repository.graphql';
import {SettingsService} from '../../../../services/settings.service';

@Injectable()
export class ProjectRepositoryEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getProjectRepositoryByProjectUid),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_PROJECT_REPOSITORY_PROJECT_UID,
        variables: { uid: action.projectUid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadProjectRepositories({projectRepositories: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveProjectRepository),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_PROJECT_REPOSITORY,
        variables: { inputDto: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertProjectRepository({projectRepository: result?.data}));
            this.settingsService.reloadCurrentRoute(null);

          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeProjectRepository),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_PROJECT_REPOSITORY,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteProjectRepository({id: action.id}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService
  ) {
  }
}
