import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {DocumentStage} from './document-stage.model';
import * as DocumentStageActions from './document-stage.actions';

export const documentStagesFeatureKey = 'documentStages';

export interface State extends EntityState<DocumentStage> {
  // additional entities state properties
}
//
export function sortByDocumentStageName(a: DocumentStage, b: DocumentStage): any {
  // return a.stage_name.localeCompare(b.stage_name);
}

export const adapter: EntityAdapter<DocumentStage> = createEntityAdapter<DocumentStage>({
  // sortComparer: sortByDocumentStageName
});

// export const adapter: EntityAdapter<DocumentStage> = createEntityAdapter<DocumentStage>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const documentStageReducer = createReducer(
  initialState,
  on(DocumentStageActions.addDocumentStage,
    (state, action) => adapter.addOne(action.documentStage, state)
  ),
  on(DocumentStageActions.upsertDocumentStage,
    (state, action) => adapter.upsertOne(action.documentStage, state)
  ),
  on(DocumentStageActions.addDocumentStages,
    (state, action) => adapter.addMany(action.documentStages, state)
  ),
  on(DocumentStageActions.upsertDocumentStages,
    (state, action) => adapter.upsertMany(action.documentStages, state)
  ),
  on(DocumentStageActions.updateDocumentStage,
    (state, action) => adapter.updateOne(action.documentStage, state)
  ),
  on(DocumentStageActions.updateDocumentStages,
    (state, action) => adapter.updateMany(action.documentStages, state)
  ),
  on(DocumentStageActions.deleteDocumentStage,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(DocumentStageActions.deleteDocumentStages,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(DocumentStageActions.loadDocumentStages,
    (state, action) => adapter.setAll(action.documentStages, state)
  ),
  on(DocumentStageActions.clearDocumentStages,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return documentStageReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllDocumentStages = selectAll;
export const getDocumentStageById = selectIds;
export const getDocumentStageEntities = selectEntities;
