import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Project} from '../../../../../store/entities/projects/project/project.model';
import {AppState} from '../../../../../store/entities';
import {NotificationService} from '../../../../../services/notification.service';
import {
  saveProjectRepository
} from '../../../../../store/entities/projects/project-repository/project-repository.actions';
import {
  selectAttachmentConfigByStage
} from "../../../../../store/entities/settings/attachment-configuration/attachment-configuration.selectors";
import {Observable} from "rxjs";
import {
  AttachmentConfig
} from "../../../../../store/entities/settings/attachment-configuration/attachment-configuration.model";

@Component({
  selector: 'app-project-repository-form',
  templateUrl: './project-repository-form.component.html',
  styleUrls: ['./project-repository-form.component.scss']
})
export class ProjectRepositoryFormComponent implements OnInit {

  title: string;
  project: Project;
  form: UntypedFormGroup;

  attachmentConfigs$: Observable<AttachmentConfig[]>;


  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.project = data?.project;
  }

  ngOnInit(): void {
    this.title = 'Project Repository' ;

    this.form = this.fb.group({
      project: [this.project?.uniqueId, Validators.required],
      description: [null],
      attachments: [null, Validators.required],
    });

    this.attachmentConfigs$ = this.store.select(selectAttachmentConfigByStage('PROJECTREPOSITORY'));

  }

  getUploadedFiles(attachments: any) {
    this.form.patchValue({ attachments });
  }

  add(formData) {
    if (formData.attachments.length === 0){
      this.notificationService.errorMessage('No Attachment');
      return;
    }
    this.store.dispatch(saveProjectRepository({ formData}));
  }

}
