import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const projectProgressField = `
  ${baseField}
  status
  description
  recordedOn
  psCoverLetter
`;

export const SAVE_PROJECT_PROGRESS = gql`
  mutation createProjectTaskProgressMutation($inputDto: ProjectTaskProgressInputObjects!) {
    createProjectTaskProgressMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectProgressField}
      }
    }
  }
`;

export const UPDATE_PROJECT_PROGRESS = gql`
  mutation updateProjectTaskProgressMutation($inputDto: ProjectTaskProgressInputObjects!) {
    updateProjectTaskProgressMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectProgressField}
      }
    }
  }
`;

export const DELETE_PROJECT_PROGRESS = gql`
  mutation deleteProjectTaskProgressMutation($uid: String!) {
    deleteProjectTaskProgressMutation(uniqueId: $uid){
      response{
        ${responseField}
      }
    }
  }
`;

