import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const projectChallengeField = `
  ${baseField}
  challenge
  wayForward
  recordedOn
`;


export const GET_PROJECT_CHALLENGE_PROJECT_UID = gql`
  query allProjectChallenges($uid: String!) {
    allProjectChallenges(project: $uid){
      response{
        ${responseField}
      }
      data{
        ${projectChallengeField}
      }
    }
  }
`;

export const SAVE_PROJECT_CHALLENGE = gql`
  mutation createProjectChallengeMutation($inputDto: ProjectChallengeInputObjects!) {
    createProjectChallengeMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectChallengeField}
      }
    }
  }
`;

export const UPDATE_PROJECT_CHALLENGE = gql`
  mutation updateProjectChallengeMutation($inputDto: ProjectChallengeInputObjects!) {
    updateProjectChallengeMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectChallengeField}
      }
    }
  }
`;

export const DELETE_PROJECT_CHALLENGE = gql`
  mutation deleteProjectChallengeMutation($uid: String!) {
    deleteProjectChallengeMutation(uniqueId: $uid){
      response{
        ${responseField}
      }
    }
  }
`;

