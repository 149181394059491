import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {DialogService} from './dialog.service';
import {DialogData} from '../shared/interfaces/data.interfaces';
import {FormValueTypeEnum} from '../shared/enums/data.enums';

@Injectable({
  providedIn: 'root'
})
export class NotificationService{

  router = inject(Router);
  snackbar = inject(MatSnackBar);
  dialogService = inject(DialogService);
  dialog = inject(MatDialog);

  successMessage(message: string): void {
    this.successSnackBar(message);
  }

  warningMessage(message: string): void {
    this.warningSnackBar(message);
  }

  errorMessage(message: string): void {
    this.errorSnackBar(message);
  }

  message(message: string): void {
    this.normalSnackBar(message);
  }

  successMessageAndCloseModals(message: string): void {
    this.successSnackBar(message);
    this.dialog.closeAll();
  }

  errorMessageAndCloseModals(message: string): void {
    this.errorSnackBar(message);
    this.dialog.closeAll();
  }

  async successMessageWithRedirect(message: string, route: string): Promise<any> {
    this.successSnackBar(message);
    this.dialog.closeAll();
    return this.router.navigate([route]);
  }

  async errorMessageWithRedirect(message: string, route: string): Promise<any> {
    this.errorSnackBar(message);
    return this.router.navigate([route]);
  }

  copySnackBar(message, closeText = 'Dismiss', duration = 20000, verticalPosition: any = 'bottom', horizontalPosition: any = 'right'): void {
    this.snackbar.open(message, closeText, {
      duration,
      verticalPosition,
      horizontalPosition
    });
  }

  /**
   * @deprecated The method should not be used, use confirmation from DialogService instead, will be removed on next version
   */
  confirmation(message: string, confirmButtonText?: string) {
    const input: DialogData = {
      message,
      buttons: (confirmButtonText !== null) ? [ { title: 'cancel', value: false}, { title: confirmButtonText !== null ? confirmButtonText : 'Yes', value: true}] : []
    };

    return this.dialogService.confirmation(input);
  }

  /**
   * @deprecated The method should not be used, use confirmation from DialogService instead, will be removed on next version
   */
  confirmationWithInput(message: string,  confirmButtonText?: string, placeholder?: string) {
    const input: DialogData = {
      message,
      placeholder,
      isForm: true,
      formFieldType: FormValueTypeEnum.TEXT,
      buttons: (confirmButtonText !== null) ? [ { title: 'cancel', value: false}, { title: confirmButtonText, value: true}] : []
    };

    return this.dialogService.confirmation(input);
  }

  /**
   * @deprecated The method should not be used, use confirmation from DialogService instead, will be removed on next version
   */
  confirmationWithNumberInput(message: string,  confirmButtonText?: string, placeholder?: string) {
    const input: DialogData = {
      message,
      placeholder,
      isForm: true,
      formFieldType: FormValueTypeEnum.NUMBER,
      buttons: (confirmButtonText !== null) ? [ { title: 'cancel', value: false}, { title: confirmButtonText, value: true}] : []
    };

    return this.dialogService.confirmation(input);
  }


  private normalSnackBar(message, closeText = 'Dismiss', duration = 5000, verticalPosition: any = 'bottom', horizontalPosition: any = 'right'): void {
    this.snackbar.open(message, closeText, {
      duration,
      verticalPosition,
      horizontalPosition,
      panelClass: ['normal-snackbar']
    });
  }

  private warningSnackBar(message, closeText = 'Dismiss', duration = 5000, verticalPosition: any = 'bottom', horizontalPosition: any = 'right'): void {
    this.snackbar.open(message, closeText, {
      duration,
      verticalPosition,
      horizontalPosition,
      panelClass: ['warning-snackbar']
    });
  }

  private successSnackBar(message, closeText = 'Dismiss', duration = 1000, verticalPosition: any = 'bottom', horizontalPosition: any = 'right'): void {
    this.snackbar.open(message, closeText, {
      duration,
      verticalPosition,
      horizontalPosition,
      panelClass: ['success-snackbar']
    });
  }

  private errorSnackBar(message, closeText = 'Dismiss', duration = 5000, verticalPosition: any = 'bottom', horizontalPosition: any = 'right'): void {
    this.snackbar.open(message, closeText, {
      duration,
      verticalPosition,
      horizontalPosition,
      panelClass: ['danger-snackbar']
    });
  }

}
