
<ng-container *ngIf="component$ | async as component">

  <app-page-title [title]="title" isBoldedTitle="false">
    <ng-template>
      <button (click)="action(null, component)" class="btn-sm mx-1" color="primary" mat-raised-button>
        <mat-icon class="material-symbols-outlined">add</mat-icon>
        {{'Add Task' | concat | translate}}
      </button>
    </ng-template>
  </app-page-title>

  <ng-container *ngIf="project$ | async as project">

    <div class="col-md-12 my-3">
      <h5>{{'Summary' | concat | translate}}</h5>
      <div class="ext-container w-100">
        <mat-card class="inst-details">
          <div class="row">

            <div class="col-md-4 my-2">
              <span class="title">{{'Project Name' | concat | translate}}</span>
              <div>{{ project?.name}}</div>
            </div>

            <div class="col-md-4 my-2">
              <span class="title">{{'Project Location' | concat | translate}}</span>
              <div>{{ project?.location}}</div>
            </div>

            <div class="col-md-4 my-2">
              <span class="title">{{'Project Category' | concat | translate}}</span>
              <div>{{ project?.category?.name}}</div>
            </div>

          </div>

          <div class="row">

            <div class="col-md-4 my-2">
              <span class="title">{{'Component Name' | concat | translate}}</span>
              <div>{{ component?.name}}</div>
            </div>

            <div class="col-md-4 my-2">
              <span class="title">{{'Component Budget' | concat | translate}}</span>
              <div>{{ component?.budget | number}}</div>
            </div>

            <div class="col-md-4 my-2">
              <span class="title">{{'Component Detail' | concat | translate}}</span>
              <div>{{ component?.details}}</div>
            </div>

          </div>
        </mat-card>
      </div>
    </div>

  </ng-container>

  <div class="row">
    <div class="col-md-12">
      <app-data-table [columnHeader]="tableColumn" [tableData$]="tasks$">
        <ng-template let-data="data">
          <div class="action-icons">

            <button [matMenuTriggerFor]="menu" class="icon-holder" mat-icon-button>
              <mat-icon svgIcon="option"></mat-icon>
            </button>

            <mat-menu #menu="matMenu">

              <button (click)="taskProgressAction(data)" *ngIf="data?.status !== status.COMPLETED" mat-menu-item>
                <mat-icon class="material-symbols-outlined">add</mat-icon>
                {{'Add Task Progress' | concat | translate}}
              </button>

              <button (click)="viewProgress(data)" mat-menu-item>
                <mat-icon svgIcon="view"></mat-icon>
                {{'View Progress' | concat | translate}}
              </button>

              <button (click)="action(data, component)" *ngIf="data?.status === status.NOTSTARTED || data?.status === status.ONHOLD"
                      mat-menu-item> <mat-icon svgIcon="edit"></mat-icon>
                {{'Edit' | concat | translate}}
              </button>

              <button (click)="remove(data)" *ngIf="data?.status === status.NOTSTARTED || data?.status === status.ONHOLD"
                      mat-menu-item>  <mat-icon svgIcon="icon_delete"></mat-icon>
                {{'Remove' | concat | translate}}
              </button>
            </mat-menu>

          </div>
        </ng-template>
      </app-data-table>
    </div>
  </div>
</ng-container>

