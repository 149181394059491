import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {ProjectRepository, ProjectRepositoryDto} from './project-repository.model';

export const loadProjectRepositories = createAction(
  '[ProjectRepository/API] Load ProjectRepositories',
  props<{ projectRepositories: ProjectRepository[] }>()
);

export const addProjectRepository = createAction(
  '[ProjectRepository/API] Add ProjectRepository',
  props<{ projectRepository: ProjectRepository }>()
);

export const upsertProjectRepository = createAction(
  '[ProjectRepository/API] Upsert ProjectRepository',
  props<{ projectRepository: ProjectRepository }>()
);

export const addProjectRepositories = createAction(
  '[ProjectRepository/API] Add ProjectRepositories',
  props<{ projectRepositories: ProjectRepository[] }>()
);

export const upsertProjectRepositories = createAction(
  '[ProjectRepository/API] Upsert ProjectRepositories',
  props<{ projectRepositories: ProjectRepository[] }>()
);

export const updateProjectRepository = createAction(
  '[ProjectRepository/API] Update ProjectRepository',
  props<{ projectRepository: Update<ProjectRepository> }>()
);

export const updateProjectRepositories = createAction(
  '[ProjectRepository/API] Update ProjectRepositories',
  props<{ projectRepositories: Update<ProjectRepository>[] }>()
);

export const deleteProjectRepository = createAction(
  '[ProjectRepository/API] Delete ProjectRepository',
  props<{ id: number }>()
);

export const deleteProjectRepositories = createAction(
  '[ProjectRepository/API] Delete ProjectRepositories',
  props<{ ids: string[] }>()
);

export const clearProjectRepositories = createAction(
  '[ProjectRepository/API] Clear ProjectRepositories'
);


export const getProjectRepositoryByProjectUid = createAction(
  '[ProjectRepository/API] getProjectRepositoryByProjectUid',
  props<{ projectUid: string }>()
);

export const saveProjectRepository = createAction(
  '[ProjectRepository/API] saveProjectRepository',
  props<{ formData: ProjectRepositoryDto }>()
);

export const removeProjectRepository = createAction(
  '[ProjectRepository/API] removeProjectRepository',
  props<{ uid: string, id: number }>()
);
