import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {SettingsService} from '../../../../services/settings.service';
import {Apollo} from 'apollo-angular';
import {
  deleteMinutesNotes,
  editMinutesNotes,
  getAllMinutesNotes,
  loadMinutesNotess,
  removeMinutesNotes,
  saveMinutesNotes,
  upsertMinutesNotes
} from './minutes-notes.actions';
import {DELETE_MINUTES_NOTES, GET_ALL_NOTES, SAVE_MINUTES_NOTES, UPDATE_MINUTES_NOTES} from './minutes-notes.graphql';

@Injectable()
export class MinutesNotesEffects {


  get = createEffect(() => this.actions$.pipe(
    ofType(getAllMinutesNotes),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_ALL_NOTES,
        variables: { agendaUniqueId: action.agendaUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadMinutesNotess({ minutesNotess: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });


  save = createEffect(() => this.actions$.pipe(
    ofType(saveMinutesNotes),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_MINUTES_NOTES,
        variables: { notes: action.notes }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertMinutesNotes({ minutesNotes: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editMinutesNotes),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_MINUTES_NOTES,
        variables: { notes: action.notes }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertMinutesNotes({ minutesNotes: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  delete = createEffect(() => this.actions$.pipe(
    ofType(removeMinutesNotes),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_MINUTES_NOTES,
        variables: { notesUniqueId: action.notesUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteMinutesNotes({ id: action.id }))
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
  ) { }

}
