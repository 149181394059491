import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {SettingsService} from '../../../../services/settings.service';
import {ActivatedRoute} from '@angular/router';
import {getProjectByUid} from '../../../../store/entities/projects/project/project.actions';
import {Project} from '../../../../store/entities/projects/project/project.model';
import {selectProjectByUid} from '../../../../store/entities/projects/project/project.selectors';
import {ProjectStatusEnum} from '../../../enums/data.enums';
import {NotificationService} from '../../../../services/notification.service';
import {ProjectChallenge} from '../../../../store/entities/projects/project-challenge/project-challenge.model';
import {
  selectAllProjectChallenges
} from '../../../../store/entities/projects/project-challenge/project-challenge.selectors';
import {
  getProjectChallengeByProjectUid,
  removeProjectChallenge
} from '../../../../store/entities/projects/project-challenge/project-challenge.actions';
import {
  ProjectChallengeFormComponent
} from '../../../../modules/portal/portal-project/portal-project-forms/project-challenge-form/project-challenge-form.component';

@Component({
  selector: 'app-project-challenge-detail',
  templateUrl: './project-challenge-detail.component.html',
  styleUrls: ['./project-challenge-detail.component.scss']
})
export class ProjectChallengeDetailComponent implements OnInit {

  title: string;
  projectUid: string;
  project$: Observable<Project>;
  challenges$: Observable<ProjectChallenge[]>;
  tableColumn: any;
  projectStatus = ProjectStatusEnum;

  constructor(
    private store: Store<AppState>,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
  ) {
    this.projectUid = this.activatedRoute.snapshot.paramMap.get('uid');
    this.store.dispatch(getProjectByUid({uid: this.projectUid, withComponents: false}));
    this.store.dispatch(getProjectChallengeByProjectUid({projectUid: this.projectUid}));
  }

  ngOnInit(): void {
    this.title = 'project-challenge-and-way-forward';

    this.tableColumn = {
      no: 'No',
      challenge: 'Challenge',
      wayForward: 'Way Forward',
      formattedRecordedOn: 'Recorded On',
      action: 'Action'
    };

    this.project$ = this.store.select(selectProjectByUid(this.projectUid));
    this.challenges$ = this.store.select(selectAllProjectChallenges);
  }

  remove(res) {
    const message = 'Are you sure you want to remove ? ';
    this.notificationService.confirmation(message).then(
      data => {
        if (data) {
          this.store.dispatch(removeProjectChallenge({ uid: res?.uniqueId, id: res?.id }));
        }
      }
    );
  }

  action(project: Project, challenge: ProjectChallenge) {
    this.settingsService.openModal({project, challenge}, ProjectChallengeFormComponent);
  }

}
