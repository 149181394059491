import {Component, ContentChild, HostListener, Input, OnInit, TemplateRef} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {

  @Input() title: string;
  @Input() subTitle: string;
  @Input() useBackButton = true;
  @Input() useRouterButton = false;
  @Input() isBoldedTitle = false;
  @Input() routerUrl: string;
  @ContentChild(TemplateRef, {static : true}) topMenu: TemplateRef<any>;

  isOnline = true;

  @HostListener('window:online', ['$event'])
  onlineNetworkStatus(event: KeyboardEvent) {
    this.isOnline = true;
  }
  @HostListener('window:offline', ['$event'])
  offlineNetworkStatus(event: KeyboardEvent) {
    this.isOnline = false;
  }

  constructor(
    private location: Location
  ) {
  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

}
