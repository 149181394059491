<app-modal-header  [title]="title"></app-modal-header>

<form (ngSubmit)="add(form.value)" [formGroup]="form"  autocomplete="off" novalidate>
  <div class="row">

    <div class="col-md-12">
      <h5 class="mb-0">{{'Description' | concat | translate}}</h5>
      <quill-editor [styles]="editorStyle" class="col-12" formControlName="description">
      </quill-editor>
    </div>

    <div class="col-12 my-3">
      <ngx-dropzone (change)="onImageSelect($event)" [expandable]="true"
                    accept="image/jpeg,image/jpg,image/png,image/gif">
        <ngx-dropzone-label>{{'Upload Images JPG or PNG are allowed' | concat | translate}}</ngx-dropzone-label>
        <ngx-dropzone-image-preview (removed)="onImageRemove(f)"
                                    *ngFor="let f of images"
                                    [file]="f" [removable]="true"
                                    ngProjectAs="ngx-dropzone-preview">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-image-preview>

      </ngx-dropzone>
    </div>

    <div class="col-md-12 text-end">
        <button [disabled]="!form.valid" color="primary" mat-raised-button type="submit"> {{'Save' | concat | translate}} </button>
    </div>
  </div>
</form>
