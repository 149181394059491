import {createSelector} from '@ngrx/store';
import * as fromPageReducer from './page.reducer';
import {AppState} from '../index';

export const currentState = (state: AppState) =>
  state[fromPageReducer.pagesFeatureKey];

export const selectPageFromReducer = createSelector(
  currentState,
  fromPageReducer.selectAll
);

export const selectPage = createSelector(
  selectPageFromReducer,
  (pages) => pages[0]
);
