import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Device} from './device.model';
import * as DeviceActions from './device.actions';

export const devicesFeatureKey = 'devices';

export interface State extends EntityState<Device> {
  // additional entities state properties
}

export function sortByDeviceName(a: Device, b: Device): any {
  return a?.name.localeCompare(b?.name);
}

export const adapter: EntityAdapter<Device> = createEntityAdapter<Device>({
  // sortComparer: sortByDeviceName
});

// export const adapter: EntityAdapter<Device> = createEntityAdapter<Device>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const deviceReducer = createReducer(
  initialState,
  on(DeviceActions.addDevice,
    (state, action) => adapter.addOne(action.device, state)
  ),
  on(DeviceActions.upsertDevice,
    (state, action) => adapter.upsertOne(action.device, state)
  ),
  on(DeviceActions.addDevices,
    (state, action) => adapter.addMany(action.devices, state)
  ),
  on(DeviceActions.upsertDevices,
    (state, action) => adapter.upsertMany(action.devices, state)
  ),
  on(DeviceActions.updateDevice,
    (state, action) => adapter.updateOne(action.device, state)
  ),
  on(DeviceActions.updateDevices,
    (state, action) => adapter.updateMany(action.devices, state)
  ),
  on(DeviceActions.deleteDevice,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(DeviceActions.deleteDevices,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(DeviceActions.loadDevices,
    (state, action) => adapter.setAll(action.devices, state)
  ),
  on(DeviceActions.clearDevices,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return deviceReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllDevices = selectAll;
export const getDeviceById = selectIds;
export const getDeviceEntities = selectEntities;
