import {inject, Injectable} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {QueryService} from './query.service';
import {NotificationService} from './notification.service';
import {MatDialog} from '@angular/material/dialog';
import gql from 'graphql-tag';
import {responseField} from '../store/entities/base.graphql';
import {AuthTokenModel} from '../shared/interfaces/data.interfaces';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {StorageService} from './storage-service.service';

@Injectable({
  providedIn: 'root'
})
export class TwoFaServiceService {

  dialog = inject(MatDialog);
  queryService = inject(QueryService);
  notificationService = inject(NotificationService);
  http = inject(HttpClient);
  storageService = inject(StorageService);


  confirmSetting2FA(token: string) {
    lastValueFrom(
      this.queryService.mutate(CONFIRM_TWO_FACTOR_AUTHENTICATION, { token })
    ).then((data) => {
        const result = data[0];
        if (result?.response?.code === 9000) {
          this.notificationService.successMessage(result?.response?.message);
          this.dialog.closeAll();
          location.reload();
        } else {
          this.notificationService.errorMessage(result?.response?.message);
        }
      }
    );
  }

  async allowDisallow2FA(allow: boolean = true): Promise<string> {
    let result = await lastValueFrom(
      this.queryService.mutate(SET_TWO_FACTOR_AUTHENTICATION, { input: { allow }})
    );
    result = result[0];
    if (result?.response?.code === 9000) {
      if (!allow) {
        this.notificationService.successMessage(result?.response?.message);
        return '';
      }
      return result?.data?.base64String;
    } else {
      this.notificationService.errorMessage(result?.response?.message);
      return '';
    }
  }

  async validate2FaToken(ticket: string, token: string): Promise<any> {

    const body = `ticket=${encodeURIComponent(ticket)}&token=${encodeURIComponent(token)}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    });

    return await lastValueFrom(
      this.http
        .post<AuthTokenModel>(environment.SERVER_URL + `/oauth2/2fa_verification`, body, { headers })
        .pipe(
          map((tokenDetails: any) => {
            return tokenDetails;
          })
        )
    );
  }

}



export const SET_TWO_FACTOR_AUTHENTICATION = gql`
  mutation SetTwoFactorAuthentication($input: TwoFactorInputObjects!) {
  setTwoFactorAuthentication(input: $input) {
    response {
      ${responseField}
    }
    data {
      base64String
    }
  }
}
  `;

export const CONFIRM_TWO_FACTOR_AUTHENTICATION = gql`
mutation ConfirmTwoFactorAuthentication($token: String!) {
  confirmTwoFactorAuthentication(token: $token) {
    response {
      ${responseField}
    }
  }
}
  `;
