import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ProjectProgress} from '../../../../store/entities/projects/project-progress/project-progress.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {
  selectAllProjectProgress
} from '../../../../store/entities/projects/project-progress/project-progress.selectors';
import {
  loadProjectProgresss,
  removeProjectProgress
} from '../../../../store/entities/projects/project-progress/project-progress.actions';
import {
  ProjectComponentTask
} from '../../../../store/entities/projects/project-component-task/project-component-task.model';
import {ProjectStatusEnum} from '../../../enums/data.enums';
import {SettingsService} from '../../../../services/settings.service';
import {NotificationService} from '../../../../services/notification.service';
import {
  ProjectComponentTaskProgressFormComponent
} from '../../../../modules/portal/portal-project/portal-project-forms/project-component-task-progress-form/project-component-task-progress-form.component';
import {getAttachmentsByUid} from '../../../../store/entities/settings/attachment/attachment.actions';

@Component({
  selector: 'app-project-progress-list',
  templateUrl: './project-progress-list.component.html',
  styleUrls: ['./project-progress-list.component.scss']
})
export class ProjectProgressListComponent implements OnInit {

  title: string;
  tableColumn: any;
  progress$: Observable<ProjectProgress[]>;
  task: ProjectComponentTask;
  status = ProjectStatusEnum;

  constructor(
    private store: Store<AppState>,
    private settingsService: SettingsService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.task = data;
    this.store.dispatch(loadProjectProgresss({projectProgresss: data?.taskProgresses}));
  }

  ngOnInit(): void {
    this.title = 'Project Progress';

    this.tableColumn = {
      no: 'No',
      description: 'Description',
      status: 'Status',
      action: 'Action'
    };
    this.progress$ = this.store.select(selectAllProjectProgress);
  }

  action(progress: ProjectProgress) {
    this.settingsService.openModal({progress, task: this.task}, ProjectComponentTaskProgressFormComponent);
  }

  remove(res: any) {
    const message = 'Are you sure you want to remove this project progress?';
    this.notificationService.confirmation(message).then(
      data => {
        if (data) {
          this.store.dispatch(removeProjectProgress({ uid: res?.uniqueId, id: res?.id }));
        }
      }
    );
  }

  viewAttachment(uid: string, title: string){
    this.store.dispatch(getAttachmentsByUid({ uid, title}));
  }
}
