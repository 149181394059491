import {AuthService} from '../../services/auth.service';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {StorageService} from '../../services/storage-service.service';
import {environment} from '../../../environments/environment';
import {NotificationService} from '../../services/notification.service';

@Injectable()
export class HttpRequestInterceptor implements HttpRequestInterceptor {

  constructor(
    private notificationService: NotificationService,
    private auth: AuthService,
    private storageService: StorageService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const currentClient = this.storageService.get('currentClient');
    const deviceId = this.storageService.get('deviceId');
    const serialNumber = this.storageService.get('serialNumber');

    if (!environment.production) {
      console.log('Access Token', currentClient);
    }

    if (currentClient && request.url !== environment.SERVER_URL + '/upload_file') {
        request = request.clone({
          // url: environment.SERVER_URL + '/' + request.url,
          setHeaders: {
            Authorization: `Bearer ${currentClient}`,
            SerialNumber: serialNumber,
            DeviceId: deviceId,
          }
        });

      // if (!request?.headers.has('RefreshToken')) {
        // this.store.dispatch(refreshToken({ refreshToken: this.storageService.get('refreshToken')}));
      // }
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {

        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (request.url.trim() === environment.SERVER_URL + '/oauth2/access_token'){
          if (error.status === 400 && error?.error?.error === 'invalid_grant') {
            this.notificationService.errorMessage('Invalid username or password');
          }
          else if (error.status === 403) {
            this.notificationService.errorMessage('Your device is not registered, kindly contact ICT support team');
          }
          else if (error.status === 423) {
            this.notificationService.errorMessage('Your account is locked due to incorrect multiple login attempts,' +
              ' kindly contact ICT support team');
          }else {
            console.error('Http ERROR: ', error);
            this.notificationService.errorMessage('Error occurred, your account may be probably locked, ' +
              'Please contact support Team');
          }
        }

        const data = {
          // reason: error && error.error.error_description ? error.error.error_description : '',
          reason: error?.statusText,
          status: error?.status,
          causedBy: error?.message
        };
        this.auth.collectFailedRequest(data);
        return throwError(() => new Error('Error: ' + error?.message));
      }));
  }

}
