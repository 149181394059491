import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {ForumMember} from './forum-member.model';
import {DocumentStageEnum} from '../../document/document.model';

export const loadForumMembers = createAction(
  '[ForumMember/API] Load ForumMembers',
  props<{ forumMembers: ForumMember[] }>()
);

export const addForumMember = createAction(
  '[ForumMember/API] Add ForumMember',
  props<{ forumMember: ForumMember }>()
);

export const upsertForumMember = createAction(
  '[ForumMember/API] Upsert ForumMember',
  props<{ forumMember: ForumMember }>()
);

export const addForumMembers = createAction(
  '[ForumMember/API] Add ForumMembers',
  props<{ forumMembers: ForumMember[] }>()
);

export const upsertForumMembers = createAction(
  '[ForumMember/API] Upsert ForumMembers',
  props<{ forumMembers: ForumMember[] }>()
);

export const updateForumMember = createAction(
  '[ForumMember/API] Update ForumMember',
  props<{ forumMember: Update<ForumMember> }>()
);

export const updateForumMembers = createAction(
  '[ForumMember/API] Update ForumMembers',
  props<{ forumMembers: Update<ForumMember>[] }>()
);

export const deleteForumMember = createAction(
  '[ForumMember/API] Delete ForumMember',
  props<{ id: number }>()
);

export const deleteForumMembers = createAction(
  '[ForumMember/API] Delete ForumMembers',
  props<{ ids: string[] }>()
);

export const clearForumMembers = createAction(
  '[ForumMember/API] Clear ForumMembers'
);

export const saveForumMembers = createAction(
  '[ForumMembers/API] save user forum by stage',
  props<{ formDate: any }>()
);

export const getAllForumMemberByStage = createAction(
  '[ForumMembers/API] get all users by forum stage',
  props<{ stage?: DocumentStageEnum }>()
);

export const removeForumMemberByStage = createAction(
  '[ForumMembers/API] remove users by forum stage',
  props<{ member: { stage: DocumentStageEnum, userId: string }, userId: number }>()
);

