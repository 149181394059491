import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Location} from './location.model';
import * as LocationActions from './location.actions';

export const locationsFeatureKey = 'locations';

export interface State extends EntityState<Location> {
  // additional entities state properties
}
//
export function sortByLocationName(a: Location, b: Location): any {
  // return a.name.localeCompare(b.location_name);
}

export const adapter: EntityAdapter<Location> = createEntityAdapter<Location>({
  // sortComparer: sortByLocationName
});

// export const adapter: EntityAdapter<Location> = createEntityAdapter<Location>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const locationReducer = createReducer(
  initialState,
  on(LocationActions.addLocation,
    (state, action) => adapter.addOne(action.location, state)
  ),
  on(LocationActions.upsertLocation,
    (state, action) => adapter.upsertOne(action.location, state)
  ),
  on(LocationActions.addLocations,
    (state, action) => adapter.addMany(action.locations, state)
  ),
  on(LocationActions.upsertLocations,
    (state, action) => adapter.upsertMany(action.locations, state)
  ),
  on(LocationActions.updateLocation,
    (state, action) => adapter.updateOne(action.location, state)
  ),
  on(LocationActions.updateLocations,
    (state, action) => adapter.updateMany(action.locations, state)
  ),
  on(LocationActions.deleteLocation,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(LocationActions.deleteLocations,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(LocationActions.loadLocations,
    (state, action) => adapter.setAll(action.locations, state)
  ),
  on(LocationActions.clearLocations,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return locationReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllLocations = selectAll;
export const getLocationById = selectIds;
export const getLocationEntities = selectEntities;
