import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {MeetingAgenda} from './meeting-agenda.model';
import * as MeetingAgendaActions from './meeting-agenda.actions';
import {addOfflineAgendaPaper} from "./meeting-agenda.actions";

export const meetingAgendasFeatureKey = 'meetingAgenda';

export interface State extends EntityState<MeetingAgenda> {
  // additional entities state properties
  offlinePapers: number
}

export function sortById(a: MeetingAgenda, b: MeetingAgenda): any {
  // return a.id.localeCompare(b.id);
}

export const adapter: EntityAdapter<MeetingAgenda> = createEntityAdapter<MeetingAgenda>({
  // sortComparer: sortById
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  offlinePapers: 0
});

const MeetingAgendaReducer = createReducer(
  initialState,
  on(MeetingAgendaActions.addMeetingAgenda,
    (state, action) => adapter.addOne(action.meetingAgenda, state)
  ),
  on(MeetingAgendaActions.upsertMeetingAgenda,
    (state, action) => adapter.upsertOne(action.meetingAgenda, state)
  ),
  on(MeetingAgendaActions.addMeetingAgendas,
    (state, action) => adapter.addMany(action.meetingAgendas, state)
  ),
  on(MeetingAgendaActions.upsertMeetingAgendas,
    (state, action) => adapter.upsertMany(action.meetingAgendas, state)
  ),
  on(MeetingAgendaActions.updateMeetingAgenda,
    (state, action) => adapter.updateOne(action.meetingAgenda, state)
  ),
  on(MeetingAgendaActions.updateMeetingAgendas,
    (state, action) => adapter.updateMany(action.meetingAgendas, state)
  ),
  on(MeetingAgendaActions.deleteMeetingAgenda,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(MeetingAgendaActions.deleteMeetingAgendas,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(MeetingAgendaActions.loadMeetingAgendas,
    (state, action) => adapter.setAll(action.meetingAgendas, state)
  ),
  on(MeetingAgendaActions.clearMeetingAgendas,
    state => adapter.removeAll(state)
  ),
  on(MeetingAgendaActions.addOfflineAgendaPaper, ((state, action) => {
      return {...state, offlinePapers: state.offlinePapers+1 };
    })
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return MeetingAgendaReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getMeetingAgendas = selectAll;
export const getNoOfflinePapers = (state: State) => state.offlinePapers;
