import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const projectCategoryField = `
  ${baseField}
  name
  description
`;

export const GET_PROJECT_CATEGORY = gql`
  query getAllProjectCategories {
    getAllProjectCategories {
      response{
        ${responseField}
      }
      data{
        ${projectCategoryField}
      }
    }
  }
`;

export const SAVE_PROJECT_CATEGORY = gql`
  mutation projectCategoryManagement($inputDto: CategoryInputObjects!) {
    projectCategoryManagement(category: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectCategoryField}
      }
    }
  }
`;
