import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {MinutesDecisions} from './minutes-decisions.model';
import * as MinutesDecisionsActions from './minutes-decisions.actions';

export const minutesDecisionssFeatureKey = 'minutesDecisions';

export interface State extends EntityState<MinutesDecisions> {
  // additional entities state properties
}

export const adapter: EntityAdapter<MinutesDecisions> = createEntityAdapter<MinutesDecisions>({
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const MinutesDecisionsReducer = createReducer(
  initialState,
  on(MinutesDecisionsActions.addMinutesDecisions,
    (state, action) => adapter.addOne(action.minutesDecisions, state)
  ),
  on(MinutesDecisionsActions.upsertMinutesDecisions,
    (state, action) => adapter.upsertOne(action.minutesDecisions, state)
  ),
  on(MinutesDecisionsActions.addMinutesDecisionss,
    (state, action) => adapter.addMany(action.minutesDecisionss, state)
  ),
  on(MinutesDecisionsActions.upsertMinutesDecisionss,
    (state, action) => adapter.upsertMany(action.minutesDecisionss, state)
  ),
  on(MinutesDecisionsActions.updateMinutesDecisions,
    (state, action) => adapter.updateOne(action.minutesDecisions, state)
  ),
  on(MinutesDecisionsActions.updateMinutesDecisionss,
    (state, action) => adapter.updateMany(action.minutesDecisionss, state)
  ),
  on(MinutesDecisionsActions.deleteMinutesDecisions,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(MinutesDecisionsActions.deleteMinutesDecisionss,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(MinutesDecisionsActions.loadMinutesDecisionss,
    (state, action) => adapter.setAll(action.minutesDecisionss, state)
  ),
  on(MinutesDecisionsActions.clearMinutesDecisionss,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return MinutesDecisionsReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getMinutesDecisionss = selectAll;
