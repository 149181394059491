import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {AuthUser} from './auth-user.model';
import * as AuthUserActions from './auth-user.actions';

export const authUsersFeatureKey = 'authUsers';

export interface State extends EntityState<AuthUser> {
  // additional entities state properties
}

export const adapter: EntityAdapter<AuthUser> = createEntityAdapter<AuthUser>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const authUserReducer = createReducer(
  initialState,
  on(AuthUserActions.addAuthUser,
    (state, action) => adapter.addOne(action.authUser, state)
  ),
  on(AuthUserActions.upsertAuthUser,
    (state, action) => adapter.upsertOne(action.authUser, state)
  ),
  on(AuthUserActions.addAuthUsers,
    (state, action) => adapter.addMany(action.authUsers, state)
  ),
  on(AuthUserActions.upsertAuthUsers,
    (state, action) => adapter.upsertMany(action.authUsers, state)
  ),
  on(AuthUserActions.updateAuthUser,
    (state, action) => adapter.updateOne(action.authUser, state)
  ),
  on(AuthUserActions.updateAuthUsers,
    (state, action) => adapter.updateMany(action.authUsers, state)
  ),
  on(AuthUserActions.deleteAuthUser,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AuthUserActions.deleteAuthUsers,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AuthUserActions.loadAuthUsers,
    (state, action) => adapter.setAll(action.authUsers, state)
  ),
  on(AuthUserActions.clearAuthUsers,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return authUserReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// export entity selectors
export const getAuthUser = selectAll;
