import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {
  deleteFolderCategory,
  editFolderCategory,
  getAllFolderCategories,
  loadFolderCategories,
  removeFolderCategory,
  saveFolderCategory,
  upsertFolderCategory
} from './folder-category.actions';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {
  DELETE_FOLDER_CATEGORY,
  GET_FOLDER_CATEGORY,
  SAVE_FOLDER_CATEGORY,
  UPDATE_FOLDER_CATEGORY
} from './folder-category.graphql';
import {FolderCategory} from './folder-category.model';
import {clearDocuments, upsertDocuments} from '../../document/document.actions';


@Injectable()
export class FolderCategoryEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllFolderCategories),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_FOLDER_CATEGORY,
        variables: { uid: action.uid, withPapers: action.withPapers}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.store.dispatch(loadFolderCategories({FolderCategories: result?.data}));

            if (action.withPapers){
              this.store.dispatch(clearDocuments());
              result?.data.forEach( (folder: FolderCategory) => {
                this.store.dispatch(upsertDocuments({ documents: folder?.folderPapers}));
              });
            }
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveFolderCategory),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_FOLDER_CATEGORY,
        variables: { inputDto: action.folderCategoryDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(getAllFolderCategories({uid: null, withPapers: false}));
            // this.store.dispatch(upsertFolderCategory({folderCategory: result?.data}));
            // window.location.reload();
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  update = createEffect(() => this.actions$.pipe(
    ofType(editFolderCategory),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_FOLDER_CATEGORY,
        variables: { inputDto: action.folderCategoryDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertFolderCategory({folderCategory: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  delete = createEffect(() => this.actions$.pipe(
    ofType(removeFolderCategory),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_FOLDER_CATEGORY,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteFolderCategory({ id: action.id}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private apollo: Apollo,
  ) {
  }
}
