<app-modal-header [title]="title"></app-modal-header>

<form (ngSubmit)="add(form.value)" [formGroup]="form"  autocomplete="off" novalidate>
  <div class="row">
      <quill-editor [styles]="editorStyle" class="col-12" formControlName="description">
      </quill-editor>
  </div>

  <div class="col-md-12 text-end mt-2">
    <button [disabled]="!form.valid" class="" color="primary" mat-raised-button type="submit">
      {{'Save' | concat | translate}}
    </button>
  </div>
</form>
