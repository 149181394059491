import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'isDateValid'
})
export class IsDateValidPipe implements PipeTransform {

  transform(value: any): boolean {
    const today = new DatePipe('en-Gb').transform(new Date(), 'yyyy-MM-dd');
    const expiryDate = new DatePipe('en-Gb').transform(value, 'yyyy-MM-dd');
    return today <= expiryDate;
  }


}
