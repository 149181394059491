import {createSelector} from '@ngrx/store';
import * as fromProjectProgressReducer from './project-progress.reducer';
import {AppState} from '../../index';
import {DatePipe} from '@angular/common';

export const currentProjectProgressState = (state: AppState) => state[fromProjectProgressReducer.projectProgresssFeatureKey];

export const selectAllProjectProgressFromReducer = createSelector(currentProjectProgressState, fromProjectProgressReducer.selectAll);
export const selectTotalProjectProgress = createSelector(currentProjectProgressState, fromProjectProgressReducer.selectTotal);

export const selectAllProjectProgress = createSelector(
  selectAllProjectProgressFromReducer,
  (projects) => {
    return projects.map( res => {
      return {
        ...res,
        formattedRecordedOn: new DatePipe('en-GB').transform( res?.recordedOn, 'dd MMM yyyy')
      };
    });
  });

export const selectProjectProgressById = (id: number) => createSelector(
  selectAllProjectProgress,
  (data) => data.find((projectProgress) => projectProgress.id === id)
);

export const selectProjectProgressByUid = (uid: string) => createSelector(
  selectAllProjectProgress,
  (data) => data.find((projectProgress) => projectProgress.uniqueId === uid)
);
