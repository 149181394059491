import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const documentCategoryField = `
  ${baseField}
  name
  description
`;

export const GET_DOCUMENT_CATEGORY = gql`
  query getAllPapersCategories {
    getAllPapersCategories {
      response{
        ${responseField}
      }
      data{
        ${documentCategoryField}
      }
    }
  }
`;

export const SAVE_DOCUMENT_CATEGORY = gql`
  mutation paperCategoryManagement($inputDto: CategoryInputObjects!) {
    paperCategoryManagement(category: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${documentCategoryField}
      }
    }
  }
`;
