import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {PageableParam} from '../../page/page.model';
import {Attendance, MeetingLeadershipEnum} from './attendance.model';

export const loadAttendances = createAction(
  '[Attendance/API] Load Attendances',
  props<{ attendances: Attendance[] }>()
);

export const addAttendance = createAction(
  '[Attendance/API] Add Attendance',
  props<{ attendance: Attendance }>()
);

export const upsertAttendance = createAction(
  '[Attendance/API] Upsert Attendance',
  props<{ attendance: Attendance }>()
);

export const addAttendances = createAction(
  '[Attendance/API] Add Attendances',
  props<{ attendances: Attendance[] }>()
);

export const upsertAttendances = createAction(
  '[Attendance/API] Upsert Attendances',
  props<{ attendances: Attendance[] }>()
);

export const updateAttendance = createAction(
  '[Attendance/API] Update Attendance',
  props<{ attendance: Update<Attendance> }>()
);

export const updateAttendances = createAction(
  '[Attendance/API] Update Attendances',
  props<{ attendances: Update<Attendance>[] }>()
);

export const deleteAttendance = createAction(
  '[Attendance/API] Delete Attendance',
  props<{ id: number }>()
);

export const deleteAttendances = createAction(
  '[Attendance/API] Delete Attendances',
  props<{ ids: number[] }>()
);

export const clearAttendances = createAction(
  '[Attendance/API] Clear Attendances'
);

export const getAllAttendancePageable = createAction(
  '[Attendance/API] get all active Attendances pageable',
  props<{ pageable: PageableParam, stageName: string, meetingStatus?: string}>()
);


export const getAllAttendance = createAction(
  '[Attendance/API] Get All Meeting Attendances',
  props<{ meetingUniqueId: string }>()
);

export const saveAttendance = createAction(
  '[Attendance/API] Save Attendance',
  props<{ input: {
      uniqueId: string;
      position: MeetingLeadershipEnum;
      hasAttend: boolean;
    } }>()
);

