import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {ProjectChallenge, ProjectChallengeDto} from './project-challenge.model';

export const loadProjectChallenges = createAction(
  '[ProjectChallenge/API] Load ProjectChallenges',
  props<{ projectChallenges: ProjectChallenge[] }>()
);

export const addProjectChallenge = createAction(
  '[ProjectChallenge/API] Add ProjectChallenge',
  props<{ projectChallenge: ProjectChallenge }>()
);

export const upsertProjectChallenge = createAction(
  '[ProjectChallenge/API] Upsert ProjectChallenge',
  props<{ projectChallenge: ProjectChallenge }>()
);

export const addProjectChallenges = createAction(
  '[ProjectChallenge/API] Add ProjectChallenges',
  props<{ projectChallenges: ProjectChallenge[] }>()
);

export const upsertProjectChallenges = createAction(
  '[ProjectChallenge/API] Upsert ProjectChallenges',
  props<{ projectChallenges: ProjectChallenge[] }>()
);

export const updateProjectChallenge = createAction(
  '[ProjectChallenge/API] Update ProjectChallenge',
  props<{ projectChallenge: Update<ProjectChallenge> }>()
);

export const updateProjectChallenges = createAction(
  '[ProjectChallenge/API] Update ProjectChallenges',
  props<{ projectChallenges: Update<ProjectChallenge>[] }>()
);

export const deleteProjectChallenge = createAction(
  '[ProjectChallenge/API] Delete ProjectChallenge',
  props<{ id: number }>()
);

export const deleteProjectChallenges = createAction(
  '[ProjectChallenge/API] Delete ProjectChallenges',
  props<{ ids: string[] }>()
);

export const clearProjectChallenges = createAction(
  '[ProjectChallenge/API] Clear ProjectChallenges'
);


export const getProjectChallengeByProjectUid = createAction(
  '[ProjectChallenge/API] remove projectChallenge by project uid',
  props<{ projectUid: string }>()
);

export const saveProjectChallenge = createAction(
  '[ProjectChallenge/API] create projectChallenge',
  props<{ formData: ProjectChallengeDto }>()
);

export const editProjectChallenge = createAction(
  '[ProjectChallenge/API] edit projectChallenge',
  props<{ formData: ProjectChallengeDto }>()
);

export const removeProjectChallenge = createAction(
  '[ProjectChallenge/API] remove projectChallenge',
  props<{ uid: string, id: number }>()
);
