import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {SettingsService} from '../../../../services/settings.service';
import {Apollo} from 'apollo-angular';
import {
  deleteMinutesDirectives,
  editMinutesDirectives,
  getAllAgendaDirectives,
  getAllMinutesDirectives,
  getDirectiveByMinistryDashboard,
  loadMinutesDirectivess,
  removeMinutesDirectives,
  saveMinutesDirectives,
  upsertMinutesDirectives,
} from './minutes-directives.actions';
import {
  DELETE_MINUTES_DIRECTIVES,
  GET_ALL_AGENDA_DIRECTIVES,
  GET_DIRECTIVES_PER_MINISTRY_DASHBOARD,
  GET_MINUTES_DIRECTIVES,
  SAVE_MINUTES_DIRECTIVES,
  UPDATE_MINUTES_DIRECTIVES
} from './minutes-directives.graphql';
import {insertPage} from "../../page/page.actions";

@Injectable()
export class MinutesDirectivesEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllMinutesDirectives),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_MINUTES_DIRECTIVES,
        variables: { filtering: action.filtering }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(insertPage({ page: result?.page }))
            this.store.dispatch(loadMinutesDirectivess({ minutesDirectivess: result?.data }));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  getAllAgendaDirectives = createEffect(() => this.actions$.pipe(
    ofType(getAllAgendaDirectives),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_ALL_AGENDA_DIRECTIVES,
        variables: { agendaUniqueId: action.agendaUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadMinutesDirectivess({ minutesDirectivess: result?.data }));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  getDirectiveDashboard = createEffect(() => this.actions$.pipe(
    ofType(getDirectiveByMinistryDashboard),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_DIRECTIVES_PER_MINISTRY_DASHBOARD,
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadMinutesDirectivess({ minutesDirectivess: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveMinutesDirectives),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_MINUTES_DIRECTIVES,
        variables: { directive: action.directive }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {

            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertMinutesDirectives({ minutesDirectives: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editMinutesDirectives),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_MINUTES_DIRECTIVES,
        variables: { directive: action.directive }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertMinutesDirectives({ minutesDirectives: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  delete = createEffect(() => this.actions$.pipe(
    ofType(removeMinutesDirectives),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_MINUTES_DIRECTIVES,
        variables: { directiveUniqueId: action.directiveUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteMinutesDirectives({ id: action?.id}))
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
  ) { }

}
