import gql from 'graphql-tag';
import {responseField} from '../../base.graphql';

export const deviceField = `
  id
  deviceUniqueId
  deviceId
  serialNumber
  deviceIsActive
  userProfile{
    id
    uniqueId
    email
    institution{
      name
    }
  }
`;

export const GET_DEVICE = gql`
  query getRegisteredUserDevices {
    getRegisteredUserDevices {
      response{
        ${responseField}
      }
      data{
        ${deviceField}
      }
    }
  }
`;

export const SAVE_DEVICE = gql`
  mutation registerUserDevice($inputDto: UserDeviceInputObjects!) {
    registerUserDevice(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${deviceField}
      }
    }
  }
`;

export const REMOVE_DEVICE = gql`
  mutation removeUserDevice($uid: String!) {
    removeUserDevice(deviceUniqueId: $uid){
      response{
        ${responseField}
      }
      data{
        id
      }
    }
  }
`;

export const DE_ACTIVATE_DEVICE = gql`
  mutation deactivateUserDevice($uid: String!) {
    deactivateUserDevice(deviceUniqueId: $uid){
      response{
        ${responseField}
      }
      data{
        ${deviceField}
      }
    }
  }
`;
