import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {SettingsService} from '../../../../services/settings.service';
import {Apollo} from 'apollo-angular';
import {
  deleteDocumentation,
  getAllDocumentation,
  loadDocumentations,
  removeDocumentation, revokeDocumentation,
  saveDocumentation,
  upsertDocumentation
} from './documentation.actions';
import {
  CREATE_DOCUMENTATION_FOLDER,
  DELETE_DOCUMENTATION,
  GET_DOCUMENTATION_FOLDERS,
  REVOKE_ACCESS_TO_DOCUMENTATION
} from './documentation.graphql';

@Injectable()
export class DocumentationEffects {


  get = createEffect(() => this.actions$.pipe(
    ofType(getAllDocumentation),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_DOCUMENTATION_FOLDERS,
        variables: { filtering: action.filtering }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadDocumentations({ documentations: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });


  save = createEffect(() => this.actions$.pipe(
    ofType(saveDocumentation),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: CREATE_DOCUMENTATION_FOLDER,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertDocumentation({ documentation: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  revoke = createEffect(() => this.actions$.pipe(
    ofType(revokeDocumentation),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: REVOKE_ACCESS_TO_DOCUMENTATION,
        variables: { accessUniqueId: action.accessUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertDocumentation({ documentation: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeDocumentation),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_DOCUMENTATION,
        variables: { categoryUniqueId: action.uniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessage(result?.response?.message);

            this.store.dispatch(deleteDocumentation({ id: action.id }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
  ) { }

}
