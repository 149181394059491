<app-modal-fixed-header [title]="title" [isTranslate]="!data?.title"></app-modal-fixed-header>

<div class="col-12">
  <app-data-table [showDownloadButtons]="!isOffline" [columnHeader]="tableColumns" [tableData$]="data | observable" [allowTableColumnFromCache]="false">
    <ng-template let-data="data">
      <div class="action-icons">

          <button class="icon-holder text-white bg-primary" mat-stroked-button (click)="viewAttachment(data?.attachmentPath, data?.attachmentName)">
            {{ 'open document' | concat | translate}}
          </button>

      </div>
    </ng-template>
  </app-data-table>
</div>
