import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Permission} from './permission.model';

export const loadPermissions = createAction(
  '[Permission/API] Load Permissions',
  props<{ permissions: Permission[] }>()
);

export const addPermission = createAction(
  '[Permission/API] Add Permission',
  props<{ permission: Permission }>()
);

export const upsertPermission = createAction(
  '[Permission/API] Upsert Permission',
  props<{ permission: Permission }>()
);

export const addPermissions = createAction(
  '[Permission/API] Add Permissions',
  props<{ permissions: Permission[] }>()
);

export const upsertPermissions = createAction(
  '[Permission/API] Upsert Permissions',
  props<{ permissions: Permission[] }>()
);

export const updatePermission = createAction(
  '[Permission/API] Update Permission',
  props<{ permission: Update<Permission> }>()
);

export const updatePermissions = createAction(
  '[Permission/API] Update Permissions',
  props<{ permissions: Update<Permission>[] }>()
);

export const deletePermission = createAction(
  '[Permission/API] Delete Permission',
  props<{ id: string }>()
);

export const deletePermissions = createAction(
  '[Permission/API] Delete Permissions',
  props<{ ids: string[] }>()
);

export const clearPermissions = createAction(
  '[Permission/API] Clear Permissions'
);


// ACTION FOR API Communications (Effect Only)
export const getAllPermissions = createAction(
  '[Permission/API] List All Permissions'
);


export const assignPermissionsToRole = createAction(
  '[Permission/API] Assign Permissions To Role',
  props<{ roleDto: any,  roleId: number }>()
);
