import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {SmsConfig, SmsConfigDto} from './sms-config.model';

export const loadSmsConfigs = createAction(
  '[SmsConfig/API] Load SmsConfigs',
  props<{ smsConfigs: SmsConfig[] }>()
);

export const addSmsConfig = createAction(
  '[SmsConfig/API] Add SmsConfig',
  props<{ smsConfig: SmsConfig }>()
);

export const upsertSmsConfig = createAction(
  '[SmsConfig/API] Upsert SmsConfig',
  props<{ smsConfig: SmsConfig }>()
);

export const addSmsConfigs = createAction(
  '[SmsConfig/API] Add SmsConfigs',
  props<{ smsConfigs: SmsConfig[] }>()
);

export const upsertSmsConfigs = createAction(
  '[SmsConfig/API] Upsert SmsConfigs',
  props<{ smsConfigs: SmsConfig[] }>()
);

export const updateSmsConfig = createAction(
  '[SmsConfig/API] Update SmsConfig',
  props<{ smsConfig: Update<SmsConfig> }>()
);

export const updateSmsConfigs = createAction(
  '[SmsConfig/API] Update SmsConfigs',
  props<{ smsConfigs: Update<SmsConfig>[] }>()
);

export const deleteSmsConfig = createAction(
  '[SmsConfig/API] Delete SmsConfig',
  props<{ id: number }>()
);

export const deleteSmsConfigs = createAction(
  '[SmsConfig/API] Delete SmsConfigs',
  props<{ ids: string[] }>()
);

export const clearSmsConfigs = createAction(
  '[SmsConfig/API] Clear SmsConfigs'
);

export const getAllSmsConfigs = createAction(
  '[SmsConfigs/API] List All SmsConfigs'
);

export const actionSmsConfig = createAction(
  '[SmsConfig/API] create, edit & delete smsConfig',
  props<{ smsConfigDto: SmsConfigDto }>()
);
