import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable, Subscription} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {MenuItem, NavMenu} from '../../../interfaces/data.interfaces';
import {SettingsService} from '../../../../services/settings.service';
import {UserTypeEnum} from '../../../../store/entities/settings/user/user.model';
import {StorageService} from '../../../../services/storage-service.service';
import {MenuService} from '../../../../services/menu.service';
import {Institution} from '../../../../store/entities/settings/institution/institution.model';
import {IconTypeEnum} from '../../../enums/data.enums';
import {selectLoggedInUser} from '../../../../store/entities/auth-user/auth-user.selector';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {AuthUser} from '../../../../store/entities/auth-user/auth-user.model';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy{

  protected readonly UserTypeEnum = UserTypeEnum;
  protected readonly IconTypeEnum = IconTypeEnum;

  @Input() moduleTitle: string;
  @Input() isPortalModule = false;
  @Input() moduleMenuItems: MenuItem[];
  @Input() moduleIconType: IconTypeEnum;
  @Input() moduleIconTitle: string;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  loggedInUserType: UserTypeEnum;
  loggedInInstitution: Institution;

  sidebarMenu: NavMenu[];

  subscription = new Subscription();
  authUser$: Observable<AuthUser>;

  isProduction = environment.production;

  constructor(
    private store: Store<AppState>,
    private breakpointObserver: BreakpointObserver,
    private settingsService: SettingsService,
    private menuService: MenuService,
    private storageService: StorageService,
  ) {
    this.loggedInUserType = this.storageService.get('category');
    this.loggedInInstitution = this.storageService.get('institution');
  }

  ngOnInit() {

    this.authUser$ = this.store.select(selectLoggedInUser);

    this.subscription.add(
      this.authUser$.subscribe( data => {
        if (data){
          this.loggedInUserType = data?.userType;
          this.loggedInInstitution = data?.institution;
          this.sidebarMenu = data?.userType === UserTypeEnum.Staff ? this.menuService.mainMenu : this.menuService.ministryMainMenu;
        }
      })
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
