import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Store} from '@ngrx/store';
import {lastValueFrom, Observable, of, Subscription} from 'rxjs';
import {
  AttachmentConfig
} from '../../../../store/entities/settings/attachment-configuration/attachment-configuration.model';
import {AppState} from '../../../../store/entities';
import {
  getAllAttachmentConfigs
} from '../../../../store/entities/settings/attachment-configuration/attachment-configuration.actions';
import {
  selectAttachmentConfigByStage
} from '../../../../store/entities/settings/attachment-configuration/attachment-configuration.selectors';
import {QueryService} from '../../../../services/query.service';
import {ViewAttachmentComponent} from '../../views/view-attachment/view-attachment.component';
import {SettingsService} from '../../../../services/settings.service';
import {
  DELETE_ATTACHMENT,
  DELETE_ATTACHMENT_WITH_TYPE,
  UPLOAD_FILE
} from '../../../../store/entities/settings/attachment/attachment.graphql';
import {getAttachmentsByUid} from '../../../../store/entities/settings/attachment/attachment.actions';
import {NotificationService} from '../../../../services/notification.service';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {StorageService} from '../../../../services/storage-service.service';

@Component({
  selector: 'app-attachment-form-field',
  templateUrl: './attachment-form-field.component.html',
  styleUrls: ['./attachment-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentFormFieldComponent implements OnInit, OnChanges, OnDestroy {

  @Input() attachmentRequiredFor: any;
  @Input() editedAttachments: any[];
  @Input() isMultiple = false;
  @Input() isSingleDragDrop = false;
  @Input() useAttachmentConfig = true;
  @Input() documentType: 'PAPER' | 'MEETING' | 'PROJECT';

  files: File[] | { name: string }[] = [];
  uploadedAttachmentList: any[] = [];

  @Output() uploadedFiles = new EventEmitter<any[]>();
  @Output() removedAttachment = new EventEmitter<{ attachmentPath: string, uniqueId: string }>();

  attachmentConfigs$: Observable<AttachmentConfig[]>;
  attachmentConfigs: AttachmentConfig[] = [];

  requiredFilesExist = false;

  subscriptions = new Subscription();
  queryService = inject(QueryService);
  settingsService = inject(SettingsService);
  storageService = inject(StorageService);
  notificationService = inject(NotificationService);
  changeDetectorRefs = inject(ChangeDetectorRef);
  fb = inject(UntypedFormBuilder);

  uploadingFile = false;

  form: UntypedFormGroup = this.fb.group({
    attachments: this.fb.array([]),
  });

  constructor(private store: Store<AppState>) {
    if (this.useAttachmentConfig){
      this.store.dispatch(getAllAttachmentConfigs());
    }
  }

  ngOnInit() {
    if (this.getFormArray?.length === 0 && this.editedAttachments?.length === 0 && !this.useAttachmentConfig){
      this.addFormRow(null);
    }

    if (!this.useAttachmentConfig){
      this.subscriptions.add(
        this.form.valueChanges.subscribe( () => {
          this.emitData();
        })
      );
    }
  }

  get getFormArray(): UntypedFormArray {
    return this.form.get('attachments') as UntypedFormArray;
  }

  addFormRow(attachmentConfig: AttachmentConfig) {
    this.getFormArray.push(
      this.fb.group({
        uniqueId: [attachmentConfig?.attachmentUniqueId],
        id: [attachmentConfig?.id],
        base64String: [null],
        attachmentName: [attachmentConfig?.attachmentName, Validators.required],
        attachment: [attachmentConfig?.attachmentUniqueId, Validators.required]
      })
    );

    this.attachmentConfigs.push({
      attachmentIsMandatory: true
    });
    if (!attachmentConfig) {
      this.uploadedAttachmentList.push({
        uniqueId: null,
        attachmentTitle: null,
        id: this.uploadedAttachmentList?.length,
        attachmentIsMandatory: true,
        base64String: null,
        file: {
          attachmentPath: null,
          attachmentName: null,
        }
      });
    }
    this.emitData();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.attachmentRequiredFor && this.useAttachmentConfig) {
      this.attachmentConfigs$ = this.store.select(selectAttachmentConfigByStage(this.attachmentRequiredFor));

      this.subscriptions.add(
        this.attachmentConfigs$.subscribe( (configs) => {
          if (configs){
            this.attachmentConfigs = configs;
            this.requiredFilesExist = configs?.filter(d => d?.attachmentIsMandatory)?.length <= 0;
          }
        })
      );
    }

    if (changes.editedAttachments){

      if (this.editedAttachments?.length > 0) {

        if (this.isMultiple) {
          this.files = this.editedAttachments.map(a => {
            return {
              name: a.file?.attachmentName
            };
          });

          this.uploadedAttachmentList = this.editedAttachments.map(a => {
            return {
              file: {
                attachmentName: a?.file?.attachmentName,
                attachmentPath: a?.file?.base64String,
                uniqueId: a?.file?.uniqueId
              },
              requiredAttachment: a?.file?.uniqueId,
              edited: true
            };
          });
        }
        else {
          if (this.useAttachmentConfig){
            this.subscriptions.add(
              this.attachmentConfigs$.subscribe( (configs) => {
                if (configs){
                  this.attachmentConfigs = configs;
                  this.requiredFilesExist = configs?.filter(d => d?.attachmentIsMandatory)?.length <= 0;

                  if (this.attachmentConfigs?.length > 0) {
                    this.uploadedAttachmentList = [];

                    this.editedAttachments?.forEach( d => {
                      this.uploadedAttachmentList.push({
                        uniqueId: d?.uniqueId,
                        id: d?.attachmentConfig?.id,
                        requiredAttachment: d?.attachmentConfig?.attachmentUniqueId,
                        attachmentIsMandatory: d?.attachmentConfig?.attachmentIsMandatory,
                        base64String: null,
                        file: {
                          attachmentPath: d?.file?.base64String,
                          attachmentName: d?.attachmentConfig?.attachmentName,
                        }
                      });
                    });

                    this.changeDetectorRefs.detectChanges();
                    this.emitData();
                  }
                }
              })
            );
          }else {

            this.editedAttachments?.forEach( (d, id: number) => {
              this.uploadedAttachmentList.push({
                uniqueId: d?.uniqueId,
                attachmentTitle: d?.attachmentConfig?.attachmentName,
                id,
                attachmentIsMandatory: true,
                base64String: null,
                file: {
                  attachmentPath: d?.file?.base64String,
                  attachmentName: d?.attachmentConfig?.attachmentName,
                }
              });

              this.addFormRow({
                ...d,
                id,
                attachmentName: d?.attachmentConfig?.attachmentName,
                attachmentUniqueId: d?.uniqueId
              });
            });
          }
        }

      }

    }

    if (changes.uploadedAttachmentList){
      this.changeDetectorRefs.detectChanges();
    }

  }

  onSingleFileSelected(event: any, index, attachmentConfig: AttachmentConfig) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {

      this.uploadingFile = true;

      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader?.result?.toString()?.split(',')[1];
        lastValueFrom(
          this.queryService.mutate(UPLOAD_FILE, { inputDto: {fileName: attachmentConfig?.attachmentName, base64String}})
        ).then( data => {
          const result = data[0];

          if (result?.response?.code === 9000){
            this.uploadingFile = false;

            this.uploadedAttachmentList.push({
              id: attachmentConfig?.id,
              requiredAttachment: attachmentConfig?.attachmentUniqueId,
              attachmentIsMandatory: attachmentConfig?.attachmentIsMandatory,
              base64String,
              file: {
                attachmentPath: result?.data?.fullFileName,
                attachmentName: attachmentConfig?.attachmentName,
              }
            });

            this.changeDetectorRefs.detectChanges();

            this.emitData();

          }else {
            this.notificationService.errorMessage(result?.response?.message);
            this.uploadingFile = false;
          }

        });
      };
    }
    event.target.value = '';
  }

  onSingleFileSelectedFormData(event: any, index: number, attachmentName: string) {
    const reader = new FileReader();
    const attachmentIndex = this.uploadedAttachmentList.findIndex(a => a?.index === index);
    if (event) {
      if (event.target.files && event.target.files.length) {

        this.uploadingFile = true;

        const [file] = event.target.files;
        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append('uploaded_file', file, attachmentName + '.pdf');
        formData.append('token', this.storageService.get('currentClient'));

        reader.onload = () => {
          lastValueFrom(this.queryService.uploadFile(formData)).then( result => {

            if (result?.full_file_name !== null){
              this.uploadingFile = false;

              if (!attachmentIndex) {
                this.uploadedAttachmentList.push({
                  id: index,
                  attachmentTitle: attachmentName,
                  attachmentIsMandatory: true,
                  base64String: reader?.result?.toString()?.split(',')[1],
                  file: {
                    attachmentPath: result?.full_file_name,
                    attachmentName,
                  }
                });
              } else {
                this.uploadedAttachmentList[index] = {
                  id: index,
                  attachmentTitle: attachmentName,
                  attachmentIsMandatory: true,
                  base64String: reader?.result?.toString()?.split(',')[1],
                  file: {
                    attachmentPath: result?.full_file_name,
                    attachmentName,
                  }
                };
              }

              this.changeDetectorRefs.detectChanges();

              this.emitData();

            }else {
              this.notificationService.errorMessage(result?.message);
              this.uploadingFile = false;
            }

          });
        };
      }
      event.target.value = '';
    } else {
      if (!attachmentIndex) {
        this.uploadedAttachmentList.push({
          id: index,
          attachmentTitle: attachmentName,
          attachmentIsMandatory: true,
          base64String: this.uploadedAttachmentList[index]?.base64String,
          file: {
            attachmentPath: this.uploadedAttachmentList[index]?.file?.attachmentPath,
            attachmentName,
          }
        });
      } else {
        this.uploadedAttachmentList[index] = {
          id: index,
          attachmentTitle: attachmentName,
          attachmentIsMandatory: true,
          base64String: this.uploadedAttachmentList[index]?.base64String,
          file: {
            attachmentPath: this.uploadedAttachmentList[index]?.file?.attachmentPath,
            attachmentName,
          }
        };
      }
      this.changeDetectorRefs.detectChanges();
      this.emitData();
    }
  }

  isAttachmentExist(attachmentConfig: AttachmentConfig): boolean {
    return this.uploadedAttachmentList?.findIndex(d => d?.id === attachmentConfig?.id && d?.file?.attachmentPath) > -1;
  }

  removeSingleFile(attachmentConfig: AttachmentConfig, orgIndex: number) {
    const index = this.uploadedAttachmentList?.findIndex(d => d?.id === attachmentConfig?.id);

    if (!this.useAttachmentConfig){
      this.getFormArray.removeAt(orgIndex);
      this.attachmentConfigs.splice(orgIndex, 1);
    }

    if (attachmentConfig?.base64String !== null) {
      /*remove from dummy */
      lastValueFrom(
        this.queryService.mutate(DELETE_ATTACHMENT, { inputDto: this.uploadedAttachmentList[index]?.file?.attachmentPath })
      ).then( data => {
        const result = data[0];

        if (result?.response?.code === 9000){
          this.uploadedAttachmentList.splice(index, 1);

          this.changeDetectorRefs.detectChanges();

          this.emitData();

        }
        else {
          this.notificationService.errorMessage(result?.response?.message);
        }
      });
    }
    else {

      if (this.uploadedAttachmentList[index]?.uniqueId){
        lastValueFrom(
          this.queryService.mutate(
            DELETE_ATTACHMENT_WITH_TYPE,
            {
              inputDto: {
                uniqueId: this.uploadedAttachmentList[index]?.uniqueId,
                deleteType: this.documentType
              }
            }
          )
        ).then( data => {
          const result = data[0];

          if (result?.response?.code === 9000){
            this.uploadedAttachmentList.splice(index, 1);

            this.changeDetectorRefs.detectChanges();

            this.emitData();

          }
          else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        });
      }else {
        this.uploadedAttachmentList.splice(index, 1);
      }
    }
  }

  emitData(){
    const totalMandatory = this.attachmentConfigs?.filter(d => d?.attachmentIsMandatory)?.length;
    const existingMandatory = this.uploadedAttachmentList?.filter( (d) => d?.attachmentIsMandatory)?.length;

    this.requiredFilesExist = totalMandatory === 0 ? false : totalMandatory === existingMandatory;

    if (!this.useAttachmentConfig && !this.form.valid){
      this.requiredFilesExist = false;
    }

    this.uploadedFiles.emit(this.uploadedAttachmentList?.map( (d) =>
      ({requiredAttachment: d?.requiredAttachment, attachmentTitle: d?.attachmentTitle, file: d?.file, uniqueId: d?.uniqueId })));

  }

  viewAttachment(id: any) {
    const uploadedFile: any  = this.uploadedAttachmentList?.find(d => d?.id === id);

    if (uploadedFile?.base64String !== null){
      this.settingsService.openModal(
        {
          base64$: of(uploadedFile?.base64String),
          fileTitle: uploadedFile?.file?.attachmentName,
          filePath: uploadedFile?.file?.attachmentPath
        },
        ViewAttachmentComponent, '85%');

    }else {
      const fileNames = uploadedFile?.file?.attachmentName?.split('.');
      let title = uploadedFile?.file?.attachmentName;
      if (fileNames?.length) {
        title = fileNames[0];
      }
      this.store.dispatch(getAttachmentsByUid({ uid: uploadedFile?.file?.attachmentPath, title }));
    }
  }

  onMultipleFileSelect(event: any) {
    if (this.isSingleDragDrop) {
      this.files = [...event.addedFiles];
    } else {
      this.files.push(...event.addedFiles);
    }

    event.addedFiles.forEach((fileData: any, id: any) => {

      if (this.uploadedAttachmentList?.findIndex( d => d?.file?.attachmentName === fileData?.name) > -1){
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onload = () => {

        const base64String = reader.result?.toString()?.split(',')[1];

        lastValueFrom(
          this.queryService.mutate(UPLOAD_FILE, { inputDto: {fileName: fileData?.name, base64String}})
        ).then( data => {
          const result = data[0];

          if (result?.response?.code === 9000){
            if (this.isSingleDragDrop) {
              this.uploadedAttachmentList = [{
                id,
                requiredAttachment: this.attachmentConfigs[0]?.attachmentUniqueId,
                attachmentIsMandatory: false,
                base64String,
                file: {
                  attachmentPath: result?.data?.fullFileName,
                  attachmentName: fileData?.name,
                }
              }];
            } else {
              this.uploadedAttachmentList.push({
                id,
                requiredAttachment: this.attachmentConfigs[0]?.attachmentUniqueId,
                attachmentIsMandatory: false,
                base64String,
                file: {
                  attachmentPath: result?.data?.fullFileName,
                  attachmentName: fileData?.name,
                }
              });
            }


            this.changeDetectorRefs.detectChanges();

            this.uploadedFiles.emit(this.uploadedAttachmentList?.map( (d) =>
              ({requiredAttachment: d?.requiredAttachment, file: d?.file, uniqueId: d?.uniqueId })));

          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        });
      };

    });
  }

  onMultipleFileRemove(event: any) {
    const index = this.uploadedAttachmentList.findIndex(d => d?.file?.attachmentName === event?.name);

    /*remove from dummy */
    if (this.uploadedAttachmentList[index]?.edited) {
      this.removedAttachment.emit({
        attachmentPath: this.uploadedAttachmentList[index]?.file?.attachmentPath,
        uniqueId: this.uploadedAttachmentList[index]?.file?.uniqueId
      });
      this.uploadedAttachmentList.splice(index, 1);
      this.files.splice(this.files.indexOf(event), 1);

      this.changeDetectorRefs.detectChanges();

      this.uploadedFiles.emit(this.uploadedAttachmentList?.map( (d) =>
        ({requiredAttachment: d?.requiredAttachment, file: d?.file, uniqueId: d?.uniqueId })));
    } else {
      lastValueFrom(
        this.queryService.mutate(DELETE_ATTACHMENT, { inputDto: this.uploadedAttachmentList[index]?.file?.attachmentPath })
      ).then( data => {
        const result = data[0];

        if (result?.response?.code === 9000){
          this.uploadedAttachmentList.splice(index, 1);
          this.files.splice(this.files.indexOf(event), 1);

          this.changeDetectorRefs.detectChanges();

          this.uploadedFiles.emit(this.uploadedAttachmentList?.map( (d) =>
            ({requiredAttachment: d?.requiredAttachment, file: d?.file, uniqueId: d?.uniqueId })));
        }
        else {
          this.notificationService.errorMessage(result?.response?.message);
        }
      });
    }

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
