import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {SettingsService} from '../../../../services/settings.service';
import {Apollo} from 'apollo-angular';
import {
  deleteInvitee,
  editInvitee,
  getAllInvitee,
  loadInvitees,
  removeInvitee,
  saveInvitee,
  upsertInvitee
} from './invitee.actions';
import {
  ADD_MEETING_INVITEES,
  EDIT_MEETING_INVITEES,
  GET_MEETING_INVITEES,
  REMOVE_MEETING_INVITEE
} from './invitee.graphql';

@Injectable()
export class InviteeEffects {


  get = createEffect(() => this.actions$.pipe(
    ofType(getAllInvitee),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_MEETING_INVITEES,
        variables: { meetingUniqueId: action.meetingUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadInvitees({ invitees: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });


  save = createEffect(() => this.actions$.pipe(
    ofType(saveInvitee),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: ADD_MEETING_INVITEES,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessage(result?.response?.message);
            this.store.dispatch(upsertInvitee({ invitee: result?.data[0] }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editInvitee),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: EDIT_MEETING_INVITEES,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessage(result?.response?.message);
            this.store.dispatch(upsertInvitee({ invitee: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeInvitee),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: REMOVE_MEETING_INVITEE,
        variables: { uniqueId: action.uniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessage(result?.response?.message);

            this.store.dispatch(deleteInvitee({ id: action.id }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
  ) { }

}
