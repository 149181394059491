import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {clearPages, insertPage, insertPageTotalElement, loadPages} from './page.actions';
import {AppState} from '../index';


@Injectable()
export class PageEffects {

  insert = createEffect(
    () =>
      this.actions$.pipe(
        ofType(insertPageTotalElement),
        tap((action) => {
          this.store.dispatch(clearPages());
          this.store.dispatch(loadPages({ pages: [ { id: 1,  totalElements: action.totalElement } ] }));
        })
      ),
    { dispatch: false }
  );

  insertPage$ = createEffect(
    () =>  this.actions$.pipe(
        ofType(insertPage),
        tap((action) => {
          this.store.dispatch(clearPages());
          this.store.dispatch(loadPages({
            pages: [
            {
              id: 1,
              totalElements: action.page?.totalElements,
              numberOfPages: action.page?.numberOfPages,
              hasNextPage: action.page?.hasNextPage
            }
            ] }));
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store<AppState>) {}

}
