import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Project} from '../../../../store/entities/projects/project/project.model';
import {ProjectComponent} from '../../../../store/entities/projects/project-component/project-component.model';
import {ProjectStatusEnum} from '../../../enums/data.enums';
import {
  getProjectChallengeByProjectUid
} from '../../../../store/entities/projects/project-challenge/project-challenge.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {Observable} from 'rxjs';
import {ProjectChallenge} from '../../../../store/entities/projects/project-challenge/project-challenge.model';
import {
  selectAllProjectChallenges
} from '../../../../store/entities/projects/project-challenge/project-challenge.selectors';

@Component({
  selector: 'app-project-report',
  templateUrl: './project-report.component.html',
  styleUrls: ['./project-report.component.scss']
})
export class ProjectReportComponent implements OnInit {

  title: string;
  today = new Date();
  project: Project;
  challenges$: Observable<ProjectChallenge[]>;
  projectStatus = ProjectStatusEnum;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.project = data;
    this.store.dispatch(getProjectChallengeByProjectUid({projectUid: this.project?.uniqueId}));
  }

  ngOnInit(): void {
    this.title = 'Project Progress';
    this.challenges$ = this.store.select(selectAllProjectChallenges);
  }

  countProjectComponentByStatus(components: ProjectComponent[], status: ProjectStatusEnum): number{
    return components?.filter( (r) => r?.status === status)?.length;
  }
}
