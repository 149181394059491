import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {SettingsService} from 'src/app/services/settings.service';
import {User} from 'src/app/store/entities/settings/user/user.model';
import {AppState} from 'src/app/store/entities';
import {getAllUsers} from '../../../../store/entities/settings/user/user.actions';
import {selectAllUsers, selectUserByUsername} from '../../../../store/entities/settings/user/user.selectors';
import {directivesMovement} from '../../../../store/entities/meetings/directive/directive.actions';
import {Directive, DirectiveMovementType} from '../../../../store/entities/meetings/directive/directive.model';
import {Decision} from '../../../../store/entities/meetings/decision/decision.model';
import {StorageService} from '../../../../services/storage-service.service';
import {AuthUser} from '../../../../store/entities/auth-user/auth-user.model';

@Component({
  selector: 'app-directive-movement-form',
  templateUrl: './directive-movement-form.component.html',
  styleUrls: ['./directive-movement-form.component.scss']
})
export class DirectiveMovementFormComponent implements OnInit, OnDestroy {

  title: string;
  form: UntypedFormGroup;
  directive: Directive;
  decision: Decision;
  users$: Observable<User[]>;
  movementType: DirectiveMovementType;
  subscriptions: Subscription = new Subscription();
  loggedInUSer: AuthUser;
  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private settingService: SettingsService,
    private storageService: StorageService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.directive = data?.directive;
    this.movementType = data?.movementType;
    this.loggedInUSer = this.storageService.get('account');
    this.store.dispatch(getAllUsers({ userUid: null}));
  }

  ngOnInit(): void {
    this.title = 'Directive Movement Form';

    this.form = this.fb.group({
      responder_directives: [this.directive?.id.toString(), Validators.required],
      movement_type: [this.movementType, Validators.required],
      responder_transfer_to: [null, Validators.required],
      responder_received_from: [null],
    });

    this.users$ = this.store.select(selectAllUsers);
    this.subscriptions.add(
      this.store.select(selectUserByUsername(this.loggedInUSer.username)).subscribe( data => {
        if (data){
          this.form.get('responder_received_from').patchValue(data?.id.toString());
        }
      })
    );
  }

  submitForm(formData): void {
    this.store.dispatch(directivesMovement({ formData }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
