import gql from 'graphql-tag';
import {responseField} from '../../base.graphql';
import {userField} from "../../settings/user/user.graphql";

export const representativeField = `
id
uuid
representativeLetterPath
respresentativeUser {
  ${userField}
}
absentUser {
  ${userField}
}
`;

export const GET_MEETING_REPRESENTATIVES = gql`
query GetMeetingRepresentatives($meetingUniqueId: String) {
  getMeetingRepresentatives(meetingUniqueId: $meetingUniqueId) {
    response {
      ${responseField}
    }
    data {
      ${representativeField}
    }
  }
}
`;


export const ADD_MEETING_REPRESENTATIVES = gql`
mutation AddMeetingRepresentatives($input: MeetingRepresentativeInputObject) {
  addMeetingRepresentatives(input: $input) {
    response {
      ${responseField}
    }
    data {
      ${representativeField}
    }
  }
}
`;


export const ADD_MINISTRY_MEETING_REPRESENTATIVES = gql`
  mutation MinistryAddMeetingRepresentativesMutation($input: MeetingRepresentativeInputObject) {
    ministryAddMeetingRepresentativesMutation(input: $input) {
    response {
      ${responseField}
    }
    data {
      ${representativeField}
    }
  }
}
`;


export const REMOVE_REPRESENTATIVE = gql`
mutation RemoveRepresentative($uniqueId: String) {
  removeRepresentative(uniqueId: $uniqueId) {
    response {
      ${responseField}
    }
  }
}
`
