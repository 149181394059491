import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {Apollo} from 'apollo-angular';
import {GET_ALL_TRANSLATIONS} from '../store/entities/settings/translation/translation.graphql';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslateLoader implements TranslateLoader {


  constructor(
    private apollo: Apollo,
  ) {}

  getTranslation(lang: string): Observable<any> {
    return new Observable<any>((observer) => {
      this.apollo.query({
        query: GET_ALL_TRANSLATIONS
      }).toPromise()
        .then((response: any) => {
          let result: any = Object.values(response)[0];
          result = Object.values(result)[0];
          if (result?.response?.code === 9000) {
            const translationObj = {};
            result?.data.forEach(translation => {
              translationObj[translation.keyword] = translation[lang];
            });
            observer.next(translationObj);
          } else {
            observer.next({});
          }
          observer.complete();
        })
        .catch((error: any) => {
          observer.error(error);
          observer.complete();
        });
    });
  }
}
