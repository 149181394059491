import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {Directive} from './directive.model';

export const loadDirectives = createAction(
  '[Directive/API] Load Directives',
  props<{ directives: Directive[] }>()
);

export const addDirective = createAction(
  '[Directive/API] Add Directive',
  props<{ directive: Directive }>()
);

export const upsertDirective = createAction(
  '[Directive/API] Upsert Directive',
  props<{ directive: Directive }>()
);

export const addDirectives = createAction(
  '[Directive/API] Add Directives',
  props<{ directives: Directive[] }>()
);

export const upsertDirectives = createAction(
  '[Directive/API] Upsert Directives',
  props<{ directives: Directive[] }>()
);

export const updateDirective = createAction(
  '[Directive/API] Update Directive',
  props<{ directive: Update<Directive> }>()
);

export const updateDirectives = createAction(
  '[Directive/API] Update Directives',
  props<{ directives: Update<Directive>[] }>()
);

export const deleteDirective = createAction(
  '[Directive/API] Delete Directive',
  props<{ id: number }>()
);

export const deleteDirectives = createAction(
  '[Directive/API] Delete Directives',
  props<{ ids: number[] }>()
);

export const clearDirectives = createAction(
  '[Directive/API] Clear Directives'
);


export const actionDirectives = createAction(
  '[Directive/API] Save / edit / delete Directives',
  props<{ formData: any }>()
);
export const directivesMovement = createAction(
  '[Directive/API] movement Directives',
  props<{ formData: any }>()
);

export const saveDirectiveResponse = createAction(
  '[Directive/API] Save Directives response',
  props<{ formData: any }>()
);
export const getDirectiveByCommittee = createAction(
  '[Directive/API] get Directives by committee',
  props<{ uid: string }>()
);

export const getAllDirectives = createAction(
  '[Directive/API] get all active Directives'
);
