import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'strLimit'
})
export class StrLimitPipe implements PipeTransform {

  transform(value: string, wordCount: number, limit: number): string {
    return value.length > wordCount ? value.slice(0, limit) + '...' : value;
  }

}
