import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {DocumentationFile} from './documentation-file.model';
import * as DocumentationFileActions from './documentation-file.actions';

export const documentationFilesFeatureKey = 'documentationFile';

export interface State extends EntityState<DocumentationFile> {
  // additional entities state properties
}

export const adapter: EntityAdapter<DocumentationFile> = createEntityAdapter<DocumentationFile>({
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const DocumentationFileFileReducer = createReducer(
  initialState,
  on(DocumentationFileActions.addDocumentationFile,
    (state, action) => adapter.addOne(action.documentationFile, state)
  ),
  on(DocumentationFileActions.upsertDocumentationFile,
    (state, action) => adapter.upsertOne(action.documentationFile, state)
  ),
  on(DocumentationFileActions.addDocumentationFiles,
    (state, action) => adapter.addMany(action.documentationFiles, state)
  ),
  on(DocumentationFileActions.upsertDocumentationFiles,
    (state, action) => adapter.upsertMany(action.documentationFiles, state)
  ),
  on(DocumentationFileActions.updateDocumentationFile,
    (state, action) => adapter.updateOne(action.documentationFile, state)
  ),
  on(DocumentationFileActions.updateDocumentationFiles,
    (state, action) => adapter.updateMany(action.documentationFiles, state)
  ),
  on(DocumentationFileActions.deleteDocumentationFile,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(DocumentationFileActions.deleteDocumentationFiles,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(DocumentationFileActions.loadDocumentationFiles,
    (state, action) => adapter.setAll(action.documentationFiles, state)
  ),
  on(DocumentationFileActions.clearDocumentationFiles,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return DocumentationFileFileReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getDocumentationFiles = selectAll;
