import {inject, Injectable, OnDestroy} from '@angular/core';
import {DatePipe} from '@angular/common';
import {DialogPosition, MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {lastValueFrom, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {TranslateService} from '@ngx-translate/core';
import {IndexedDBTableName, TableColumn} from '../shared/interfaces/table.interface';
import {Translation} from '../store/entities/settings/translation/translation.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService implements OnDestroy {

  subscription: Subscription = new Subscription();

  dbService = inject(NgxIndexedDBService);
  dialog = inject(MatDialog);
  translate = inject(TranslateService);
  router = inject(Router);

  openModal(data: any, component: any, width?: string, panelClass?: string[], position?: DialogPosition, height?: string) {
    const dialogConfig = new MatDialogConfig();
    const classes: string[] = [];
    if (panelClass?.length > 0) {
      classes.push(...panelClass);
    }
    const customClasses = classes?.length > 0 ? classes : ['custom-modal-dialog'];
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = width ? width : '60%';
    dialogConfig.data = data;
    dialogConfig.panelClass = customClasses;
    if (position != null) { dialogConfig.position = position; }
    if (height != null) { dialogConfig.height = height; }
    return this.dialog.open(component, dialogConfig);
  }

  reloadCurrentRoute(currentUrl: string | null) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    currentUrl = currentUrl === null ? this.router.url : currentUrl;
    this.router.navigate([currentUrl]);
  }

  dateRangeFormat(startDate, endDate, format) {
    return new DatePipe('en-Gb').transform(startDate, format) +
      ' to ' + new DatePipe('en-Gb').transform(endDate, format);
  }

  generatePagination(current: number, lastPage: number) {
    // return  Array((totalPages === 1) ? 1 : totalPages).fill(0)?.map((x, i) => ({id: i}));
    const delta = 6;
    const range = [0];
    const rangeWithDots = [];
    let l;

    if (current === 1 && lastPage === 1){
      return [current];
    }

    if (lastPage > 1) {
      lastPage = lastPage - 1;
    }

    range.push(1);
    for (let i = current - delta; i <= current + delta; i++) {
      if (i < lastPage && i > 1) {
        range.push(i);
      }
    }
    range.push(lastPage);

    for (const i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }
    return Array.from(new Set(rangeWithDots));
  }


  loadTranslation(){
    lastValueFrom(
      this.dbService.getAll(IndexedDBTableName.translation)
    ).then( (translations: Translation[]) => {
      if (translations){

        const enObj = {};
        const swObj = {};

        translations?.forEach( (translation) => {
          if (enObj[translation?.keyword]){
            // console.log('English translation with keyword ', translation?.keyword, ' already exist');
          }else {
            Object.defineProperty(enObj, translation?.keyword , {value : translation?.en});
          }
        });

        translations?.forEach( (translation) => {
          if (swObj[translation?.keyword]){
            // console.log('Swahili translation with keyword ', translation?.keyword, ' already exist');
          }else {
            Object.defineProperty(swObj, translation?.keyword , {value : translation?.sw});
          }
        });

        this.translate.setTranslation('en', enObj);
        this.translate.setTranslation('sw', swObj);
      }
    });
  }

  getTableObjectValue(object: any, keyword: string) {
    const keyArray = keyword?.split('.');
    let value = object;

    if (keyArray?.length > 0) {
      keyArray?.forEach( (key) => {
        if (value != null){
          if (Object?.keys(value)?.findIndex( k => k === key) > -1){
            value = value[key];
          } else {
            return null;
          }
        }
      });
    }
    return value;
  }

  switchLang(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
  }

  getTableColumnKeywords(cacheTableColumns: TableColumn[]) {
    return cacheTableColumns?.sort( (a, b) => a.id - b.id)?.map( d => d?.keyword);
  }

  getStartDate(){
    return new DatePipe('en-Gb').transform( new Date().setUTCFullYear(2020), 'yyyy-MM-dd');
    // return new DatePipe('en-Gb').transform( new Date().setUTCFullYear(new Date().getFullYear() - 1), 'yyyy-MM-dd');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

