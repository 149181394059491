import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Page} from './page.model';
import * as PageActions from './page.actions';

export const pagesFeatureKey = 'pages';

export interface State extends EntityState<Page> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Page> = createEntityAdapter<Page>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(PageActions.addPage,
    (state, action) => adapter.addOne(action.page, state)
  ),
  on(PageActions.upsertPage,
    (state, action) => adapter.upsertOne(action.page, state)
  ),
  on(PageActions.addPages,
    (state, action) => adapter.addMany(action.pages, state)
  ),
  on(PageActions.upsertPages,
    (state, action) => adapter.upsertMany(action.pages, state)
  ),
  on(PageActions.updatePage,
    (state, action) => adapter.updateOne(action.page, state)
  ),
  on(PageActions.updatePages,
    (state, action) => adapter.updateMany(action.pages, state)
  ),
  on(PageActions.deletePage,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(PageActions.deletePages,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(PageActions.loadPages,
    (state, action) => adapter.setAll(action.pages, state)
  ),
  on(PageActions.clearPages,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
