<app-modal-header  [title]="title"></app-modal-header>

<form (ngSubmit)="add(form.value)" [formGroup]="form"  autocomplete="off" novalidate>
  <div class="row" *ngIf="(attachmentConfigs$ | async)?.length; else noAttachmentConfiguration">
    <div class="col-12 my-3">
      <app-attachment-form-field
                                 (uploadedFiles)="getUploadedFiles($event)"
                                 [editedAttachments]="[]"
                                 [documentType]="'PROJECT'"
                                 [attachmentRequiredFor]="'PROJECTREPOSITORY'">
      </app-attachment-form-field>


    </div>

    <div class="col-md-12 text-end">
        <button [disabled]="!form.valid" color="primary" mat-raised-button type="submit"> {{'Save' | concat | translate}} </button>
    </div>
  </div>

  <ng-template #noAttachmentConfiguration>
    <div>
      {{ 'No Attachment Configuration Available' | concat | translate }}
    </div>
  </ng-template>
</form>
