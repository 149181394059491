import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {
  createCommittee,
  deleteCommittee, editCommittee,
  getAllCommittees,
  loadCommittees, removeCommittee,
  upsertCommittee
} from "./committee.actions";
import {map, switchMap} from 'rxjs/operators';
import { CREATE_COMMITTEE, DELETE_COMMITTEE, GET_COMMITTEE, UPDATE_COMMITTEE } from "./committee.graphql";
import {Apollo} from 'apollo-angular';
import {ActionTypeEnum} from '../../../../shared/enums/data.enums';
import {SettingsService} from '../../../../services/settings.service';

@Injectable()
export class CommitteeEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllCommittees),
    switchMap(() => {
      return this.apollo.query({
        query: GET_COMMITTEE
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.store.dispatch(loadCommittees({committees: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(createCommittee),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: CREATE_COMMITTEE,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(upsertCommittee({committee: result?.data}));
            // this.settingsService.reloadCurrentRoute(null);

          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editCommittee),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_COMMITTEE,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(upsertCommittee({committee: result?.data}));

          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeCommittee),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_COMMITTEE,
        variables: { commiteeUniqueId: action.uniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(deleteCommittee({ id: action?.id }));

          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apollo: Apollo,
    private notificationService: NotificationService,
    private settingsService: SettingsService
  ) {
  }
}
