import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {AssignmentConfiguration} from './assignment-configuration.model';
import * as AssignmentConfigurationActions from './assignment-configuration.actions';

export const assignmentConfigurationsFeatureKey = 'AssignmentConfigurations';

export interface State extends EntityState<AssignmentConfiguration> {
  // additional entities state properties
}
//
export function sortByAssignmentConfigurationName(a: AssignmentConfiguration, b: AssignmentConfiguration): any {
  // return a?.name.localeCompare(b?.name);
}

export const adapter: EntityAdapter<AssignmentConfiguration> = createEntityAdapter<AssignmentConfiguration>({
  // sortComparer: sortByAssignmentConfigurationName
});

// export const adapter: EntityAdapter<AssignmentConfiguration> = createEntityAdapter<AssignmentConfiguration>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const assignmentConfigurationReducer = createReducer(
  initialState,
  on(AssignmentConfigurationActions.addAssignmentConfiguration,
    (state, action) => adapter.addOne(action.assignmentConfiguration, state)
  ),
  on(AssignmentConfigurationActions.upsertAssignmentConfiguration,
    (state, action) => adapter.upsertOne(action.assignmentConfiguration, state)
  ),
  on(AssignmentConfigurationActions.addAssignmentConfigurations,
    (state, action) => adapter.addMany(action.assignmentConfigurations, state)
  ),
  on(AssignmentConfigurationActions.upsertAssignmentConfigurations,
    (state, action) => adapter.upsertMany(action.assignmentConfigurations, state)
  ),
  on(AssignmentConfigurationActions.updateAssignmentConfiguration,
    (state, action) => adapter.updateOne(action.assignmentConfiguration, state)
  ),
  on(AssignmentConfigurationActions.updateAssignmentConfigurations,
    (state, action) => adapter.updateMany(action.assignmentConfigurations, state)
  ),
  on(AssignmentConfigurationActions.deleteAssignmentConfiguration,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AssignmentConfigurationActions.deleteAssignmentConfigurations,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AssignmentConfigurationActions.loadAssignmentConfigurations,
    (state, action) => adapter.setAll(action.assignmentConfigurations, state)
  ),
  on(AssignmentConfigurationActions.clearAssignmentConfigurations,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return assignmentConfigurationReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllAssignmentConfigurations = selectAll;
export const getAssignmentConfigurationById = selectIds;
export const getAssignmentConfigurationEntities = selectEntities;
