import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {Subscription} from 'rxjs';
import {StorageService} from '../../../../services/storage-service.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {sendResetPasswordLink} from '../../../../store/entities/settings/user/user.actions';
import {WebAuthService} from '../../../../services/web-auth.service';
import {SettingsService} from '../../../../services/settings.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  currentYear: any;
  staffLoginForm: UntypedFormGroup;
  loading = false;
  subscription: Subscription = new Subscription();
  hide: boolean;
  resetPwdForm: UntypedFormGroup;
  passwordHide = true;

  username: string = null;

  deviceId: string;
  serialNumber: string;

  coordinateLatitude: number;
  coordinateLongitude: number;
  coordinateFound = true;
  coordinateErrorMessage: string;

  constructor(
    private fb: UntypedFormBuilder,
    private route: Router,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private webAuthService: WebAuthService,
    private settingsService: SettingsService,
    private store: Store<AppState>,
  ) {
    this.hide = true;
    this.username = this.activatedRoute.snapshot.paramMap.get('email');
    this.deviceId = this.activatedRoute.snapshot.queryParams.deviceId;
    this.serialNumber = this.activatedRoute.snapshot.queryParams.serialNumber;

    if (this.deviceId){
      this.storageService.set('deviceId', this.deviceId);
    }

    if (this.serialNumber){
      this.storageService.set('serialNumber', this.serialNumber);
    }

    /*get Geolocation*/
    navigator.geolocation.getCurrentPosition((position) => {
      if (position?.coords?.latitude !== null && position?.coords?.longitude) {
        this.coordinateLatitude = position?.coords?.latitude;
        this.coordinateLongitude = position?.coords?.longitude;

        this.storageService.set('coordLa', this.coordinateLatitude);
        this.storageService.set('coordLo', this.coordinateLongitude);

        this.coordinateFound = true;

      }else {
        this.coordinateErrorMessage = 'Kindly enable location on your browser';
      }
    }, (err) => {
      console.error(err);
      this.coordinateErrorMessage = 'Error on Device location detector found (' + err.message + '), Kindly enable location on your browser';
    });

  }

  ngOnInit(): void {
    // this.webAuthService.registerUser('ict@gov.go.tz').then();
    // this.webAuthService.authenticateUser('ict@gov.go.tz').then();

    // this.settingsService.openModal({
    //   ticket: 'tokenDetails?.ticket',
    //   userEmail: 'ict@gov.go.tz',
    //   totp: true,
    //   passkey: true
    // }, OfflineOnlineSelectComponent, '30%');

    this.currentYear = new Date().getFullYear();
    this.staffLoginForm = this.fb.group({
      username: [this.username, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    });
    this.resetPwdForm = this.fb.group({
      email: [null]
    });
  }

  async login() {
    this.loading = true;

    try {

      if (this.staffLoginForm.get('username').valid && this.staffLoginForm.get('password').valid){
        await this.auth.login( this.staffLoginForm.get('username').value, this.staffLoginForm.get('password').value);

        this.storageService.set('username', this.staffLoginForm.get('username').value);

        await this.auth.authRole();
        const category = this.storageService.get('category');
        if (category){
          await this.route.navigate(['', 'home']);
        } else {
          await this.route.navigate(['', 'login']);
        }
      }
    } catch (e) {
    }
    this.loading = false;
  }


  toggleLoginPassword() {
    if (this.hide) {
      this.hide = false;
      this.resetPwdForm.controls.email.setValidators([Validators.required, Validators.email]);
    } else {
      this.hide = true;
      this.resetPwdForm.controls.email.clearValidators();
    }
    this.resetPwdForm.controls.email.updateValueAndValidity();
  }

  submitResetPassword(formData: any) {
    this.store.dispatch(sendResetPasswordLink( {email: formData.email}));
  }

}
