import {Injectable} from '@angular/core';
import * as SecureLS from 'secure-ls';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private ls = new SecureLS({ encodingType: 'aes', isCompression: true });

  constructor() { }

  set(key: string, value: any, expired: number = 0) {
    this.ls.set(key, value);
  }

  remove(key: string) {
    this.ls.remove(key);
  }

  get(key: string) {
    return this.ls.get(key);
  }

  clear() {
    this.ls.removeAll();
  }
}
