import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {getAllPermissions, loadPermissions} from './permission.actions';
import {Apollo} from 'apollo-angular';
import {GET_PERMISSION} from './permission.graphql';


@Injectable()
export class PermissionEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllPermissions),
    switchMap(() => {
      return this.apollo.query({
        query: GET_PERMISSION
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadPermissions({permissions: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apollo: Apollo
  ) {
  }
}
