import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ProjectImage} from './project-image.model';
import * as ProjectImageActions from './project-image.actions';

export const projectImagesFeatureKey = 'ProjectImages';

export interface State extends EntityState<ProjectImage> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ProjectImage> = createEntityAdapter<ProjectImage>();

// export const adapter: EntityAdapter<ProjectImage> = createEntityAdapter<ProjectImage>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const projectImageImageReducer = createReducer(
  initialState,
  on(ProjectImageActions.addProjectImage,
    (state, action) => adapter.addOne(action.projectImage, state)
  ),
  on(ProjectImageActions.upsertProjectImage,
    (state, action) => adapter.upsertOne(action.projectImage, state)
  ),
  on(ProjectImageActions.addProjectImages,
    (state, action) => adapter.addMany(action.projectImages, state)
  ),
  on(ProjectImageActions.upsertProjectImages,
    (state, action) => adapter.upsertMany(action.projectImages, state)
  ),
  on(ProjectImageActions.updateProjectImage,
    (state, action) => adapter.updateOne(action.projectImage, state)
  ),
  on(ProjectImageActions.updateProjectImages,
    (state, action) => adapter.updateMany(action.projectImages, state)
  ),
  on(ProjectImageActions.deleteProjectImage,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ProjectImageActions.deleteProjectImages,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ProjectImageActions.loadProjectImages,
    (state, action) => adapter.setAll(action.projectImages, state)
  ),
  on(ProjectImageActions.clearProjectImages,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return projectImageImageReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllProjectImages = selectAll;
export const getProjectImageById = selectIds;
export const getProjectImageEntities = selectEntities;
