import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ProjectChallenge} from './project-challenge.model';
import * as ProjectChallengeActions from './project-challenge.actions';

export const projectChallengesFeatureKey = 'ProjectChallenges';

export interface State extends EntityState<ProjectChallenge> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ProjectChallenge> = createEntityAdapter<ProjectChallenge>();

// export const adapter: EntityAdapter<ProjectChallenge> = createEntityAdapter<ProjectChallenge>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const projectChallengeChallengeReducer = createReducer(
  initialState,
  on(ProjectChallengeActions.addProjectChallenge,
    (state, action) => adapter.addOne(action.projectChallenge, state)
  ),
  on(ProjectChallengeActions.upsertProjectChallenge,
    (state, action) => adapter.upsertOne(action.projectChallenge, state)
  ),
  on(ProjectChallengeActions.addProjectChallenges,
    (state, action) => adapter.addMany(action.projectChallenges, state)
  ),
  on(ProjectChallengeActions.upsertProjectChallenges,
    (state, action) => adapter.upsertMany(action.projectChallenges, state)
  ),
  on(ProjectChallengeActions.updateProjectChallenge,
    (state, action) => adapter.updateOne(action.projectChallenge, state)
  ),
  on(ProjectChallengeActions.updateProjectChallenges,
    (state, action) => adapter.updateMany(action.projectChallenges, state)
  ),
  on(ProjectChallengeActions.deleteProjectChallenge,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ProjectChallengeActions.deleteProjectChallenges,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ProjectChallengeActions.loadProjectChallenges,
    (state, action) => adapter.setAll(action.projectChallenges, state)
  ),
  on(ProjectChallengeActions.clearProjectChallenges,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return projectChallengeChallengeReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllProjectChallenges = selectAll;
export const getProjectChallengeById = selectIds;
export const getProjectChallengeEntities = selectEntities;
