import gql from 'graphql-tag';
import {responseField} from '../../base.graphql';

export const attachmentConfigField = `
  id
  attachmentUniqueId
  attachmentName
  attachmentRequiredFor
  attachmentIsMandatory
`;

export const GET_ATTACHMENT_CONFIG = gql`
  query getPapersRequiredAttachments {
    getPapersRequiredAttachments {
      response{
        ${responseField}
      }
      data{
        ${attachmentConfigField}
      }
    }
  }
`;

export const SAVE_ATTACHMENT_CONFIG = gql`
  mutation createPaperRequiredAttachments($inputDto: PaperRequiredAttachmentsInputObjects!) {
    createPaperRequiredAttachments(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${attachmentConfigField}
      }
    }
  }
`;

export const UPDATE_ATTACHMENT_CONFIG = gql`
  mutation updatePaperRequiredAttachments($inputDto: PaperRequiredAttachmentsInputObjects!) {
    updatePaperRequiredAttachments(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${attachmentConfigField}
      }
    }
  }
`;

export const DELETE_ATTACHMENT_CONFIG = gql`
  mutation deletePaperRequiredAttachments($uid: String) {
    deletePaperRequiredAttachments(attachmentUniqueId: $uid){
      response{
        id
        ${responseField}
      }
    }
  }
`;
