import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-date-range-form-field',
  templateUrl: './date-range-form-field.component.html',
  styleUrls: ['./date-range-form-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateRangeFormFieldComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class DateRangeFormFieldComponent implements OnInit, OnDestroy {

  @Input() label = 'select-date-range';
  @Input() isCustomField = true;
  @Input() isFullWidth = false;

  startDate = new DatePipe('en-Gb').transform(new Date().setUTCFullYear(new Date().getFullYear() - 1), 'yyyy-MM-dd');

  endDate = new DatePipe('en-Gb').transform(new Date(), 'yyyy-MM-dd');
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });

  subscriptions: Subscription = new Subscription();

  @Output() selectedValue = new EventEmitter<{ startDate: string, endDate: string }>();

  protected onDestroy = new Subject<void>();

  constructor(
  ) {
  }

  ngOnInit() {
    this.selectedValue.emit({ startDate: this.startDate, endDate: this.endDate });

    this.subscriptions.add(
      this.range.valueChanges.subscribe(dates => {
        if (dates?.start && dates?.end) {
          this.startDate = new DatePipe('en-Gb').transform(dates?.start, 'yyyy-MM-dd');
          this.endDate = new DatePipe('en-Gb').transform(dates?.end, 'yyyy-MM-dd');

          this.selectedValue.emit({ startDate: this.startDate, endDate: this.endDate });
        }
      })
    );

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  generateDateRange(days: number) {

    this.startDate = new DatePipe('en-Gb').transform(
      new Date().setDate(new Date().getDate() - days), 'yyyy-MM-dd');

    this.endDate = new DatePipe('en-Gb').transform(new Date(), 'yyyy-MM-dd');

    this.selectedValue.emit({ startDate: this.startDate, endDate: this.endDate });
  }
}
