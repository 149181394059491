<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-header-title d-flex" mat-dialog-title>
  <div cdkDragHandle class="title">
    @if (isTranslate) {
      {{ title | concat | translate }}
    } @else {
      {{ title }}
    }
    <div class="handle"></div>
  </div>
  <span class="close-modal" mat-dialog-close><mat-icon class="material-symbols-outlined">close</mat-icon></span>
</div>
