import {DBConfig} from 'ngx-indexed-db';
import {IndexedDBTableName} from '../interfaces/table.interface';
import {environment} from '../../../environments/environment';

export const INDEX_DB_CONFIGURATION: DBConfig = {
  name: 'Nyerere',
  version: environment.INDEXED_DB_VERSION,
  objectStoresMeta: [
    {
      store: IndexedDBTableName.advance_table_search,
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'route', keypath: 'route', options: { unique: true } },
        { name: 'pageableParam', keypath: 'pageableParam', options: { unique: false } }
      ]
    },
    {
      store: IndexedDBTableName.translation,
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'keyword', keypath: 'keyword', options: { unique: true } },
        { name: 'en', keypath: 'en', options: { unique: false } },
        { name: 'sw', keypath: 'sw', options: { unique: false } }
      ]
    },
    {
      store: IndexedDBTableName.table_column,
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'route', keypath: 'route', options: { unique: true } },
        { name: 'columns', keypath: 'columns', options: { unique: false } },
      ]
    },
    {
      store: IndexedDBTableName.active_meeting,
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'data', keypath: 'data', options: { unique: true } },
      ]
    },
    {
      store: IndexedDBTableName.active_meeting_agenda,
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'data', keypath: 'data', options: { unique: true } },
      ]
    },
    {
      store: IndexedDBTableName.active_meeting_agenda_paper,
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'agenda', keypath: 'agenda', options: { unique: false } },
        { name: 'data', keypath: 'data', options: { unique: true } },
      ]
    }
  ]
};
