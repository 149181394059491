import {inject, Injectable} from '@angular/core';
import {DialogPosition, MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {lastValueFrom} from 'rxjs';
import {DialogData} from '../shared/interfaces/data.interfaces';
import {
  ConfirmationDialogComponent
} from '../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  dialogConfig = new MatDialogConfig();
  classes: string[] = [];

  dialog = inject(MatDialog);

  openModal(
    input: {
      data: any,
      component: any,
      width?: string,
      panelClass?: string[],
      position?: DialogPosition,
      height?: string
    }
  ) {
    if (input?.panelClass?.length > 0) {
      this.classes.push(...input?.panelClass);
    }
    const customClasses = this.classes?.length > 0 ? this.classes : ['custom-modal-dialog'];
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = input?.width ? input?.width : '60%';
    this.dialogConfig.data = input?.data;
    this.dialogConfig.panelClass = customClasses;
    if (input?.position != null) { this.dialogConfig.position = input?.position; }
    if (input?.height != null) { this.dialogConfig.height = input?.height; }
    this.dialog.open(input?.component, this.dialogConfig);
  }

  confirmation(input?: DialogData, config?: { width?: string, panelClass?: string[], position?: DialogPosition, height?: string }) {
    if (config?.panelClass?.length > 0) {
      this.classes.push(...config?.panelClass);
    }

    let width = config?.width ? config?.width : '20%';

    if (input.isForm){
      width = '35%';
    }
    const customClasses = this.classes?.length > 0 ? this.classes : ['confirmation-modal-dialog'];
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = width;
    this.dialogConfig.data = input;
    this.dialogConfig.panelClass = customClasses;
    if (config?.position != null) { this.dialogConfig.position = config?.position; }
    if (config?.height != null) { this.dialogConfig.height = config?.height; }

    return lastValueFrom(
      this.dialog.open(ConfirmationDialogComponent, this.dialogConfig).afterClosed()
    );
  }

  open(
    input: {
      data: any,
      component: any,
      width?: string,
      panelClass?: string[],
      position?: DialogPosition,
      height?: string
    }
  ) {
    if (input?.panelClass?.length > 0) {
      this.classes.push(...input?.panelClass);
    }
    const customClasses = this.classes?.length > 0 ? this.classes : ['custom-modal-dialog'];
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = input?.width ? input?.width : '60%';
    this.dialogConfig.data = input?.data;
    this.dialogConfig.panelClass = customClasses;
    if (input?.position != null) { this.dialogConfig.position = input?.position; }
    if (input?.height != null) { this.dialogConfig.height = input?.height; }

    return lastValueFrom(
      this.dialog.open(input?.component, this.dialogConfig).afterClosed()
    );
  }

}

