import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Attendance} from './attendance.model';
import * as AttendanceActions from './attendance.actions';

export const attendancesFeatureKey = 'attendance';

export interface State extends EntityState<Attendance> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Attendance> = createEntityAdapter<Attendance>({
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const AttendanceReducer = createReducer(
  initialState,
  on(AttendanceActions.addAttendance,
    (state, action) => adapter.addOne(action.attendance, state)
  ),
  on(AttendanceActions.upsertAttendance,
    (state, action) => adapter.upsertOne(action.attendance, state)
  ),
  on(AttendanceActions.addAttendances,
    (state, action) => adapter.addMany(action.attendances, state)
  ),
  on(AttendanceActions.upsertAttendances,
    (state, action) => adapter.upsertMany(action.attendances, state)
  ),
  on(AttendanceActions.updateAttendance,
    (state, action) => adapter.updateOne(action.attendance, state)
  ),
  on(AttendanceActions.updateAttendances,
    (state, action) => adapter.updateMany(action.attendances, state)
  ),
  on(AttendanceActions.deleteAttendance,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AttendanceActions.deleteAttendances,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AttendanceActions.loadAttendances,
    (state, action) => adapter.setAll(action.attendances, state)
  ),
  on(AttendanceActions.clearAttendances,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return AttendanceReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getAttendances = selectAll;
