<app-page-title [subTitle]="subTitle" [title]="title" isBoldedTitle="true">
  <ng-template>
    <!-- INNER LINKS -->
    <div class="row">
      <div class="col-md-12">
          <button (click)="download(null)" class="btn-sm" color="primary" mat-raised-button>
            <mat-icon class="material-symbols-outlined">download</mat-icon>
            {{'Download Report' | concat | translate}}
          </button>
      </div>
    </div>
    <!-- End of Inner Links -->
  </ng-template>
</app-page-title>


<div class="row my-4">
  <div class="col-md-12">
    <h5>{{'Paper Summary' | concat | translate}}</h5>
      <div class="row">
        <div class="ext-container col-md-12">
          <mat-card class="inst-details">
            <div class="row">

              <div class="col-md-4 my-2">
                <span class="title">{{'Paper No' | concat | translate}}</span>
                <div>here</div>
              </div>

              <div class="col-md-4 my-2">
                <span class="title">File No</span>
                <div>here</div>
              </div>

              <div class="col-md-4 my-2">
                <span class="title">Paper Title</span>
                <div>title here</div>
              </div>

              <div class="col-md-4 my-2">
                <span class="title"> Ministry</span>
                <div> here</div>
              </div>

              <div class="col-md-4 my-2">
                <span class="title">Category</span>
                <div> Fedha</div>
              </div>

            </div>
          </mat-card>
        </div>
      </div>
  </div>
</div>


<div class="row my-4">
  <div class="col-md-12">
    <h5>Meetings</h5>

    <div class="col-md-12 px-0 mat-elevation-z1 pb-3 mb-3">
      <table class="table align-middle custom-table" matSort>
        <thead class="table w-100">
          <tr>
            <th>No.</th>
            <th>Responsible</th>
            <th>Meeting Date</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
</div>


<div class="row my-4">
  <div class="col-md-12">
    <h5>Activity Progress</h5>

    <div class="col-md-12 px-0 mat-elevation-z1 pb-3 mb-3">
      <table class="table align-middle custom-table" matSort>
        <thead class="table w-100">
          <tr>
            <th>No.</th>
            <th>Description</th>
            <th>Challenge</th>
            <th>Action Taken</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
</div>

