import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  deleteProjectProgress,
  editProjectProgress,
  removeProjectProgress,
  saveProjectProgress,
  upsertProjectProgress
} from './project-progress.actions';
import {map, switchMap} from 'rxjs/operators';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {Apollo} from 'apollo-angular';
import {DELETE_PROJECT_PROGRESS, SAVE_PROJECT_PROGRESS, UPDATE_PROJECT_PROGRESS} from './project-progress.graphql';

@Injectable()
export class ProjectProgressEffects {

  save = createEffect(() => this.actions$.pipe(
    ofType(saveProjectProgress),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_PROJECT_PROGRESS,
        variables: { inputDto: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertProjectProgress({projectProgress: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editProjectProgress),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_PROJECT_PROGRESS,
        variables: { inputDto: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertProjectProgress({projectProgress: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  remove = createEffect(() => this.actions$.pipe(
    ofType(removeProjectProgress),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_PROJECT_PROGRESS,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteProjectProgress({ id: action.id }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService
  ) {
  }
}
