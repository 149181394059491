import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import { Committee, CommitteeInput } from "./committee.model";

export const loadCommittees = createAction(
  '[Committee/API] Load Committees',
  props<{ committees: Committee[] }>()
);

export const addCommittee = createAction(
  '[Committee/API] Add Committee',
  props<{ committee: Committee }>()
);

export const upsertCommittee = createAction(
  '[Committee/API] Upsert Committee',
  props<{ committee: Committee }>()
);

export const addCommittees = createAction(
  '[Committee/API] Add Committees',
  props<{ committees: Committee[] }>()
);

export const upsertCommittees = createAction(
  '[Committee/API] Upsert Committees',
  props<{ committees: Committee[] }>()
);

export const updateCommittee = createAction(
  '[Committee/API] Update Committee',
  props<{ committee: Update<Committee> }>()
);

export const updateCommittees = createAction(
  '[Committee/API] Update Committees',
  props<{ committees: Update<Committee>[] }>()
);

export const deleteCommittee = createAction(
  '[Committee/API] Delete Committee',
  props<{ id: number }>()
);

export const deleteCommittees = createAction(
  '[Committee/API] Delete Committees',
  props<{ ids: string[] }>()
);

export const clearCommittees = createAction(
  '[Committee/API] Clear Committees'
);

export const getAllCommittees = createAction(
  '[Committees/API] List All Committees'
);

// export const actionCommittee = createAction(
//   '[Committee/API] create, edit & delete committee',
//   props<{ committeeDto: CommitteeDto, id?: number }>()
// );

export const createCommittee = createAction(
  '[Committee/API] create committee',
  props<{ input: CommitteeInput }>()
);

export const editCommittee = createAction(
  '[Committee/API] edit committee',
  props<{ input: CommitteeInput }>()
);

export const removeCommittee = createAction(
  '[Committee/API] remove committee',
  props<{ uniqueId: string; id: number }>()
);
