import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {SettingsService} from '../../../../services/settings.service';
import {Apollo} from 'apollo-angular';
import {
  deleteDocumentationFile,
  getAllDocumentationFile,
  loadDocumentationFiles,
  removeDocumentationFile,
  saveDocumentationFile,
  upsertDocumentationFile
} from './documentation-file.actions';
import * as fromGraphql from './documentation-file.graphql';

@Injectable()
export class DocumentationFileEffects {


  get = createEffect(() => this.actions$.pipe(
    ofType(getAllDocumentationFile),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_DOCUMENTATION_FILES,
        variables: { filtering: action.filtering }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadDocumentationFiles({ documentationFiles: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });


  save = createEffect(() => this.actions$.pipe(
    ofType(saveDocumentationFile),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.CREATE_DOCUMENTATION_FILE,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertDocumentationFile({ documentationFile: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeDocumentationFile),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.DELETE_DOCUMENTATION_FILE,
        variables: { documentUniqueId: action.uniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessage(result?.response?.message);

            this.store.dispatch(deleteDocumentationFile({ id: action.id }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
  ) { }

}
