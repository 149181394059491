import {Action, ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import * as fromAuthUser from './auth-user/auth-user.reducer';
import * as fromRole from './settings/role/role.reducer';
import * as fromPermissions from './settings/permission/permission.reducer';
import * as fromUsers from './settings/user/user.reducer';
import * as fromAttachment from './settings/attachment/attachment.reducer';
import * as fromPage from './page/page.reducer';
import * as fromDocument from './document/document.reducer';
import * as fromDocumentComment from './document/document-comment/document-comment.reducer';
import * as fromInstitution from './settings/institution/institution.reducer';
import * as fromCommittee from './settings/committee/committee.reducer';
import * as fromFinancialYear from './settings/financial-year/financial-year.reducer';
import * as fromProjectCategory from './settings/project-category/project-category.reducer';
import * as fromDocumentCategory from './settings/document-category/document-category.reducer';
import * as fromDocumentStage from './settings/document-stage/document-stage.reducer';
import * as fromProject from './projects/project/project.reducer';
import * as fromProjectComponent from './projects/project-component/project-component.reducer';
import * as fromProjectComponentTask from './projects/project-component-task/project-component-task.reducer';
import * as fromProjectChallenge from './projects/project-challenge/project-challenge.reducer';
import * as fromProjectRecommendation from './projects/project-recommendation/project-recommendation.reducer';
import * as fromProjectProgress from './projects/project-progress/project-progress.reducer';
import * as fromProjectImage from './projects/project-image/project-image.reducer';
import * as fromDecision from './meetings/decision/decision.reducer';
import * as fromLocation from './settings/location/location.reducer';
import * as fromDirective from './meetings/directive/directive.reducer';
import * as fromForthComing from './document/forthcoming/forthcoming.reducer';
import * as fromDesignation from './settings/designation/designation.reducer';
import * as fromSmsConfig from './settings/sms-config/sms-config.reducer';
import * as fromMailConfig from './settings/mail-config/mail-config.reducer';
import * as fromFolderCategory from './settings/folder-category/folder-category.reducer';
import * as fromScheduledMeeting from './meetings/scheduled-meeting/scheduled-meeting.reducer';
import * as fromMeetingAgenda from './meetings/meeting-agenda/meeting-agenda.reducer';
import * as fromMinutesNotes from './meetings/minutes-notes/minutes-notes.reducer';
import * as fromMinutesDirectives from './meetings/minutes-directives/minutes-directives.reducer';
import * as fromMinutesDecisions from './meetings/minutes-decisions/minutes-decisions.reducer';
import * as fromRecommendations from './meetings/recommendations/recommendations.reducer';
import * as fromDirectiveProgress from './meetings/directives-progress/directives-progress.reducer';
import * as fromForumMember from './settings/forum-member/forum-member.reducer';
import * as fromTask from './tasks/task/task.reducer';
import * as fromTaskResponse from './tasks/task-response/task-response.reducer';
import * as fromTranslationResponse from './settings/translation/translation.reducer';
import * as fromAttachmentConfig from './settings/attachment-configuration/attachment-configuration.reducer';
import * as fromAttendance from './meetings/attendance/attendance.reducer';
import * as fromInvitee from './meetings/invitee/invitee.reducer';
import * as fromRepresentative from './meetings/representative/representative.reducer';
import * as fromAssignmentConfiguration from './settings/assignment-configuration/assignment-configuration.reducer';
import * as fromDevice from './settings/device/device.reducer';
import * as fromProjectRepository from './projects/project-repository/project-repository.reducer';
import * as fromDocumentation from './document/documentation/documentation.reducer';
import * as fromDocumentationFile from './document/documentation-file/documentation-file.reducer';

export interface AppState {
  [fromAuthUser.authUsersFeatureKey]: fromAuthUser.State;
  [fromRole.rolesFeatureKey]: fromRole.State;
  [fromPermissions.permissionsFeatureKey]: fromPermissions.State;
  [fromUsers.usersFeatureKey]: fromUsers.State;
  [fromAttachment.attachmentsFeatureKey]: fromAttachment.State;
  [fromPage.pagesFeatureKey]: fromPage.State;
  [fromDocument.documentsFeatureKey]: fromDocument.State;
  [fromInstitution.institutionsFeatureKey]: fromInstitution.State;
  [fromCommittee.committeesFeatureKey]: fromCommittee.State;
  [fromFinancialYear.financialYearsFeatureKey]: fromFinancialYear.State;
  [fromProjectCategory.projectCategoriesFeatureKey]: fromProjectCategory.State;
  [fromProject.projectsFeatureKey]: fromProject.State;
  [fromProjectComponent.projectComponentsFeatureKey]: fromProjectComponent.State;
  [fromProjectComponentTask.projectComponentTasksFeatureKey]: fromProjectComponentTask.State;
  [fromProjectChallenge.projectChallengesFeatureKey]: fromProjectChallenge.State;
  [fromProjectRecommendation.projectRecommendationsFeatureKey]: fromProjectRecommendation.State;
  [fromProjectProgress.projectProgresssFeatureKey]: fromProjectProgress.State;
  [fromProjectImage.projectImagesFeatureKey]: fromProjectImage.State;
  [fromDocumentCategory.documentCategoriesFeatureKey]: fromDocumentCategory.State;
  [fromDocumentStage.documentStagesFeatureKey]: fromDocumentStage.State;
  [fromDecision.decisionsFeatureKey]: fromDecision.State;
  [fromLocation.locationsFeatureKey]: fromLocation.State;
  [fromDirective.directivesFeatureKey]: fromDirective.State;
  [fromForthComing.forthComingsFeatureKey]: fromForthComing.State;
  [fromDesignation.designationsFeatureKey]: fromDesignation.State;
  [fromSmsConfig.smsConfigsFeatureKey]: fromSmsConfig.State;
  [fromMailConfig.mailConfigsFeatureKey]: fromMailConfig.State;
  [fromFolderCategory.folderCategoriesFeatureKey]: fromFolderCategory.State;
  [fromScheduledMeeting.scheduledMeetingsFeatureKey]: fromScheduledMeeting.State;
  [fromMeetingAgenda.meetingAgendasFeatureKey]: fromMeetingAgenda.State;
  [fromMinutesNotes.minutesNotessFeatureKey]: fromMinutesNotes.State;
  [fromMinutesDirectives.minutesDirectivessFeatureKey]: fromMinutesDirectives.State;
  [fromMinutesDecisions.minutesDecisionssFeatureKey]: fromMinutesDecisions.State;
  [fromRecommendations.recommendationssFeatureKey]: fromRecommendations.State;
  [fromDirectiveProgress.directiveProgresssFeatureKey]: fromDirectiveProgress.State;
  [fromForumMember.forumMembersFeatureKey]: fromForumMember.State;
  [fromTask.tasksFeatureKey]: fromTask.State;
  [fromTaskResponse.taskResponsesFeatureKey]: fromTaskResponse.State;
  [fromTranslationResponse.translationsFeatureKey]: fromTranslationResponse.State;
  [fromAttachmentConfig.attachmentConfigsFeatureKey]: fromAttachmentConfig.State;
  [fromAttendance.attendancesFeatureKey]: fromAttendance.State;
  [fromInvitee.inviteesFeatureKey]: fromInvitee.State;
  [fromRepresentative.representativesFeatureKey]: fromRepresentative.State;
  [fromAssignmentConfiguration.assignmentConfigurationsFeatureKey]: fromAssignmentConfiguration.State;
  [fromDevice.devicesFeatureKey]: fromDevice.State;
  [fromProjectRepository.projectRepositoriesFeatureKey]: fromProjectRepository.State;
  [fromDocumentComment.documentCommentsFeatureKey]: fromDocumentComment.State;
  [fromDocumentation.documentationsFeatureKey]: fromDocumentation.State;
  [fromDocumentationFile.documentationFilesFeatureKey]: fromDocumentationFile.State;
}

export const reducers: ActionReducerMap<AppState> = {
  [fromAuthUser.authUsersFeatureKey]: fromAuthUser.reducer,
  [fromRole.rolesFeatureKey]: fromRole.reducer,
  [fromPermissions.permissionsFeatureKey]: fromPermissions.reducer,
  [fromUsers.usersFeatureKey]: fromUsers.reducer,
  [fromAttachment.attachmentsFeatureKey]: fromAttachment.reducer,
  [fromPage.pagesFeatureKey]: fromPage.reducer,
  [fromInstitution.institutionsFeatureKey]: fromInstitution.reducer,
  [fromDocument.documentsFeatureKey]: fromDocument.reducer,
  [fromFinancialYear.financialYearsFeatureKey]: fromFinancialYear.reducer,
  [fromProjectCategory.projectCategoriesFeatureKey]: fromProjectCategory.reducer,
  [fromProject.projectsFeatureKey]: fromProject.reducer,
  [fromProjectComponent.projectComponentsFeatureKey]: fromProjectComponent.reducer,
  [fromProjectComponentTask.projectComponentTasksFeatureKey]: fromProjectComponentTask.reducer,
  [fromProjectChallenge.projectChallengesFeatureKey]: fromProjectChallenge.reducer,
  [fromProjectRecommendation.projectRecommendationsFeatureKey]: fromProjectRecommendation.reducer,
  [fromProjectProgress.projectProgresssFeatureKey]: fromProjectProgress.reducer,
  [fromProjectImage.projectImagesFeatureKey]: fromProjectImage.reducer,
  [fromDocumentStage.documentStagesFeatureKey]: fromDocumentStage.reducer,
  [fromDocumentCategory.documentCategoriesFeatureKey]: fromDocumentCategory.reducer,
  [fromCommittee.committeesFeatureKey]: fromCommittee.reducer,
  [fromDecision.decisionsFeatureKey]: fromDecision.reducer,
  [fromLocation.locationsFeatureKey]: fromLocation.reducer,
  [fromDirective.directivesFeatureKey]: fromDirective.reducer,
  [fromForthComing.forthComingsFeatureKey]: fromForthComing.reducer,
  [fromDesignation.designationsFeatureKey]: fromDesignation.reducer,
  [fromSmsConfig.smsConfigsFeatureKey]: fromSmsConfig.reducer,
  [fromMailConfig.mailConfigsFeatureKey]: fromMailConfig.reducer,
  [fromFolderCategory.folderCategoriesFeatureKey]: fromFolderCategory.reducer,
  [fromScheduledMeeting.scheduledMeetingsFeatureKey]: fromScheduledMeeting.reducer,
  [fromMeetingAgenda.meetingAgendasFeatureKey]: fromMeetingAgenda.reducer,
  [fromMinutesNotes.minutesNotessFeatureKey]: fromMinutesNotes.reducer,
  [fromMinutesDirectives.minutesDirectivessFeatureKey]: fromMinutesDirectives.reducer,
  [fromMinutesDecisions.minutesDecisionssFeatureKey]: fromMinutesDecisions.reducer,
  [fromRecommendations.recommendationssFeatureKey]: fromRecommendations.reducer,
  [fromDirectiveProgress.directiveProgresssFeatureKey]: fromDirectiveProgress.reducer,
  [fromForumMember.forumMembersFeatureKey]: fromForumMember.reducer,
  [fromTask.tasksFeatureKey]: fromTask.reducer,
  [fromTaskResponse.taskResponsesFeatureKey]: fromTaskResponse.reducer,
  [fromTranslationResponse.translationsFeatureKey]: fromTranslationResponse.reducer,
  [fromAttachmentConfig.attachmentConfigsFeatureKey]: fromAttachmentConfig.reducer,
  [fromAttendance.attendancesFeatureKey]: fromAttendance.reducer,
  [fromInvitee.inviteesFeatureKey]: fromInvitee.reducer,
  [fromRepresentative.representativesFeatureKey]: fromRepresentative.reducer,
  [fromAssignmentConfiguration.assignmentConfigurationsFeatureKey]: fromAssignmentConfiguration.reducer,
  [fromDevice.devicesFeatureKey]: fromDevice.reducer,
  [fromProjectRepository.projectRepositoriesFeatureKey]: fromProjectRepository.reducer,
  [fromDocumentComment.documentCommentsFeatureKey]: fromDocumentComment.reducer,
  [fromDocumentation.documentationsFeatureKey]: fromDocumentation.reducer,
  [fromDocumentationFile.documentationFilesFeatureKey]: fromDocumentationFile.reducer,
};

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state: AppState, action: Action): AppState => {
    if (action.type === '[AuthUser/API] Logout') {
      state = undefined;
    }
    return reducer(state, action);
  };
}

// export const metaReducers: MetaReducer<AppState>[] = [clearState];
export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [clearState] : [];

// export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
export const appState = (state: AppState) => state;
