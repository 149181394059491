import {Component, ContentChild, OnInit, TemplateRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../../services/auth.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {

  currentYear: any;
  staffLoginForm: UntypedFormGroup;
  loading = false;
  @ContentChild(TemplateRef, {static : true}) AuthContent: TemplateRef<any>;

  subscription: Subscription = new Subscription();

  isProduction = environment.production;

  constructor(
    private fb: UntypedFormBuilder,
    private route: Router,
    private auth: AuthService
  ) {
    if (this.auth.alreadyLoggedIn()) {
      this.route.navigate(['/home']).then();
    }
    document.body.classList.add('bg-gradient');
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.staffLoginForm = this.fb.group({
      username : [null, [Validators.required, Validators.email]],
      password : [null, [Validators.required, Validators.min(6)]]
    });
  }
}
