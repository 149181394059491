
<!-- Filter -->
<div class="row table-filter">

  <div class="d-md-flex">

    <div class="col-12 col-md-9">
      <mat-form-field class="search-form-field col-12" appearance="outline">
        <input (keyup)="filterTable()" [(ngModel)]="searchKey" autocomplete="off" matInput placeholder="{{'Search' | concat | translate}}" />
        <button (click)="onSearchClear()" *ngIf="searchKey" mat-icon-button matSuffix type="button">
          <mat-icon class="material-symbols-outlined">close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="col-12 col-md-3">
      <div class="d-flex justify-content-center justify-content-md-end">

        <button mat-icon-button type="button" matTooltip="{{ openAdvanceFilter ? 'close Advance Search' : 'open advance search' | concat | translate}}"
                (click)="toggleAdvanceFilter()">
          <mat-icon svgIcon="search"></mat-icon>
        </button>

        <div class="my-auto bg-primary"><span class="vr"></span></div>

        <button mat-icon-button type="button" [matMenuTriggerFor]="pageSizeMenu" matTooltip="{{'Select Page Size' | concat | translate}}">
          <mat-icon svgIcon="next-page-arrow"></mat-icon>
        </button>
        <mat-menu #pageSizeMenu="matMenu">
          <button mat-menu-item type="button" (click)="getPageSize(pageOption)" *ngFor="let pageOption of pageSizeOptions">
            <span>{{pageOption | number}}</span>
          </button>
        </mat-menu>

        <button mat-icon-button type="button" [matMenuTriggerFor]="pageToGo" matTooltip="{{'Go To Page' | concat | translate}}">
          <mat-icon svgIcon="page-break"></mat-icon>
        </button>

        <mat-menu #pageToGo="matMenu">
          <ng-container *ngFor="let page of pagination">
            <button mat-menu-item type="button" [disabled]="page === '...'"  (click)="getPage(page)">
              <ng-container *ngIf="page === '...'">  -- {{'Middle' | concat | translate}} --  </ng-container>
              <ng-container *ngIf="page !== '...'"> Page {{ page }} of {{ totalPages | number}} </ng-container>
            </button>
          </ng-container>
        </mat-menu>

        <button mat-icon-button (click)="openColumnSection()" type="button" matTooltip="{{'Select Columns to Display' | concat | translate}}">
          <mat-icon svgIcon="columns"></mat-icon>
        </button>

        <button mat-icon-button (click)="updateCacheTableColumns(tableColumns)" type="button" matTooltip="{{'Reset Columns Display' | concat | translate}}">
          <mat-icon svgIcon="reset"></mat-icon>
        </button>

        <div class="my-auto bg-primary"><span class="vr"></span></div>

        <button mat-icon-button matTooltip="{{'Copy table' | concat | translate}}" (click)="copyTable()" type="button">
          <mat-icon svgIcon="copy"></mat-icon>
        </button>

        <button mat-icon-button matTooltip="{{'Export' | concat | translate}}" (click)="downloadExcelFile(true)" type="button">
          <mat-icon svgIcon="excel"></mat-icon>
        </button>

      </div>
    </div>

  </div>
</div>
<!-- end of Filter -->

<div class="col-12 advance-filter p-3 mb-2 rounded-3 move-down --order-4" *ngIf="openAdvanceFilter">
  <p class="mb-2 fw-bold">{{'Advance Filter' | concat | translate}}</p>
  <hr>
  <ng-container [ngTemplateOutlet]="advanceFilterContext"></ng-container>
</div>

<div class="col-md-12">
  <table (matSortChange)="sortData($event)" id="table" class="table rounded-4 align-middle custom-table mb-0 {{ tableCssClass }}" matSort>
    <thead class="">
    <tr>
      <ng-container *ngFor="let tableColumn of cacheTableColumns">
        <!-- if sort exist-->
        <ng-container *ngIf="tableColumn?.sortableColumn; else noSort">
          <th [mat-sort-header]="tableColumn?.sortableColumn" class="{{ tableColumn?.theadCellClass}}">
            {{tableColumn.title }}
          </th>
        </ng-container>
        <!-- /if sort exist-->

        <!-- no sort-->
        <ng-template #noSort>
          <th class="{{ tableColumn?.theadCellClass}}"> {{tableColumn.title | concat | translate}} </th>
        </ng-template>
        <!--/ no sort-->

      </ng-container>
    </tr>
    </thead>
    <tbody class="border-top-0">
    <tr *ngFor="let tableData of tableData$ | async; let j = index" class="move-up" style="{{ '--order:' + 0.25 * (1 + j)}}">
      <ng-container *ngFor="let tableColumn of cacheTableColumns">
        <td class="{{ tableColumn?.tbodyCellClass}}">
          <span class="d-md-none d-block table-header-sm">{{ tableColumn?.title | concat | translate}}</span>
          <!--index cell-->
          <ng-container *ngIf="tableColumn.type === tableColumnType.index">
            {{ j + 1 + ((currentPage - 1) * pageSize) | number}}
          </ng-container>
          <!--/ index cell-->

          <!--checkbox cell-->
          <ng-container *ngIf="tableColumn.type === tableColumnType.inputCheckbox">
            <mat-checkbox
              (change)="$event ? selection.toggle(tableData) : null"
              (click)="$event.stopPropagation()"
              [checked]="selection.isSelected(tableData)">
            </mat-checkbox>
          </ng-container>
          <!--/ checkbox cell-->

          <!-- text or html cell-->
          <ng-container *ngIf="(tableColumn.type == tableColumnType.text || tableColumn.type == null || tableColumn.type === undefined) && !tableColumn?.isActionColumn">
            <span [innerHTML]="settingsService.getTableObjectValue(tableData, tableColumn.keyword)"></span>
          </ng-container>
          <!--/ text or html cell-->

          <!-- url cell-->
          <ng-container *ngIf="tableColumn.type == tableColumnType.url">
            <a href="{{settingsService.getTableObjectValue(tableData, tableColumn.keyword)}}" target="_blank">
              <span [innerHTML]="settingsService.getTableObjectValue(tableData, tableColumn.keyword)"></span>
            </a>
          </ng-container>
          <!--/ url cell-->

          <!-- img cell-->
          <ng-container *ngIf="tableColumn.type == tableColumnType.img">
            <ng-container *ngIf="settingsService.getTableObjectValue(tableData, tableColumn.keyword); else noImage">
              <img [src]="'data:image/jpg;base64,' + settingsService.getTableObjectValue(tableData, tableColumn.keyword) | safeResource" alt="img"
                   style="border-radius: 8px; width: 100px">
            </ng-container>

            <ng-template #noImage>
              <mat-icon style="color: #d8dbe0;">face</mat-icon>
            </ng-template>
          </ng-container>
          <!--/ img cell-->

          <!--number cell-->
          <ng-container *ngIf="tableColumn.type == tableColumnType.number">
            {{ settingsService.getTableObjectValue(tableData, tableColumn.keyword) | number}}
          </ng-container>
          <!--/ number cell-->

          <!--date cell-->
          <ng-container *ngIf="tableColumn.type === tableColumnType.date">
            {{ settingsService.getTableObjectValue(tableData, tableColumn.keyword) | date : tableColumn.dateFormat}}
          </ng-container>
          <!--/ date column-->

          <!--action cell-->
          <ng-container *ngIf="tableColumn?.isActionColumn">
            <ng-container [ngTemplateOutletContext]="{data : tableData}" [ngTemplateOutlet]="actions"></ng-container>
          </ng-container>
          <!--/ action cell-->

        </td>
      </ng-container>
    </tr>
    </tbody>
  </table>

  <ng-container *ngIf="cacheTotalElements === 0">
    <div class="col-12 d-flex justify-content-center fs-6 my-4">
      <small class="move-up --order-3">No Record Found</small>
    </div>
  </ng-container>

  <div class="col-md-12 pagination move-up --order-1">
    <div class="w-100 d-block d-md-flex">

      <div class="col-12 align-items-end order-1">
        <mat-paginator #paginator
                       class="mat-paginator"
                       (page)="getPage($event.pageIndex)"
                       [length]="cacheTotalElements"
                       [pageSize]="pageSize"
                       [showFirstLastButtons]="true"
                       [hidePageSize]="true"
                       [pageIndex]="currentPage - 1"
                       aria-label="Select page">
        </mat-paginator>
      </div>

    </div>
  </div>

</div>

