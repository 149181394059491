import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {SettingsService} from '../../../../services/settings.service';
import {Apollo} from 'apollo-angular';
import {getAllAttendance, loadAttendances, saveAttendance, upsertAttendance} from './attendance.actions';
import {ADD_MEETING_ATTENDANCE, GET_MEETING_ATTENDANCE} from './attendance.graphql';

@Injectable()
export class AttendanceEffects {


  get = createEffect(() => this.actions$.pipe(
    ofType(getAllAttendance),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_MEETING_ATTENDANCE,
        variables: { meetingUniqueId: action.meetingUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadAttendances({ attendances: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });


  save = createEffect(() => this.actions$.pipe(
    ofType(saveAttendance),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: ADD_MEETING_ATTENDANCE,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessage(result?.response?.message);
            this.store.dispatch(upsertAttendance({ attendance: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
  ) { }

}
