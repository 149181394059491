<app-modal-header [title]="title" (closeDialog)="closeDialog()"></app-modal-header>

<div class="text-muted mb-3">
  {{ '2Fa description login' | concat | translate }}
</div>

<div class="card-two-factor mb-3" *ngIf="passkey">
  <div class="d-flex gap-2 align-items-center mb-2">
    <mat-icon svgIcon="help" class="cursor-pointer" matTooltip="{{ 'Passkey Help Message' | concat | translate }}"></mat-icon>
    <div class="factor-header">
      {{ 'Passkey Title' | concat | translate }}
    </div>
  </div>
  <mat-form-field appearance="outline" class="mt-2">
    <mat-label>{{ 'Select Passkey' | concat | translate }}</mat-label>
    <input type="email" matInput [ngModel]="userEmail" autocomplete="username webauthn" />
    <div matSuffix class="p-2">
      <mat-icon svgIcon="finger_print2"></mat-icon>
    </div>
  </mat-form-field>
</div>

<div class="d-flex align-items-center justify-content-center mb-3" *ngIf="passkey && totp">
  {{ 'Or' | concat | translate }}
</div>

@if (totp) {
  <div class="card-two-factor mb-3">
    <div class="d-flex gap-2 align-items-center mb-2">
      <mat-icon svgIcon="help" class="cursor-pointer"
                matTooltip="{{ 'TOTP Help Message' | concat | translate }}"></mat-icon>
      <div class="factor-header">
        {{ 'TOTP Code Title' | concat | translate }}
      </div>
    </div>
    <form [formGroup]="form">
      <mat-form-field appearance="outline" class="">
        <mat-label>{{ '2FA Code' | concat | translate }}</mat-label>
        <input type="number" min="0" max="999999" matInput formControlName="twoFACode"/>
        <span matSuffix>
        <div
          class="p-2 fw-bold {{ countDown > dangerBreakPoint ? 'text-primary' : 'text-danger' }}">00:{{ countDown >= 10 ? countDown : '0' + countDown }}</div>
      </span>
      </mat-form-field>
    </form>
  </div>

  <div class="d-flex justify-content-end">
    <button [disabled]="!form.valid" (click)="submit2FA(form.value)" mat-raised-button color="primary">
      {{ 'Confirm' | concat | translate }}
    </button>
  </div>
}
