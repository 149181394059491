import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {FinancialYear} from './financial-year.model';

export const loadFinancialYears = createAction(
  '[FinancialYear/API] Load FinancialYears',
  props<{ financialYears: FinancialYear[] }>()
);

export const addFinancialYear = createAction(
  '[FinancialYear/API] Add FinancialYear',
  props<{ financialYear: FinancialYear }>()
);

export const upsertFinancialYear = createAction(
  '[FinancialYear/API] Upsert FinancialYear',
  props<{ financialYear: FinancialYear }>()
);

export const addFinancialYears = createAction(
  '[FinancialYear/API] Add FinancialYears',
  props<{ financialYears: FinancialYear[] }>()
);

export const upsertFinancialYears = createAction(
  '[FinancialYear/API] Upsert FinancialYears',
  props<{ financialYears: FinancialYear[] }>()
);

export const updateFinancialYear = createAction(
  '[FinancialYear/API] Update FinancialYear',
  props<{ financialYear: Update<FinancialYear> }>()
);

export const updateFinancialYears = createAction(
  '[FinancialYear/API] Update FinancialYears',
  props<{ financialYears: Update<FinancialYear>[] }>()
);

export const deleteFinancialYear = createAction(
  '[FinancialYear/API] Delete FinancialYear',
  props<{ id: number }>()
);

export const deleteFinancialYears = createAction(
  '[FinancialYear/API] Delete FinancialYears',
  props<{ ids: string[] }>()
);

export const clearFinancialYears = createAction(
  '[FinancialYear/API] Clear FinancialYears'
);

export const getAllFinancialYears = createAction(
  '[FinancialYears/API] List All FinancialYears'
);

export const actionFinancialYear = createAction(
  '[FinancialYear/API] create, edit & delete financialYear',
  props<{ financialYearDto: any }>()
);
