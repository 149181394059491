import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ProjectComponent} from './project-component.model';
import * as ProjectComponentActions from './project-component.actions';

export const projectComponentsFeatureKey = 'ProjectComponents';

export interface State extends EntityState<ProjectComponent> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ProjectComponent> = createEntityAdapter<ProjectComponent>();

// export const adapter: EntityAdapter<ProjectComponent> = createEntityAdapter<ProjectComponent>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const projectComponentComponentReducer = createReducer(
  initialState,
  on(ProjectComponentActions.addProjectComponent,
    (state, action) => adapter.addOne(action.projectComponent, state)
  ),
  on(ProjectComponentActions.upsertProjectComponent,
    (state, action) => adapter.upsertOne(action.projectComponent, state)
  ),
  on(ProjectComponentActions.addProjectComponents,
    (state, action) => adapter.addMany(action.projectComponents, state)
  ),
  on(ProjectComponentActions.upsertProjectComponents,
    (state, action) => adapter.upsertMany(action.projectComponents, state)
  ),
  on(ProjectComponentActions.updateProjectComponent,
    (state, action) => adapter.updateOne(action.projectComponent, state)
  ),
  on(ProjectComponentActions.updateProjectComponents,
    (state, action) => adapter.updateMany(action.projectComponents, state)
  ),
  on(ProjectComponentActions.deleteProjectComponent,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ProjectComponentActions.deleteProjectComponents,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ProjectComponentActions.loadProjectComponents,
    (state, action) => adapter.setAll(action.projectComponents, state)
  ),
  on(ProjectComponentActions.clearProjectComponents,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return projectComponentComponentReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllProjectComponents = selectAll;
export const getProjectComponentById = selectIds;
export const getProjectComponentEntities = selectEntities;
