<!-- rest of your app omitted for brevity -->
<!--<div>IDLE_STATE: {{idleState}}, COUNTDOWN: {{countdown}}, LAST_PING: {{lastPing | date}}</div>-->
<!-- rest of your app omitted for brevity -->

<router-outlet></router-outlet>

<ng-http-loader
  [backdrop]="false"
  [backgroundColor]="'#009A65'"
  [entryComponent]="spinnerComponent">
</ng-http-loader>


