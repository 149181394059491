<ng-container *ngIf="project$ | async as project">

  <app-page-title [title]="title" isBoldedTitle="true">
    <ng-template>
      <button (click)="action(project)" class="btn-sm mx-1" color="primary" mat-raised-button>
        <mat-icon class="material-symbols-outlined">add</mat-icon>
        {{'Add Evidence' | concat | translate}}
      </button>
    </ng-template>
  </app-page-title>

   <div class="col-md-12 my-3">
      <h5>{{'Project Summary' | concat | translate}}</h5>
        <div class="ext-container w-100">
          <mat-card class="inst-details">
            <div class="row">

              <div class="col-md-6 my-2">
                <span class="title">{{'Project Name' | concat | translate}}</span>
                <div>{{ project?.name}}</div>
              </div>

              <div class="col-md-6 my-2">
                <span class="title">{{'Project Location' | concat | translate}}</span>
                <div>{{ project?.location}}</div>
              </div>
            </div>
          </mat-card>
        </div>
    </div>


<!--  <ng-container *ngIf="images$ | async as images">-->
<!--    {{ isImage(images[2]?.base64Data) }}-->
<!--  </ng-container>-->

  <div class="row mt-3">
    <ng-container *ngFor="let image of images$ | async;let i = index">
      <div class="col-md-3">
        <div [matMenuTriggerFor]="menu" class="ratio ratio ratio-16x9 cursor-pointer">

          <ng-container *ngIf="isImage(image?.attachmentName); else isVideo">
            <img *ngIf="image?.base64Data" [src]="'data:image/png;base64,' + image?.base64Data | safeResource" alt="{{ image?.attachmentName}}"
                 class=" w-100 img-thumbnail img-responsive" style="object-fit: cover;">

            <img *ngIf="image?.base64Data === null" alt="{{ image?.attachmentName}}"
                 class="w-100 img-responsive img-thumbnail" src="/assets/images/no-image.png" style="object-fit: cover;">
          </ng-container>

          <ng-template #isVideo>
            <video controls>
              <source [src]="'data:video/mp4;base64,' + image?.base64Data | safeResource" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </ng-template>

        </div>

        <div class="col-12">
          <span [innerHTML]="image?.description"></span>
        </div>

        <div #modal class="modal fade fadeInDown py-5" id="image{{i}}" role="dialog">
          <div class="modal-dialog modal-xl modal-dialog-centered" role="document">

            <div class="modal-content rounded-6 border-2">
              <div class="modal-body">

                <h3 class="fw-bold mb-0"> {{ image?.attachmentName}}</h3>

                <button (click)="modal.classList.remove('d-block')" aria-label="Close" class="btn btn-close-outline position-absolute top-0 end-0"
                        data-bs-dismiss="modal">
                  <span class="material-symbols-outlined">close</span>
                </button>

                <div class="col-md-12">

                  <img *ngIf="image?.base64Data" [src]="'data:image/png;base64,' + image?.base64Data | safeResource" alt="{{ image?.attachmentName}}"
                       class=" w-100 img-thumbnail img-responsive" style="object-fit: cover;">

                  <img *ngIf="image?.base64Data === null" alt="{{ image?.attachmentName}}"
                       class="w-100 img-responsive img-thumbnail" src="/assets/images/no-image.png" style="object-fit: cover;">

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <mat-menu #menu="matMenu">

        <button (click)="viewImage(i)" mat-menu-item>
          <mat-icon class="material-symbols-outlined">image</mat-icon>
          {{'View Large Image' | concat | translate}}
        </button>

        <ng-container *ngIf="project?.projectStatusEnum !== projectStatus.COMPLETED; else notEditable">

          <button (click)="remove(image)" mat-menu-item>
            <mat-icon svgIcon="icon_deactivate"></mat-icon>
            {{'Remove' | concat | translate}}
          </button>
        </ng-container>

      </mat-menu>

      <ng-template #notEditable>
        <button disabled mat-raised-button> <mat-icon class="material-symbols-outlined">info</mat-icon>
          {{'Cant be Editable ' | concat | translate}}</button>
      </ng-template>

    </ng-container>
  </div>

</ng-container>
