import {createSelector} from '@ngrx/store';
import * as fromDirective from './directive.reducer';
import {AppState} from '../../index';

export const currentState = (state: AppState) => (state[fromDirective.directivesFeatureKey]);

export const selectDirectivesFromReducer = createSelector(currentState, fromDirective.selectAll);
export const selectEntities = createSelector(currentState, fromDirective.selectEntities);

export const selectDirectives = createSelector(
  selectDirectivesFromReducer,
  (directives) => {
    return directives.map( (directive) => {
      return{
        ...directive,
        response: directive?.directive_responses?.map( data => data?.response_descriptions).join( ' , ')
      };
    });
  });


export const selectDirectiveById = (id: number) => createSelector(
  selectDirectives,
  (directives) => directives.find((directive) => directive.id === id)
);

export const selectDirectiveByCommittee = (committee: string) => createSelector(
  selectDirectives,
  (directives) => directives.filter((directive) => directive.directive_committee === committee)
);
