import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Committee} from './committee.model';
import * as CommitteeActions from './committee.actions';

export const committeesFeatureKey = 'committees';

export interface State extends EntityState<Committee> {
  // additional entities state properties
}
//
export function sortByCommitteeName(a: Committee, b: Committee): any {
  // return a?.name.localeCompare(b?.name);
}

export const adapter: EntityAdapter<Committee> = createEntityAdapter<Committee>({
  // sortComparer: sortByCommitteeName
});

// export const adapter: EntityAdapter<Committee> = createEntityAdapter<Committee>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const committeeReducer = createReducer(
  initialState,
  on(CommitteeActions.addCommittee,
    (state, action) => adapter.addOne(action.committee, state)
  ),
  on(CommitteeActions.upsertCommittee,
    (state, action) => adapter.upsertOne(action.committee, state)
  ),
  on(CommitteeActions.addCommittees,
    (state, action) => adapter.addMany(action.committees, state)
  ),
  on(CommitteeActions.upsertCommittees,
    (state, action) => adapter.upsertMany(action.committees, state)
  ),
  on(CommitteeActions.updateCommittee,
    (state, action) => adapter.updateOne(action.committee, state)
  ),
  on(CommitteeActions.updateCommittees,
    (state, action) => adapter.updateMany(action.committees, state)
  ),
  on(CommitteeActions.deleteCommittee,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(CommitteeActions.deleteCommittees,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(CommitteeActions.loadCommittees,
    (state, action) => adapter.setAll(action.committees, state)
  ),
  on(CommitteeActions.clearCommittees,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return committeeReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllCommittees = selectAll;
export const getCommitteeById = selectIds;
export const getCommitteeEntities = selectEntities;
