import {Component, inject, Input, OnInit, ViewChild} from '@angular/core';
import {lastValueFrom, Observable, of} from 'rxjs';
import {
  AnnotationEditorEditorModeChangedEvent,
  EditorAnnotation,
  NgxExtendedPdfViewerService
} from 'ngx-extended-pdf-viewer';
import {AnnotationType} from '../../../enums/data.enums';
import {QueryService} from '../../../../services/query.service';
import {DELETE_ANNOTATION, GET_ANNOTATION, SAVE_ANNOTATION} from '../../../../store/entities/annotation.graphql';
import {NotificationService} from '../../../../services/notification.service';

@Component({
  selector: 'app-custom-pdf-viewer',
  templateUrl: './custom-pdf-viewer.component.html',
  styleUrls: ['./custom-pdf-viewer.component.scss']
})
export class CustomPdfViewerComponent implements OnInit {

  @Input() fullscreenDiv;
  @Input() height = '100vh';
  @Input() filenameForDownload;
  @Input() showBookmarkButton = false;
  @Input() showDownloadButton = false;
  @Input() showFindButton = true;
  @Input() showHandToolButton = true;
  @Input() showOpenFileButton = false;
  @Input() showPrintButton = false;
  @Input() textLayer = true;
  @Input() showTextEditor = true;
  @Input() showZoomButtons = true;
  @Input() showRotateButton = true;
  @Input() showScrollingButton = true;
  @Input() showSpreadButton = true;
  @Input() showPropertiesButton = true;
  @Input() showDrawEditor = true;
  @Input() filePath = null;

  @Input() zoom = 'page-width';
  @Input() base64Src$: Observable<string> = of(
    'JVBERi0xLjcKCjEgMCBvYmogICUgZW50cnkgcG9pbnQKPDwKICAvVHlwZSAvQ2F0YWxvZwogIC9QYWdlcy' +
    'AyIDAgUgo+PgplbmRvYmoKCjIgMCBvYmoKPDwKICAvVHlwZSAvUGFnZXMKICAvTWVkaWFCb3ggWyAwIDAgMjAwIDIwMCBdCiAgL0NvdW50IDEKICAvS2l' +
    'kcyBbIDMgMCBSIF0KPj4KZW5kb2JqCgozIDAgb2JqCjw8CiAgL1R5cGUgL1BhZ2UKICAvUGFyZW50IDIgMCBSCiAgL1Jlc291cmNlcyA8PAogICAgL0Zv' +
    'bnQgPDwKICAgICAgL0YxIDQgMCBSIAogICAgPj4KICA+PgogIC9Db250ZW50cyA1IDAgUgo+PgplbmRvYmoKCjQgMCBvYmoKPDwKICAvVHlwZSAvRm9ud' +
    'AogIC9TdWJ0eXBlIC9UeXBlMQogIC9CYXNlRm9udCAvVGltZXMtUm9tYW4KPj4KZW5kb2JqCgo1IDAgb2JqICAlIHBhZ2UgY29udGVudAo8PAogIC9MZW' +
    '5ndGggNDQKPj4Kc3RyZWFtCkJUCjcwIDUwIFRECi9GMSAxMiBUZgooSGVsbG8sIHdvcmxkISkgVGoKRVQKZW5kc3RyZWFtCmVuZG9iagoKeHJlZgowID' +
    'YKMDAwMDAwMDAwMCA2NTUzNSBmIAowMDAwMDAwMDEwIDAwMDAwIG4gCjAwMDAwMDAwNzkgMDAwMDAgbiAKMDAwMDAwMDE3MyAwMDAwMCBuIAowMDAwMD' +
    'AwMzAxIDAwMDAwIG4gCjAwMDAwMDAzODAgMDAwMDAgbiAKdHJhaWxlcgo8PAogIC9TaXplIDYKICAvUm9vdCAxIDAgUgo+PgpzdGFydHhyZWYKNDkyCiUlRU9G'
  );

  showOpenFullscreenBtn = true;
  hideAnnotation = true;
  annotationEvent: any
  ;
  @ViewChild('fullscreen') divRef;

  editorAnnotations: EditorAnnotation[] = [];
  pdfService = inject(NgxExtendedPdfViewerService);
  queryService = inject(QueryService);
  notificationService = inject(NotificationService);

  protected readonly AnnotationType = AnnotationType;

  ngOnInit(): void {

  }

  pageRendered(event: any){
    if (this.filePath !== null && event?.pagesCount > 0){

      lastValueFrom(
        this.queryService.query(GET_ANNOTATION, { path: this.filePath})
      ).then( data => {
        if (data){
          const result: any = Object.values(data)[0];

          if (result?.response?.code === 9000){
            const responseData = result?.data[0]?.details as any[];
            let annotations = [];
            annotations = responseData?.map( d => JSON.parse(d?.annotation));

            this.editorAnnotations.push(...annotations);
            this.editorAnnotations?.forEach( d => this.pdfService.addEditorAnnotation(d));

            if (this.editorAnnotations?.length > 0){
              this.hideAnnotation = false;
            }
          }
        }
      });

    }
  }

  toggleFullScreen() {
    const elem = this.divRef.nativeElement;

    if (this.showOpenFullscreenBtn) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullscreen();
        elem.webkitRequestFullScreen(elem.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      // alert('Press Ok To Close Fullscreen');
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      // else if (document.mozCancelFullScreen) {
      //   document.mozCancelFullScreen();
      // }
      // else if (document.webkitCancelFullScreen) {
      //   document.webkitCancelFullScreen();
      // }
    }

    this.showOpenFullscreenBtn = !this.showOpenFullscreenBtn;

  }

  exportAnnotation(){

    const inputDto = {
      path: this.filePath,
      details: this.pdfService.getSerializedAnnotations()?.map( d => {
        return {
          index: d?.pageIndex,
          annotationType: d?.annotationType,
          annotation: JSON.stringify(d)
        };
      })
    };

    lastValueFrom(
      this.queryService.mutate(SAVE_ANNOTATION, { inputDto })
    ).then( data => {
      if (data){
        const result: any = Object.values(data)[0];

        if (result?.response?.code === 9000){
          this.notificationService.successMessage(result?.response?.message);
          this.editorAnnotations = [...this.pdfService.getSerializedAnnotations()];
        }else {
          this.notificationService.errorMessage(result?.response?.message);
        }
      }
    });
  }

  addAnnotations(serializedAnnotation: any){
    this.pdfService.addEditorAnnotation(serializedAnnotation);
  }

  removeAnnotation(annotationType: AnnotationType){

    lastValueFrom(
      this.queryService.mutate(DELETE_ANNOTATION, { inputDto: {path: this.filePath, annotationType} })
    ).then( data => {
      if (data){
        const result: any = Object.values(data)[0];

        if (result?.response?.code === 9000){
          this.pdfService.removeEditorAnnotations((serial: any) => serial.annotationType === annotationType);
          this.notificationService.successMessage(result?.response?.message);

        }else {
          this.notificationService.errorMessage(result?.response?.message);
        }
      }
    });

  }

  toggleHideAnnotation(){

    if (this.pdfService.getSerializedAnnotations()?.length > 0 && this.editorAnnotations?.length === 0){
      this.editorAnnotations = this.pdfService.getSerializedAnnotations();
    }

    this.hideAnnotation = !this.hideAnnotation;

    if (this.hideAnnotation){
      this.pdfService.removeEditorAnnotations();
    }else {
      this.addAnnotations(this.editorAnnotations);
    }
  }

  annotationEditorModeChanged($event: AnnotationEditorEditorModeChangedEvent) {
    /*TODO: to be continue, to add custom toolbar to handle text functionalities*/
    this.annotationEvent = $event;
  }
}
