import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DirectiveService {

  constructor(
    private http: HttpClient
  ) {
  }

  post(formData){
    return this.http.post('kmk_send_directives_to_cse', formData);
  }

  movement(formData){
    return this.http.post('kmk_directives_movements', formData);
  }

  put(formData){
    return this.http.put('kmk_send_directives_to_cse', formData);
  }

  getByCommittee(committeeUid){
    return this.http.get('kmk_send_directives_to_cse/' + committeeUid);
  }

  getAll(){
    return this.http.get('kmk_send_directives_to_cse');
  }

}
