<h5>{{ title | concat | translate }}</h5>
<hr class="bg-secondary mb-0">

<div class="col-md-12">
      <ng-container *ngIf="tableColumns">
        <div class="permission-checkbox">
          <div class="row">
            <div *ngFor="let tableColumn of tableColumns" class="col-md-6">
              <mat-checkbox (change)="onChange(tableColumn, $event)"
                            [checked]="hasColumn(tableColumn.keyword)"
                            [value]="tableColumn.keyword"
                            [disabled]="tableColumn?.keyword?.toLowerCase() === 'action' || tableColumn?.keyword?.toLowerCase() === 'actions'">
                <span>{{ tableColumn?.title }}</span>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </ng-container>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button mat-dialog-close>{{'Close' | concat | translate}}</button>
<!--  <button mat-button [mat-dialog-close]="selectedColumns">{{'Display' | concat | translate}}</button>-->
</div>
