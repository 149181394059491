import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../services/notification.service';
import {AppState} from '../index';
import {map, switchMap} from 'rxjs/operators';
import {
  attendMinistryNewIncomingPaper,
  deleteDocument,
  dismissPaper,
  editDocument,
  editDocumentFromMinistry,
  getAllCommiteesPapers,
  getAllDocumentByStage,
  getAllDocumentPageable,
  getAllDocuments,
  getIncomingPapers,
  loadDocuments, reviewPaper,
  saveDocument,
  saveDocumentFromMinistry,
  stampDocument,
  upsertDocument
} from './document.actions';
import {SettingsService} from '../../../services/settings.service';
import {
  ATTEND_DOCUMENT, CREATE_PAPER_REVIEW,
  DISMISS_PAPER,
  GET_ALL_COMMITTEE_DOCUMENT,
  GET_DOCUMENT_BY_STAGE,
  GET_DOCUMENT_PAGEABLE,
  GET_INCOMING_PAPERS,
  SAVE_DOCUMENT,
  SAVE_DOCUMENT_FROM_MINISTRY,
  STAMP_DOCUMENT,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_FROM_MINISTRY, VERIFY_PAPER_REVIEW
} from './document.graphql';
import {Apollo} from 'apollo-angular';
import {insertPage} from '../page/page.actions';

@Injectable()
export class DocumentEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllDocuments),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_DOCUMENT_PAGEABLE,
        variables: {
          filtering: {
            status: action.status,
            fyUniqueId: action.fyUniqueId,
            pageNumber: action.pageNumber,
            itemsPerPage: action.itemsPerPage
          }
        }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.store.dispatch(insertPage({ page: {
                ...result?.page
              } }));
            this.store.dispatch(loadDocuments({documents: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  getCommitteePaper = createEffect(() => this.actions$.pipe(
    ofType(getAllCommiteesPapers),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_ALL_COMMITTEE_DOCUMENT,
        variables: {
          commiteeUniqueId: action.commiteeUniqueId,
          status: action.status,
          paperUniqueId: action.paperUniqueId,
          paperHasReview: action.paperHasReview
        }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.store.dispatch(loadDocuments({documents: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  pageable = createEffect(() => this.actions$.pipe(
    ofType(getAllDocumentPageable),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_DOCUMENT_PAGEABLE,
        variables: {filtering: { ...action.pageableParam, fyUniqueId: action.fyUniqueId, status: action?.status }}
      }).pipe(
        map(({ data }: any) => this.loadPageable(Object.values(data)[0]))
      );
    })
  ), { dispatch: false });

  stage = createEffect(() => this.actions$.pipe(
    ofType(getAllDocumentByStage),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_DOCUMENT_BY_STAGE,
        variables: { inputDto: action.stage}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.store.dispatch(loadDocuments({documents: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  stampDocument = createEffect(() => this.actions$.pipe(
    ofType(stampDocument),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: STAMP_DOCUMENT,
        variables: { input: action.input}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertDocument({document: result?.data}));
            window.location.reload();
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveDocument),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_DOCUMENT,
        variables: { inputDto: action.documentDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(upsertDocument({document: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  dismiss = createEffect(() => this.actions$.pipe(
    ofType(dismissPaper),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DISMISS_PAPER,
        variables: { uniqueId: action.uniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteDocument({id: action.id}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  saveMinistry = createEffect(() => this.actions$.pipe(
    ofType(saveDocumentFromMinistry),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_DOCUMENT_FROM_MINISTRY,
        variables: { inputDto: action.documentDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(upsertDocument({document: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editDocument),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_DOCUMENT,
        variables: { inputDto: action.documentDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(upsertDocument({document: result?.data}));
            this.settingsService.reloadCurrentRoute(null);
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  attend = createEffect(() => this.actions$.pipe(
    ofType(attendMinistryNewIncomingPaper),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: ATTEND_DOCUMENT,
        variables: { inputDto: action.documentDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(upsertDocument({document: result?.data}));
            this.settingsService.reloadCurrentRoute(null);
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  editMinistry = createEffect(() => this.actions$.pipe(
    ofType(editDocumentFromMinistry),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_DOCUMENT_FROM_MINISTRY,
        variables: { inputDto: action.documentDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(upsertDocument({document: result?.data}));
            this.settingsService.reloadCurrentRoute(null);
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  incoming$ = createEffect(() => this.actions$.pipe(
    ofType(getIncomingPapers),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_INCOMING_PAPERS
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.store.dispatch(loadDocuments({documents: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  review = createEffect(() => this.actions$.pipe(
    ofType(reviewPaper),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: action?.actionType === 'REVIEW' ? CREATE_PAPER_REVIEW : VERIFY_PAPER_REVIEW,
        variables: { input: {
            paperUniqueId: action.paperUniqueId,
            comments: action.comments
          } }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(upsertDocument({document: result?.data}));
            this.settingsService.reloadCurrentRoute(null);
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  loadPageable(data){
    const response: any = Object.values(data)[0];
    const page: any = Object.values(data)[1];
    const documents: any = Object.values(data)[2];

    if (response?.code === 9000){
      this.store.dispatch(insertPage({ page }));
      this.store.dispatch(loadDocuments({ documents }) );
    }else {
      this.notificationService.errorMessage(response?.message);
    }
  }

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
  ){}

}
