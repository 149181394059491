import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const documentStageField = `
  ${baseField}
  name
`;

export const GET_DOCUMENT_STAGE = gql`
  query getAllDocumentStage {
    getAllDocumentStage {
      response{
        ${responseField}
      }
      data{
        ${documentStageField}
      }
    }
  }
`;

export const SAVE_DOCUMENT_STAGE = gql`
  mutation documentStageManagement($inputDto: DocumentStageInputObjects!) {
    documentStageManagement(documentStage: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${documentStageField}
      }
    }
  }
`;
