import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './shared/components/auth/login/login.component';
import {BeforeLoginGuard} from './shared/guards/before-login.guard';
import {ResetPasswordComponent} from './shared/components/auth/reset-password/reset-password.component';
import {LandingPageComponent} from './shared/components/layouts/landing-page/landing-page.component';
import {AfterLoginGuard} from './shared/guards/after-login.guard';
import {IsStaffGuard} from './shared/guards/is-staff.guard';
import {IsMinistryGuard} from './shared/guards/is-ministry.guard';
import {Error300Component} from './shared/components/errors/502/error-300.component';
import {CustomPdfViewerComponent} from './shared/components/views/custom-pdf-viewer/custom-pdf-viewer.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'annotation',
    component: CustomPdfViewerComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [BeforeLoginGuard]
  },
  {
    path: 'login-with-email/:email',
    component: LoginComponent,
    canActivate: [BeforeLoginGuard]
  },
  {
    path: '303',
    component: Error300Component,
  },
  {
    path: 'password-reset/:token',
    component: ResetPasswordComponent,
    canActivate: [BeforeLoginGuard]
  },
  {
    path: 'home',
    component: LandingPageComponent,
    canActivate: [AfterLoginGuard]
  },
  {
    path: 'setting',
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AfterLoginGuard, IsStaffGuard]
  },
  {
    path: 'document',
    loadChildren: () => import('./modules/document/document.module').then(m => m.DocumentModule),
    canActivate: [AfterLoginGuard, IsStaffGuard]
  },
  {
    path: 'project',
    loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule),
    canActivate: [AfterLoginGuard, IsStaffGuard]
  },
  {
    path: 'portal-meeting',
    loadChildren: () => import('./modules/portal/portal-meeting/portal-meeting.module').then(m => m.PortalMeetingModule),
    canActivate: [AfterLoginGuard, IsMinistryGuard]
  },
  {
    path: 'portal-project',
    loadChildren: () => import('./modules/portal/portal-project/portal-project.module').then(m => m.PortalProjectModule),
    canActivate: [AfterLoginGuard, IsMinistryGuard]
  },
  {
    path: 'report',
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AfterLoginGuard, IsStaffGuard]
  },
  {
    path: 'task',
    loadChildren: () => import('./modules/task/task.module').then(m => m.TaskModule),
    canActivate: [AfterLoginGuard, IsStaffGuard]
  },
  {
    path: 'meetings',
    loadChildren: () => import('./modules/meetings/meetings.module').then(m => m.MeetingsModule),
    canActivate: [AfterLoginGuard, IsStaffGuard]
  },
  {
    path: 'offline',
    loadChildren: () => import('./modules/offline-meetings/offline-meetings.module').then(m => m.OfflineMeetingsModule),
    canActivate: [AfterLoginGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
    routes, {
      preloadingStrategy: PreloadAllModules,
        useHash: false,
        onSameUrlNavigation: 'reload'
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
