import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {SettingsService} from '../../../../services/settings.service';
import {Apollo} from 'apollo-angular';
import {
  editDirectiveProgress,
  getAllDirectivesImplementations,
  getDirectiveReviewReports,
  loadDirectiveProgresss,
  reviewDirectiveProgressReport,
  saveDirectiveProgress,
} from './directives-progress.actions';
import {
  GET_DIRECTIVE_PROGRESS,
  GET_DIRECTIVE_REVIEW_PROGRESS_REPORT,
  REVIEW_DIRECTIVE_PROGRESS,
  SAVE_DIRECTIVE_PROGRESS,
  UPDATE_DIRECTIVE_PROGRESS
} from './directives-progress.graphql';

@Injectable()
export class DirectiveProgressEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllDirectivesImplementations),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_DIRECTIVE_PROGRESS,
        variables: { filtering: action.filtering, directiveUniqueId: action.directiveUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadDirectiveProgresss({ directiveProgresss: result?.data }));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  getDirectiveReviewReports$ = createEffect(() => this.actions$.pipe(
    ofType(getDirectiveReviewReports),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_DIRECTIVE_REVIEW_PROGRESS_REPORT,
        variables: { directive: action.directive }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadDirectiveProgresss({ directiveProgresss: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveDirectiveProgress),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_DIRECTIVE_PROGRESS,
        variables: { progress: action.progress }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            // this.store.dispatch(upsertDirectiveProgress({ DirectiveProgress: result?.data }));
            this.settingsService.reloadCurrentRoute(null);
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  reviewDirectiveProgressReport$ = createEffect(() => this.actions$.pipe(
    ofType(reviewDirectiveProgressReport),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: REVIEW_DIRECTIVE_PROGRESS,
        variables: { review: action.review }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.settingsService.reloadCurrentRoute(null);
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editDirectiveProgress),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_DIRECTIVE_PROGRESS,
        variables: { progress: action.progress }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            // this.store.dispatch(upsertDirectiveProgress({ DirectiveProgress: result?.data }));
            this.settingsService.reloadCurrentRoute(null);
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });




  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
  ) { }

}
