import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sum'
})
export class SumPipe implements PipeTransform {

  transform(value: any[], arg: any): number {
    // tslint:disable-next-line:triple-equals
    return value?.map( p => p[arg] == null || p[arg] == undefined ? 0 : p[arg])?.reduce( (a, b) => a + b);
  }
}
