import {Role} from '../role/role.model';
import {BaseEntity} from '../../BaseEntity.model';
import {Institution} from '../institution/institution.model';
import {Designation} from '../designation/designation.model';

export interface User extends BaseEntity{
  email?: string;
  suffix?: string;
  prefix?: string;
  firstName?: string;
  middleName?: string;
  phone?: string;
  photo?: string;
  lastName?: string;
  username?: string;
  role_name?: string;
  roleId?: number;
  role?: Role[];
  isStaff?: boolean;
  updatedPassword?: boolean;
  accountLocked?: boolean;
  twoFaEnabled?: boolean;
  webauthnEnabled?: boolean;
  type?: UserTypeEnum;
  userType?: UserTypeEnum;
  institution?: Institution;
  designation?: Designation;
  rolesList?: Role[];
  group?: UserGroupsTypeEnum;
}

export enum UserTypeEnum {
  Staff = 'Staff',
  Ministry = 'Ministry'
}

export enum UserGroupsTypeEnum {
  NATIONAL_LEADERS ='NATIONAL_LEADERS',
  CS = 'CS',
  CC = 'CC',
  CUS = 'CUS',
  CAS = 'CAS',
  REGISTRY = 'REGISTRY',
  TECHNICAL = 'TECHNICAL',
  MINISTERS = 'MINISTERS',
  DEPUTY_MINISTER = 'DEPUTY_MINISTER',
  PS = 'PS',
  DEPUTY_PS = 'DEPUTY_PS',
  CLO = 'CLO',
  PROJECT_REPOTERS = 'PROJECT_REPOTERS',
  PERMANENT_CHAIR = 'PERMANENT_CHAIR',
}

