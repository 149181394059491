import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ProjectCategory} from './project-category.model';
import * as ProjectCategoryActions from './project-category.actions';

export const projectCategoriesFeatureKey = 'ProjectCategories';

export interface State extends EntityState<ProjectCategory> {
  // additional entities state properties
}
//
export function sortByProjectCategoryName(a: ProjectCategory, b: ProjectCategory): any {
  // return a?.name.localeCompare(b?.name);
}

export const adapter: EntityAdapter<ProjectCategory> = createEntityAdapter<ProjectCategory>({
  // sortComparer: sortByProjectCategoryName
});

// export const adapter: EntityAdapter<ProjectCategory> = createEntityAdapter<ProjectCategory>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const projectCategoryReducer = createReducer(
  initialState,
  on(ProjectCategoryActions.addProjectCategory,
    (state, action) => adapter.addOne(action.projectCategory, state)
  ),
  on(ProjectCategoryActions.upsertProjectCategory,
    (state, action) => adapter.upsertOne(action.projectCategory, state)
  ),
  on(ProjectCategoryActions.addProjectCategories,
    (state, action) => adapter.addMany(action.ProjectCategories, state)
  ),
  on(ProjectCategoryActions.upsertProjectCategories,
    (state, action) => adapter.upsertMany(action.ProjectCategories, state)
  ),
  on(ProjectCategoryActions.updateProjectCategory,
    (state, action) => adapter.updateOne(action.projectCategory, state)
  ),
  on(ProjectCategoryActions.updateProjectCategories,
    (state, action) => adapter.updateMany(action.ProjectCategories, state)
  ),
  on(ProjectCategoryActions.deleteProjectCategory,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ProjectCategoryActions.deleteProjectCategories,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ProjectCategoryActions.loadProjectCategories,
    (state, action) => adapter.setAll(action.ProjectCategories, state)
  ),
  on(ProjectCategoryActions.clearProjectCategories,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return projectCategoryReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllProjectCategories = selectAll;
export const getProjectCategoryById = selectIds;
export const getProjectCategoryEntities = selectEntities;
