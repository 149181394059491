  <app-modal-header  [title]="title"></app-modal-header>

  <div class="row" id="printSection">
    <div class="col-md-12">
      <div class="certificate-content">
        <div class="cert-heading text-center bb-dotted">
          <div class="row justify-content-center">
            <div class="col-md-4">
              <img alt="" class="w-25" src="/assets/images/emblem.png">
            </div>

            <div class="col-md-12 my-auto">
              <h2 class="my-1">{{'The United Republic of Tanzania' | concat | translate}}</h2>
              <h2 class="my-1">{{'eCabinet' | concat | translate}}</h2>
              <h3 class="my-1 fw-bold">{{'Project Progress Report' | concat | translate}}</h3>
            </div>

          </div>

        </div>

        <div class="row">

          <div class="col">
            <div class="dash-box p-3 h-100">
              <div class="col-md-12 px-0 pb-2">{{'Total Components' | concat | translate}}</div>
              <div class="count col-md-12 px-0"> {{ project?.components?.length | number}} </div>
            </div>
          </div>

          <div class="col">
            <div class="dash-box p-3 h-100">
              <div class="col-md-12 px-0 pb-2">{{'Completed'  | concat | translate}}</div>
              <div class="count col-md-12 px-0"> {{ countProjectComponentByStatus(project?.components, projectStatus.COMPLETED) | number}} </div>
            </div>
          </div>

          <div class="col">
            <div class="dash-box p-3 h-100">
              <div class="col-md-12 px-0 pb-2">{{'On Going' | concat | translate}}</div>
              <div class="count col-md-12 px-0"> {{ countProjectComponentByStatus(project?.components, projectStatus.ONGOING) | number}} </div>
            </div>
          </div>

          <div class="col">
            <div class="dash-box p-3 h-100">
              <div class="col-md-12 px-0 pb-2">{{'On Hold' | concat | translate}}</div>
              <div class="count col-md-12 px-0"> {{ countProjectComponentByStatus(project?.components, projectStatus.ONHOLD) | number }} </div>
            </div>
          </div>

          <div class="col">
            <div class="dash-box p-3 h-100">
              <div class="col-md-12 px-0 pb-2">{{'Not Started' | concat | translate}}</div>
              <div class="count col-md-12 px-0"> {{ countProjectComponentByStatus(project?.components, projectStatus.NOTSTARTED) | number }} </div>
            </div>
          </div>

          <div class="col">
            <div class="dash-box p-3 h-100">
              <div class="col-md-12 px-0 pb-2">{{'Terminated' | concat | translate}}</div>
              <div class="count col-md-12 px-0"> {{ countProjectComponentByStatus(project?.components, projectStatus.TERMINATED) | number }} </div>
            </div>
          </div>

        </div>

        <div class="col-md-12 px-0 mt-3">
          <h5>{{'Project Summary' | concat | translate}}</h5>
          <div class="ext-container w-100">
            <mat-card class="inst-details">
              <div class="row">

                <div class="col-md-4 my-2">
                  <span class="title"><b>{{'Project Name' | concat | translate}}</b></span>
                  <div>{{ project?.name}}</div>
                </div>

                <div class="col-md-4 my-2">
                  <span class="title"><b>{{'Project Location' | concat | translate}}</b></span>
                  <div>{{ project?.location}}</div>
                </div>

                <div class="col-md-4 my-2">
                  <span class="title"><b>{{'Supervisor' | concat | translate}}</b></span>
                  <div>{{ project?.engineer}}</div>
                </div>

                <div class="col-md-12 my-2">
                  <span class="title"><b>{{'Project Description' | concat | translate}}</b></span>
                  <div><span [innerHTML]="project?.details"></span></div>
                </div>

                <div class="col-md-4 my-2">
                  <span class="title"><b>{{'Contractor' | concat | translate}}</b></span>
                  <div>{{ project?.contractor}}</div>
                </div>

                <div class="col-md-4 my-2">
                  <span class="title"><b>{{'Employer Name' | concat | translate}}</b></span>
                  <div>{{ project?.employer}}</div>
                </div>

                <div class="col-md-4 my-2">
                  <span class="title"><b>{{'Objective' | concat | translate}}</b></span>
                  <div>{{ project?.objective}}</div>
                </div>

                <div class="col-md-4 my-2">
                  <span class="title"><b>{{'Contract Date' | concat | translate}}</b></span>
                  <div>{{ project?.signatureDate | date: 'dd MMM yyyy'}}</div>
                </div>

                <div class="col-md-4 my-2">
                  <span class="title"><b>{{'Commencement Date' | concat | translate}}</b></span>
                  <div>{{ project?.commencementDate | date: 'dd MMM yyyy'}}</div>
                </div>

                <div class="col-md-4 my-2">
                  <span class="title"><b>{{'Completion Date' | concat | translate}}</b></span>
                  <div>{{ project?.completionDate | date: 'dd MMM yyyy'}}</div>
                </div>

                <div class="col-md-4 my-2">
                  <span class="title"><b>{{'Allocated Budget' | concat | translate}} </b></span>
                  <div>TZS. {{ project?.allocatedBudget | number}}</div>
                </div>

                <div class="col-md-4 my-2">
                  <span class="title"><b>{{'Government Fund' | concat | translate}}</b> </span>
                  <div>TZS. {{ project?.governmentFund | number}}</div>
                </div>

                <div class="col-md-4 my-2">
                  <span class="title"><b>{{'Foreign Fund' | concat | translate}}</b> </span>
                  <div>TZS. {{ project?.foreignFund | number}}</div>
                </div>
                <div class="col-md-4 my-2">
                  <span class="title"><b>{{'Status' | concat | translate}}</b> </span>
                  <div>{{ project?.formattedStatus}}</div>
                </div>

              </div>
            </mat-card>
          </div>
        </div>

        <ng-container *ngIf="project?.components?.length > 0">

            <h5>Components</h5>
            <div *ngFor="let component of project?.components" class="col-md-12 alert  alert alert-info">
              <div class="row">
                <div class="col-md-3">
                  <b>{{'Component Name' | concat | translate}}</b> <br>{{ component?.name}}
                </div>

                <div class="col-md-3">
                  <b>{{'Component Start Date' | concat | translate}}</b> <br>{{ component?.startDate | date: 'dd MMM yyyy'}}
                </div>

                <div class="col-md-3">
                  <b>{{'Component Completion Date' | concat | translate}}</b> <br>{{ component?.handoverDate}}
                </div>

                <div class="col-md-3">
                  <b>{{'Component Status' | concat | translate}}</b> <br>{{ component?.status}}
                </div>

              </div>

              <!--tasks-->
              <ng-container *ngIf="component?.projectTasks?.length > 0">
                <br>
                <b>{{'Component Tasks' | concat | translate}}</b>
                <br>
                <hr>
                <div class="row pl-4">
                  <div *ngFor="let task of component?.projectTasks" [style]="{background: '#bcdcef'}" class="col-md-12 alert alert-info">
                    <div class="row">
                      <div class="col-md-3">
                        <b>{{'Name' | concat | translate}}</b> <br>{{ task?.name}}
                      </div>

                      <div class="col-md-3">
                        <b>{{'Budget' | concat | translate}}</b> <br>{{ task?.budget | number}}
                      </div>

                      <div class="col-md-3">
                        <b>{{'Location' | concat | translate}}</b> <br>{{ task?.location}}
                      </div>

                      <div class="col-md-3">
                        <b>{{'Manager' | concat | translate}}</b> <br>{{ task?.manager}}
                      </div>

                      <div class="col-md-3">
                        <b>{{'Percent Composition' | concat | translate}}</b> <br>{{ task?.percentCompositionOnComponent}} %
                      </div>

                      <div class="col-md-3">
                        <b>{{'Start Date' | concat | translate}}</b> <br>{{ task?.startDate}}
                      </div>

                      <div class="col-md-3">
                        <b>{{'Completion Date' | concat | translate}}</b> <br>{{ task?.handoverDate}}
                      </div>

                    </div>

                    <ng-container *ngIf="task?.taskProgress">
                      <br>
                      <b>{{'Task Progress' | concat | translate}}</b>
                      <br>
                      <hr>
                      <table [style]="{background: '#bcdcef'}" class="table table-info table-hover table-bordered col-md-12">
                        <thead>
                        <tr>
                          <td><b>{{'No' | concat | translate}}.</b></td>
                          <td><b>{{'Description' | concat | translate}}</b></td>
                          <td><b>{{'Reported Date' | concat | translate}}</b></td>
                          <td><b>{{'Status' | concat | translate}}</b></td>
                        </tr>
                        </thead>

                        <tbody>
                        <tr *ngFor="let progress of task?.taskProgress; let i = index">
                          <td>{{ i + 1}}</td>
                          <td [innerHTML]="progress?.descriptions"></td>
                          <td>{{ progress?.recordedOn | date: 'dd MMM yyyy'}}</td>
                          <td>{{ progress?.status}}</td>
                        </tr>
                        </tbody>

                      </table>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <!--/tasks-->
            </div>
          </ng-container>

        <div *ngIf="challenges$ | async as challenges" class="col-md-12">
          <table class="table table-hover table-striped table-bordered">
            <thead>
            <tr>
              <th>{{'Challenges' | concat | translate}}</th>
              <th>{{'Way Forward' | concat | translate}}</th>
            </tr>
            </thead>

            <tbody>
            <ng-container *ngFor="let challenge of challenges">
              <tr>
                <td><span [innerHTML]="challenge?.challenge"></span> </td>
                <td><span [innerHTML]="challenge?.wayForward"></span> </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>

        <div class="col-md-12 text-center mt-2">
          <p>{{'eCabinet' | concat | translate}} &copy; {{ today | date: 'yyyy' }} {{'All Rights Reserved' | concat | translate}}. </p>
        </div>

      </div>
    </div>

  </div>


  <div class="col-md-12 mt-3">
    <div class="float-end">

      <button [printStyle]="{h2 : {'font-size': '20px !important'}, h3: {'font-size': '16px !important'}}"
              [useExistingCss]="true" color="warn" mat-raised-button ngxPrint
              printSectionId="printSection"
              printTitle="Project Report - {{ project?.project_name}}">
        <mat-icon class="material-symbols-outlined">print</mat-icon>
        {{'Print' | concat | translate}}
      </button>
    </div>
  </div>
