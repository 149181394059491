import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {
  deleteTaskResponse,
  getAllTaskResponsesByTaskUid,
  loadTaskResponses,
  removeTaskResponse,
  saveTaskResponse
} from './task-response.actions';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {DELETE_TASK_RESPONSE, GET_TASK_RESPONSE_BY_ASSIGNMENT, SAVE_TASK_RESPONSE} from './task-response.graphql';
import {SettingsService} from "../../../../services/settings.service";

@Injectable()
export class TaskResponseEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllTaskResponsesByTaskUid),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_TASK_RESPONSE_BY_ASSIGNMENT,
        variables: { assignment: action.uid}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadTaskResponses({taskResponses: result?.dataList}));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveTaskResponse),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_TASK_RESPONSE,
        variables: { inputDto: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.settingsService.reloadCurrentRoute(null);
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeTaskResponse),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_TASK_RESPONSE,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteTaskResponse({id: action.id}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private settingsService: SettingsService,
    private notificationService: NotificationService,
    private apollo: Apollo
  ) {
  }
}
