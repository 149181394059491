<app-modal-header  [title]="title"></app-modal-header>

<form (ngSubmit)="add(form.value)" [formGroup]="form"  autocomplete="off" novalidate>
  <div class="row">
    <div class="col-md-12">
      <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{'Progress Description' | concat | translate}}</mat-label>
        <textarea cols="3" formControlName="description" matInput rows="10"></textarea>
        <mat-error>
                <span *ngIf="!form.get('description').valid && form.get('description').touched">
                    {{'this field is required' | concat | translate}}
                </span>
        </mat-error>
      </mat-form-field>
    </div>

<!--    <div class="col-md-6">-->
<!--      <mat-form-field appearance="outline" class="material-form">-->
<!--        <mat-label>Activity Date </mat-label>-->
<!--        <input [matDatepicker]="picker" formControlName="dateOfActivity" matInput>-->
<!--        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>-->
<!--        <mat-datepicker #picker></mat-datepicker>-->
<!--        <mat-error>-->
<!--                <span *ngIf="!form.get('dateOfActivity').valid && form.get('dateOfActivity').touched">-->
<!--                    {{'this field is required' | concat | translate}}-->
<!--                </span>-->
<!--        </mat-error>-->
<!--      </mat-form-field>-->
<!--    </div>-->

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{'Completion Status' | concat | translate}} </mat-label>
        <mat-select formControlName="status">
          <mat-option *ngFor="let data of status" [value]="data">{{ data }}</mat-option>
        </mat-select>
        <mat-error>
            <span *ngIf="!form.get('status').valid && form.get('status').touched">
                {{'this field is required' | concat | translate}}
            </span>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-4">
      <input #importInput (change)="onSingleFileSelected($event)" accept=".pdf" style="display: none" type="file">
      <button (click)="importInput.click()" class="mx-1 btm-sm" color="primary" mat-raised-button type="button">
        <span>{{'attach ps letter' | translate}}</span>
      </button>

      <ng-container *ngIf="filePath;">

        <button (click)="viewAttachment(attachmentConfig?.id)" color="primary" class="mx-1" mat-mini-fab
                matTooltip="{{ 'view attachment' | concat | translate}}" type="button">
          <mat-icon svgIcon="document"></mat-icon>
        </button>

        <button (click)="removeSingleFile(attachmentConfig)" mat-mini-fab class="bg-danger"
                matTooltip="{{'Remove' | concat | translate}}" type="button">
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </ng-container>
    </div>

  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="text-end">
        <button [disabled]="!form.valid" color="primary" mat-raised-button type="submit">
          {{'Save' | concat | translate}}
        </button>
      </div>
    </div>
  </div>
</form>
