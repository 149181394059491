
<mat-sidenav-container class="sidenav-container">

  <mat-sidenav #drawer class="sidenav"
               style="{{ loggedInUserType !== UserTypeEnum.Staff ? '--sidenav-width: 240px; --sidebar-width: 3rem' : '' }}"
               fixedInViewport
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="(isHandset$ | async) === false">

    <ng-container *ngIf="loggedInUserType === UserTypeEnum.Staff; else otherUser">
      <div class="d-flex flex-nowrap">

        <div class="d-flex flex-column flex-shrink-0 bg-primary-subtle h-100 vertical-menu vh-100">

          <a href="/home" class="d-block p-3 text-decoration-none" data-bs-toggle="tooltip">
            <img src="assets/images/emblem.png" class="w-100" alt="emblem">
          </a>

          <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">

            <ng-container *ngFor="let menuItem of sidebarMenu">
              <li class="nav-item" *ngxPermissionsOnly="menuItem?.permissions">
                <a class="nav-link py-3 border-bottom rounded-0"
                   routerLink="{{ menuItem.route }}"
                   routerLinkActive="active"
                   matTooltip="{{ menuItem.name | concat | translate }}"
                   matTooltipPosition="right">
                  <mat-icon *ngIf="menuItem?.iconType === IconTypeEnum.MATERIAL">{{ menuItem.icon }}</mat-icon>
                  <mat-icon *ngIf="menuItem?.iconType === IconTypeEnum.SVG" svgIcon="{{ menuItem.icon }}"></mat-icon>
                </a>
              </li>
            </ng-container>

          </ul>

        </div>

        <div class="flex-shrink-0 module-sidebar">
          <app-sidebar [sidebarMenu]="moduleMenuItems"
                       [moduleTitle]="moduleTitle"
                       [moduleIconTitle]="moduleIconTitle"
                       [moduleIconType]="moduleIconType">
          </app-sidebar>
        </div>
      </div>

    </ng-container>

    <ng-template #otherUser>
      <div class="">
        <app-sidebar [sidebarMenu]="moduleMenuItems"
                     [moduleTitle]="moduleTitle"
                     [moduleIconTitle]="moduleIconTitle"
                     [moduleIconType]="moduleIconType">
        </app-sidebar>
      </div>
    </ng-template>

  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar class="bg-white py-2" style="height: 60px !important;">
<!--      <button type="button" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">-->
      <button type="button" mat-icon-button (click)="drawer.toggle()">
        <mat-icon svgIcon="menu"></mat-icon>
      </button>
      <span>
         {{'eCabinet' | concat | translate}}
        <span *ngIf="!isProduction" class="demo-banner"> DEMO</span>

      </span>

      <span class="spacer"></span>

      <ng-container *ngIf="authUser$ | async as authUser">
        <app-top-right-toolbar [loggedUser]="authUser" [loggedInUserType]="authUser?.userType"></app-top-right-toolbar>
      </ng-container>
    </mat-toolbar>

    <div class="container py-2">
      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>


