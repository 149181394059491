import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TwoFaServiceService} from '../../../../services/two-fa-service.service';
import {NotificationService} from '../../../../services/notification.service';
import {WebAuthService} from "../../../../services/web-auth.service";

@Component({
  selector: 'app-two-factor-auth-form',
  templateUrl: './two-factor-auth-login.component.html',
  styleUrls: ['./two-factor-auth-login.component.scss']
})
export class TwoFactorAuthLoginComponent implements OnInit {

  title: any;
  form: UntypedFormGroup;
  ticket: string;
  countDown = 60;
  dangerBreakPoint = 15;

  userEmail: string;
  totp: boolean;
  passkey: boolean;

  constructor(
    private fb: FormBuilder,
    private twoFaService: TwoFaServiceService,
    private dialogRef: MatDialogRef<TwoFactorAuthLoginComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private notificationService: NotificationService,
    private webAuthService: WebAuthService,
  ) {
    this.ticket = data.ticket;
    this.userEmail = data.userEmail;
    this.totp = data.totp;
    this.passkey = data.passkey;
  }

  async ngOnInit() {
    this.title = '2Factor Authentication';
    if (this.passkey) {
      this.webAuthService.authenticateUser(this.userEmail, this.ticket).then((response: any) => {
        if (!response?.access_token) {
          this.notificationService.errorMessage(response?.message);
        } else {
          this.closeDialog(response);
        }
      });
    }

    this.form = this.fb.group({
      twoFACode: [null, Validators.required],
    });
    setInterval(() => {
      if (this.countDown > 0) { this.countDown -= 1; }
      if (this.countDown <= 0) {
        this.closeDialog();
      }
    }, 1000);
  }

  async submit2FA(formValue: any) {
    const response = await this.twoFaService.validate2FaToken(this.ticket, formValue.twoFACode.toString());
    if (!response?.verified) {
      this.notificationService.errorMessage(response?.message);
    } else {
      this.closeDialog(response);
    }
  }

  closeDialog(data: any = null) {
    this.dialogRef.close(data);
  }

}
