import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TaskResponse} from './task-response.model';
import * as TaskResponseActions from './task-response.actions';

export const taskResponsesFeatureKey = 'taskResponses';

export interface State extends EntityState<TaskResponse> {
  // additional entities state properties
}

export const adapter: EntityAdapter<TaskResponse> = createEntityAdapter<TaskResponse>({
  // sortComparer: sortByTaskResponseName
});

// export const adapter: EntityAdapter<TaskResponse> = createEntityAdapter<TaskResponse>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const taskResponseResponseReducer = createReducer(
  initialState,
  on(TaskResponseActions.addTaskResponse,
    (state, action) => adapter.addOne(action.taskResponse, state)
  ),
  on(TaskResponseActions.upsertTaskResponse,
    (state, action) => adapter.upsertOne(action.taskResponse, state)
  ),
  on(TaskResponseActions.addTaskResponses,
    (state, action) => adapter.addMany(action.taskResponses, state)
  ),
  on(TaskResponseActions.upsertTaskResponses,
    (state, action) => adapter.upsertMany(action.taskResponses, state)
  ),
  on(TaskResponseActions.updateTaskResponse,
    (state, action) => adapter.updateOne(action.taskResponse, state)
  ),
  on(TaskResponseActions.updateTaskResponses,
    (state, action) => adapter.updateMany(action.taskResponses, state)
  ),
  on(TaskResponseActions.deleteTaskResponse,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(TaskResponseActions.deleteTaskResponses,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(TaskResponseActions.loadTaskResponses,
    (state, action) => adapter.setAll(action.taskResponses, state)
  ),
  on(TaskResponseActions.clearTaskResponses,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return taskResponseResponseReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllTaskResponses = selectAll;
export const getTaskResponseById = selectIds;
export const getTaskResponseEntities = selectEntities;
