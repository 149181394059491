
export const baseField = `
  id
  uniqueId
`;

export const responseField = `
  code
  message
`;

export const basePageableGraphqlField = `
    totalElements
    numberOfPages
    currentPageNumber
`;

