import gql from 'graphql-tag';
import {baseField, basePageableGraphqlField, responseField} from "../../base.graphql";
import {institutionField} from '../../settings/institution/institution.graphql';

export const minutesDirectivesField = `
  ${baseField}
  title
  descriptions
  dueDate
  completedDate
  completedStatus
  implementationStatus
  directedInstitutions{
    ${institutionField}
  }
`;

export const GET_MINUTES_DIRECTIVES = gql`
  query getAllDirectives($filtering: DirectiveSearchingInputObjects) {
    getAllDirectives(filtering: $filtering) {
      response{
        ${responseField}
      }
      page {
        ${basePageableGraphqlField}
      }
      data{
        ${minutesDirectivesField}
      }
    }
  }
`;

export const GET_DIRECTIVES_PER_MINISTRY_DASHBOARD = gql`
  query getDirectiveByMinistryDashboard {
    getDirectiveByMinistryDashboard{
      response{
        ${responseField}
      }
      data{
        id
        uniqueId
        ministry{
          ${institutionField}
        }
        completed
        notCompleted
      }
    }
  }
`;



export const SAVE_MINUTES_DIRECTIVES = gql`
  mutation addMinutesDirectives($directive: MinutesDirectivesInputObjects!) {
    addMinutesDirectives(directive: $directive){
      response{
        ${responseField}
      }
      data{
        ${minutesDirectivesField}
      }
    }
  }
`;

export const UPDATE_MINUTES_DIRECTIVES = gql`
  mutation updateMinutesDirectives($directive: MinutesDirectivesInputObjects!) {
    updateMinutesDirectives(directive: $directive){
      response{
        ${responseField}
      }
      data{
        ${minutesDirectivesField}
      }
    }
  }
`;

export const DELETE_MINUTES_DIRECTIVES = gql`
  mutation deleteMinutesDirectives($directiveUniqueId: String!) {
    deleteMinutesDirectives(directiveUniqueId: $directiveUniqueId){
      response{
        ${responseField}
      }
    }
  }
`;


export const GET_ALL_AGENDA_DIRECTIVES = gql`
query GetAllAgendaDirectives($agendaUniqueId: String) {
  getAllAgendaDirectives(agendaUniqueId: $agendaUniqueId) {
    response {
      ${responseField}
    }
    data {
      ${minutesDirectivesField}
    }
  }
}
`
