import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';
import {institutionField} from '../../settings/institution/institution.graphql';

export const planField = `
  ${baseField}
  title
  submissionDate
  recordedOn
  institution{
    ${institutionField}
  }
`;

export const GET_ALL_PAPER_PLANS = gql`
  query allPaperSubmissionPlans($search: String) {
    allPaperSubmissionPlans(search: $search) {
      response{
        ${responseField}
      }
      dataList{
        ${planField}
      }
    }
  }
`;

export const GET_PLANS_BY_INSTITUTION = gql`
  query allInstitutionPaperSubmissionPlans($institution: String) {
    allInstitutionPaperSubmissionPlans(institution: $institution) {
      response{
        ${responseField}
      }
      dataList{
        ${planField}
      }
    }
  }
`;

export const SAVE_PAPER_PLAN = gql`
  mutation CreateUpdateCabinetPaperSubmissionPlan($paperSubmissionPlanDto: CabinetPaperSubmissionPlanObjectsInputObjects!) {
    CreateUpdateCabinetPaperSubmissionPlan(paperSubmissionPlanDto: $paperSubmissionPlanDto){
      response{
        ${responseField}
      }
      data{
        ${planField}
      }
    }
  }
`;

export const DELETE_PAPER_PLAN = gql`
  mutation DeleteCabinetPaperSubmissionPlan($uniqueId: String!) {
    DeleteCabinetPaperSubmissionPlan(uniqueId: $uniqueId){
      response{
        ${responseField}
      }
    }
  }
`;
