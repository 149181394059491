import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {NotificationService} from 'src/app/services/notification.service';
import {SettingsService} from 'src/app/services/settings.service';
import {UserTypeEnum} from 'src/app/store/entities/settings/user/user.model';
import {AppState} from 'src/app/store/entities';
import {Directive, DirectiveMovementType} from '../../../../store/entities/meetings/directive/directive.model';
import {actionDirectives, getAllDirectives} from '../../../../store/entities/meetings/directive/directive.actions';
import {selectDirectives} from '../../../../store/entities/meetings/directive/directive.selectors';
import {ActionTypeEnum} from '../../../enums/data.enums';
import {DirectiveResponseFormComponent} from '../../forms/directive-response-form/directive-response-form.component';
import {environment} from '../../../../../environments/environment';
import {ViewAttachmentComponent} from '../view-attachment/view-attachment.component';
import {DirectiveMovementFormComponent} from '../../forms/directive-movement-form/directive-movement-form.component';

@Component({
  selector: 'app-directive-list',
  templateUrl: './directive-list.component.html',
  styleUrls: ['./directive-list.component.scss']
})
export class DirectiveListComponent implements OnInit {

  title: string;
  tableColumns: any;
  subscription: Subscription = new Subscription();
  userTypes: string[] = Object.keys(UserTypeEnum);
  directives$: Observable<Directive[]>;
  actionTypeEnum = ActionTypeEnum;
  directiveMovementType = DirectiveMovementType;
  showAll = true;
  selectedDirective: Directive;

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
  ) {
    this.store.dispatch(getAllDirectives());
  }

  ngOnInit(): void {
    this.title = 'Directives';
    this.tableColumns = {
      no: 'SN',
      directive_descriptions: 'Directive',
      directive_decision: 'Decision',
      // directive_committee: 'Assigned Committee',
      // directive_handler: 'Assigned Staff',
      response: 'Response',
      action: 'Actions'
    };

    this.directives$ = this.store.select(selectDirectives);
  }


  addRespond(directive: Directive): void {
    this.settingsService.openModal({ directive}, DirectiveResponseFormComponent);
  }

  viewAttachment(path: string) {
    this.settingsService.openModal({
        src: environment.SERVER_URL + path, showDownloadButton: false
      },
      ViewAttachmentComponent);
  }
  // tslint:disable-next-line:variable-name
  directiveAction(id: number, actionType: ActionTypeEnum) {
    this.notificationService.confirmation('Are you sure ?', 'Yes').then(data => {
      if (data) {
        const formData = {
          id: id.toString(),
          actionType
        };
        this.store.dispatch(actionDirectives({ formData }));
      }
    });
  }

  viewDetail(data: Directive) {
    this.showAll = !this.showAll;
    this.selectedDirective = {
      ...data,
      assignedStaff: data?.directive_handler?.map( res => res?.full_name).join(' , ')
    };
  }

  move(directive: Directive, movementType: DirectiveMovementType) {
    this.settingsService.openModal({ directive, movementType}, DirectiveMovementFormComponent);
  }
}
