import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Project} from './project.model';
import * as ProjectActions from './project.actions';

export const projectsFeatureKey = 'Projects';

export interface State extends EntityState<Project> {
  // additional entities state properties
}
//
export function sortByProjectName(a: Project, b: Project): any {
  // return a?.name.localeCompare(b?.name);
}

export const adapter: EntityAdapter<Project> = createEntityAdapter<Project>({
  // sortComparer: sortByProjectName
});

// export const adapter: EntityAdapter<Project> = createEntityAdapter<Project>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const projectReducer = createReducer(
  initialState,
  on(ProjectActions.addProject,
    (state, action) => adapter.addOne(action.project, state)
  ),
  on(ProjectActions.upsertProject,
    (state, action) => adapter.upsertOne(action.project, state)
  ),
  on(ProjectActions.addProjects,
    (state, action) => adapter.addMany(action.projects, state)
  ),
  on(ProjectActions.upsertProjects,
    (state, action) => adapter.upsertMany(action.projects, state)
  ),
  on(ProjectActions.updateProject,
    (state, action) => adapter.updateOne(action.project, state)
  ),
  on(ProjectActions.updateProjects,
    (state, action) => adapter.updateMany(action.projects, state)
  ),
  on(ProjectActions.deleteProject,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ProjectActions.deleteProjects,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ProjectActions.loadProjects,
    (state, action) => adapter.setAll(action.projects, state)
  ),
  on(ProjectActions.clearProjects,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return projectReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// export entity selectors
export const getAllProjects = selectAll;
