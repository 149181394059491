import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const institutionField = `
  ${baseField}
  name
  shortform
`;

export const GET_INSTITUTION = gql`
  query getInstitutions {
    getInstitutions {
      response{
        ${responseField}
      }
      data{
        ${institutionField}
      }
    }
  }
`;

export const SAVE_INSTITUTION = gql`
  mutation institutionsManagement($inputDto: InstitutionsInputObjects!) {
    institutionsManagement(institution: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${institutionField}
      }
    }
  }
`;
