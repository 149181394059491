 <app-modal-header [title]="title"></app-modal-header>

  <form (ngSubmit)="submitForm(form.value)" [formGroup]="form" autocomplete="off" novalidate>
    <div class="row">

      <div class="col-md-12">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Select Staff' | concat | translate}}</mat-label>
          <mat-select formControlName="responder_transfer_to" required>
            <mat-option *ngFor="let user of users$ | async" [value]="user?.id.toString()">
              {{ user?.firstName}} {{ user?.lastName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="text-end">
          <button [disabled]="!form.valid" color="primary" mat-raised-button type="submit">
            {{'Save' | concat | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
