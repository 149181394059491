import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {DocumentComment, DocumentCommentInput} from './document-comment.model';

export const loadDocumentComments = createAction(
  '[DocumentComment/API] Load DocumentComments',
  props<{ documentComments: DocumentComment[] }>()
);

export const addDocumentComment = createAction(
  '[DocumentComment/API] Add DocumentComment',
  props<{ documentComment: DocumentComment }>()
);

export const upsertDocumentComment = createAction(
  '[DocumentComment/API] Upsert DocumentComment',
  props<{ documentComment: DocumentComment }>()
);

export const addDocumentComments = createAction(
  '[DocumentComment/API] Add DocumentComments',
  props<{ documentComments: DocumentComment[] }>()
);

export const upsertDocumentComments = createAction(
  '[DocumentComment/API] Upsert DocumentComments',
  props<{ documentComments: DocumentComment[] }>()
);

export const updateDocumentComment = createAction(
  '[DocumentComment/API] Update DocumentComment',
  props<{ documentComment: Update<DocumentComment> }>()
);

export const updateDocumentComments = createAction(
  '[DocumentComment/API] Update DocumentComments',
  props<{ documentComments: Update<DocumentComment>[] }>()
);

export const deleteDocumentComment = createAction(
  '[DocumentComment/API] Delete DocumentComment',
  props<{ id: number }>()
);

export const deleteDocumentComments = createAction(
  '[DocumentComment/API] Delete DocumentComments',
  props<{ ids: number[] }>()
);

export const clearDocumentComments = createAction(
  '[DocumentComment/API] Clear DocumentComments'
);


export const getAllDocumentComment = createAction(
  '[DocumentComment/API] Get All DocumentComments',
);

export const removeDocumentComment = createAction(
  '[DocumentComment/API] remove DocumentComment',
  props<{ uniqueId: string, id: number }>()
);

export const saveDocumentComment = createAction(
  '[DocumentComment/API] Save DocumentComment',
  props<{ input: DocumentCommentInput }>()
);

export const dismissDocumentComment = createAction(
  '[DocumentComment/API] Dismiss DocumentComment',
  props<{ uniqueId: string, id: number }>()
);



