import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'strSpace'
})
export class StrSpacePipe implements PipeTransform {

  transform(value: string): string {
    return  value.replace(/_/g, ' ');
  }

}
