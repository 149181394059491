import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Recommendations} from './recommendations.model';
import * as RecommendationsActions from './recommendations.actions';

export const recommendationssFeatureKey = 'recommendations';

export interface State extends EntityState<Recommendations> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Recommendations> = createEntityAdapter<Recommendations>({
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const RecommendationsReducer = createReducer(
  initialState,
  on(RecommendationsActions.addRecommendations,
    (state, action) => adapter.addOne(action.recommendations, state)
  ),
  on(RecommendationsActions.upsertRecommendations,
    (state, action) => adapter.upsertOne(action.recommendations, state)
  ),
  on(RecommendationsActions.addRecommendationss,
    (state, action) => adapter.addMany(action.recommendationss, state)
  ),
  on(RecommendationsActions.upsertRecommendationss,
    (state, action) => adapter.upsertMany(action.recommendationss, state)
  ),
  on(RecommendationsActions.updateRecommendations,
    (state, action) => adapter.updateOne(action.recommendations, state)
  ),
  on(RecommendationsActions.updateRecommendationss,
    (state, action) => adapter.updateMany(action.recommendationss, state)
  ),
  on(RecommendationsActions.deleteRecommendations,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(RecommendationsActions.deleteRecommendationss,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(RecommendationsActions.loadRecommendationss,
    (state, action) => adapter.setAll(action.recommendationss, state)
  ),
  on(RecommendationsActions.clearRecommendationss,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return RecommendationsReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getRecommendationss = selectAll;
