import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {AssignmentConfiguration, AssignmentConfigurationDto} from './assignment-configuration.model';

export const loadAssignmentConfigurations = createAction(
  '[AssignmentConfiguration/API] Load AssignmentConfigurations',
  props<{ assignmentConfigurations: AssignmentConfiguration[] }>()
);

export const addAssignmentConfiguration = createAction(
  '[AssignmentConfiguration/API] Add AssignmentConfiguration',
  props<{ assignmentConfiguration: AssignmentConfiguration }>()
);

export const upsertAssignmentConfiguration = createAction(
  '[AssignmentConfiguration/API] Upsert AssignmentConfiguration',
  props<{ assignmentConfiguration: AssignmentConfiguration }>()
);

export const addAssignmentConfigurations = createAction(
  '[AssignmentConfiguration/API] Add AssignmentConfigurations',
  props<{ assignmentConfigurations: AssignmentConfiguration[] }>()
);

export const upsertAssignmentConfigurations = createAction(
  '[AssignmentConfiguration/API] Upsert AssignmentConfigurations',
  props<{ assignmentConfigurations: AssignmentConfiguration[] }>()
);

export const updateAssignmentConfiguration = createAction(
  '[AssignmentConfiguration/API] Update AssignmentConfiguration',
  props<{ assignmentConfiguration: Update<AssignmentConfiguration> }>()
);

export const updateAssignmentConfigurations = createAction(
  '[AssignmentConfiguration/API] Update AssignmentConfigurations',
  props<{ assignmentConfigurations: Update<AssignmentConfiguration>[] }>()
);

export const deleteAssignmentConfiguration = createAction(
  '[AssignmentConfiguration/API] Delete AssignmentConfiguration',
  props<{ id: number }>()
);

export const deleteAssignmentConfigurations = createAction(
  '[AssignmentConfiguration/API] Delete AssignmentConfigurations',
  props<{ ids: string[] }>()
);

export const clearAssignmentConfigurations = createAction(
  '[AssignmentConfiguration/API] Clear AssignmentConfigurations'
);

export const getAllAssignmentConfigurations = createAction(
  '[AssignmentConfigurations/API] List All AssignmentConfigurations'
);

export const saveAssignmentConfiguration = createAction(
  '[AssignmentConfiguration/API] save assignmentConfiguration',
  props<{ assignmentConfigurationDto: AssignmentConfigurationDto }>()
);

export const editAssignmentConfiguration = createAction(
  '[AssignmentConfiguration/API] edit assignmentConfiguration',
  props<{ assignmentConfigurationDto: AssignmentConfigurationDto }>()
);

export const removeAssignmentConfiguration = createAction(
  '[AssignmentConfiguration/API] remove assignmentConfiguration',
  props<{ uniqueId: string; id: number }>()
);
