 <app-modal-header [title]="title"></app-modal-header>

 <div *ngIf="decision" class="alert alert-info">
   {{'Decision' | concat | translate}}: <span [innerHTML]="decision?.decision_descriptions "></span>
 </div>

  <form (ngSubmit)="submitForm(form.value)" [formGroup]="form" autocomplete="off" novalidate>
    <div class="row">

      <div class="col-md-12">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Description' | concat | translate}}</mat-label>
          <textarea formControlName="directive_descriptions" matInput required></textarea>
          <mat-error>
                <span *ngIf="!form.get('directive_descriptions').valid && form.get('directive_descriptions').touched">
                    {{'this is required' | concat | translate}}
                </span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'assigned To Staff Only' | concat | translate}}</mat-label>
          <mat-select formControlName="directive_to_person">
            <mat-option [value]="true">{{'Yes' | concat | translate}}</mat-option>
            <mat-option [value]="false">{{'No' | concat | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="form.get('directive_to_person').value" class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Select Staff' | concat | translate}}</mat-label>
          <mat-select formControlName="directive_handler">
            <mat-option *ngFor="let user of users$ | async" [value]="user?.id.toString()">
              {{ user?.firstName}} {{ user?.lastName}}
            </mat-option>
          </mat-select>
          <mat-error>
                <span *ngIf="!form.get('directive_handler').valid && form.get('directive_handler').touched">
                    {{'This field is required' | concat | translate}}.
                </span>
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="!form.get('directive_to_person').value" class="col-md-4">
        <mat-form-field appearance="outline" class="material-form">
          <mat-label>{{'Select Committee' | concat | translate}}</mat-label>
          <mat-select formControlName="directive_committee">
            <mat-option *ngFor="let committee of committees$ | async" [value]="committee?.id.toString()">{{ committee?.shortform}}</mat-option>
          </mat-select>
          <mat-error>
                <span *ngIf="!form.get('directive_committee').valid && form.get('directive_committee').touched">
                    {{'This field is required' | concat | translate}}
                </span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
          <div class="col-md-12 pt-2">
            <input #importInput (change)="onSingleFileSelected($event)" accept=".pdf" style="display: none" type="file">
            <button (click)="importInput.click()" class="mx-1 btm-sm" color="primary" mat-raised-button type="button">
              {{'Attachment' | concat | translate}}
            </button>
          </div>
          <div class="col-md-8 pt-3">
            {{documentTitle}}
          </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="text-end">
          <button [disabled]="!form.valid" color="primary" mat-raised-button type="submit">
            {{'Save' | concat | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
