import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Role, RoleDto} from './role.model';

export const loadRoles = createAction(
  '[Role/API] Load Roles',
  props<{ roles: Role[] }>()
);

export const addRole = createAction(
  '[Role/API] Add Role',
  props<{ role: Role }>()
);

export const upsertRole = createAction(
  '[Role/API] Upsert Role',
  props<{ role: Role }>()
);

export const addRoles = createAction(
  '[Role/API] Add Roles',
  props<{ roles: Role[] }>()
);

export const upsertRoles = createAction(
  '[Role/API] Upsert Roles',
  props<{ roles: Role[] }>()
);

export const updateRole = createAction(
  '[Role/API] Update Role',
  props<{ role: Update<Role> }>()
);

export const updateRoles = createAction(
  '[Role/API] Update Roles',
  props<{ roles: Update<Role>[] }>()
);

export const deleteRole = createAction(
  '[Role/API] Delete Role',
  props<{ id: number }>()
);

export const deleteRoles = createAction(
  '[Role/API] Delete Roles',
  props<{ ids: string[] }>()
);

export const clearRoles = createAction(
  '[Role/API] Clear Roles'
);


export const getAllRoles = createAction(
  '[Roles/API] List All Roles',
  props<{ uid?: string}>()
);

export const actionRole = createAction(
  '[Role/API] create, edit & delete role',
  props<{ roleDto: RoleDto, id?: number }>()
);
