<div class="col-12">
  <div class="row page-title">
    <div class="col-md-4 d-flex align-items-center">

      <button (click)="goBack()" *ngIf="useBackButton && isOnline" class="btn-sm btn-back me-2 d-print-none" color="primary" mat-mini-fab
              matTooltip="{{'go back' | concat | translate}}">
        <mat-icon svgIcon="back-arrow"></mat-icon>
      </button>

      <div>
        <span *ngIf="!isBoldedTitle" class="mb-1 pb-0 title fs-5 fw-bold "> {{ title | concat | translate}}</span>
        <span *ngIf="isBoldedTitle" class="mb-1 bolded-title fs-4 pb-0 fw-500"> {{ title | concat | translate}}</span>

        <ng-container *ngIf="subTitle">
          <div><span class="mb-1 sub-title fs-6 pb-0"> <small><i>{{ subTitle }}</i></small></span></div>
        </ng-container>
      </div>

    </div>

    <div class="col-md-8 text-end mb-1 d-flex d-md-block align-items-center justify-content-end">
      <ng-container *ngTemplateOutlet="topMenu"></ng-container>
    </div>
  </div>
</div>

<hr class="line bg-secondary">










