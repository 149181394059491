import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';
import {projectComponentField} from '../project-component/project-component.graphql';

export const projectField = `
  ${baseField}
  name
  location
  objective
  employer
  engineerName
  consultant
  contractor
  duration
  tenderName
  tenderNumber
  signatureDate
  commencementDate
  completionDate
  expectedOutcome
  allocatedBudget
  governmentFund
  foreignFund
  domesticEmployees
  domesticForeign
  details
  recordedOn
  projectStatusEnum
  isActive
  institution{
    ${baseField}
    name
    shortform
  }
`;

export const GET_PROJECTS = gql`
  query allProjects($inputDto: ProjectFilteringInputObjects) {
    allProjects(filtering: $inputDto) {
      response{
        ${responseField}
      }
      data{
        ${projectField}
      }
    }
  }
`;

export const GET_PROJECT_BY_UID = gql`
  query getProjectById($uid: String, $withComponents: Boolean!) {
    getProjectById(projectUniqueId: $uid) {
      response{
        ${responseField}
      }
      data{
        ${projectField}
        components @include(if: $withComponents){
          ${projectComponentField}
        }
      }
    }
  }
`;

export const SAVE_PROJECT = gql`
  mutation createProjectMutation($inputDto: ProjectInputObjects!) {
    createProjectMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectField}
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProjectMutation($inputDto: ProjectInputObjects!) {
    updateProjectMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectField}
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProjectMutation($uid: String!) {
    deleteProjectMutation(uniqueId: $uid){
      response{
        ${responseField}
      }
    }
  }
`;

export const GET_PROJECT_GANT_CHART = gql`
  query getGantChatActivitiesData($uid: String) {
    getGanntChatActivitiesData(projectUniqueId: $uid){
      response{
        ${responseField}
      }
      data {
        duration:activityDuration
        endDate:activityEndDate
        status:activityImplementationStatus
        name:activityName
        startDate:activityStartDate
      }
    }
  }
`;
