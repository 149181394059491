import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ProjectProgress} from './project-progress.model';
import * as ProjectProgressActions from './project-progress.actions';

export const projectProgresssFeatureKey = 'ProjectProgresss';

export interface State extends EntityState<ProjectProgress> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ProjectProgress> = createEntityAdapter<ProjectProgress>();

// export const adapter: EntityAdapter<ProjectProgress> = createEntityAdapter<ProjectProgress>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const projectProgressProgressReducer = createReducer(
  initialState,
  on(ProjectProgressActions.addProjectProgress,
    (state, action) => adapter.addOne(action.projectProgress, state)
  ),
  on(ProjectProgressActions.upsertProjectProgress,
    (state, action) => adapter.upsertOne(action.projectProgress, state)
  ),
  on(ProjectProgressActions.addProjectProgresss,
    (state, action) => adapter.addMany(action.projectProgresss, state)
  ),
  on(ProjectProgressActions.upsertProjectProgresss,
    (state, action) => adapter.upsertMany(action.projectProgresss, state)
  ),
  on(ProjectProgressActions.updateProjectProgress,
    (state, action) => adapter.updateOne(action.projectProgress, state)
  ),
  on(ProjectProgressActions.updateProjectProgresss,
    (state, action) => adapter.updateMany(action.projectProgresss, state)
  ),
  on(ProjectProgressActions.deleteProjectProgress,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ProjectProgressActions.deleteProjectProgresss,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ProjectProgressActions.loadProjectProgresss,
    (state, action) => adapter.setAll(action.projectProgresss, state)
  ),
  on(ProjectProgressActions.clearProjectProgresss,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return projectProgressProgressReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllProjectProgresss = selectAll;
export const getProjectProgressById = selectIds;
export const getProjectProgressEntities = selectEntities;
