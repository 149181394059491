<app-auth-layout>
  <ng-template #AuthContent>

    <ng-container *ngIf="hide">

      <ng-container *ngIf="username === null">
        <h5 class="text-center">{{'Login' | concat | translate}}</h5>
      </ng-container>

      <ng-container *ngIf="username !== null">
        <p class="text-center">
          <small><i>{{ username}}</i></small>
        </p>
      </ng-container>

      <ng-container *ngIf="!coordinateFound">
        <div class="alert alert-warning">
          <p class="d-flex align-items-center">
            <mat-icon class="material-symbols-outlined me-2">map</mat-icon>
            {{ coordinateErrorMessage }}
          </p>
        </div>
      </ng-container>

      <form (ngSubmit)="login()" [formGroup]="staffLoginForm" autocomplete="off"  *ngIf="coordinateFound">
        <div class="row">
          <mat-form-field   appearance="outline" class="material-form col-md-12" *ngIf="username === null">
            <mat-label> {{'E - Mail' | concat | translate}}</mat-label>
            <input formControlName="username" matInput type="email" autocomplete="webauthn">
            <mat-icon matSuffix svgIcon="email"></mat-icon>
            <mat-error>
                  <span *ngIf="!staffLoginForm.get('username').valid && staffLoginForm.get('username').touched">
                      {{'This field is required' | concat | translate}}
                  </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{'Password' | concat | translate}}</mat-label>
            <input [type]="passwordHide ? 'password' : 'text'" formControlName="password" matInput required>
            <mat-icon matSuffix svgIcon="{{ passwordHide ? 'eye' : 'password' }}" class="cursor-pointer"
                      (click)="passwordHide = !passwordHide">
            </mat-icon>
            <mat-error>
                  <span *ngIf="!staffLoginForm.get('password').valid && staffLoginForm.get('password').touched">
                      {{'This field is required' | concat | translate}}
                  </span>
            </mat-error>
          </mat-form-field>

        </div>

        <div class="row">
          <div class="col-6">
            <button (click)="toggleLoginPassword()" mat-stroked-button type="button">
              {{'Forgot Password' | concat | translate}}
            </button>
          </div>

          <div class="col-6 text-end">
            <button [disabled]="!staffLoginForm.valid || loading" color="primary" mat-raised-button type="submit">
              {{'LOGIN' | concat | translate}}
            </button>
          </div>
        </div>
      </form>

    </ng-container>

    <ng-container *ngIf="!hide">
<!--      <h5 class="text-center">{{'Recover Your Password' | concat | translate }}</h5>-->
      <h5 class="text-center">{{'contact us' | concat | translate }}</h5>

      <div class="row my-2">
        <div class="col-md-2"><mat-icon svgIcon="email"></mat-icon></div>
        <div class="col-md-4">{{ 'email' | concat | translate }}:</div>
        <div class="col-md-6"><a href="mailto:{{ 'support email' | concat | translate}}">{{ 'support email' | concat | translate}}</a></div>
      </div>

      <div class="row my-2">
        <div class="col-md-2 pt-2"><mat-icon svgIcon="phone"></mat-icon></div>
        <div class="col-md-4">{{ 'phone number' | concat | translate }}:</div>
        <div class="col-md-6"><a href="tel:{{ 'support phone' | concat | translate}}">{{ 'support phone' | concat | translate}}</a></div>
      </div>

      <div class="row mt-3">
        <div class="col-12 text-center">
          <button (click)="toggleLoginPassword()" mat-stroked-button type="button">
            {{'Back' | concat | translate }}
          </button>
        </div>
      </div>

<!--      <form (ngSubmit)="submitResetPassword(resetPwdForm.value)" [formGroup]="resetPwdForm">-->

<!--        <div class="row">-->
<!--          <mat-form-field appearance="outline" class="material-form col-md-12">-->
<!--            <mat-label>{{'email' | concat | translate}}</mat-label>-->
<!--            <input formControlName="email" matInput required type="email">-->
<!--            <mat-icon matSuffix svgIcon="email"></mat-icon>-->
<!--            <mat-error>-->
<!--                  <span *ngIf="!resetPwdForm.get('email').valid && resetPwdForm.get('email').touched">-->
<!--                      {{'This field is required' | concat | translate}}-->
<!--                  </span>-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->
<!--        </div>-->

<!--        <div class="row">-->
<!--          <div class="col-6 forgot-button">-->
<!--            <button (click)="toggleLoginPassword()" mat-stroked-button type="button">-->
<!--              {{'Back' | concat | translate }}-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="col-6">-->
<!--            <button [disabled]="!resetPwdForm.valid || loading" class="btn float-end fs-6" color="primary"-->
<!--                    mat-raised-button style="color: #fff;" type="submit"> {{'Send Reset Link' | concat | translate}}-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </form>-->

    </ng-container>
  </ng-template>
</app-auth-layout>

