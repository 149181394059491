import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';


export const fileField = `
id
uniqueId
attachmentName
base64String
`;


export const attachmentField = `
  ${baseField}
  requiredAttachment {
    id
    attachmentUniqueId
    attachmentName
    attachmentRequiredFor
    attachmentIsMandatory
  }
  file {
    ${fileField}
  }
`;

export const GET_ATTACHMENTS_BY_MEETING = gql`
  query getAttachmentsByMeeting($meetingUniqueId: String) {
    getAttachmentsByMeeting(meetingUniqueId: $meetingUniqueId) {
      response{
        ${responseField}
      }
      data{
        ${attachmentField}
      }
    }
  }
`;


export const UPLOAD_FILE = gql`
  mutation uploadBase64File($inputDto: Base64FileInputObjects!) {
    uploadBase64File(input: $inputDto) {
      response{
        ${responseField}
      }
      data{
        fullFileName
        fileName
      }
    }
  }
`;

export const GET_ATTACHMENT_CONTENT = gql`
  query getOpenFiles($uid: String!) {
    getOpenFiles(filePath: $uid) {
      response{
        ${responseField}
      }
      data{
        base64String
        fileName
      }
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation deleteUploadedDocuments($inputDto: String!) {
    deleteUploadedDocuments(filePath: $inputDto) {
      response{
        ${responseField}
      }
    }
  }
`;

export const DELETE_ATTACHMENT_WITH_TYPE = gql`
  mutation deleteUploadedRequiredAttachments($inputDto: DeleteAttachmentsInputObjects!) {
    deleteUploadedRequiredAttachments(input: $inputDto) {
      response{
        ${responseField}
      }
    }
  }
`;
