import {createSelector} from '@ngrx/store';
import * as fromProjectCategoryReducer from '../project-category/project-category.reducer';
import {projectCategoriesFeatureKey} from './project-category.reducer';
import {AppState} from '../../index';

export const currentProjectCategoryState = (state: AppState) => state[projectCategoriesFeatureKey];

export const selectAllProjectCategories = createSelector(currentProjectCategoryState, fromProjectCategoryReducer.selectAll);
export const selectTotalProjectCategories = createSelector(currentProjectCategoryState, fromProjectCategoryReducer.selectTotal);

export const selectProjectCategoryById = (id: number) => createSelector(
  selectAllProjectCategories,
  (ProjectCategories) => ProjectCategories.find((projectCategory) => projectCategory.id === id)
);
