import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const committeeField = `
  id
  uniqueId
  name
  shortform
  members {
    id
    uniqueId
    isLeader
    member {
      ${baseField}
      firstName
      lastName
      email
      phone
    }
  }
`;

export const GET_COMMITTEE_MINIMUM = gql`
  query getAllCommittees {
    getAllCommittees {
      response{
        ${responseField}
      }
      data{
        uniqueId
        name
      }
    }
  }
`;


export const GET_COMMITTEE = gql`
  query GetAllCommittees {
    getAllCommittees {
      response{
        ${responseField}
      }
      data{
        ${committeeField}
      }
    }
  }
`;

export const CREATE_COMMITTEE = gql`
  mutation CreateCommitteeMutation($input: CommiteeInputObjects!) {
    createCommitteeMutation(input: $input) {
      response {
        ${responseField}
      }
      data {
        ${committeeField}
      }
    }
  }
`;

export const UPDATE_COMMITTEE = gql`
  mutation UpdateCommitteeMutation($input: CommiteeInputObjects!) {
    updateCommitteeMutation(input: $input) {
      response {
        ${responseField}
      }
      data {
        ${committeeField}
      }
    }
  }
`;


export const DELETE_COMMITTEE = gql`
  mutation DeleteCommitteeMutation($commiteeUniqueId: String!) {
    deleteCommitteeMutation(commiteeUniqueId: $commiteeUniqueId) {
      response {
        ${responseField}
      }
    }
  }
`
