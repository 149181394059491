import {BaseEntity} from '../../BaseEntity.model';
import {DocumentStageEnum} from '../../document/document.model';

export interface ScheduledMeeting extends BaseEntity {
  title?: string;
  primaryKey?: string;
  meetingUniqueId?: string;
  meetingNumber?: string;
  uniqueId?: string;
  date?: string;
  location?: any;
  startTime?: any;
  meetingDate?: string;
  meetingVenue?: string;
  stage?: DocumentStageEnum;
  meetingStatus?: any;
  meetingMaxInvitees?: number;
  meetingTotals?: any;
  meetingTypes?: MeetingTypeEnum;
  withdrawOrClosingRemarks?: string;
  withdrawOrClosingAttachmentPath?: string;
  withdrawOrClosingAttachmentName?: string;
  withdrawOrClosingAttachmentExtension?: string;
}

export enum MeetingStatusTypeEnum{
  DRAFT= 'DRAFT',
  PUBLISHED= 'PUBLISHED',
  IN_MINUTES= 'IN_MINUTES',
  WITHDRAWN= 'WITHDRAWN',
  CLOSED= 'CLOSED'
}
export enum MeetingDecisionStatusEnum{
  CLOSED= 'CLOSED',
  WITHDRAWN= 'WITHDRAWN'
}
export enum MeetingTypeEnum{
  NORMAL= 'NORMAL',
  ADHOC= 'ADHOC',
  EMERGENCY= 'EMERGENCY',
}
