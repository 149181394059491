import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Location, LocationDto} from './location.model';

export const loadLocations = createAction(
  '[Location/API] Load Locations',
  props<{ locations: Location[] }>()
);

export const addLocation = createAction(
  '[Location/API] Add Location',
  props<{ location: Location }>()
);

export const upsertLocation = createAction(
  '[Location/API] Upsert Location',
  props<{ location: Location }>()
);

export const addLocations = createAction(
  '[Location/API] Add Locations',
  props<{ locations: Location[] }>()
);

export const upsertLocations = createAction(
  '[Location/API] Upsert Locations',
  props<{ locations: Location[] }>()
);

export const updateLocation = createAction(
  '[Location/API] Update Location',
  props<{ location: Update<Location> }>()
);

export const updateLocations = createAction(
  '[Location/API] Update Locations',
  props<{ locations: Update<Location>[] }>()
);

export const deleteLocation = createAction(
  '[Location/API] Delete Location',
  props<{ id: any }>()
);

export const deleteLocations = createAction(
  '[Location/API] Delete Locations',
  props<{ ids: string[] }>()
);

export const clearLocations = createAction(
  '[Location/API] Clear Locations'
);

export const getAllLocations = createAction(
  '[Locations/API] List All Locations'
);

export const actionLocation = createAction(
  '[Location/API] create, edit & delete location',
  props<{ locationDto: LocationDto, id?: number }>()
);
