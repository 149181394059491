import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Document} from './document.model';
import * as DocumentActions from './document.actions';

export const documentsFeatureKey = 'document';

export interface State extends EntityState<Document> {
  // additional entities state properties
}

export function sortById(a: Document, b: Document): any {
  // return a.id.localeCompare(b.id);
}

export const adapter: EntityAdapter<Document> = createEntityAdapter<Document>({
  // sortComparer: sortById
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const documentReducer = createReducer(
  initialState,
  on(DocumentActions.addDocument,
    (state, action) => adapter.addOne(action.document, state)
  ),
  on(DocumentActions.upsertDocument,
    (state, action) => adapter.upsertOne(action.document, state)
  ),
  on(DocumentActions.addDocuments,
    (state, action) => adapter.addMany(action.documents, state)
  ),
  on(DocumentActions.upsertDocuments,
    (state, action) => adapter.upsertMany(action.documents, state)
  ),
  on(DocumentActions.updateDocument,
    (state, action) => adapter.updateOne(action.document, state)
  ),
  on(DocumentActions.updateDocuments,
    (state, action) => adapter.updateMany(action.documents, state)
  ),
  on(DocumentActions.deleteDocument,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(DocumentActions.deleteDocuments,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(DocumentActions.loadDocuments,
    (state, action) => adapter.setAll(action.documents, state)
  ),
  on(DocumentActions.clearDocuments,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return documentReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getDocuments = selectAll;
