import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {StorageService} from '../../services/storage-service.service';
import {AuthUser} from '../../store/entities/auth-user/auth-user.model';

@Injectable({
  providedIn: 'root'
})
export class AfterLoginGuard implements CanActivate {
  loggedUser: AuthUser;
  constructor(
    private auth: AuthService,
    private router: Router,
    private storageService: StorageService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> |
    Promise<boolean | UrlTree> | boolean | UrlTree {

    this.loggedUser = this.storageService.get('account');

    if (this.loggedUser.hasHandover){
      return this.router.navigate(['303']);
    }
    if (this.storageService.get('updateProfile')){
      // this.settingsService.openModal( this.storageService.get('account'), OfflineOnlineSelectComponent);
    }
    return this.auth.alreadyLoggedIn() ? true : this.router.navigate(['' , 'login']);
  }
}
