import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {Decision} from './decision.model';

export const loadDecisions = createAction(
  '[Decision/API] Load Decisions',
  props<{ decisions: Decision[] }>()
);

export const addDecision = createAction(
  '[Decision/API] Add Decision',
  props<{ decision: Decision }>()
);

export const upsertDecision = createAction(
  '[Decision/API] Upsert Decision',
  props<{ decision: Decision }>()
);

export const addDecisions = createAction(
  '[Decision/API] Add Decisions',
  props<{ decisions: Decision[] }>()
);

export const upsertDecisions = createAction(
  '[Decision/API] Upsert Decisions',
  props<{ decisions: Decision[] }>()
);

export const updateDecision = createAction(
  '[Decision/API] Update Decision',
  props<{ decision: Update<Decision> }>()
);

export const updateDecisions = createAction(
  '[Decision/API] Update Decisions',
  props<{ decisions: Update<Decision>[] }>()
);

export const deleteDecision = createAction(
  '[Decision/API] Delete Decision',
  props<{ id: number }>()
);

export const deleteDecisions = createAction(
  '[Decision/API] Delete Decisions',
  props<{ ids: number[] }>()
);

export const clearDecisions = createAction(
  '[Decision/API] Clear Decisions'
);


export const getAllDecisions = createAction(
  '[Decision/API] get all active Decisions',
);

export const getDecisionReport = createAction(
  '[Decision/API] get Decision report',
);
