import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Institution} from './institution.model';
import * as InstitutionActions from './institution.actions';

export const institutionsFeatureKey = 'institutions';

export interface State extends EntityState<Institution> {
  // additional entities state properties
}

export function sortByInstitutionName(a: Institution, b: Institution): any {
  return a?.name.localeCompare(b?.name);
}

export const adapter: EntityAdapter<Institution> = createEntityAdapter<Institution>({
  // sortComparer: sortByInstitutionName
});

// export const adapter: EntityAdapter<Institution> = createEntityAdapter<Institution>();
export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const institutionReducer = createReducer(
  initialState,
  on(InstitutionActions.addInstitution,
    (state, action) => adapter.addOne(action.institution, state)
  ),
  on(InstitutionActions.upsertInstitution,
    (state, action) => adapter.upsertOne(action.institution, state)
  ),
  on(InstitutionActions.addInstitutions,
    (state, action) => adapter.addMany(action.institutions, state)
  ),
  on(InstitutionActions.upsertInstitutions,
    (state, action) => adapter.upsertMany(action.institutions, state)
  ),
  on(InstitutionActions.updateInstitution,
    (state, action) => adapter.updateOne(action.institution, state)
  ),
  on(InstitutionActions.updateInstitutions,
    (state, action) => adapter.updateMany(action.institutions, state)
  ),
  on(InstitutionActions.deleteInstitution,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(InstitutionActions.deleteInstitutions,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(InstitutionActions.loadInstitutions,
    (state, action) => adapter.setAll(action.institutions, state)
  ),
  on(InstitutionActions.clearInstitutions,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return institutionReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


// export entity selectors
export const getAllInstitutions = selectAll;
export const getInstitutionById = selectIds;
export const getInstitutionEntities = selectEntities;
