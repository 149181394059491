<app-modal-header [title]="title"></app-modal-header>



@if (qrCodeBase64) {
  @if (viewType === 'view') {
    <div class="text-muted">
      {{ 'Two Factor Scan Description' | concat | translate }}
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <img src="{{qrCodeBase64}}" alt="Qr Code"
           style="border-radius: 8px; width: 300px">
    </div>

    <div class="d-flex justify-content-end">
      <button (click)="viewType = 'confirm'" mat-raised-button color="primary">
        {{ 'Set 2FA' | concat | translate }}
      </button>
    </div>
  } @else {
    <div class="text-muted">
      {{ '2FA Confirmation Message' | concat | translate }}
    </div>

    <form [formGroup]="form">
      <mat-form-field appearance="outline" class="mt-4">
        <mat-label>{{ '2FA Code' | concat | translate }}</mat-label>
        <input type="number" min="0" max="999999" matInput formControlName="twoFACode" />
        <mat-error> Invalid Code</mat-error>
      </mat-form-field>

      <div class="d-flex justify-content-end">
        <button [disabled]="!form.valid" (click)="confirm2FA(form.value)" mat-raised-button color="primary">
          {{ 'Confirm' | concat | translate }}
        </button>
      </div>
    </form>
  }
}


<!--  <form (ngSubmit)="saveChangePassword(changePasswordForm.value)" [formGroup]="changePasswordForm" autocomplete="off" class="col-md-12" novalidate>-->

<!--    <div class="col-md-12">-->
<!--      <mat-form-field appearance="outline" class="material-form">-->
<!--        <mat-label>{{'Old Password' | concat | translate}}</mat-label>-->
<!--        <input formControlName="oldPassword" matInput type="password">-->
<!--        <mat-error>-->
<!--          <span *ngIf="!changePasswordForm.get('oldPassword').valid && changePasswordForm.get('oldPassword').touched">-->
<!--            {{'This field is required' | concat | translate}}-->
<!--          </span>-->
<!--        </mat-error>-->
<!--      </mat-form-field>-->
<!--    </div>-->

<!--    <div class="col-md-12">-->
<!--      <mat-form-field appearance="outline" class="material-form">-->
<!--        <mat-label>{{'New Password' | concat | translate}}</mat-label>-->
<!--        <input formControlName="newPassword" matInput type="password">-->
<!--        <mat-hint>{{'At least three Uppercase, three lowercase and number are required' | concat | translate}}</mat-hint>-->
<!--        <mat-error>-->
<!--          <span *ngIf="!changePasswordForm.get('newPassword').valid && changePasswordForm.get('newPassword').touched">-->
<!--            {{'This field is required' | concat | translate}}-->
<!--          </span>-->
<!--        </mat-error>-->
<!--      </mat-form-field>-->
<!--    </div>-->

<!--    <div class="col-md-12">-->
<!--      <mat-form-field appearance="outline" class="material-form">-->
<!--        <mat-label>{{'Confirm Password' | concat | translate}}</mat-label>-->
<!--        <input formControlName="confirmPassword" matInput type="password">-->
<!--        <mat-error>-->
<!--          <span-->
<!--            *ngIf="!changePasswordForm.get('confirmPassword').valid && changePasswordForm.get('confirmPassword').touched">-->
<!--            {{'This field is required' | concat | translate }}-->
<!--          </span>-->
<!--        </mat-error>-->
<!--      </mat-form-field>-->
<!--    </div>-->

<!--     <div class="row">-->
<!--      <div class="col-md-12">-->
<!--        <div class="text-end">-->
<!--          <button [disabled]="!changePasswordForm.valid && changePasswordForm.get('newPassword') === changePasswordForm.get('confirmPassword')"-->
<!--                  color="primary" mat-raised-button type="submit">-->
<!--            {{'Save' | concat | translate}}-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--  </form>-->
