import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {DirectiveService} from './directive.service';
import {
  actionDirectives,
  directivesMovement,
  getAllDirectives,
  getDirectiveByCommittee,
  loadDirectives,
  saveDirectiveResponse
} from './directive.actions';
import {SettingsService} from '../../../../services/settings.service';

@Injectable()
export class DirectiveEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllDirectives),
    switchMap(() => {
      return this.directiveService.getAll().pipe(
        map((res: any) => {
          if (res !== null){
            this.store.dispatch(loadDirectives({directives: res}));
          }
        })
      );
    })
  ), {dispatch: false});


  action = createEffect(() => this.actions$.pipe(
    ofType(actionDirectives),
    switchMap((action) => {
      return this.directiveService.post(action?.formData).pipe(
        map((res: any) => {
          if (res?.code === 200){
            this.notificationService.successMessageAndCloseModals('Completed Successfully');
            this.settingsService.reloadCurrentRoute(null);
          } else {
            this.notificationService.errorMessage(res?.message);
          }
        })
      );
    })
  ), {dispatch: false});

  move = createEffect(() => this.actions$.pipe(
    ofType(directivesMovement),
    switchMap((action) => {
      return this.directiveService.movement(action?.formData).pipe(
        map((res: any) => {
          if (res?.code === 200){
            this.notificationService.successMessageAndCloseModals('Completed Successfully');
            this.settingsService.reloadCurrentRoute(null);
          } else {
            this.notificationService.errorMessage(res?.message);
          }
        })
      );
    })
  ), {dispatch: false});


  respond = createEffect(() => this.actions$.pipe(
    ofType(saveDirectiveResponse),
    switchMap((action) => {
      return this.directiveService.put(action?.formData).pipe(
        map((res: any) => {
          if (res?.code === 200){
            this.notificationService.successMessageAndCloseModals('Completed Successfully');
            this.settingsService.reloadCurrentRoute(null);
          } else {
            this.notificationService.errorMessage(res?.message);
          }
        })
      );
    })
  ), {dispatch: false});

  getByCommittee = createEffect(() => this.actions$.pipe(
    ofType(getDirectiveByCommittee),
    switchMap((action) => {
      return this.directiveService.getByCommittee(action?.uid).pipe(
        map((res: any) => {
          if (res !== null){
            this.store.dispatch(loadDirectives({directives: res}));
          }
        })
      );
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private directiveService: DirectiveService,
  ){}

}
