import {Component, HostListener, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import { StorageService } from "../../../../services/storage-service.service";

@Component({
  selector: 'app-view-attachment',
  templateUrl: './view-attachment.component.html',
  styleUrls: ['./view-attachment.component.scss']
})
export class ViewAttachmentComponent implements OnInit {

  @Input() src;
  @Input() type: string;
  @Input() showCoverPhoto = true;
  title: string;
  base64$: Observable<string>;
  filePath: string;

  isStaff: boolean = false;

  isOnline = true;

  @HostListener('window:online', ['$event'])
  onlineNetworkStatus(event: KeyboardEvent) {
    this.isOnline = true;
  }
  @HostListener('window:offline', ['$event'])
  offlineNetworkStatus(event: KeyboardEvent) {
    this.isOnline = false;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storageService: StorageService
  ) {
    this.filePath = data?.filePath;
    this.base64$ = data?.base64$;
    this.showCoverPhoto = data.showCoverPhoto !== null ? data.showCoverPhoto : this.showCoverPhoto;
    this.title = data.fileTitle ? data.fileTitle : 'DOCUMENT';
    this.isStaff = this.storageService.get('category') === 'Staff'
  }

  ngOnInit() {
  }

}
