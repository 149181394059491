import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {SettingsService} from '../../../../services/settings.service';
import {ActivatedRoute} from '@angular/router';
import {getProjectByUid} from '../../../../store/entities/projects/project/project.actions';
import {Project} from '../../../../store/entities/projects/project/project.model';
import {selectProjectByUid} from '../../../../store/entities/projects/project/project.selectors';
import {ProjectStatusEnum} from '../../../enums/data.enums';
import {NotificationService} from '../../../../services/notification.service';
import {
  ProjectRepositoryFormComponent
} from '../../../../modules/portal/portal-project/portal-project-forms/project-repository-form/project-repository-form.component';
import {
  getProjectRepositoryByProjectUid,
  removeProjectRepository
} from '../../../../store/entities/projects/project-repository/project-repository.actions';
import {
  selectAllProjectRepositories
} from '../../../../store/entities/projects/project-repository/project-repository.selectors';
import {ProjectRepository} from '../../../../store/entities/projects/project-repository/project-repository.model';
import {getAttachmentsByUid} from '../../../../store/entities/settings/attachment/attachment.actions';

@Component({
  selector: 'app-project-repository-detail',
  templateUrl: './project-repository-detail.component.html',
  styleUrls: ['./project-repository-detail.component.scss']
})
export class ProjectRepositoryDetailComponent implements OnInit {

  title: string;
  projectUid: string;
  project$: Observable<Project>;
  repositories$: Observable<ProjectRepository[]>;
  projectStatus = ProjectStatusEnum;

  tableColumns: any;

  constructor(
    private store: Store<AppState>,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
  ) {
    this.projectUid = this.activatedRoute.snapshot.paramMap.get('uid');
    this.store.dispatch(getProjectByUid({uid: this.projectUid, withComponents: false}));
    this.store.dispatch(getProjectRepositoryByProjectUid({projectUid: this.projectUid}));
  }

  ngOnInit(): void {
    this.title = 'Project Repository';

    this.tableColumns = {
      sn: 'SN',
      'file.attachmentName': 'attachment Name',
      action: ''
    };

    this.project$ = this.store.select(selectProjectByUid(this.projectUid));
    this.repositories$ = this.store.select(selectAllProjectRepositories);
  }

  remove(res) {
    const message = 'Are you sure you want to remove? ';
    this.notificationService.confirmation(message).then(
      data => {
        if (data) {
          this.store.dispatch(removeProjectRepository({ uid: res?.uniqueId, id: res?.id }));
        }
      }
    );
  }

  viewAttachment(uid: string, title: string){
    this.store.dispatch(getAttachmentsByUid({ uid, title}));
  }

  action(project: Project) {
    this.settingsService.openModal({project}, ProjectRepositoryFormComponent);
  }

}
