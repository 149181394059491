import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  deleteProject,
  editProject,
  getAllProjects,
  getProjectByUid,
  loadProjects,
  removeProject,
  saveProject,
  upsertProject,
  upsertProjects
} from './project.actions';
import {map, switchMap} from 'rxjs/operators';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {DELETE_PROJECT, GET_PROJECT_BY_UID, GET_PROJECTS, SAVE_PROJECT, UPDATE_PROJECT} from './project.graphql';
import {Apollo} from 'apollo-angular';
import {loadProjectComponents} from '../project-component/project-component.actions';

@Injectable()
export class ProjectEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllProjects),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_PROJECTS,
        variables: { inputDto: action.filtering}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadProjects({projects: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  uid = createEffect(() => this.actions$.pipe(
    ofType(getProjectByUid),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_PROJECT_BY_UID,
        variables: { uid: action.uid, withComponents: action.withComponents }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(upsertProjects({projects: result?.data}));
            if (result?.data[0]?.components?.length > 0 && action.withComponents){
              this.store.dispatch(loadProjectComponents({projectComponents: result?.data[0]?.components}));
            }
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveProject),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_PROJECT,
        variables: { inputDto: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertProject({project: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  update = createEffect(() => this.actions$.pipe(
    ofType(editProject),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_PROJECT,
        variables: { inputDto: action.formData }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertProject({project: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  delete = createEffect(() => this.actions$.pipe(
    ofType(removeProject),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_PROJECT,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(deleteProject({id: action.id}));
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService
  ) {
  }
}
