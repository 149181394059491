import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {SettingsService} from 'src/app/services/settings.service';
import {AppState} from 'src/app/store/entities';
import {saveDirectiveResponse} from '../../../../store/entities/meetings/directive/directive.actions';
import {Directive} from '../../../../store/entities/meetings/directive/directive.model';

@Component({
  selector: 'app-directive-response-form',
  templateUrl: './directive-response-form.component.html',
  styleUrls: ['./directive-response-form.component.scss']
})
export class DirectiveResponseFormComponent implements OnInit, OnDestroy {

  title: string;
  form: UntypedFormGroup;
  directive: Directive;

  subscriptions: Subscription = new Subscription();
  documentTitle: any;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private settingService: SettingsService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.directive = data?.directive;
  }

  ngOnInit(): void {
    this.title = 'Directive Response Form';

    this.form = this.fb.group({
      response_descriptions: [null, Validators.required],
      response_attachments: [''],
      has_attachments: ['False'],
    });
  }

  onSingleFileSelected(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.documentTitle = file.name;
        this.form.get('response_attachments').patchValue(file);
        this.form.get('has_attachments').patchValue('True');
      };
    }
    event.target.value = '';
  }

  submitForm(formData): void {
    if (this.directive) {
      formData.directives_id = this.directive?.id;
    }

    const directiveFormData = new FormData();
    Object.entries(formData).forEach(
      ([key, value]: any[]) => {
        directiveFormData.set(key, value);
      });

    this.store.dispatch(saveDirectiveResponse({ formData: directiveFormData }));

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
