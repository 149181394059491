import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {SettingsService} from '../../../../services/settings.service';
import {Apollo} from 'apollo-angular';
import {
  deleteMinutesDecisionProgress,
  deleteMinutesDecisions, editMinutesDecisionProgress,
  editMinutesDecisions,
  getAllMinutesDecisions,
  loadMinutesDecisionss,
  removeMinutesDecisions, saveMinutesDecisionProgress,
  saveMinutesDecisions,
  upsertMinutesDecisions
} from './minutes-decisions.actions';
import {
  ADD_DECISION_PROGRESS,
  DELETE_MINUTES_DECISIONS,
  GET_ALL_DECISIONS, REMOVE_DECISION_PROGRESS,
  SAVE_MINUTES_DECISIONS, UPDATE_DECISION_PROGRESS,
  UPDATE_MINUTES_DECISIONS
} from './minutes-decisions.graphql';

@Injectable()
export class MinutesDecisionsEffects {


  get = createEffect(() => this.actions$.pipe(
    ofType(getAllMinutesDecisions),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_ALL_DECISIONS,
        variables: { agendaUniqueId: action.agendaUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadMinutesDecisionss({ minutesDecisionss: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveMinutesDecisions),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_MINUTES_DECISIONS,
        variables: { decision: action.decision }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertMinutesDecisions({ minutesDecisions: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  saveProgress = createEffect(() => this.actions$.pipe(
    ofType(saveMinutesDecisionProgress),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: ADD_DECISION_PROGRESS,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(getAllMinutesDecisions({ agendaUniqueId: action.agendaUniqueId }))
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  editProgress = createEffect(() => this.actions$.pipe(
    ofType(editMinutesDecisionProgress),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_DECISION_PROGRESS,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(getAllMinutesDecisions({ agendaUniqueId: action.agendaUniqueId }))
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  removeProgress = createEffect(() => this.actions$.pipe(
    ofType(deleteMinutesDecisionProgress),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: REMOVE_DECISION_PROGRESS,
        variables: { uniqueId: action.uniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(getAllMinutesDecisions({ agendaUniqueId: action.agendaUniqueId }))
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editMinutesDecisions),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_MINUTES_DECISIONS,
        variables: { decision: action.decision }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertMinutesDecisions({ minutesDecisions: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  delete = createEffect(() => this.actions$.pipe(
    ofType(removeMinutesDecisions),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_MINUTES_DECISIONS,
        variables: { decisionUniqueId: action.decisionUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteMinutesDecisions({id: action.id}))
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
  ) { }

}
