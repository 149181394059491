import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {SettingsService} from 'src/app/services/settings.service';
import {User} from 'src/app/store/entities/settings/user/user.model';
import {AppState} from 'src/app/store/entities';
import {Committee} from '../../../../store/entities/settings/committee/committee.model';
import {getAllCommittees} from '../../../../store/entities/settings/committee/committee.actions';
import {getAllUsers} from '../../../../store/entities/settings/user/user.actions';
import {ActionTypeEnum} from '../../../enums/data.enums';
import {selectAllUsers} from '../../../../store/entities/settings/user/user.selectors';
import {selectAllCommittees} from '../../../../store/entities/settings/committee/committee.selectors';
import {actionDirectives} from '../../../../store/entities/meetings/directive/directive.actions';
import {Directive} from '../../../../store/entities/meetings/directive/directive.model';
import {Decision} from '../../../../store/entities/meetings/decision/decision.model';

@Component({
  selector: 'app-directive-form',
  templateUrl: './directive-form.component.html',
  styleUrls: ['./directive-form.component.scss']
})
export class DirectiveFormComponent implements OnInit, OnDestroy {

  title: string;
  form: UntypedFormGroup;
  directive: Directive;
  decision: Decision;
  users$: Observable<User[]>;
  committees$: Observable<Committee[]>;

  subscriptions: Subscription = new Subscription();
  documentTitle: any;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private settingService: SettingsService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.directive = data?.directive;
    this.decision = data?.decision;
    this.store.dispatch(getAllCommittees());
    this.store.dispatch(getAllUsers({ userUid: null}));
  }

  ngOnInit(): void {
    this.title = 'Directive Form';

    this.form = this.fb.group({
      directive_descriptions: [null, Validators.required],
      directive_to_person: [true, Validators.required],
      directive_committee: [null],
      directive_handler: [null],
      directive_attachments: [''],
      has_attachments: ['False'],
      actionType: [ActionTypeEnum.create, Validators.required],
    });

    if (this.directive) {
      this.form.get('directive_descriptions').patchValue(this.directive?.directive_descriptions);
      this.form.get('actionType').patchValue(ActionTypeEnum.update);
    }

    this.users$ = this.store.select(selectAllUsers);
    this.committees$ = this.store.select(selectAllCommittees);

  }

  onSingleFileSelected(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.documentTitle = file.name;
        this.form.get('directive_attachments').patchValue(file);
        this.form.get('has_attachments').patchValue('True');
      };
    }
    event.target.value = '';
  }

  submitForm(formData): void {
    if (this.directive) {
      formData.id = this.directive?.id;
    }
    formData.has_decision = 'False';

    if (this.decision) {
      formData.directive_decision = this.decision?.id.toString();
      formData.has_decision = 'True';
    }

    formData.directive_committee = formData.directive_committee !== null ? formData.directive_committee.toString() : '';
    formData.directive_handler = formData.directive_handler !== null ? formData.directive_handler.toString() : '';

    if (formData.directive_to_person){
      formData.directive_committee = '';
    }else {
      formData.directive_handler = '';
    }
    formData.directive_to_person = formData.directive_to_person ? 'True' : 'False';

    const directiveFormData = new FormData();
    Object.entries(formData).forEach(
      ([key, value]: any[]) => {
        directiveFormData.set(key, value);
      });

    this.store.dispatch(actionDirectives({ formData: directiveFormData }));

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
