import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {lastValueFrom, Observable, of, Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {Clipboard} from '@angular/cdk/clipboard';
import * as XLSX from 'xlsx';
import {SettingsService} from '../../../../services/settings.service';
import {NotificationService} from '../../../../services/notification.service';
import {DialogService} from '../../../../services/dialog.service';
import {TableColumnSelectionComponent} from '../table-column-selection/table-column-selection.component';
import {IndexedDBTableName, TableColumn} from '../../../interfaces/table.interface';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {CdkTable} from '@angular/cdk/table';
import {TableColumnSelectionService} from '../table-column-selection/table-column-selection.service';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() tableData: any;
  @Input() tableData$: Observable<any>;
  @Input() extraTableColumns: TableColumn[];
  @Input() columnHeader: object;
  @Input() hasActionsColumn = true;
  @Input() allowTableColumnFromCache = true;
  @Input() hasPaginator = true;

  listData: MatTableDataSource<any>;
  searchKey: string;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ContentChild(TemplateRef, {static: true}) actions: TemplateRef<any>;
  @ViewChild(CdkTable) cdkTable!: CdkTable<any>;

  subscriptions: Subscription = new Subscription();

  @Input() showDownloadButtons = true;
  @Input() reportTitle = 'report';
  @Input() showLoading = true;
  @Input() showFilter = true;
  @Input() showCheckbox = false;
  @Input() pageSizeOptions: number[] = [10, 50, 100, 250];
  @Input() pageSize = 10;

  selection = new SelectionModel<any>(true, []);
  @Output() selectedRows: EventEmitter<any[]> = new EventEmitter();

  tableColumns: TableColumn[];
  cacheTableColumns: TableColumn[];
  cacheTableColumnKeywords: string[];
  cacheTableColumnIndexedKey: number = null;

  settingsService = inject(SettingsService);
  notificationService = inject(NotificationService);
  clipboard = inject(Clipboard);
  dialogService = inject(DialogService);
  dbService = inject(NgxIndexedDBService);
  changeDetectorRefs = inject(ChangeDetectorRef);
  columnSelectionService = inject(TableColumnSelectionService);

  ngOnInit(){

    if (this.tableData){
      this.tableData$ = of(this.tableData);
    }

    this.tableColumns = [
      ...Object.keys(this.columnHeader)?.map( (keyword, id) => {
        return {
          id,
          keyword,
          title: this.columnHeader[keyword],
          isActionColumn: keyword?.toLowerCase() === 'action' || keyword?.toLowerCase() === 'actions'
        };
      })
    ];

      /*listen for column selection dialog emitted data*/
    this.subscriptions.add(
        this.columnSelectionService.currentData.subscribe( data => {
          if (data?.columns){
            let value = data?.columns;

            if (value?.length > 0){
              /*NOTE: check is object has no action columns while exist object has action column, update returned value*/
              if (this.hasActionsColumn && value?.findIndex( d => d?.isActionColumn) < 0) {
                value = [...value, { id: value?.length + 1, keyword: 'action', title: 'action', isActionColumn: true}];
              }
              this.updateCacheTableColumns(value);
            }
          }
        })
      );

    this.getValuesFromIndexedDb().then();

  }

  async getValuesFromIndexedDb(){
    return lastValueFrom(
      this.dbService.getByIndex(IndexedDBTableName.table_column, 'route', window.location.pathname)
    ).then( (data: any) => {

      if (data) {

        if (this.allowTableColumnFromCache) {
          this.cacheTableColumnIndexedKey = data?.id;
          this.cacheTableColumns = data?.columns;
          this.cacheTableColumnKeywords = this.settingsService.getTableColumnKeywords(data?.columns);
        }else {
          this.cacheTableColumns = this.tableColumns;
          this.cacheTableColumnKeywords = this.settingsService.getTableColumnKeywords(this.tableColumns);
        }

        this.changeDetectorRefs.detectChanges();
        this.cdkTable.renderRows();
      }else {
        /*add new record on indexedTable*/
        this.updateCacheTableColumns(this.tableColumns);
      }
    });
  }

  ngAfterViewInit() {
    this.subscriptions.add(
      this.tableData$.subscribe(data => {
        this.listData = new MatTableDataSource(data);
        this.listData.sort = this.sort;
        this.listData.paginator = this.paginator;
      })
    );

    this.listData.sort = this.sort;
    this.listData.paginator = this.paginator;

    this.subscriptions.add(
      this.selection.changed.subscribe( () => {
        this.selectedRows.emit(this.selection.selected);
      })
    );
  }

  setPageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.paginator.pageSize = pageSize;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  filterTable() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  onSearchClear() {
    this.searchKey = '';
    this.filterTable();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.listData.data);
    this.selectedRows.emit(this.selection.selected);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any[]): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row `;
  }

  copyTable() {
    if (this.clipboard.copy(document.getElementById('table').innerText)){
      this.notificationService.message('Table Content Copied!');
    }else {
      this.notificationService.errorMessage('Copy Failed!');
    }
  }

  downloadExcelFile() {
    const dataToBeExported = [];
    const keys: string[] = Object.keys(this.columnHeader);

    // if (this.cacheTableColumns?.length === 0){
    //   keys = this.tableColumns?.map(d => d?.keyword);
    // }else {
    //   keys = this.cacheTableColumns?.map(d => d?.keyword);
    // }

    this.subscriptions.add(
      this.tableData$.subscribe( (dataList) => {
        if (dataList?.length > 0){
          dataList?.forEach( dataObject => dataToBeExported.push(this.pickFromObject(dataObject, keys)));
        }
      })
    );

    if (dataToBeExported?.length > 0){

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToBeExported);
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, 'exported-data');

      /* save to file */
      XLSX.writeFile(wb, 'exported-data.xlsx');
    }
  }

  pickFromObject(object: object, keys: string[]): object{
    return keys.reduce((o, k) => {
      return Object.assign(o, { [k]: this.settingsService.getTableObjectValue(object, k) });
    }, {});
  }

  openColumnSection() {
    const data = {
      tableColumns: this.tableColumns,
      extraTableColumns: this.extraTableColumns,
      cacheTableColumns: this.cacheTableColumns,
    };

    this.dialogService.open({ data, component: TableColumnSelectionComponent, width: '20%'}).then();
  }

  updateCacheTableColumns(tableColumns) {

    if (this.allowTableColumnFromCache){
      this.cacheTableColumns = tableColumns;
      this.cacheTableColumnKeywords = this.settingsService.getTableColumnKeywords(tableColumns);

      if (this.cacheTableColumnIndexedKey){
        /*update indexed record*/
        lastValueFrom(
          this.dbService.update(IndexedDBTableName.table_column,
            {columns: this.cacheTableColumns, route: window.location.pathname, id: this.cacheTableColumnIndexedKey})
        ).then();

      }else {

        lastValueFrom(
          this.dbService.add(IndexedDBTableName.table_column,
            {columns: this.cacheTableColumns, route: window.location.pathname})
        ).then( data => {
          if (data){
            this.cacheTableColumnIndexedKey = data?.id;
          }
        });

      }
    }else {
      this.cacheTableColumns = this.tableColumns;
      this.cacheTableColumnKeywords = this.settingsService.getTableColumnKeywords(tableColumns);

    }


    this.changeDetectorRefs.detectChanges();
    this.cdkTable.renderRows();

  }

}
