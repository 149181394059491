import gql from 'graphql-tag';
import {responseField} from '../../base.graphql';
import {userField} from '../../settings/user/user.graphql';

export const attendanceField = `
  id
  attendanceUniqueId
  attendanceHasAttend
  position
  attendanceCreateddate
  attendanceUser {
    ${userField}
  }
`;

export const GET_MEETING_ATTENDANCE = gql`
query GetMeetingAttendance($meetingUniqueId: String) {
  getMeetingAttendance(meetingUniqueId: $meetingUniqueId) {
    response {
      ${responseField}
    }
    data {
      ${attendanceField}
    }
  }
}
`;


export const ADD_MEETING_ATTENDANCE = gql`
mutation AddMeetingAttendance($input: MeetingAttendaceInputObject) {
  addMeetingAttendance(input: $input) {
    response {
      ${responseField}
    }
    data {
      ${attendanceField}
    }
  }
}
`;
