import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {StorageService} from '../../../../services/storage-service.service';
import {CustomValidators} from 'src/app/shared/customs/custom-validators';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {resetPassword} from '../../../../store/entities/settings/user/user.actions';

@Component({
  selector: 'app-password-reset',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  currentYear: any;
  staffForm: UntypedFormGroup;
  loading = false;
  hide: boolean;
  rememberToken: string;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private store: Store<AppState>,
  ) {
    this.hide = true;
    this.rememberToken = this.activatedRoute.snapshot.paramMap.get('token');

    this.storageService.clear();
    // if (this.auth.alreadyLoggedIn()) {
    //   this.route.navigate(['', 'dashboard']);
    // }
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    const specialCharacters = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    this.currentYear = new Date().getFullYear();
    this.staffForm = this.fb.group({
      newPassword: [null,
        [
          // 1. Password Field is Required
          Validators.required,
          // 2. check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {hasNumber: true}),
          // 3. check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
          // 4. check whether the entered password has a lower-case letter
          CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
          // 5. check whether the entered password has a special character
          CustomValidators.patternValidator(specialCharacters, {hasSpecialCharacters: true}),
          // 6. Has a minimum length of 8 characters
          Validators.minLength(6)
        ]
      ],
      confirmPassword: [null,
        [
          // 1. Password Field is Required
          Validators.required,
          // 2. check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {hasNumber: true}),
          // 3. check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
          // 4. check whether the entered password has a lower-case letter
          CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
          // 5. check whether the entered password has a special character
          CustomValidators.patternValidator(specialCharacters, {hasSpecialCharacters: true}),
          // 6. Has a minimum length of 8 characters
          Validators.minLength(6)
        ]
      ]
    });
  }

  onSubmit(): any {
    const formValue = this.staffForm.value;
    this.store.dispatch(resetPassword({
      password: formValue.newPassword,
      confirmPassword: formValue.confirmPassword,
      token: this.rememberToken
    }));
  }
}
