import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const roleField = `
  ${baseField}
  name
  description
  isSuperAdmin
  permissions {
    ${baseField}
    name
    code
  }
`;

export const GET_ROLES = gql `
  query getAllSystemUserRolesList($uid: String) {
    getAllSystemUserRolesList(roleUniqueId: $uid) {
      response{
        ${responseField}
      }
      data{
        ${roleField}
      }
    }
  }
`;

export const SAVE_ROLE = gql`
  mutation createUserRole($inputDto: RoleInputObjects!){
    createUserRole(inputRole: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${roleField}
      }
    }
  }
`;
