import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {SettingsService} from '../../../../services/settings.service';
import {ViewAttachmentComponent} from '../view-attachment/view-attachment.component';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-decision-progress',
  templateUrl: './decision-progress.component.html',
  styleUrls: ['./decision-progress.component.scss']
})
export class DecisionProgressComponent implements OnInit {

  title: string;
  subscriptions: Subscription = new Subscription();
  progresses: any[];
  constructor(
    private settingService: SettingsService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.progresses = data;
  }

  ngOnInit(): void {
    this.title = 'Progress';
  }

  viewAttachment(path: string) {
    this.settingService.openModal({
      src: environment.SERVER_URL + path, showDownloadButton: false
      },
      ViewAttachmentComponent);
  }
}
