import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ForthComing} from './forthcoming.model';
import * as ForthComingActions from './forthcoming.actions';

export const forthComingsFeatureKey = 'forthComing';

export interface State extends EntityState<ForthComing> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ForthComing> = createEntityAdapter<ForthComing>({
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const forthcomingReducer = createReducer(
  initialState,
  on(ForthComingActions.addForthComing,
    (state, action) => adapter.addOne(action.forthComing, state)
  ),
  on(ForthComingActions.upsertForthComing,
    (state, action) => adapter.upsertOne(action.forthComing, state)
  ),
  on(ForthComingActions.addForthComings,
    (state, action) => adapter.addMany(action.forthComings, state)
  ),
  on(ForthComingActions.upsertForthComings,
    (state, action) => adapter.upsertMany(action.forthComings, state)
  ),
  on(ForthComingActions.updateForthComing,
    (state, action) => adapter.updateOne(action.forthComing, state)
  ),
  on(ForthComingActions.updateForthComings,
    (state, action) => adapter.updateMany(action.forthComings, state)
  ),
  on(ForthComingActions.deleteForthComing,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ForthComingActions.deleteForthComings,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ForthComingActions.loadForthComings,
    (state, action) => adapter.setAll(action.forthComings, state)
  ),
  on(ForthComingActions.clearForthComings,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return forthcomingReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getForthComings = selectAll;
