import gql from 'graphql-tag';
import {responseField} from '../../base.graphql';


export const documentationField = `
id
uniqueId
categoryName
accessUsers {
  accessUniqueId
  hasAccess
  accessUser {
    id
    uniqueId
    firstName
    lastName
    designation {
      shortForm
    }
  }
}
`;

export const GET_DOCUMENTATION_FOLDERS = gql`
query GetDocumentationCategory($filtering: DocumentationCategoryFilteringInputObjects) {
  getDocumentationCategory(filtering: $filtering) {
    response {
      ${responseField}
    }
    data {
      ${documentationField}
    }
  }
}
`;


export const CREATE_DOCUMENTATION_FOLDER = gql`
mutation CreateDocumentationCategoryMutation($input: DocumentationCategoryInput) {
  createDocumentationCategoryMutation(input: $input) {
    response {
      ${responseField}
    }
    data {
      ${documentationField}
    }
  }
}
`;


export const DELETE_DOCUMENTATION = gql`
mutation DeleteDocumentationCategoryMutation($categoryUniqueId: String) {
  deleteDocumentationCategoryMutation(categoryUniqueId: $categoryUniqueId) {
    response {
      ${responseField}
    }
  }
}
`;


export const REVOKE_ACCESS_TO_DOCUMENTATION = gql`
mutation RevokeDocumentationCategoryAccessMutation($accessUniqueId: String) {
  revokeDocumentationCategoryAccessMutation(accessUniqueId: $accessUniqueId) {
    response {
      ${responseField}
    }
    data {
      ${documentationField}
    }
  }
}
`
