import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {AttachmentConfig} from './attachment-configuration.model';

export const loadAttachmentConfigs = createAction(
  '[AttachmentConfig/API] Load AttachmentConfigs',
  props<{ attachmentConfigs: AttachmentConfig[] }>()
);

export const addAttachmentConfig = createAction(
  '[AttachmentConfig/API] Add AttachmentConfig',
  props<{ attachmentConfig: AttachmentConfig }>()
);

export const upsertAttachmentConfig = createAction(
  '[AttachmentConfig/API] Upsert AttachmentConfig',
  props<{ attachmentConfig: AttachmentConfig }>()
);

export const addAttachmentConfigs = createAction(
  '[AttachmentConfig/API] Add AttachmentConfigs',
  props<{ attachmentConfigs: AttachmentConfig[] }>()
);

export const upsertAttachmentConfigs = createAction(
  '[AttachmentConfig/API] Upsert AttachmentConfigs',
  props<{ attachmentConfigs: AttachmentConfig[] }>()
);

export const updateAttachmentConfig = createAction(
  '[AttachmentConfig/API] Update AttachmentConfig',
  props<{ attachmentConfig: Update<AttachmentConfig> }>()
);

export const updateAttachmentConfigs = createAction(
  '[AttachmentConfig/API] Update AttachmentConfigs',
  props<{ attachmentConfigs: Update<AttachmentConfig>[] }>()
);

export const deleteAttachmentConfig = createAction(
  '[AttachmentConfig/API] Delete AttachmentConfig',
  props<{ id: number }>()
);

export const deleteAttachmentConfigs = createAction(
  '[AttachmentConfig/API] Delete AttachmentConfigs',
  props<{ ids: string[] }>()
);

export const clearAttachmentConfigs = createAction(
  '[AttachmentConfig/API] Clear AttachmentConfigs'
);

export const getAllAttachmentConfigs = createAction(
  '[AttachmentConfigs/API] List All AttachmentConfigs'
);

export const createAttachmentConfig = createAction(
  '[AttachmentConfig/API] create attachmentConfig',
  props<{ attachmentConfigDto: any }>()
);

export const editAttachmentConfig = createAction(
  '[AttachmentConfig/API] edit attachmentConfig',
  props<{ attachmentConfigDto: any }>()
);

export const removeAttachmentConfig = createAction(
  '[AttachmentConfig/API] remove attachmentConfig',
  props<{ uid: any }>()
);
