import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'splitCamelCase'
})
export class SplitCamelCasePipe implements PipeTransform {

  transform(value: any): any {
    return typeof value !== 'string' ? value : value?.split(/(?=[A-Z])/).join(' ');
  }

}
