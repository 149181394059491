import {Component, Inject, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../../../store/entities';
import {Observable} from 'rxjs';
import {User} from '../../../../store/entities/settings/user/user.model';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {getUserByUid} from '../../../../store/entities/settings/user/user.actions';
import {selectUserByUid} from '../../../../store/entities/settings/user/user.selectors';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  userUid: string;
  title = 'User Profile';
  user$: Observable<User>;

  constructor(
    private notificationService: NotificationService,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.userUid = data;
    this.store.dispatch(getUserByUid({uid: this.userUid}));
    this.user$ = this.store.select(selectUserByUid(this.userUid));
  }

  ngOnInit(): void {

  }

}
