import {BaseEntity} from '../../BaseEntity.model';

export interface Directive extends BaseEntity{
  directive_descriptions?: string;
  directive_committee?: string;
  directive_handler?: any[];
  directive_responses?: any[];
  directive_to_person?: boolean;
  directive_decision?: string;
  assignedStaff?: string;
}

export enum DirectiveMovementType {
  ASS = 'ASS', RET = 'RET', ASSOS = 'ASSOS'
}
