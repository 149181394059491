import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {returnDuty} from 'src/app/store/entities/settings/user/user.actions';
import {StorageService} from '../../../../services/storage-service.service';
import {AuthUser} from '../../../../store/entities/auth-user/auth-user.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-error-502',
  templateUrl: './error-300.component.html',
  styleUrls: ['./error-300.component.scss']
})
export class Error300Component implements OnInit {

  title = '303';
  loggedUser: AuthUser;

  constructor(
    private storageService: StorageService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.loggedUser = this.storageService.get('account');
    if (!this.loggedUser.hasHandover){
      this.router.navigate(['/home']);
    }
  }

  ngOnInit() {
  }

  returnDuty() {
    this.store.dispatch(returnDuty());
  }

}
