import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Invitee} from './invitee.model';
import * as InviteeActions from './invitee.actions';

export const inviteesFeatureKey = 'invitee';

export interface State extends EntityState<Invitee> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Invitee> = createEntityAdapter<Invitee>({
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const InviteeReducer = createReducer(
  initialState,
  on(InviteeActions.addInvitee,
    (state, action) => adapter.addOne(action.invitee, state)
  ),
  on(InviteeActions.upsertInvitee,
    (state, action) => adapter.upsertOne(action.invitee, state)
  ),
  on(InviteeActions.addInvitees,
    (state, action) => adapter.addMany(action.invitees, state)
  ),
  on(InviteeActions.upsertInvitees,
    (state, action) => adapter.upsertMany(action.invitees, state)
  ),
  on(InviteeActions.updateInvitee,
    (state, action) => adapter.updateOne(action.invitee, state)
  ),
  on(InviteeActions.updateInvitees,
    (state, action) => adapter.updateMany(action.invitees, state)
  ),
  on(InviteeActions.deleteInvitee,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(InviteeActions.deleteInvitees,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(InviteeActions.loadInvitees,
    (state, action) => adapter.setAll(action.invitees, state)
  ),
  on(InviteeActions.clearInvitees,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return InviteeReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getInvitees = selectAll;
