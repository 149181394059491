import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {ProjectProgress} from '../../../../../store/entities/projects/project-progress/project-progress.model';
import {
  editProjectProgress,
  saveProjectProgress
} from '../../../../../store/entities/projects/project-progress/project-progress.actions';
import {ProjectStatusEnum} from '../../../../../shared/enums/data.enums';
import {AppState} from '../../../../../store/entities';
import {
  ProjectComponentTask
} from '../../../../../store/entities/projects/project-component-task/project-component-task.model';
import {lastValueFrom, of} from 'rxjs';
import {DELETE_ATTACHMENT, UPLOAD_FILE} from '../../../../../store/entities/settings/attachment/attachment.graphql';
import {QueryService} from '../../../../../services/query.service';
import {NotificationService} from '../../../../../services/notification.service';
import {
  ViewAttachmentComponent
} from '../../../../../shared/components/views/view-attachment/view-attachment.component';
import {SettingsService} from '../../../../../services/settings.service';

@Component({
  selector: 'app-project-component-task-progress-form',
  templateUrl: './project-component-task-progress-form.component.html',
  styleUrls: ['./project-component-task-progress-form.component.scss']
})
export class ProjectComponentTaskProgressFormComponent implements OnInit {

  title: string;
  progress: ProjectProgress;
  task: ProjectComponentTask;
  form: UntypedFormGroup;
  status: string[] = Object.values(ProjectStatusEnum);

  filePath: string = null;
  base64String: string = null;

  constructor(
    private fb: UntypedFormBuilder,
    private queryService: QueryService,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.task = data?.task;
    this.progress = data?.progress;
  }

  ngOnInit(): void {
    this.title = 'Project Task Progress';

    this.form = this.fb.group({
      projectTask: [this.task?.uniqueId, Validators.required],
      status: [null, Validators.required],
      description: [null, Validators.required],
      psCoverLetter: [null, Validators.required],
      // dateOfActivity: [null, Validators.required]
    });

    if (this.progress) {
      this.form.patchValue(this.progress);
    }
  }

  onSingleFileSelected(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {

      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
         this.base64String = reader?.result?.toString()?.split(',')[1];
         const fileName = file?.name;
         lastValueFrom(
          this.queryService.mutate(UPLOAD_FILE, { inputDto: {fileName, base64String: this.base64String}})
        ).then( data => {
          const result = data[0];

          if (result?.response?.code === 9000){
            this.filePath = result?.data?.fullFileName;
            this.form.get('psCoverLetter').patchValue(this.filePath);
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        });
      };
    }
    event.target.value = '';

  }

  removeSingleFile() {

    if (this.filePath !== null) {
      /*remove from dummy */
      lastValueFrom(
        this.queryService.mutate(DELETE_ATTACHMENT, {inputDto: this.filePath})
      ).then(data => {
        const result = data[0];
        if (result?.response?.code === 9000) {
          this.filePath = null;
          this.base64String = null;
          this.form.get('psCoverLetter').reset();
        } else {
          this.notificationService.errorMessage(result?.response?.message);
        }
      });
    }
  }

  viewAttachment(id) {
      this.settingsService.openModal({base64$: of(this.base64String), filePath: this.filePath }, ViewAttachmentComponent, '85%');
  }

  add(formData) {
    if (this.progress) {
      formData.uniqueId = this.progress?.uniqueId;
      this.store.dispatch(editProjectProgress({ formData}));
    }else {
      this.store.dispatch(saveProjectProgress({ formData}));
    }
  }

}
