import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-dote-spinner',
  templateUrl: './spinner-dotted.component.html',
  styleUrls: ['./spinner-dotted.component.scss']
})
export class SpinnerDottedComponent implements OnInit  {

  ngOnInit(): void {
  }

}
