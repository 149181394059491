import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as TranslationActions from './translation.actions';
import {Translation} from './translation.model';

export const translationsFeatureKey = 'translations';

export interface State extends EntityState<Translation> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Translation> = createEntityAdapter<Translation>({
  // sortComparer: sortByName
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const translationReducer = createReducer(
  initialState,
  on(TranslationActions.addTranslation,
    (state, action) => adapter.addOne(action.translation, state)
  ),
  on(TranslationActions.upsertTranslation,
    (state, action) => adapter.upsertOne(action.translation, state)
  ),
  on(TranslationActions.addTranslations,
    (state, action) => adapter.addMany(action.translations, state)
  ),
  on(TranslationActions.upsertTranslations,
    (state, action) => adapter.upsertMany(action.translations, state)
  ),
  on(TranslationActions.updateTranslation,
    (state, action) => adapter.updateOne(action.translation, state)
  ),
  on(TranslationActions.updateTranslations,
    (state, action) => adapter.updateMany(action.translations, state)
  ),
  on(TranslationActions.deleteTranslation,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(TranslationActions.deleteTranslations,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(TranslationActions.loadTranslations,
    (state, action) => adapter.setAll(action.translations, state)
  ),
  on(TranslationActions.clearTranslations,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return translationReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getTranslations = selectAll;
