import {inject, Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Apollo} from 'apollo-angular';
import {map, take} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QueryService implements OnDestroy {
  subscription: Subscription = new Subscription();

  http = inject(HttpClient);

  constructor(
    private apollo: Apollo
  ) {
  }

  query(query, variables?: any) {
    return this.apollo.query({query, variables}).pipe(
      take(1),
      map(({ data }: any) => {
        const result: any = Object.values(data);
        if (result != null) {
          return result;
        }
      })
    );
  }

  mutate(mutation, variables?: any) {
    return this.apollo.mutate({mutation, variables}).pipe(
      take(1),
      map(({ data }: any) => {
        const result: any = Object.values(data);
        if (result != null) {
          return result;
        }
      })
    );
  }

  uploadFile(formData: FormData) {

    const headers = new HttpHeaders({Accept: 'application/json'});

    return this.http.post(environment.SERVER_URL + `/upload_file`, formData, { headers })
        .pipe(map((tokenDetails: any) => tokenDetails));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
