import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const directiveProgressField = `
  ${baseField}
  descriptions
  date
  isCurrent
  progressType
  attachment {
    id
    uniqueId
    attachmentName
    attachmentPath
  }
`;

export const GET_DIRECTIVE_PROGRESS = gql`
  query getAllDirectivesImplementations($filtering: ImplementationSearchingInputObjects ,$directiveUniqueId: String) {
    getAllDirectivesImplementations(filtering: $filtering ,directiveUniqueId:$directiveUniqueId) {
      response{
        ${responseField}
      }
      data{
        ${directiveProgressField}
      }
    }
  }
`;

export const GET_DIRECTIVE_REVIEW_PROGRESS_REPORT = gql`
  query getDirectiveReviewReports($directive: String) {
    getDirectiveReviewReports(directive: $directive) {
      response{
        ${responseField}
      }
      data{
        ${directiveProgressField}
      }
    }
  }
`;



export const SAVE_DIRECTIVE_PROGRESS = gql`
  mutation addDirectiveProgressReport($progress: NewsDireciveProgressInputObject!) {
    addDirectiveProgressReport(progress: $progress){
      response{
        ${responseField}
      }
      data{
        ${directiveProgressField}
      }
    }
  }
`;


export const REVIEW_DIRECTIVE_PROGRESS = gql`
  mutation reviewDirectiveProgressReport($review: ProgressReviewInputObject!) {
    reviewDirectiveProgressReport(review: $review){
      response{
        ${responseField}
      }
      data{
        ${directiveProgressField}
      }
    }
  }
`;

export const UPDATE_DIRECTIVE_PROGRESS = gql`
  mutation updateDirectiveProgressReport($progress: NewsDireciveProgressInputObject!) {
    updateDirectiveProgressReport(progress: $progress){
      response{
        ${responseField}
      }
      data{
        ${directiveProgressField}
      }
    }
  }
`;
