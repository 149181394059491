import {createSelector} from '@ngrx/store';
import * as fromAppState from '../index';
import * as fromUser from './auth-user.reducer';

export const getAuthUserState = createSelector(fromAppState.appState, (appState) => appState.authUsers);

// query to get all users
export const selectAuthUser = createSelector( getAuthUserState, fromUser.getAuthUser);

export const selectLoggedInUser = createSelector(selectAuthUser, (u) => u[0]);
