<ng-container *ngIf="isCustomField">
  <button [matMenuTriggerFor]="menu" type="button" class="custom-date-picker d-print-none {{ isFullWidth ? 'w-100' : '' }}" mat-stroked-button>
    {{ label | concat | translate}}
  </button>

  <mat-menu #menu="matMenu">
    <button (click)="generateDateRange(1)" mat-menu-item>{{'One Day' | concat | translate}}</button>
    <button (click)="generateDateRange(7)" mat-menu-item>{{'Last 7 Days' | concat | translate}}</button>
    <button (click)="generateDateRange(14)" mat-menu-item>{{'Last 14 Days' | concat | translate}}</button>

    <mat-divider></mat-divider>

    <button (click)="generateDateRange(30)" mat-menu-item>{{'Last One Month' | concat | translate}}</button>
    <button (click)="generateDateRange(90)" mat-menu-item>{{'Last Three Month' | concat | translate}}</button>

    <mat-divider></mat-divider>
    <button (click)="generateDateRange(365)" mat-menu-item>{{'Last One Year' | concat | translate}}</button>
    <button (click)="generateDateRange(1825)" mat-menu-item>{{'Last Five Year' | concat | translate}}</button>
    <button [matMenuTriggerFor]="dateRangeMenu" mat-menu-item> {{'Custom Date Range' | concat | translate}} </button>
  </mat-menu>

  <mat-menu #dateRangeMenu="matMenu" class="px-3">

    <mat-form-field appearance="outline" class="">
      <mat-label>{{label | concat | translate}}</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input formControlName="start" matStartDate placeholder="{{'Start Date' | concat | translate}}">
        <input formControlName="end" matEndDate placeholder="{{'End Date' | concat | translate}}">
      </mat-date-range-input>
      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'Invalid Start Date' | translate}}</mat-error>
      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'Invalid End Date' | concat | translate}}</mat-error>
    </mat-form-field>
  </mat-menu>

</ng-container>

<ng-container *ngIf="!isCustomField">

  <mat-form-field appearance="outline" class="d-print-none">
    <mat-label>{{label | concat | translate}}</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input formControlName="start" matStartDate placeholder="{{'Start Date' | concat | translate}}">
      <input formControlName="end" matEndDate placeholder="{{'End Date' | concat | translate}}">
    </mat-date-range-input>
    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'Invalid Start Date' | concat | translate}}</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'Invalid End Date' | concat | translate}}</mat-error>
  </mat-form-field>
</ng-container>
