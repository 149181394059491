import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {
  actionDesignation,
  deleteDesignation,
  getAllDesignations,
  loadDesignations,
  upsertDesignation
} from './designation.actions';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {GET_DESIGNATION, SAVE_DESIGNATION} from './designation.graphql';
import {ActionTypeEnum} from '../../../../shared/enums/data.enums';
import {SettingsService} from 'src/app/services/settings.service';

@Injectable()
export class DesignationEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllDesignations),
    switchMap(() => {
      return this.apollo.query({
        query: GET_DESIGNATION
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadDesignations({designations: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(actionDesignation),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_DESIGNATION,
        variables: { inputDto: action.designationDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            if (action.designationDto.actionType === ActionTypeEnum.delete){
              this.store.dispatch(deleteDesignation({id: action.id}));
              this.settingsService.reloadCurrentRoute(null);
            }
            this.store.dispatch(upsertDesignation({designation: result?.data}));

          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private apollo: Apollo,
    private settingsService: SettingsService,

  ) {
  }
}
