import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {MinutesNotes} from './minutes-notes.model';
import * as MinutesNotesActions from './minutes-notes.actions';

export const minutesNotessFeatureKey = 'minutesNotes';

export interface State extends EntityState<MinutesNotes> {
  // additional entities state properties
}

export const adapter: EntityAdapter<MinutesNotes> = createEntityAdapter<MinutesNotes>({
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const MinutesNotesReducer = createReducer(
  initialState,
  on(MinutesNotesActions.addMinutesNotes,
    (state, action) => adapter.addOne(action.minutesNotes, state)
  ),
  on(MinutesNotesActions.upsertMinutesNotes,
    (state, action) => adapter.upsertOne(action.minutesNotes, state)
  ),
  on(MinutesNotesActions.addMinutesNotess,
    (state, action) => adapter.addMany(action.minutesNotess, state)
  ),
  on(MinutesNotesActions.upsertMinutesNotess,
    (state, action) => adapter.upsertMany(action.minutesNotess, state)
  ),
  on(MinutesNotesActions.updateMinutesNotes,
    (state, action) => adapter.updateOne(action.minutesNotes, state)
  ),
  on(MinutesNotesActions.updateMinutesNotess,
    (state, action) => adapter.updateMany(action.minutesNotess, state)
  ),
  on(MinutesNotesActions.deleteMinutesNotes,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(MinutesNotesActions.deleteMinutesNotess,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(MinutesNotesActions.loadMinutesNotess,
    (state, action) => adapter.setAll(action.minutesNotess, state)
  ),
  on(MinutesNotesActions.clearMinutesNotess,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return MinutesNotesReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getMinutesNotess = selectAll;
