import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {DirectiveProgress} from './directives-progress.model';
import * as DirectiveProgressActions from './directives-progress.actions';

export const directiveProgresssFeatureKey = 'directiveProgress';

export interface State extends EntityState<DirectiveProgress> {
  // additional entities state properties
}

export const adapter: EntityAdapter<DirectiveProgress> = createEntityAdapter<DirectiveProgress>({
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const DirectiveProgressReducer = createReducer(
  initialState,
  on(DirectiveProgressActions.addDirectiveProgress,
    (state, action) => adapter.addOne(action.directiveProgress, state)
  ),
  on(DirectiveProgressActions.upsertDirectiveProgress,
    (state, action) => adapter.upsertOne(action.directiveProgress, state)
  ),
  on(DirectiveProgressActions.addDirectiveProgresss,
    (state, action) => adapter.addMany(action.directiveProgresss, state)
  ),
  on(DirectiveProgressActions.upsertDirectiveProgresss,
    (state, action) => adapter.upsertMany(action.directiveProgresss, state)
  ),
  on(DirectiveProgressActions.updateDirectiveProgress,
    (state, action) => adapter.updateOne(action.directiveProgress, state)
  ),
  on(DirectiveProgressActions.updateDirectiveProgresss,
    (state, action) => adapter.updateMany(action.directiveProgresss, state)
  ),
  on(DirectiveProgressActions.deleteDirectiveProgress,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(DirectiveProgressActions.deleteDirectiveProgresss,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(DirectiveProgressActions.loadDirectiveProgresss,
    (state, action) => adapter.setAll(action.directiveProgresss, state)
  ),
  on(DirectiveProgressActions.clearDirectiveProgresss,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return DirectiveProgressReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getDirectiveProgresss = selectAll;
