import {createSelector} from '@ngrx/store';
import * as fromProjectImageReducer from './project-image.reducer';
import {AppState} from '../../index';
import {DatePipe} from '@angular/common';

export const currentProjectImageState = (state: AppState) => state[fromProjectImageReducer.projectImagesFeatureKey];

export const selectAllProjectImagesFromReducer = createSelector(currentProjectImageState, fromProjectImageReducer.selectAll);
export const selectTotalProjectImages = createSelector(currentProjectImageState, fromProjectImageReducer.selectTotal);

export const selectAllProjectImages = createSelector(
  selectAllProjectImagesFromReducer,
  (projects) => {
    return projects.map( res => {
      return {
        ...res,
        formattedRecordedOn: new DatePipe('en-GB').transform( res?.recordedOn, 'dd MMM yyyy')
      };
    });
  });

export const selectProjectImageById = (id: number) => createSelector(
  selectAllProjectImages,
  (ProjectImages) => ProjectImages.find((projectImage) => projectImage.id === id)
);

export const selectProjectImageByUid = (uid: string) => createSelector(
  selectAllProjectImages,
  (ProjectImages) => ProjectImages.find((projectImage) => projectImage.uniqueId === uid)
);
