import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {Invitee} from './invitee.model';

export const loadInvitees = createAction(
  '[Invitee/API] Load Invitees',
  props<{ invitees: Invitee[] }>()
);

export const addInvitee = createAction(
  '[Invitee/API] Add Invitee',
  props<{ invitee: Invitee }>()
);

export const upsertInvitee = createAction(
  '[Invitee/API] Upsert Invitee',
  props<{ invitee: Invitee }>()
);

export const addInvitees = createAction(
  '[Invitee/API] Add Invitees',
  props<{ invitees: Invitee[] }>()
);

export const upsertInvitees = createAction(
  '[Invitee/API] Upsert Invitees',
  props<{ invitees: Invitee[] }>()
);

export const updateInvitee = createAction(
  '[Invitee/API] Update Invitee',
  props<{ invitee: Update<Invitee> }>()
);

export const updateInvitees = createAction(
  '[Invitee/API] Update Invitees',
  props<{ invitees: Update<Invitee>[] }>()
);

export const deleteInvitee = createAction(
  '[Invitee/API] Delete Invitee',
  props<{ id: number }>()
);

export const deleteInvitees = createAction(
  '[Invitee/API] Delete Invitees',
  props<{ ids: number[] }>()
);

export const clearInvitees = createAction(
  '[Invitee/API] Clear Invitees'
);


export const getAllInvitee = createAction(
  '[Invitee/API] Get All Meeting Invitees',
  props<{ meetingUniqueId: string }>()
);

export const saveInvitee = createAction(
  '[Invitee/API] Save Meeting Invitee',
  props<{ input: {
      meeting: string;
      invitess: {
        inviteeFullName: string;
        inviteeContact: string;
        inviteeInsitution: string;
        inviteeDesignation: string;
      }[]
    } }>()
);

export const editInvitee = createAction(
  '[Invitee/API] Edit Meeting Invitee',
  props<{ input: {
        inviteeUniqueId: string;
        inviteeFullName: string;
        inviteeContact: string;
        inviteeInsitution: string;
        inviteeDesignation: string;
    } }>()
);

export const removeInvitee = createAction(
  '[Invitee/API] Remove Meeting Invitee',
  props<{ uniqueId: string, id: number }>()
);

