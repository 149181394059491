import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {
  activateDeactivateUser,
  assignUsersToHandOverGroup,
  changePassword,
  deleteUser,
  editUser,
  getAllUsers,
  getSystemUsers,
  getUserRoleAndPermissions,
  getUsersOfTaskAssignedDesignation,
  getUsersToBeHandedOver,
  handOverDuty,
  loadUsers,
  refreshToken,
  removeUserFromHandOverGroup,
  resetPassword,
  returnDuty,
  revokeToken,
  saveDeviceLocation,
  saveUser,
  sendResetPasswordLink, transferUser,
  unlockUserAccount,
  upsertUser
} from "./user.actions";
import {AuthService} from '../../../../services/auth.service';
import {Router} from '@angular/router';
import {Apollo} from 'apollo-angular';
import {
  ACTIVATE_DEACTIVATE_USER,
  ASSIGN_USER_TO_HANDOVER_GROUP,
  CHANGE_PASSWORD,
  GET_ALL_USERS,
  GET_LOGGED_IN_USER_DETAIL,
  GET_SYSTEM_USERS,
  GET_USERS_OF_TASK_ASSIGNED_DESIGNATION,
  HANDOVER_DUTY,
  HANDOVER_USERS,
  REMOVE_USER_TO_HANDOVER_GROUP,
  RESET_PASSWORD,
  RETURN_HANDOVER_DUTY,
  REVOKE_TOKEN,
  SAVE_DEVICE_LOCATION,
  SAVE_USER,
  SUBMIT_RESET_PASSWORD, TRANSFER_USER,
  UN_LOCK_USER,
  UPDATE_USER
} from "./user.graphql";
import {StorageService} from '../../../../services/storage-service.service';
import {AuthUser} from '../../auth-user/auth-user.model';
import {addAuthUser} from '../../auth-user/auth-user.actions';
import {NgxPermissionsService} from 'ngx-permissions';
import {SettingsService} from '../../../../services/settings.service';
import {throwError} from 'rxjs';

@Injectable()
export class UserEffects {

  refresh = createEffect(() => this.actions$.pipe(
    ofType(refreshToken),
    switchMap(() => {
      return null;
      // return this.apollo.mutate({
      //   mutation: REFRESH_TOKEN,
      //   variables: { refreshToken: action.refreshToken },
      //   context: {
      //     Headers: {
      //       ...Headers,
      //       RefreshToken: this.storageService.get('refreshToken')
      //     }
      //   }
      // }).pipe(
      //   map(({ data }: any) => {
      //     const result: any = Object.values(data)[0];
      //
      //     if (result?.success){
      //       this.storageService.set('currentClient', result?.token );
      //       this.storageService.set('refreshToken', result?.refreshToken);
      //
      //       // Session Refresh
      //       const expiry = Math.round(Date.now() / 1000) + 600; // Session expires in 10 min from now
      //       this.storageService.set('expiry', expiry);
      //
      //     }else {
      //       this.notificationService.errorMessage('Fail to get a Refresh Token');
      //     }
      //   })
      // );
    })
  ), { dispatch: false });

  userDetails = createEffect(() => this.actions$.pipe(
    ofType(getUserRoleAndPermissions),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_LOGGED_IN_USER_DETAIL
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result === null){
            this.authService.logout();
          }
          if (result?.response?.code === 9000) {

            const authUser: AuthUser = {
              ...result?.userProfile,
              hasHandover: result?.hasHandover,
              roleObject: result?.data[0]
            };

            this.storageService.set('account', authUser);
            this.storageService.set('category', result?.userProfile?.userType);

            this.store.dispatch(addAuthUser({ authUser }));

            const permissions: string [] = result?.data[0]?.permissions?.map( res => res?.code);
            this.storageService.set('updateProfile', result?.updatePassword);
            this.storageService.set('profile', result?.userProfile);
            this.storageService.set('institution', result?.institution);

            if (permissions?.length > 0){
              this.storageService.set('access', permissions);
              this.ngxPermissionsService.loadPermissions(permissions);
            }

            if (authUser?.hasHandover){
              this.router.navigate(['/303']).then();
            }

            if (action.redirectHome){
              this.router.navigate(['', 'home']).then();
            }

          }else if (result?.response?.code === 9024 || result?.response?.code === 9010){
            this.authService.logout();
          }
        }),
        catchError(() => {
          this.authService.logout();
          return throwError(() => new Error('User Role & Permission Error'));
        })
      );
    }), catchError( () => this.authService.logout())
  ), { dispatch: false });

  revokeToken = createEffect(() => this.actions$.pipe(
    ofType(revokeToken),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: REVOKE_TOKEN,
        variables: { token: action.token}
      });
    })
  ), { dispatch: false });

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllUsers),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_ALL_USERS,
        variables: { uid: action.userUid}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result?.response?.code === 9000) {
            this.store.dispatch(loadUsers({users: result?.data}));
          }
        })
      );
    }),
  ), { dispatch: false });

  getSystemUsers = createEffect(() => this.actions$.pipe(
    ofType(getSystemUsers),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_SYSTEM_USERS,
        variables: { filtering: action.filtering }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result?.response?.code === 9000) {
            this.store.dispatch(loadUsers({users: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  getTaskUsers = createEffect(() => this.actions$.pipe(
    ofType(getUsersOfTaskAssignedDesignation),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_USERS_OF_TASK_ASSIGNED_DESIGNATION,
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result?.response?.code === 9000) {
            this.store.dispatch(loadUsers({users: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  assign = createEffect(() => this.actions$.pipe(
    ofType(assignUsersToHandOverGroup),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: ASSIGN_USER_TO_HANDOVER_GROUP,
        variables: { inputDto: action.handoverGroup}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeUserFromHandOverGroup),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: REMOVE_USER_TO_HANDOVER_GROUP,
        variables: { inputDto: action.inputDto}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteUser({ id: action?.userId}));
          }
        })
      );
    })
  ), { dispatch: false });

  device = createEffect(() => this.actions$.pipe(
    ofType(saveDeviceLocation),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_DEVICE_LOCATION,
        variables: { inputDto: action.inputDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result?.response?.code !== 9000){
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveUser),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_USER,
        variables: { inputDto: action.userDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertUser({user: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editUser),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_USER,
        variables: { inputDto: action.userDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertUser({user: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  transfer = createEffect(() => this.actions$.pipe(
    ofType(transferUser),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: TRANSFER_USER,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertUser({user: result?.data}));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  activate = createEffect(() => this.actions$.pipe(
    ofType(activateDeactivateUser),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: ACTIVATE_DEACTIVATE_USER,
        variables: { uid: action.uniqueId, isActive: action.isActive }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertUser({user: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  handOver = createEffect(() => this.actions$.pipe(
    ofType(handOverDuty),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: HANDOVER_DUTY,
        variables: { inputDto: action.inputDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.authService.logout();
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  returnDuty = createEffect(() => this.actions$.pipe(
    ofType(returnDuty),
    switchMap(() => {
      return this.apollo.mutate({
        mutation: RETURN_HANDOVER_DUTY,
        variables: {
          inputDto: {
            onBehalf: false,
            handingOverUser: this.storageService.get('account')?.uniqueId
          }
        }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.authService.logout();
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  handOverUsers = createEffect(() => this.actions$.pipe(
    ofType(getUsersToBeHandedOver),
    switchMap((action) => {
      return this.apollo.query({
        query: HANDOVER_USERS,
        variables: { uid: action.roleUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000){
            this.store.dispatch(loadUsers({ users: result?.data[0]?.usersList}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  changePassword = createEffect(() => this.actions$.pipe(
    ofType(changePassword),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: CHANGE_PASSWORD,
        variables: { oldPassword: action.oldPassword, newPassword: action.newPassword, confirmPassword: action.confirmPassword }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result?.success) {

            this.storageService.set('currentClient', result?.token );
            this.storageService.set('refreshToken', result?.refreshToken);

            this.notificationService.successMessageAndCloseModals('Password Changed Successfully');
            this.authService.logout();
          }else {
            this.notificationService.errorMessage('Failed to change password');
          }
        })
      );
    })
  ), { dispatch: false });


  submitReset = createEffect(() => this.actions$.pipe(
    ofType(sendResetPasswordLink),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SUBMIT_RESET_PASSWORD,
        variables: { email: action.email}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals('Reset Link sent to your email address successfully');
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  reset = createEffect(() => this.actions$.pipe(
    ofType(resetPassword),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: RESET_PASSWORD,
        variables: { password: action.password, confirmPassword: action.confirmPassword, token: action.token}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result?.response?.code === 9000) {
            this.notificationService.successMessageWithRedirect('Reset completed successfully', '/login');
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  unlock = createEffect(() => this.actions$.pipe(
    ofType(unlockUserAccount),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UN_LOCK_USER,
        variables: { uid: action.uid}
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals('completed successfully');
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apollo: Apollo,
    private authService: AuthService,
    private router: Router,
    private ngxPermissionsService: NgxPermissionsService,
    private settingsService: SettingsService,
    private storageService: StorageService,
    private notificationService: NotificationService
  ) {
  }
}
