import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {Store} from '@ngrx/store';
import {Translation} from './translation.model';
import {
  createTranslations,
  deleteTranslation,
  getAllTranslations,
  removeTranslations,
  upsertTranslations
} from './translation.actions';
import {CREATE_TRANSLATIONS, GET_ALL_TRANSLATIONS, REMOVE_TRANSLATIONS} from './translation.graphql';
import {NotificationService} from '../../../../services/notification.service';
import {lastValueFrom} from 'rxjs';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {IndexedDBTableName} from '../../../../shared/interfaces/table.interface';
import {AppState} from '../../index';

@Injectable()
export class TranslationEffects {

  save = createEffect(() => this.actions$.pipe(
    ofType(createTranslations),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: CREATE_TRANSLATIONS,
        variables: {
          translations: action?.translations
        }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result?.response?.code === 9000) {
            this.store.dispatch(upsertTranslations({ translations: result?.data as Translation[] }));

            if (result?.data?.length > 0) {
              lastValueFrom(this.dbService.bulkPut(IndexedDBTableName.translation, result?.data)).then();
            }
            return this.notificationService.successMessageAndCloseModals('Saved Successful');
          } else {
            return this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })

  ), { dispatch: false });

  delete = createEffect(() => this.actions$.pipe(
    ofType(removeTranslations),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: REMOVE_TRANSLATIONS,
        variables: {
          keyword: action?.keyword
        }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result?.response?.code === 9000) {
            this.store.dispatch(deleteTranslation({ id: action.id }));
            return this.notificationService.successMessageAndCloseModals('Removed Successful');
          } else {
            return this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })

  ), { dispatch: false });

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllTranslations),
    switchMap(() => {
      return this.apollo.query({
        query: GET_ALL_TRANSLATIONS
      }).pipe(
        map(({ data }: any) => {
          if (Object.values(data)[0] !== null) {
            const result: any = Object.values(data)[0];

            this.store.dispatch(upsertTranslations({ translations: result?.data as Translation[] }));

            if (result?.data?.length > 0) {

              const translations = result?.data?.map( (d, i) => ({id: i + 1, keyword: d?.keyword, en: d?.en, sw: d?.sw}));
              this.dbService.clear(IndexedDBTableName.translation);
              lastValueFrom(this.dbService.bulkAdd(IndexedDBTableName.translation, translations)).then();
            }

          }
        })
      );
    })
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private dbService: NgxIndexedDBService
  ) { }

}
