import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const taskResponseField = `
  ${baseField}
  description
  attachmentName
  attachmentPath
  recordedOn
  status
  recordedBy {
    firstName
    prefix
    lastName
  }
`;

export const GET_TASK_RESPONSE_BY_ASSIGNMENT = gql`
  query getMeetingTaskAssignmentResponses($assignment: String) {
    getMeetingTaskAssignmentResponses(assignment: $assignment) {
      response {
        ${responseField}
      }
      dataList{
        ${taskResponseField}
      }
    }
  }
`;


export const SAVE_TASK_RESPONSE = gql`
  mutation createUpdateMeetingTaskAssignmentResponse($inputDto: MeetingTaskAssignmentResponseInputObjects!) {
    createUpdateMeetingTaskAssignmentResponse(input: $inputDto){
      response{
        ${responseField}
      }
    }
  }
`;

export const DELETE_TASK_RESPONSE = gql`
  mutation deleteMeetingTaskAssignmentResponse($uid: String!) {
    deleteMeetingTaskAssignmentResponse(uniqueId: $uid){
      response{
        ${responseField}
      }
    }
  }
`;
