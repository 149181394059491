import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const designationField = `
  ${baseField}
  name
  shortForm
`;

export const GET_DESIGNATION = gql`
  query getAllDesignationsList {
    getAllDesignationsList {
      response{
        ${responseField}
      }
      data{
        ${designationField}
      }
    }
  }
`;

export const SAVE_DESIGNATION = gql`
  mutation userDesignationManagement($inputDto: DesignationInputObjects!) {
    userDesignationManagement(designation: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${designationField}
      }
    }
  }
`;
