import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {ProjectProgress, ProjectProgressDto} from './project-progress.model';

export const loadProjectProgresss = createAction(
  '[ProjectProgress/API] Load ProjectProgresss',
  props<{ projectProgresss: ProjectProgress[] }>()
);

export const addProjectProgress = createAction(
  '[ProjectProgress/API] Add ProjectProgress',
  props<{ projectProgress: ProjectProgress }>()
);

export const upsertProjectProgress = createAction(
  '[ProjectProgress/API] Upsert ProjectProgress',
  props<{ projectProgress: ProjectProgress }>()
);

export const addProjectProgresss = createAction(
  '[ProjectProgress/API] Add ProjectProgresss',
  props<{ projectProgresss: ProjectProgress[] }>()
);

export const upsertProjectProgresss = createAction(
  '[ProjectProgress/API] Upsert ProjectProgresss',
  props<{ projectProgresss: ProjectProgress[] }>()
);

export const updateProjectProgress = createAction(
  '[ProjectProgress/API] Update ProjectProgress',
  props<{ projectProgress: Update<ProjectProgress> }>()
);

export const updateProjectProgresss = createAction(
  '[ProjectProgress/API] Update ProjectProgresss',
  props<{ projectProgresss: Update<ProjectProgress>[] }>()
);

export const deleteProjectProgress = createAction(
  '[ProjectProgress/API] Delete ProjectProgress',
  props<{ id: number }>()
);

export const deleteProjectProgresss = createAction(
  '[ProjectProgress/API] Delete ProjectProgresss',
  props<{ ids: string[] }>()
);

export const clearProjectProgresss = createAction(
  '[ProjectProgress/API] Clear ProjectProgresss'
);


export const saveProjectProgress = createAction(
  '[ProjectProgress/API] create projectProgress',
  props<{ formData: ProjectProgressDto }>()
);

export const editProjectProgress = createAction(
  '[ProjectProgress/API] edit projectProgress',
  props<{ formData: ProjectProgressDto }>()
);

export const removeProjectProgress = createAction(
  '[ProjectProgress/API] remove projectProgress',
  props<{ uid: string, id?: number }>()
);
