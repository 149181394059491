import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/entities';
import {SettingsService} from '../../../../services/settings.service';
import {NotificationService} from '../../../../services/notification.service';
import {
  removeProjectComponentTask
} from '../../../../store/entities/projects/project-component-task/project-component-task.actions';
import {
  ProjectComponentTask
} from '../../../../store/entities/projects/project-component-task/project-component-task.model';
import {ProjectComponent} from '../../../../store/entities/projects/project-component/project-component.model';
import {ProjectStatusEnum} from '../../../enums/data.enums';
import {getProjectByUid} from '../../../../store/entities/projects/project/project.actions';
import {ActivatedRoute} from '@angular/router';
import {
  getProjectComponentByUid
} from '../../../../store/entities/projects/project-component/project-component.actions';
import {Observable} from 'rxjs';
import {Project} from '../../../../store/entities/projects/project/project.model';
import {
  selectProjectComponentByUid
} from '../../../../store/entities/projects/project-component/project-component.selectors';
import {selectProjectByUid} from '../../../../store/entities/projects/project/project.selectors';
import {
  selectAllProjectComponentTasks
} from '../../../../store/entities/projects/project-component-task/project-component-task.selectors';
import {ProjectProgressListComponent} from '../project-progress-list/project-progress-list.component';
import {
  ProjectComponentTaskFormComponent
} from '../../../../modules/portal/portal-project/portal-project-forms/project-component-task-form/project-component-task-form.component';
import {
  ProjectComponentTaskProgressFormComponent
} from '../../../../modules/portal/portal-project/portal-project-forms/project-component-task-progress-form/project-component-task-progress-form.component';

@Component({
  selector: 'app-project-component-task',
  templateUrl: './project-component-task.component.html',
  styleUrls: ['./project-component-task.component.scss']
})
export class ProjectComponentTaskComponent implements OnInit {

  title: string;
  projectUid: string;
  componentUid: string;
  tableColumn: any;

  project$: Observable<Project>;
  component$: Observable<ProjectComponent>;
  tasks$: Observable<ProjectComponentTask[]>;

  status = ProjectStatusEnum;

  constructor(
    private store: Store<AppState>,
    private settingsService: SettingsService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.projectUid = this.activatedRoute.snapshot.paramMap.get('uid');
    this.componentUid = this.activatedRoute.snapshot.paramMap.get('componentUid');
    this.store.dispatch(getProjectByUid({uid: this.projectUid, withComponents: false}));
    this.store.dispatch(getProjectComponentByUid({uid: this.componentUid}));
  }

  ngOnInit(): void {
    this.title = 'Project Component Tasks';

    this.tableColumn = {
      no: 'No',
      name: 'Task Title',
      location: 'Location',
      manager: 'Manager',
      percentCompositionOnComponent: 'Percent Composition',
      budget: 'Budget',
      formattedStartDate: 'Start Date',
      formattedHandoverDate: 'End Date',
      status: 'Status',
      action: 'Action'
    };

    this.project$ = this.store.select(selectProjectByUid(this.projectUid));
    this.component$ = this.store.select(selectProjectComponentByUid(this.componentUid));
    this.tasks$ = this.store.select(selectAllProjectComponentTasks);
  }

  action(task: ProjectComponentTask, component: ProjectComponent) {
    this.settingsService.openModal({task, component}, ProjectComponentTaskFormComponent);
  }

  taskProgressAction(task: ProjectComponentTask) {
    this.settingsService.openModal({task}, ProjectComponentTaskProgressFormComponent);
  }

  remove(res) {
    const message = 'Are you sure you want to remove this project component task?';
    this.notificationService.confirmation(message).then(
      data => {
        if (data) {
          this.store.dispatch(removeProjectComponentTask({ uid: res?.uniqueId, id: res?.id }));
        }
      }
    );
  }

  viewProgress(task: ProjectComponentTask) {
    this.settingsService.openModal(task, ProjectProgressListComponent, '85%');
  }
}
