import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Designation, DesignationDto} from './designation.model';

export const loadDesignations = createAction(
  '[Designation/API] Load Designations',
  props<{ designations: Designation[] }>()
);

export const addDesignation = createAction(
  '[Designation/API] Add Designation',
  props<{ designation: Designation }>()
);

export const upsertDesignation = createAction(
  '[Designation/API] Upsert Designation',
  props<{ designation: Designation }>()
);

export const addDesignations = createAction(
  '[Designation/API] Add Designations',
  props<{ designations: Designation[] }>()
);

export const upsertDesignations = createAction(
  '[Designation/API] Upsert Designations',
  props<{ designations: Designation[] }>()
);

export const updateDesignation = createAction(
  '[Designation/API] Update Designation',
  props<{ designation: Update<Designation> }>()
);

export const updateDesignations = createAction(
  '[Designation/API] Update Designations',
  props<{ designations: Update<Designation>[] }>()
);

export const deleteDesignation = createAction(
  '[Designation/API] Delete Designation',
  props<{ id: number }>()
);

export const deleteDesignations = createAction(
  '[Designation/API] Delete Designations',
  props<{ ids: string[] }>()
);

export const clearDesignations = createAction(
  '[Designation/API] Clear Designations'
);

export const getAllDesignations = createAction(
  '[Designations/API] List All Designations'
);

export const actionDesignation = createAction(
  '[Designation/API] create, edit & delete designation',
  props<{ designationDto: DesignationDto, id?: number }>()
);
