import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {Project} from '../../../../../store/entities/projects/project/project.model';
import {
  editProjectChallenge,
  saveProjectChallenge
} from '../../../../../store/entities/projects/project-challenge/project-challenge.actions';
import {ProjectChallenge} from '../../../../../store/entities/projects/project-challenge/project-challenge.model';
import {AppState} from '../../../../../store/entities';
import * as sanitizeHtml from 'sanitize-html';

@Component({
  selector: 'app-project-challenge-form',
  templateUrl: './project-challenge-form.component.html',
  styleUrls: ['./project-challenge-form.component.scss']
})
export class ProjectChallengeFormComponent implements OnInit, OnDestroy {

  title: string;
  form: UntypedFormGroup;
  subscriptions: Subscription = new Subscription();
  editorStyle: any;
  project: Project;
  challenge: ProjectChallenge;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.project = data?.project;
    this.challenge = data?.challenge;
  }

  ngOnInit(): void {
    this.title = 'Project Challenge & Way forward ' ;

    this.form = this.fb.group({
      project: [this.project?.uniqueId, Validators.required],
      challenge: [null, Validators.required],
      wayForward: [null, Validators.required],
    });

    this.editorStyle = {
      height: 'auto'
    };

    if (this.challenge){
      this.form.patchValue(this.challenge);
      this.form.get('challenge').patchValue(sanitizeHtml(this.challenge?.challenge));
      this.form.get('wayForward').patchValue(sanitizeHtml(this.challenge?.wayForward));
    }
  }

  add(formData) {
    if (this.challenge){
      formData.uniqueId = this.challenge?.uniqueId;
      this.store.dispatch(editProjectChallenge({ formData }));
    }else {
      this.store.dispatch(saveProjectChallenge({ formData }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
