import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {DecisionService} from './decision.service';
import {getAllDecisions, loadDecisions} from './decision.actions';
import {SettingsService} from '../../../../services/settings.service';
import {Decision} from './decision.model';

@Injectable()
export class DecisionEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllDecisions),
    switchMap(() => {
      return this.decisionService.getAll().pipe(
        map((res: any) => {
          if (res !== null){
            let decisions: Decision[] = [];
            decisions = res?.meeting_decision?.map( (d, index) => {
              return {
                ...d,
                decision_id: d?.id,
                id: index + 1
              };
            });

            const otherDecision: any[] = res?.other_decision?.map( (d, index) => {
                return {
                  ...d,
                  decision_id: d?.id,
                  nyaraka_title: 'AOBs',
                  decision_completed_status: d?.decision_is_completed,
                  id: decisions?.length + index + 1
                };
              }
            );
            decisions.push(...otherDecision);

            this.store.dispatch(loadDecisions({decisions}));
          }
        })
      );
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private decisionService: DecisionService,
  ){}

}
