<app-modal-fixed-header [title]="title"></app-modal-fixed-header>

<div class="row" id="printSection">
      <div class="col-md-12">
        <div class="certificate-content">
          <div class="cert-heading text-center bb-dotted">
            <div class="row justify-content-center">
              <div class="col-md-4">
                <img alt="" class="w-25" src="/assets/images/emblem.png">
              </div>

              <div class="col-md-12 my-auto">
                <h2 class="my-1">{{'The United Republic of Tanzania' | concat | translate}}</h2>
                <h2 class="my-1">{{'ecabinet' | concat | translate}}</h2>
                <h3 class="my-1">{{'KEY PROJECTS STATUS' | concat | translate}}: {{'WORKDONE AND BUDGET EXPENDITURE' | concat | translate}}</h3>
              </div>

            </div>

          </div>


          <div class="col-md-12 text-center">
            <p>{{'ecabinet' | concat | translate}} &copy; {{ today | date: 'yyyy' }} {{'All Rights Reserved' | concat | translate}}. </p>
          </div>

        </div>
      </div>
  </div>


<div class="col-md-12 mt-3">
  <div class="float-end">

    <button [printStyle]="{h2 : {'font-size': '20px !important'}, h3: {'font-size': '16px !important'}}"
            [useExistingCss]="true" color="warn" mat-raised-button ngxPrint
            printSectionId="printSection"
            printTitle="Project Report">
      <mat-icon class="material-symbols-outlined">print</mat-icon>
      {{'Print' | concat | translate}}
    </button>
  </div>
</div>
