import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {ProjectRecommendation, ProjectRecommendationDto} from './project-recommendation.model';

export const loadProjectRecommendations = createAction(
  '[ProjectRecommendation/API] Load ProjectRecommendations',
  props<{ projectRecommendations: ProjectRecommendation[] }>()
);

export const addProjectRecommendation = createAction(
  '[ProjectRecommendation/API] Add ProjectRecommendation',
  props<{ projectRecommendation: ProjectRecommendation }>()
);

export const upsertProjectRecommendation = createAction(
  '[ProjectRecommendation/API] Upsert ProjectRecommendation',
  props<{ projectRecommendation: ProjectRecommendation }>()
);

export const addProjectRecommendations = createAction(
  '[ProjectRecommendation/API] Add ProjectRecommendations',
  props<{ projectRecommendations: ProjectRecommendation[] }>()
);

export const upsertProjectRecommendations = createAction(
  '[ProjectRecommendation/API] Upsert ProjectRecommendations',
  props<{ projectRecommendations: ProjectRecommendation[] }>()
);

export const updateProjectRecommendation = createAction(
  '[ProjectRecommendation/API] Update ProjectRecommendation',
  props<{ projectRecommendation: Update<ProjectRecommendation> }>()
);

export const updateProjectRecommendations = createAction(
  '[ProjectRecommendation/API] Update ProjectRecommendations',
  props<{ projectRecommendations: Update<ProjectRecommendation>[] }>()
);

export const deleteProjectRecommendation = createAction(
  '[ProjectRecommendation/API] Delete ProjectRecommendation',
  props<{ id: number }>()
);

export const deleteProjectRecommendations = createAction(
  '[ProjectRecommendation/API] Delete ProjectRecommendations',
  props<{ ids: string[] }>()
);

export const clearProjectRecommendations = createAction(
  '[ProjectRecommendation/API] Clear ProjectRecommendations'
);


export const getProjectRecommendationByProjectUid = createAction(
  '[ProjectRecommendation/API] remove projectRecommendation by project uid',
  props<{ projectUid: string }>()
);

export const saveProjectRecommendation = createAction(
  '[ProjectRecommendation/API] create projectRecommendation',
  props<{ formData: ProjectRecommendationDto }>()
);

export const editProjectRecommendation = createAction(
  '[ProjectRecommendation/API] edit projectRecommendation',
  props<{ formData: ProjectRecommendationDto }>()
);

export const removeProjectRecommendation = createAction(
  '[ProjectRecommendation/API] remove projectRecommendation',
  props<{ uid: string, id: number }>()
);
