import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {Document, DocumentStageEnum, DocumentStatusEnum} from './document.model';
import {PageableParam} from '../page/page.model';

export const loadDocuments = createAction(
  '[Document/API] Load Documents',
  props<{ documents: Document[] }>()
);

export const addDocument = createAction(
  '[Document/API] Add Document',
  props<{ document: Document }>()
);

export const upsertDocument = createAction(
  '[Document/API] Upsert Document',
  props<{ document: Document }>()
);

export const addDocuments = createAction(
  '[Document/API] Add Documents',
  props<{ documents: Document[] }>()
);

export const upsertDocuments = createAction(
  '[Document/API] Upsert Documents',
  props<{ documents: Document[] }>()
);

export const updateDocument = createAction(
  '[Document/API] Update Document',
  props<{ document: Update<Document> }>()
);

export const updateDocuments = createAction(
  '[Document/API] Update Documents',
  props<{ documents: Update<Document>[] }>()
);

export const deleteDocument = createAction(
  '[Document/API] Delete Document',
  props<{ id: number }>()
);

export const deleteDocuments = createAction(
  '[Document/API] Delete Documents',
  props<{ ids: number[] }>()
);

export const clearDocuments = createAction(
  '[Document/API] Clear Documents'
);


export const getAllDocuments = createAction(
  '[Document/API] get all Documents',
  props<{ status?: DocumentStatusEnum, fyUniqueId?: string, itemsPerPage?: number, pageNumber?: number}>()
);


export const stampDocument = createAction(
  '[Document/API] stamp Document',
  props<{ input?: {
      paperUniqueId: string,
      stampSign: 'CONFIDENTIAL' | 'SECRET',
      documentsPath: string[]
    } }>()
);

export const getAllDocumentPageable = createAction(
  '[Document/API] get all Documents pageable',
  props<{ pageableParam: PageableParam, fyUniqueId?: string, status?: string }>()
);

export const getAllDocumentByStage = createAction(
  '[Document/API] get all active Documents by stage',
  props<{ stage: DocumentStageEnum}>()
);

export const getAllCommiteesPapers = createAction(
  '[FolderCategories/API] get all Committee Papers',
  props<{commiteeUniqueId: string, status?: string, paperUniqueId?: string, paperHasReview?: boolean }>()
);

export const getAllMinistryDecisions = createAction(
  '[Document/API] list All ministry decision',
  props<{ ministryUid: string }>()
);

export const saveDocument = createAction(
  '[Document/API] Save Document',
  props<{ documentDto: any }>()
);

export const saveDocumentFromMinistry = createAction(
  '[Document/API] ministryRegisterNewCabinetPaper',
  props<{ documentDto: any }>()
);

export const editDocument = createAction(
  '[Document/API] edit Document',
  props<{ documentDto: any }>()
);

export const attendMinistryNewIncomingPaper = createAction(
  '[Document/API] attendMinistryNewIncomingPaper',
  props<{ documentDto: any }>()
);

export const editDocumentFromMinistry = createAction(
  '[Document/API] editDocumentFromMinistry',
  props<{ documentDto: any }>()
);

export const submitOtherDecisionProgress = createAction(
  '[Document/API] submit other Decision progress',
  props<{ documentCommentDto: any }>()
);

export const submitDecisionProgress = createAction(
  '[Document/API] submit cabinet Decision progress',
  props<{ documentCommentDto: any }>()
);

export const getIncomingPapers = createAction(
  '[Document/API] getIncomingPapers'
);

export const approveProgress = createAction(
  '[Document/API] approve progress',
  props<{ documentCommentDto: any }>()
);

export const markAsDiscussed = createAction(
  '[Document/API] mark as discussed',
  props<{ documentCommentDto: any }>()
);

export const markAsWithDrawn = createAction(
  '[Document/API] mark as withdrawn',
  props<{ uid: string, withdrawnReasons: string }>()
);

export const returnPaperToCC = createAction(
  '[Document/API] return paper to CC',
  props<{ uid: string, withdrawnReasons: string }>()
);

export const dismissPaper = createAction(
  '[Document/API] dismiss paper',
  props<{ uniqueId: string, id: number }>()
);

export const reviewPaper = createAction(
  '[Document/API] review paper',
  props<{ uniqueId?: string, paperUniqueId: string, comments: string, actionType: 'REVIEW' | 'VERIFY' }>()
);

