import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {Project} from '../../../../../store/entities/projects/project/project.model';
import {
  ProjectRecommendation
} from '../../../../../store/entities/projects/project-recommendation/project-recommendation.model';
import {
  editProjectRecommendation,
  saveProjectRecommendation
} from '../../../../../store/entities/projects/project-recommendation/project-recommendation.actions';
import {AppState} from '../../../../../store/entities';
import * as sanitizeHtml from 'sanitize-html';

@Component({
  selector: 'app-project-recommendation-form',
  templateUrl: './project-recommendation-form.component.html',
  styleUrls: ['./project-recommendation-form.component.scss']
})
export class ProjectRecommendationFormComponent implements OnInit, OnDestroy {

  title: string;
  form: UntypedFormGroup;
  subscriptions: Subscription = new Subscription();
  editorStyle: any;
  project: Project;
  recommendation: ProjectRecommendation;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.project = data?.project;
    this.recommendation = data?.recommendation;
  }

  ngOnInit(): void {
    this.title = 'Project Recommendation' ;

    this.form = this.fb.group({
      project: [this.project?.uniqueId, Validators.required],
      description: [null, Validators.required]
    });

    this.editorStyle = {
      height: 'auto'
    };

    if (this.recommendation){
      this.form.get('description').patchValue(sanitizeHtml(this.recommendation?.descriptions));
    }
  }

  add(formData) {
    if (this.recommendation) {
      formData.uniqueId = this.recommendation?.uniqueId;
      this.store.dispatch(editProjectRecommendation({ formData }));
    }else {
      this.store.dispatch(saveProjectRecommendation({ formData }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
