import {StorageService} from '../../services/storage-service.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {UserTypeEnum} from '../../store/entities/settings/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class IsMinistryGuard implements CanActivate {

  constructor(
    private storageService: StorageService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const category = this.storageService.get('category');
    if (category === UserTypeEnum.Ministry) {
      return true;
    }
    else if (category === UserTypeEnum.Staff) {
      return this.router.navigate(['', 'home']);
    }
    return this.router.navigate(['', 'login']);
  }
}
