<app-auth-layout>
  <ng-template>

    <ng-container>
      <h3 class="text-center">{{'Reset Password' | concat | translate}}</h3>

      <form (ngSubmit)="onSubmit()" [formGroup]="staffForm" autocomplete="off" novalidate>

        <div class="row">
          <mat-form-field appearance="outline" class="material-form col-md-12">
            <mat-label>{{'Enter New Password' | concat | translate}}</mat-label>
            <input formControlName="newPassword" matInput type="password">
            <mat-icon matSuffix>lock</mat-icon>
            <mat-error>
                        <span *ngIf="!staffForm.get('newPassword').valid && staffForm.get('newPassword').touched">
                            {{'This field is required' | concat | translate}}
                        </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="material-form col-md-12">
            <mat-label>{{'Re - enter the Password' | concat | translate}}</mat-label>
            <input formControlName="confirmPassword" matInput type="password">
            <mat-icon matSuffix>lock</mat-icon>
            <mat-error>
                        <span
                          *ngIf="!staffForm.get('confirmPassword').valid && staffForm.get('confirmPassword').touched">
                            {{'This field is required' | concat | translate}}
                        </span>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="row">
          <div class="col-6">
            <button mat-stroked-button routerLink="/login" type="button">
              {{'Login' | concat | translate}}
            </button>
          </div>

          <div class="col-6 text-end">
            <button [disabled]="!staffForm.valid || loading" color="primary" mat-raised-button
                    style="color: #fff;" type="submit">
              {{'Continue' | concat | translate}}
            </button>
          </div>
        </div>

      </form>

    </ng-container>

  </ng-template>
</app-auth-layout>






















<!--
<app-auth-layout>
  <ng-template #AuthContent>
    <div *ngIf="!loginView" class="logo-image">
      <h1 class="display1"> Login</h1>
      &lt;!&ndash; Login Form &ndash;&gt;
      <form (ngSubmit)="login()" [formGroup]="staffLoginForm" autocomplete="off" novalidate>
        <div class="row">
          <mat-form-field appearance="outline" class="material-form col-md-12">
            <mat-label>Email</mat-label>
            <input formControlName="username" matInput placeholder="Username">
            <mat-icon matSuffix>email</mat-icon>
            <mat-error>
                        <span *ngIf="!staffLoginForm.get('username').valid && staffLoginForm.get('username').touched">
                            Email is required filed
                        </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="material-form col-md-12">
            <mat-label>Password</mat-label>
            <input formControlName="password" matInput placeholder="Password" type="password">
            <mat-icon matSuffix>lock</mat-icon>
            <mat-error>
                      <span *ngIf="!staffLoginForm.get('password').valid && staffLoginForm.get('password').touched">
                          Password is required filed
                      </span>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="row">
          <div class="col-md-12">
                  <span *ngIf="loading">
                      <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
                  </span>
          </div>
          <div class="col-md-12 d-flex px-0">
            <div (click)="changeView()"
                 class="float-left text-capitalize cursor-pointer order-xs-2 col-xl-7 text-start text-xs-center col-xs-12">
              <i>forgot your password?</i>
            </div>
            <div class="float-lg-right col-lg-5 order-xs-1 col-xs-12">
              <button [disabled]="!staffLoginForm.valid || loading" class="btn w-100" color="primary" mat-raised-button
                      type="submit">
                Login
              </button>
            </div>
          </div>
        </div>

      </form>
      &lt;!&ndash; End of Login Form &ndash;&gt;
    </div>

    <div *ngIf="loginView" class="logo-image">
      <h1 class="display1"> Forgot Password?</h1>
      &lt;!&ndash; forgot password Form &ndash;&gt;
      <form (ngSubmit)="resetPassword(resetPasswordForm.value)" [formGroup]="resetPasswordForm" autocomplete="off"
            novalidate>
        <div class="row">
          <mat-form-field appearance="outline" class="material-form col-md-12">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput placeholder="Enter Your E-mail Address" type="email">
            <mat-icon matSuffix>email</mat-icon>
            <mat-error>
                        <span
                          *ngIf="!resetPasswordForm.get('email').valid && resetPasswordForm.get('email').touched">
                            Email is required filed
                        </span>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="row">
          <div class="col-md-12">
                  <span *ngIf="loading">
                      <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
                  </span>
          </div>
          <div class="col-md-12 d-flex px-0">
            <div (click)="changeView()"
                 class="float-left text-capitalize cursor-pointer order-xs-2 col-xl-7 text-start text-xs-center col-xs-12">
              <i>Back to Login page</i>
            </div>
            <div class="float-lg-right col-lg-5 order-xs-1 col-xs-12">
              <button [disabled]="!resetPasswordForm.valid || loading" class="w-100" color="primary" mat-raised-button
                      type="submit">
                Send Reset Link
              </button>
            </div>
          </div>
        </div>

      </form>
      &lt;!&ndash; End of forgot password Form &ndash;&gt;
    </div>
  </ng-template>
</app-auth-layout>
-->
