import {createSelector} from '@ngrx/store';
import {AppState} from '../../index';
import * as fromUserReducer from './user.reducer';
import {TitleCasePipe} from '@angular/common';
import {User, UserTypeEnum} from './user.model';

export const currentState = (state: AppState) => (state[fromUserReducer.usersFeatureKey]);

export const selectAllUsersFromReducer = createSelector(currentState, fromUserReducer.selectAll);

export const selectAllUsers = createSelector(
  selectAllUsersFromReducer,
  (users) => users.map((user) => {
    return {
      ...user,
      isStaff: user?.userType !== UserTypeEnum.Ministry,
      status: user?.isActive ? 'Active' : 'In Active',
      ministryName: user?.institution?.name,
      roleName: user?.role[0]?.name,
      fullName: new TitleCasePipe().transform(user.firstName + ' ' + user.middleName + ' ' + user.lastName),
    };
  })
);


export const selectAllTaskUsers = createSelector(
  selectAllUsersFromReducer,
  (users) => users.map((user) => {
    return {
      ...user,
      fullName: new TitleCasePipe().transform(user.firstName + ' ' + user.middleName + ' ' + user.lastName),
    };
  })
);


export const selectUsersByType = (isStaff: boolean) => createSelector(
  selectAllUsers,
  (users) => users.filter((user) => user.isStaff === isStaff)
);
export const selectTotalUsersByType = (isStaff: boolean) => createSelector(
  selectUsersByType(isStaff),  (users) => users.length
);

export const selectUserByUid = (uid: string) => createSelector(
  selectAllUsers,
  (users: User[]) => users.find((user) => user.uniqueId === uid)
);

export const selectUserByUsername = (email: string) => createSelector(
  selectAllUsers,
  (users: User[]) => users.find((user) => user.email === email)
);

export const selectMinistryUsers = createSelector(selectAllUsers, (allItems) =>
  allItems.filter(res => res.isStaff === false)
);
export const selectCSUsers = createSelector(selectAllUsers, (allItems) =>
  allItems.filter(res => res.isStaff === true)
);
