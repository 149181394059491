import {ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import Quill from 'quill';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';

const font = Quill.import('formats/font');
// font.whitelist = [
//   'sans-serif', 'serif', 'monospace', 'Quicksand-Bold', 'mirza', 'roboto',
//   'Roboto Condensed', 'Times New Roman', 'Calibri', 'Calibri Light',
//   'Arial', 'Courier', 'Garamond', 'Tahoma', 'Verdana', 'quicksand'
// ];
// Quill.register(font, true);

@Component({
  selector: 'app-text-editor-field',
  templateUrl: './text-editor-field.component.html',
  styleUrls: ['./text-editor-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextEditorFieldComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextEditorFieldComponent implements OnInit, OnDestroy, ControlValueAccessor {

  editorModel = [{
    attributes: {
      font
    },
    insert: 'test'
  }];

  private valueData: string;
  @Output() selectedValue = new EventEmitter<any>();
  private change = new EventEmitter<any>();

  subscriptions: Subscription = new Subscription();
  protected onDestroy = new Subject<void>();

  ngOnInit() {
  }

  get value(): string {
    return this.valueData;
  }

  onTouched(fn) {
    return fn;
  }

  onChange(fn){
    return fn;
  }


  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.subscriptions.unsubscribe();
  }

  writeValue(value: string) {
    const valueChanged = value !== this.valueData;
    if (valueChanged) {
      this.valueData = value;
      this.change.emit(value);
    }
  }

  onSelectionChange(value) {
    const valueChanged = value !== this.valueData;
    if (valueChanged) {
      this.valueData = value;
      this.onChange(value);
      this.change.emit(value);
      this.selectedValue.emit(value);
    }
  }

  onBlur(value: string) {
    this.writeValue(value);
    this.onTouched(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

}
