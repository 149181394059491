import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {PageableParam} from '../../page/page.model';
import {MinutesDecisions} from './minutes-decisions.model';

export const loadMinutesDecisionss = createAction(
  '[MinutesDecisions/API] Load MinutesDecisionss',
  props<{ minutesDecisionss: MinutesDecisions[] }>()
);

export const addMinutesDecisions = createAction(
  '[MinutesDecisions/API] Add MinutesDecisions',
  props<{ minutesDecisions: MinutesDecisions }>()
);

export const upsertMinutesDecisions = createAction(
  '[MinutesDecisions/API] Upsert MinutesDecisions',
  props<{ minutesDecisions: MinutesDecisions }>()
);

export const addMinutesDecisionss = createAction(
  '[MinutesDecisions/API] Add MinutesDecisionss',
  props<{ minutesDecisionss: MinutesDecisions[] }>()
);

export const upsertMinutesDecisionss = createAction(
  '[MinutesDecisions/API] Upsert MinutesDecisionss',
  props<{ minutesDecisionss: MinutesDecisions[] }>()
);

export const updateMinutesDecisions = createAction(
  '[MinutesDecisions/API] Update MinutesDecisions',
  props<{ minutesDecisions: Update<MinutesDecisions> }>()
);

export const updateMinutesDecisionss = createAction(
  '[MinutesDecisions/API] Update MinutesDecisionss',
  props<{ minutesDecisionss: Update<MinutesDecisions>[] }>()
);

export const deleteMinutesDecisions = createAction(
  '[MinutesDecisions/API] Delete MinutesDecisions',
  props<{ id: number }>()
);

export const deleteMinutesDecisionss = createAction(
  '[MinutesDecisions/API] Delete MinutesDecisionss',
  props<{ ids: number[] }>()
);

export const clearMinutesDecisionss = createAction(
  '[MinutesDecisions/API] Clear MinutesDecisionss'
);

export const getAllMinutesDecisionsPageable = createAction(
  '[MinutesDecisions/API] get all active MinutesDecisionss pageable',
  props<{ pageable: PageableParam, stageName: string, meetingStatus?: string}>()
);


export const getAllMinutesDecisionss = createAction(
  '[MinutesDecisions/API] Get All Meetings',
  props<{ filtering: any }>()
);

export const saveMinutesDecisions = createAction(
  '[MinutesDecisions/API] Save MinutesDecisions',
  props<{ decision: any }>()
);

export const editMinutesDecisions = createAction(
  '[MinutesDecisions/API] edit MinutesDecisions',
  props<{ decision: any }>()
);

export const removeMinutesDecisions = createAction(
  '[MinutesDecisions/API] remove Scheduled Meeting',
  props<{ decisionUniqueId: string, id: number }>()
);

export const getAllMinutesDecisions = createAction(
  '[MinutesDecisions/API] get All Minutes Decisions',
  props<{ agendaUniqueId: string }>()
);


export const saveMinutesDecisionProgress = createAction(
  '[MinutesDecisions/API] Save Minutes Decision Progress',
  props<{ input: any, agendaUniqueId: string }>()
);


export const editMinutesDecisionProgress = createAction(
  '[MinutesDecisions/API] Edit Minutes Decision Progress',
  props<{ input: any, agendaUniqueId: string }>()
);


export const deleteMinutesDecisionProgress = createAction(
  '[MinutesDecisions/API] Remove Minutes Decision Progress',
  props<{ uniqueId: string, id: string, agendaUniqueId: string }>()
);
