import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {ProjectComponentTask, ProjectComponentTaskDto} from './project-component-task.model';

export const loadProjectComponentTasks = createAction(
  '[ProjectComponentTask/API] Load ProjectComponentTasks',
  props<{ projectComponentTasks: ProjectComponentTask[] }>()
);

export const addProjectComponentTask = createAction(
  '[ProjectComponentTask/API] Add ProjectComponentTask',
  props<{ projectComponentTask: ProjectComponentTask }>()
);

export const upsertProjectComponentTask = createAction(
  '[ProjectComponentTask/API] Upsert ProjectComponentTask',
  props<{ projectComponentTask: ProjectComponentTask }>()
);

export const addProjectComponentTasks = createAction(
  '[ProjectComponentTask/API] Add ProjectComponentTasks',
  props<{ projectComponentTasks: ProjectComponentTask[] }>()
);

export const upsertProjectComponentTasks = createAction(
  '[ProjectComponentTask/API] Upsert ProjectComponentTasks',
  props<{ projectComponentTasks: ProjectComponentTask[] }>()
);

export const updateProjectComponentTask = createAction(
  '[ProjectComponentTask/API] Update ProjectComponentTask',
  props<{ projectComponentTask: Update<ProjectComponentTask> }>()
);

export const updateProjectComponentTasks = createAction(
  '[ProjectComponentTask/API] Update ProjectComponentTasks',
  props<{ projectComponentTasks: Update<ProjectComponentTask>[] }>()
);

export const deleteProjectComponentTask = createAction(
  '[ProjectComponentTask/API] Delete ProjectComponentTask',
  props<{ id: number }>()
);

export const deleteProjectComponentTasks = createAction(
  '[ProjectComponentTask/API] Delete ProjectComponentTasks',
  props<{ ids: string[] }>()
);

export const clearProjectComponentTasks = createAction(
  '[ProjectComponentTask/API] Clear ProjectComponentTasks'
);

export const saveProjectComponentTask = createAction(
  '[ProjectComponentTask/API] create projectComponentTask',
  props<{ formData: ProjectComponentTaskDto }>()
);

export const editProjectComponentTask = createAction(
  '[ProjectComponentTask/API] edit projectComponentTask',
  props<{ formData: ProjectComponentTaskDto }>()
);

export const removeProjectComponentTask = createAction(
  '[ProjectComponentTask/API] remove projectComponentTask',
  props<{ uid: string, id: number }>()
);
