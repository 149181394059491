import {createSelector} from '@ngrx/store';
import * as fromCommitteeReducer from '../committee/committee.reducer';
import {committeesFeatureKey} from './committee.reducer';
import {AppState} from '../../index';
import {TitleCasePipe} from '@angular/common';
import {ReplacePipe} from '../../../../shared/pipes/replace.pipe';

export const currentCommitteeState = (state: AppState) => state[committeesFeatureKey];

export const selectAllCommitteesFromReducer = createSelector(currentCommitteeState, fromCommitteeReducer.selectAll);

export const selectAllCommittees = createSelector(
  selectAllCommitteesFromReducer,
  (committees) => committees.map((res) => {
    let leader = res?.members?.find(m => m.isLeader)
    return {
      ...res,
      leader,
      leaderName: new TitleCasePipe().transform(leader?.member?.firstName + ' ' + leader?.member?.lastName),
    };
  })
);

export const selectCommitteeByUid = (uid: string) => createSelector(
  selectAllCommittees,
  (committees) => committees.find((committee) => committee.uniqueId === uid)
);
