import {Injectable} from '@angular/core';
import {MenuItem} from '../shared/interfaces/data.interfaces';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor() { }

  get mainMenu(): MenuItem[] {
    return [
      {
        name: 'Cabinet Papers',
        icon: 'icon_applications',
        iconType: 'SVG',
        description: 'module-paper-description',
        route: '/document',
        class: 'card-paper',
        permissions: ['can_view_papers']
      },
      {
        name: 'Meetings',
        icon: 'icon_people',
        iconType: 'SVG',
        description: 'module-meeting-description',
        route: '/meetings',
        class: 'card-meeting',
        permissions: ['can_view_meeting']
      },
      {
        name: 'Task',
        icon: 'icon_task',
        iconType: 'SVG',
        description: 'module-task-description',
        route: '/task',
        class: 'card-task',
        permissions: ['can_view_task']
      },
      {
        name: 'Projects',
        icon: 'icon_stock',
        iconType: 'SVG',
        description: 'module-project-description',
        route: '/project',
        class: 'card-project',
        permissions: ['can_view_project']
      },
      {
        name: 'Reports',
        icon: 'icon_report',
        iconType: 'SVG',
        description: 'module-report-description',
        route: '/report',
        class: 'card-report',
        permissions: ['can_view_reports']
      },
      {
        name: 'Settings',
        icon: 'settings',
        iconType: 'SVG',
        description: 'module-setting-description',
        route: '/setting',
        class: 'card-setting',
        permissions: ['can_manage_settings']
      },
    ];
  }

  get ministryMainMenu(): MenuItem[] {
    return [
      {
        name: 'Paper & Meeting',
        icon: 'receipt_long',
        iconType: 'MATERIAL',
        description: 'module-ministry-paper-description',
        route: '/portal-meeting',
        class: 'card-meeting',
        permissions: ['can_register_ministry_papers', 'can_view_ministry_meeting', 'can_view_ministry_papers']
      },
      {
        name: 'Projects',
        icon: 'icon_stock',
        iconType: 'SVG',
        description: 'module-ministry-project-description',
        route: '/portal-project',
        class: 'card-project',
        permissions: ['can_manage_ministry_project', 'can_view_ministry_project']
      },
    ];
  }
}

