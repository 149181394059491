import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TableColumnSelectionService {
  private data = new BehaviorSubject<any>(null);
  currentData = this.data.asObservable();

  changeData(message: any) {
    this.data.next(message);
  }
}
