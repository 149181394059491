import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../index';
import {NotificationService} from '../../../../services/notification.service';
import {
  createAttachmentConfig,
  deleteAttachmentConfig,
  editAttachmentConfig,
  getAllAttachmentConfigs,
  loadAttachmentConfigs,
  removeAttachmentConfig,
  upsertAttachmentConfig
} from './attachment-configuration.actions';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {
  DELETE_ATTACHMENT_CONFIG,
  GET_ATTACHMENT_CONFIG,
  SAVE_ATTACHMENT_CONFIG,
  UPDATE_ATTACHMENT_CONFIG
} from './attachment-configuration.graphql';

@Injectable()
export class AttachmentConfigurationEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllAttachmentConfigs),
    switchMap(() => {
      return this.apollo.query({
        query: GET_ATTACHMENT_CONFIG
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadAttachmentConfigs({attachmentConfigs: result?.data}));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(createAttachmentConfig),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_ATTACHMENT_CONFIG,
        variables: { inputDto: action.attachmentConfigDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertAttachmentConfig({attachmentConfig: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editAttachmentConfig),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_ATTACHMENT_CONFIG,
        variables: { inputDto: action.attachmentConfigDto }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertAttachmentConfig({attachmentConfig: result?.data}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  remove = createEffect(() => this.actions$.pipe(
    ofType(removeAttachmentConfig),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_ATTACHMENT_CONFIG,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000){
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteAttachmentConfig({id: result?.response?.id}));
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private apollo: Apollo
  ) {
  }
}
