import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {User} from './user.model';

export const loadUsers = createAction(
  '[User/API] Load Users',
  props<{ users: User[] }>()
);

export const addUser = createAction(
  '[User/API] Add User',
  props<{ user: User }>()
);

export const upsertUser = createAction(
  '[User/API] Upsert User',
  props<{ user: User }>()
);

export const addUsers = createAction(
  '[User/API] Add Users',
  props<{ users: User[] }>()
);

export const upsertUsers = createAction(
  '[User/API] Upsert Users',
  props<{ users: User[] }>()
);

export const updateUser = createAction(
  '[User/API] Update User',
  props<{ user: Update<User> }>()
);

export const updateUsers = createAction(
  '[User/API] Update Users',
  props<{ users: Update<User>[] }>()
);

export const deleteUser = createAction(
  '[User/API] Delete User',
  props<{ id: number }>()
);

export const deleteUsers = createAction(
  '[User/API] Delete Users',
  props<{ ids: number[] }>()
);

export const clearUsers = createAction(
  '[User/API] Clear Users'
);


export const getUserRoleAndPermissions = createAction(
  '[User/API] get user roles and permissions',
  props<{ redirectHome: boolean }>()
);

export const changePassword = createAction(
  '[User/API] change loggedIn password',
  props<{ oldPassword: string, newPassword: string, confirmPassword: string }>()
);

export const getAllUsers = createAction(
  '[User/API] get all users',
  props<{ userUid?: string }>()
);

export const getSystemUsers = createAction(
  '[User/API] get all system users',
  props<{ filtering?: any }>()
);

export const getUserByUid = createAction(
  '[User/API] get User By Uid',
  props<{ uid: string }>()
);

export const getMinistryUsersById = createAction(
  '[User/API] get ministry Users By id',
  props<{ id: number }>()
);

export const getUsersOfTaskAssignedDesignation = createAction(
  '[User/API] get users of task assigned designation'
);

export const saveUser = createAction(
  '[User/API] create User',
  props<{ userDto: any }>()
);

export const editUser = createAction(
  '[User/API] edit User',
  props<{ userDto: any }>()
);

export const transferUser = createAction(
  '[User/API] transfer User',
  props<{ input: any }>()
);

export const activateDeactivateUser = createAction(
  '[User/API] activate & deactivate User',
  props<{ isActive: boolean, uniqueId: string }>()
);

export const getUsersToBeHandedOver = createAction(
  '[User/API] getUsersToBeHandedOver',
  props<{ roleUniqueId: string }>()
);

export const handOverDuty = createAction(
  '[User/API] hand over duty',
  props<{ inputDto: { onBehalf: boolean, handingOverUser: string, receiver: string, permissionsList: string[] } }>()
);

export const returnDuty = createAction(
  '[User/API] return duty'
);

export const revokeToken = createAction(
  '[User/API] revoke user token',
  props<{ token: string }>()
);

export const assignUsersToHandOverGroup = createAction(
  '[User/API] assignUserToHandoverGroup',
  props<{ handoverGroup: { roleUniqueId: string, usersList: string[] } }>()
);

export const removeUserFromHandOverGroup = createAction(
  '[User/API] removeUserFromHandOverGroup',
  props<{ inputDto: { roleUniqueId: string, user: string }, userId: number }>()
);

export const refreshToken = createAction(
  '[User/API] refresh token',
  props<{ refreshToken: string }>()
);


export const sendResetPasswordLink = createAction(
  '[User/API] sendResetPasswordLink',
  props<{ email: string }>()
);

export const resetPassword = createAction(
  '[User/API] resetPassword',
  props<{ password: string, confirmPassword: string, token: string }>()
);

export const unlockUserAccount = createAction(
  '[User/API] unlockUserAccountMutation',
  props<{ uid: string }>()
);


export const saveDeviceLocation = createAction(
  '[User/API] saveDeviceLocation',
  props<{ inputDto: any }>()
);


