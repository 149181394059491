import {createSelector} from '@ngrx/store';
import * as fromDocumentComment from './document-comment.reducer';
import {AppState} from '../../index';

export const currentState = (state: AppState) => (state[fromDocumentComment.documentCommentsFeatureKey]);

export const selectDocumentCommentsFromReducer = createSelector(currentState, fromDocumentComment.selectAll);
export const selectEntities = createSelector(currentState, fromDocumentComment.selectEntities);

export const selectDocumentComments = createSelector(
  selectDocumentCommentsFromReducer,
  (documentComments) => {
    return documentComments;
  });

