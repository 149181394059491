import gql from 'graphql-tag';
import {userField} from '../user/user.graphql';
import {responseField} from '../../base.graphql';

export const GET_ALL_USERS_BY_FORUM_STAGE = gql`
  query getAllForumsAndMembers($uid: ForumTypeEnum) {
    getAllForumsAndMembers(forum: $uid) {
      response {
        code
        message
      }
      data {
        members{
          ${userField}
        }
      }
    }
  }
`;

export const SAVE_USERS_BY_FORUM_STAGE = gql`
  mutation addForumsMembersManagement($inputDto: ForumMemberInputObjects!) {
    addForumsMembersManagement(members: $inputDto){
      response{
        ${responseField}
      }
      data {
        members{
          ${userField}
        }
      }
    }
  }
`;

export const REMOVE_USERS_BY_FORUM_STAGE = gql`
  mutation removeForumsMembersManagement($inputDto: RemoveForumMemberInputObjects!) {
    removeForumsMembersManagement(member: $inputDto){
      response{
        ${responseField}
      }
    }
  }
`;
