<div class="marquee z-index-0" *ngIf="!isProduction">
  <div class="marquee-text" *ngFor="let i of [1,2,3,4,5,6,7,8]">
    <span>DEMO</span>
  </div>
</div>

<ng-container *ngIf="authUser$ | async as authUser">
  <div class="alert bg-warning text-dark text-center d-flex justify-content-center" role="alert"
       *ngIf="authUser.userType !== userTypeEnum.Staff && authUser.userType !== userTypeEnum.Ministry">
    <span class="material-symbols-outlined">info</span>
    <span class="mb-0 pt-1">
    {{'No User Profile detail found' | concat | translate}}
    <span class="text-secondary cursor-pointer fst-italic" (click)="authService.logout()">{{'Log out' | concat | translate}}</span>
  </span>
  </div>

  <div class="main-landing-page d-flex align-items-center" *ngIf="authUser.userType === userTypeEnum.Staff || authUser.userType === userTypeEnum.Ministry">

    <div class="container main">

      <div class="row align-items-center ">
        <div class="col-md-6 text-center text-md-start mb-2 order-2 order-md-1">
          <div class="row align-items-center">
            <div class="col-2">
              <a href="/home" class="d-block p-3 text-decoration-none text-center">
                <img src="assets/images/emblem.png" class="w-100" alt="emblem">
              </a>
            </div>

            <div class="col-10">
              <p class="my-0 welcome-color">
                <span class=""> {{'the united republic of tanzania' | concat | translate}}  </span>
              </p>
              <h4 class="my-0">
                <span> {{'ecabinet' | concat | translate}} </span>
              </h4>
            </div>
          </div>

        </div>

        <div class="col-md-6 justify-content-end d-flex text-white order-1 order-md-2 mt-2 mt-md-0">
          <ng-container *ngIf="authUser$ | async as authUser">
            <app-top-right-toolbar [isLanding]="true" [loggedUser]="authUser" [loggedInUserType]="authUser?.userType">
            </app-top-right-toolbar>
          </ng-container>
        </div>
      </div>

      <div class="row row-cols-1 row-cols-md-3 row-cols-lg-4 align-items-stretch g-4 py-md-5 py-2">

        <ng-container *ngFor="let module of moduleMenu; let i = index">
          <ng-container *ngIf="(module?.permissions != undefined) && (module?.permissions.length > 0)">

            <ng-container *ngxPermissionsOnly="module?.permissions">
              <div class="col move-up" style="{{ '--order: ' + (i + 1) * 1.25 }}">
                <a
                  class="nav-link card {{ module?.class }} menu-card h-100 overflow-hidden text-bg-primary rounded-4 border-0 shadow-lg {{ isLoadingLiveMeeting? 'cursor-not-allowed': 'cursor-pointer' }}"
                  [href]="!isLoadingLiveMeeting ? module.route : 'javascript:void(0)'">
                  <div class="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                    <h5 class="lh-1 fw-bold">{{ module?.name | concat | translate }}</h5>
                    <h6 class="mb-4">{{ module?.description | concat | translate }}</h6>
                    <ul class="d-flex list-unstyled mt-auto">
                      <li class="d-flex align-items-center me-3">
                        <mat-icon *ngIf="module?.iconType === IconTypeEnum.MATERIAL">{{ module.icon }}</mat-icon>
                        <mat-icon *ngIf="module?.iconType === IconTypeEnum.SVG" svgIcon="{{ module.icon }}"></mat-icon>
                      </li>
                    </ul>
                  </div>
                </a>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

      </div>

    </div>

  </div>

</ng-container>

