import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {ProjectComponent, ProjectComponentDto} from './project-component.model';

export const loadProjectComponents = createAction(
  '[ProjectComponent/API] Load ProjectComponents',
  props<{ projectComponents: ProjectComponent[] }>()
);

export const addProjectComponent = createAction(
  '[ProjectComponent/API] Add ProjectComponent',
  props<{ projectComponent: ProjectComponent }>()
);

export const upsertProjectComponent = createAction(
  '[ProjectComponent/API] Upsert ProjectComponent',
  props<{ projectComponent: ProjectComponent }>()
);

export const addProjectComponents = createAction(
  '[ProjectComponent/API] Add ProjectComponents',
  props<{ projectComponents: ProjectComponent[] }>()
);

export const upsertProjectComponents = createAction(
  '[ProjectComponent/API] Upsert ProjectComponents',
  props<{ projectComponents: ProjectComponent[] }>()
);

export const updateProjectComponent = createAction(
  '[ProjectComponent/API] Update ProjectComponent',
  props<{ projectComponent: Update<ProjectComponent> }>()
);

export const updateProjectComponents = createAction(
  '[ProjectComponent/API] Update ProjectComponents',
  props<{ projectComponents: Update<ProjectComponent>[] }>()
);

export const deleteProjectComponent = createAction(
  '[ProjectComponent/API] Delete ProjectComponent',
  props<{ id: number }>()
);

export const deleteProjectComponents = createAction(
  '[ProjectComponent/API] Delete ProjectComponents',
  props<{ ids: string[] }>()
);

export const clearProjectComponents = createAction(
  '[ProjectComponent/API] Clear ProjectComponents'
);

export const getProjectComponentByUid = createAction(
  '[ProjectComponent/API] get projectComponent by uid',
  props<{ uid: string }>()
);

export const saveProjectComponent = createAction(
  '[ProjectComponent/API] create projectComponent',
  props<{ formData: ProjectComponentDto }>()
);

export const editProjectComponent = createAction(
  '[ProjectComponent/API] edit projectComponent',
  props<{ formData: ProjectComponentDto }>()
);

export const removeProjectComponent = createAction(
  '[ProjectComponent/API] remove projectComponent',
  props<{ uid: string, id: number }>()
);
