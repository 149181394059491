import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';
import {documentField} from '../../document/document.graphql';

export const folderCategoryField = `
  ${baseField}
  name
  canBeDeleted
`;

export const GET_FOLDER_CATEGORY = gql`
  query getAllFoldersList($uid: String, $withPapers: Boolean!) {
    getAllFoldersList(folderUniqueId: $uid) {
      response{
        ${responseField}
      }
      data{
        ${folderCategoryField}
        folderPapers @include(if: $withPapers) {
          ${documentField}
        }
      }
    }
  }
`;

export const SAVE_FOLDER_CATEGORY = gql`
  mutation createFolders($inputDto: NewFolderInputObjects!) {
    createFolders(folder: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${folderCategoryField}
      }
    }
  }
`;

export const UPDATE_FOLDER_CATEGORY = gql`
  mutation updateFolders($inputDto: FolderInputObjects!) {
    updateFolders(folder: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${folderCategoryField}
      }
    }
  }
`;

export const DELETE_FOLDER_CATEGORY = gql`
  mutation deleteFolders($uid: String!) {
    deleteFolders(uniqueId: $uid){
      response{
        ${responseField}
      }
    }
  }
`;
