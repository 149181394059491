import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {DocumentStage, DocumentStageDto} from './document-stage.model';

export const loadDocumentStages = createAction(
  '[DocumentStage/API] Load DocumentStages',
  props<{ documentStages: DocumentStage[] }>()
);

export const addDocumentStage = createAction(
  '[DocumentStage/API] Add DocumentStage',
  props<{ documentStage: DocumentStage }>()
);

export const upsertDocumentStage = createAction(
  '[DocumentStage/API] Upsert DocumentStage',
  props<{ documentStage: DocumentStage }>()
);

export const addDocumentStages = createAction(
  '[DocumentStage/API] Add DocumentStages',
  props<{ documentStages: DocumentStage[] }>()
);

export const upsertDocumentStages = createAction(
  '[DocumentStage/API] Upsert DocumentStages',
  props<{ documentStages: DocumentStage[] }>()
);

export const updateDocumentStage = createAction(
  '[DocumentStage/API] Update DocumentStage',
  props<{ documentStage: Update<DocumentStage> }>()
);

export const updateDocumentStages = createAction(
  '[DocumentStage/API] Update DocumentStages',
  props<{ documentStages: Update<DocumentStage>[] }>()
);

export const deleteDocumentStage = createAction(
  '[DocumentStage/API] Delete DocumentStage',
  props<{ id: number }>()
);

export const deleteDocumentStages = createAction(
  '[DocumentStage/API] Delete DocumentStages',
  props<{ ids: string[] }>()
);

export const clearDocumentStages = createAction(
  '[DocumentStage/API] Clear DocumentStages'
);

export const getAllDocumentStages = createAction(
  '[DocumentStages/API] List All DocumentStages'
);

export const actionDocumentStage = createAction(
  '[DocumentStage/API] create, edit & delete documentStage',
  props<{ documentStageDto: DocumentStageDto }>()
);
