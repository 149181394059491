import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';
import {projectComponentTaskField} from '../project-component-task/project-component-task.graphql';

export const projectComponentField = `
  ${baseField}
  name
  budget
  location
  duration
  manager
  percentCompositionOnProject
  startDate
  handoverDate
  details
  status
  active
  recordedOn
  status
  projectTasks{
    ${projectComponentTaskField}
  }
`;


export const GET_PROJECT_COMPONENT_BY_UID = gql`
  query allProjectComponents($uid: String) {
    allProjectComponents(uniqueId: $uid){
      response{
        ${responseField}
      }
      data{
        ${projectComponentField}
      }
    }
  }
`;

export const SAVE_PROJECT_COMPONENT = gql`
  mutation createProjectComponentMutation($inputDto: ProjectComponentInputObjects!) {
    createProjectComponentMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectComponentField}
      }
    }
  }
`;

export const UPDATE_PROJECT_COMPONENT = gql`
  mutation updateProjectComponentMutation($inputDto: ProjectComponentInputObjects!) {
    updateProjectComponentMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectComponentField}
      }
    }
  }
`;

export const DELETE_PROJECT_COMPONENT = gql`
  mutation deleteProjectComponentMutation($uid: String!) {
    deleteProjectComponentMutation(uniqueId: $uid){
      response{
        ${responseField}
      }
    }
  }
`;

