import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const recommendationField = `
  ${baseField}
  isActive
  date
  descriptions
`;

export const GET_RECOMMENDATIONS = gql`
query GetAllRecommendations($agendaUniqueId: String) {
  getAllRecommendations(agendaUniqueId: $agendaUniqueId) {
    response {
      ${responseField}
    }
    data {
      ${recommendationField}
    }
  }
}
`;


export const SAVE_RECOMMENDATION = gql`
  mutation addPaperRecommendations($recommendation: PaperRecommendationsInputObjects!) {
    addPaperRecommendations(recommendation: $recommendation){
      response{
        ${responseField}
      }
      data{
        ${recommendationField}
      }
    }
  }
`;

export const UPDATE_RECOMMENDATION = gql`
  mutation updatePaperRecommendations($recommendation: PaperRecommendationsInputObjects!) {
    updatePaperRecommendations(recommendation: $recommendation){
      response{
        ${responseField}
      }
      data{
        ${recommendationField}
      }
    }
  }
`;

export const DELETE_RECOMMENDATION = gql`
  mutation deletePaperRecommendations($uniqueId: String!) {
    deletePaperRecommendations(uniqueId: $uniqueId){
      response{
        ${responseField}
      }
    }
  }
`;
