import {Component, inject, Input, OnInit} from '@angular/core';
import {MenuItem} from '../../../interfaces/data.interfaces';
import {UserTypeEnum} from '../../../../store/entities/settings/user/user.model';
import {StorageService} from '../../../../services/storage-service.service';
import {MenuService} from '../../../../services/menu.service';
import {SettingsService} from '../../../../services/settings.service';
import {AuthService} from '../../../../services/auth.service';
import {Router} from '@angular/router';
import {NgxPermissionsService} from 'ngx-permissions';
import {IconTypeEnum} from '../../../enums/data.enums';
import {Institution} from '../../../../store/entities/settings/institution/institution.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input() isPortalModule = false;
  @Input() sidebarMenu: MenuItem[];
  @Input() moduleTitle: string;
  @Input() moduleIconType: IconTypeEnum;
  @Input() moduleIconTitle: string;

  menuData: MenuItem[];
  mainMenu: MenuItem[];

  permissionService = inject(NgxPermissionsService);
  storageService = inject(StorageService);
  settingsService = inject(SettingsService);
  menuService = inject(MenuService);
  auth = inject(AuthService);
  router = inject(Router);

  userPermissions = this.permissionService.getPermissions();
  loggedInUserType = this.storageService.get('category');
  loggedInInstitution: Institution = this.storageService.get('institution');

  ngOnInit(): void {
    this.menuData = this.sidebarMenu;
    this.mainMenu = this.loggedInUserType === UserTypeEnum.Staff ? this.menuService.mainMenu : this.menuService.ministryMainMenu;
  }

  logout() {
    this.auth.logout();
    return this.router.navigate(['/']);
  }

  getChildrenPermission(children: MenuItem[]): boolean {
    let permissionsExist = [];
    children?.forEach( c => permissionsExist.push(...c?.permissions));
    return this.has(Object.keys(this.userPermissions), permissionsExist);
  }

  isActiveRoute(children: MenuItem[]) {
    return children?.findIndex( d => d?.route === this.router?.url) > -1;
  }

  protected has(arr: string[], valuesToCheck: string[]): boolean {
    for (const value of valuesToCheck) {
      if (arr.includes(value)) {
        return true;
      }
    }
    return false;
  }

  protected readonly IconTypeEnum = IconTypeEnum;
}
