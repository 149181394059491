<mat-sidenav-container class="sidenav-container">

  <mat-sidenav-content>
    <mat-toolbar class="mat-elevation-z6" color="primary">
      <button mat-icon-button routerLink="/home" type="button">
        <img alt="" class="w-100 mr-2" src="/assets/images/emblem.png">
        <span>{{'eCabinet' | concat | translate}}</span>
      </button>

      <span class="spacer"></span>

      <app-top-right-toolbar></app-top-right-toolbar>

    </mat-toolbar>


    <div class="top-120 container">
      <div class="error-box row">

          <div class="col-md-8 mx-auto text-center mt-3">
            <h1 class="zoom-area">
              {{'you have handed over' | concat | translate}}
            </h1>
            <div class="link-container mt-2">
              <button (click)="returnDuty()" class="more-link" mat-raised-button>{{'Return to Office' | concat | translate}}</button>
            </div>
          </div>

      </div>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>

