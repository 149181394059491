import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';
import {minutesDecisionsField} from '../minutes-decisions/minutes-decisions.graphql';
import {minutesDirectivesField} from '../minutes-directives/minutes-directives.graphql';
import {minutesNotesField} from '../minutes-notes/minutes-notes.graphql';
import {recommendationField} from '../recommendations/recommendations.graphql';
import {attachmentField} from "../../settings/attachment/attachment.graphql";


export const meetingAgendaField = `
  ${baseField}
  title
  number
  isActive
  isPaper
  agendaStartTime
  attachments{
    ${attachmentField}
  }
  recommendations{
    ${recommendationField}
  }
  minuties{
    decisions{
      ${minutesDecisionsField}
    }
    directives{
      ${minutesDirectivesField}
    }
    notes{
      ${minutesNotesField}
    }
  }
  paper{
    id
    uniqueId
    title
    attachments{
      ${attachmentField}
    }
    paperHasReview
    paperReviewsComments {
      id
      uniqueId
      reviewedByCc
      comments
    }
    committee {
      name
      members {
        uniqueId
        isLeader
        member {
          uniqueId
          email
        }
      }
    }
  }
  attachedMinuties {
    id
    uniqueId
    attachmentName
    base64String
    isStamped
  }
`;

export const GET_MEETING_AGENDAS = gql`
  query getAllMeetings($filtering: MeettingSearchingInputObjects) {
    getAllMeetings(filtering: $filtering) {
      response{
        ${responseField}
      }
      data{
        ${meetingAgendaField}
      }
    }
  }
`;

export const GET_MEETING_AGENDA_BY_STAGE = gql`
  query getPapersByStageList($inputDto: PaperStageTypeEnum) {
    getPapersByStageList(stage: $inputDto) {
      response{
        ${responseField}
      }
      data{
        ${meetingAgendaField}
      }
    }
  }
`;
export const GET_MEETING_AGENDA_BY_MEETING = gql`
  query getMeetingsAgendas($meetingUniqueId: String) {
    getMeetingsAgendas(meetingUniqueId: $meetingUniqueId) {
      response{
        ${responseField}
      }
      data{
        ${meetingAgendaField}
      }
    }
  }
`;
export const GET_MINUTES_BY_PAPER = gql`
  query getMinutesByPaper($paperUniqueId: String) {
    getMinutesByPaper(paperUniqueId: $paperUniqueId) {
      response{
        ${responseField}
      }
      data{
        ${meetingAgendaField}
      }
    }
  }
`;

export const SAVE_MEETING_AGENDA = gql`
  mutation addMeetingAgendas($agenda: MeettingAgendaInputObjects!) {
    addMeetingAgendas(agenda: $agenda){
      response{
        ${responseField}
      }
      data{
        ${meetingAgendaField}
      }
    }
  }
`;


export const UPLOAD_SIGNED_MEETING_MINUTES = gql`
  mutation UploadSignedMeetingMinutes($input: SignedMinutesAttachmentInputObjects) {
    uploadSignedMeetingMinutes(input: $input) {
      response {
        ${responseField}
      }
      data {
        ${meetingAgendaField}
      }
    }
  }
`;

export const UPDATE_MEETING_AGENDA = gql`
  mutation updateMeetingAgendas($agenda: MeettingAgendaInputObjects!) {
    updateMeetingAgendas(agenda: $agenda){
      response{
        ${responseField}
      }
      data{
        ${meetingAgendaField}
      }
    }
  }
`;

export const DELETE_MEETING_AGENDA = gql`
  mutation deleteMeetingAgendas($uniqueId: String) {
    deleteMeetingAgendas(uniqueId: $uniqueId){
      response{
        ${responseField}
      }
    }
  }
`;


export const DELETE_AGENDA_ATTACHMENT = gql`
mutation DeleteAgendasAttachments($uniqueId: String) {
  deleteAgendasAttachments(uniqueId: $uniqueId) {
    response{
        ${responseField}
    }
    data{
        ${meetingAgendaField}
    }
  }
}
`;


export const PRINT_MEETING_MINUTES_SUMMARY = gql`
mutation PrintMeetingMinutesSummary($meetingUniqueId: String!) {
  printMeetingMinutesSummary(meetingUniqueId: $meetingUniqueId) {
    data {
      attachmentName
      base64
    }
  }
}
`


export const PRINT_MEETING_AGENDA_SUMMARY = gql`
mutation PrintMeetingAgendaSummary($meetingUniqueId: String!) {
  printMeetingAgendaSummary(meetingUniqueId: $meetingUniqueId) {
    data {
      attachmentName
      base64
    }
  }
}
`


export const PRINT_MEETING_MINISTRY_EXTRACT_SUMMARY = gql`
mutation PrintMeetingMinistryExtractSummary($input: MeetingMinistryExtractInputObject) {
  printMeetingMinistryExtractSummary(input: $input) {
    data {
      attachmentName
      base64
    }
  }
}
`

export const SET_AGENDA_TIME = gql`
  mutation SetAgendaTime($input: AgendaStartTimeInputObjects!) {
    setAgendaTime(input: $input) {
      response{
        ${responseField}
      }
      data{
        ${meetingAgendaField}
      }
    }
  }
`

