import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const meetingLocationField = `
  ${baseField}
  name
`;

export const GET_MEETING_LOCATION = gql`
  query getMeetingLocations {
    getMeetingLocations {
      response{
        ${responseField}
      }
      data{
        ${meetingLocationField}
      }
    }
  }
`;

export const SAVE_MEETING_LOCATION = gql`
  mutation meetingLocation($inputDto: MeetingLocationInputObjects!) {
    meetingLocation(location: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${meetingLocationField}
      }
    }
  }
`;
