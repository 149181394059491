import gql from 'graphql-tag';
import {baseField, responseField} from '../../base.graphql';

export const projectRepositoryField = `
  ${baseField}
  file{
    uniqueId
    attachmentName
    base64String
  }
`;


export const GET_PROJECT_REPOSITORY_PROJECT_UID = gql`
  query allProjectRepositories($uid: String!) {
    allProjectRepositories(projectUniqueId: $uid){
      response{
        ${responseField}
      }
      data{
        ${projectRepositoryField}
      }
    }
  }
`;

export const SAVE_PROJECT_REPOSITORY = gql`
  mutation createProjectRepositoryMutation($inputDto: ProjectRepositoryDocumentsInputObjects!) {
    createProjectRepositoryMutation(input: $inputDto){
      response{
        ${responseField}
      }
      data{
        ${projectRepositoryField}
      }
    }
  }
`;

export const DELETE_PROJECT_REPOSITORY = gql`
  mutation deleteProjectRepositoryMutation($uid: String!) {
    deleteProjectRepositoryMutation(uniqueId: $uid){
      response{
        ${responseField}
      }
    }
  }
`;

